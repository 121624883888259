import {
  Routes,
  Route,
  NavLink,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import { createPortal } from "react-dom";
import Home from "./Pages/home";
import Credentials from "./Pages/credetinals";
import Header from "./Templates/Header";
import Footer from "./Templates/Footer";
import SideMenu from "./Templates/Side-menu";
import ChooseEventType from "./Pages/choose-event-type";
import CreateEvent from "./Pages/create-event";
import EventCreated from "./Pages/event-created";
import EditProfile from "./Pages/edit-profile";
import ContactUs from "./Pages/contact-us";
import TermsPage from "./Pages/terms-page";
import ConditionsPage from "./Pages/conditions-page";
import Reminders from "./Pages/reminders";
import ExplainingSteps from "./Pages/explaining-steps";
import SendPresent from "./Pages/send-present";
import ChoosePresentOrMoney from "./Pages/choose-present-or-money";
import SuccessfullPayment from "./Pages/successful-payment";
import Cart from "./Pages/cart";
import MyBank from "./Pages/MyBank";
import EventsHistory from "./Pages/events-history";
import MyEvents from "./Pages/my-events";
import AllCategories from "./Pages/all-categories";
import Category from "./Pages/category";
import Products from "./Pages/products";
import ProductPage from "./Pages/single-product";
import ItemAddedToCartPopup from "./Popups/item-added-to-cart-popup";
import ImagePopup from "./Components/ImagePopup";
import axios from "axios";
import ExplainingSteps2 from "./Pages/explaining-steps-2";
import ExplainingStepsReferal from "./Pages/explaining-steps-referal";
import UpdateEvent from "./Pages/updage-wishlist";
import MyBankAllCategories from "./Pages/my-bank-all-categories";
import MyBankCategory from "./Components/MyBank/MyBankCategory";
import MyBankProducts from "./Pages/mybank-products";
import SuccessfullPaymentReferal from "./Pages/successful-payment-referal";
import SuccessfullPaymentMyBank from "./Pages/successful-payment-my-bank";
import { API_DOMAIN } from "./Libs/config";
import WishlistCategories from "./Pages/wishlist-categories";
import ProductsByCategories from "./Pages/ProductsByCategories";
import ProductsNewEvent from "./Pages/ProductsNewEvent";
import ProductPageNewEvent from "./Pages/single-product-new-event";
import CategoryNewEvent from "./Pages/category-new-event";
import { useClickOutsideMulti } from "./hooks/useClickOutside";

const languageToggle = {
  sendMoney: {
    english: {
      btn1: "I want to add a greeting card",
      btn2: "Transfer me to payment",
      btn3: "Approval",
      btn4: "Continue",
      btn5: "Continue",
    },
    hebrew: {
      btn1: "אני רוצה להוסיף כרטיס ברכה",
      btn2: "העבר אותי לתשלום",
      btn3: "אישור",
      btn4: "המשךз",
      btn5: "המשך",
    }
  },
  sendPresent: {
    english: {
      btn1: "Approval",
      btn2: "Approval",
      btn3: "Continue",
      btn4: "Continue",
    },
    hebrew: {
      btn1: "אישור",
      btn2: "אישור",
      btn3: "המשך",
      btn4: "המשך",
    }
  },
  byPresentForGiftMoney: {
    english: {
      btn1: "Approval",
      btn2: "Approval",
      btn3: "Continue",
      btn4: "Continue",
    },
    hebrew: {
      btn1: "אישור",
      btn2: "אישור",
      btn3: "המשך",
      btn4: "המשך",
    }
  },
  createEventBtnsLanguage: {
    english: {
      btn1: "for viewing and approval",
      btn2: "Approval",
      btn3: "editing",
      btn4: "To invite friends to the wish list",
    },
    hebrew: {
      btn1: "לצפיה ואישור",
      btn2: "אישור",
      btn3: "עריכה",
      btn4: "להזמנת חברים לרשימת המשאלות",
    }
  },
  loginBtnsLanguage: {
    english: {
      btn1: "Send me a code",
      btn2: "Entrance",
    },
    hebrew: {
      btn1: "שלחו לי קוד",
      btn2: "כניסה",
    }
  },
  registrationBtnsLanguage: {
    english: {
      btn1: "Send me a code",
      btn2: "Entrance",
    },
    hebrew: {
      btn1: "שלחו לי קוד",
      btn2: "כניסה",
    }
  },
  newEvent: {
    english: {
      btn1: "for viewing and approval",
      btn2: "Approval",
      btn3: "Edit",
      btn4: "To invite friends to the wish list",
    },
    hebrew: {
      btn1: "לצפיה ואישור",
      btn2: "אישור",
      btn3: "עריכה",
      btn4: "להזמנת חברים לרשימת המשאלות",
    }
  }
}

function App() {
  const navigate = useNavigate();
  const exceptionListWishlist = [
    '/my-events',
    '/create-event',
    '/all-categories-new-event',
    '/category-new-event',
    '/products-new-event',
    '/credentials-new-wishlist/',
  ]
  const isSidemenuVisible = exceptionListWishlist.includes(window.location.href)
  const [isPopup, setPopup] = useState(false);
  const [isFilterOpen, setFilterOpen] = useState(false);
  const [isBurgerOpen, setBurgerOpen] = useState(false);
  const isUserNotFirstTime = Boolean(localStorage.getItem("userNotFirstTime"));
  const [userNotFirstTime, setUserNotFirstTime] = useState(isUserNotFirstTime);
  const [isItemAdded, setIsItemAdded] = useState({
    added: false,
    prodPrice: 0,
    wishlistPrice: 0,
  });
  const containerRef = useRef(null);
  const [popupImgUrl, setPopupImgUrl] = useState(null);
  const [whishlistArray, setWhishlistArray] = useState({
    /// friends whishlists?????
    myWishlists: [],
    friendsWishlists: [],
    historyWishlists: [],
  });
  // const [currWishlistObj, setCurrWishlistObj] = useState({
  //   wishlistId: 0,
  // });
  const [currWishlist, setCurrWishlist] = useState({});
  const [currentWishListCategories, setCurrentWishlistCategories] = useState([])
  const [currUserObject, setCurrUserObject] = useState(
    JSON.parse(localStorage.getItem("currUserObject"))
  );
  // console.log(JSON.parse(localStorage.getItem("currUserObject")));
  const [showImagePopup, setShowImagePopup] = useState(false);

  const [cartForFriendsList, setCartForFriendsList] = useState([]);

  const [myEventsActiveTab, setMyEventsActiveTab] = useState(1);

  const [showCategMenuItem, setShowCategMenuItem] = useState(false)

  const [isLoggedIn, setIsLoggedIn] = useState(
    Boolean(localStorage.getItem("currUserObject"))
  );

  const [allCategoriesList, setAllCategoriesList] = useState(null);
  const [choosedCategoriesList, setChoosedCategoriesList] = useState([]);

  const [filterObject, setFilterObject] = useState({
    choosedCategoriesList: [],
    manufacturersList: [],
    priceRange: null,
    lastChangedType: "",
  })

  const [language, setLanguage] = useState('english') // 'hebrew'

  const location = useLocation();


  useEffect(() => {
    // handles scroll to the top when route is changed for all pages
    // const isExceptionPage = location?.pathname?.includes('all-categories') || location?.pathname?.includes('products-new-event');
    const isExceptionPage = location?.pathname?.includes('all-categories')
      || location?.pathname?.includes('all-categories-new-event')
      || location?.pathname?.includes('category-new-event')
      || location?.pathname?.includes('my-bank-products')
      || location?.pathname?.includes('my-bank-category')
      || location?.pathname?.includes('products-new-event');
    if (!isExceptionPage) {
      setShowCategMenuItem(false)
    } else {
      setShowCategMenuItem(true)
    }
    // console.log('setShowCategMenuItem:', location)
    window.scrollTo(0, 0);
  }, [location]);

  const updateCurrWishCategories = (data) => {

    const currentSelectedCategoriesIds = data.map(cat => cat.id);

    const updatedWishCategories = [...allCategoriesList].filter(category => currentSelectedCategoriesIds.includes(category.mainCategory.id))

    setCurrentWishlistCategories(updatedWishCategories)

    // console.log('updateCurrWishCategories: currentSelectedCategoriesIds, updatedWishCategories,allCategoriesList', currentSelectedCategoriesIds, updatedWishCategories, allCategoriesList)
  }





  const isLoggedInHandler = (value) => {
    setIsLoggedIn(value);
  };

  const openImagePopup = (e, popupImgUrl) => {
    e.preventDefault();
    setPopupImgUrl(popupImgUrl);
    setShowImagePopup(true);
  };

  const closeImagePopup = () => {
    // console.log("ImagePopup");
    setShowImagePopup(false);
  };

  const addItemHandler = (state) => {
    setIsItemAdded(state);
  };

  const updatePopupState = (value) => {
    setPopup(value);
  };
  function burgerToggle() {
    const updateBurger = !isBurgerOpen;
    setBurgerOpen(updateBurger);
  }

  const filterToggle = () => {
    const updateFilter = !isFilterOpen;
    setFilterOpen(updateFilter);

  };


  const closeFilterOutsideClick = (e) => {
    console.log('closeFilterOutsideClick: target, isFilterOpen', e.target, isFilterOpen)
    if (e.target.closest('.filter-btn')) {
      setFilterOpen(true);
    } else if (!e.target.closest('.filter')) {
      setFilterOpen(false);
      document.removeEventListener('click', closeFilterOutsideClick);
    }
  };

  console.log('isFilterOpen:', isFilterOpen)
  // useEffect(() => {
  //   if (isFilterOpen) {
  //     document.addEventListener('click', closeFilterOutsideClick);
  //   }

  //   // Cleanup the event listener when the component unmounts or when isFilterOpen changes to false
  //   return () => {
  //     document.removeEventListener('click', closeFilterOutsideClick);
  //   };
  // }, [isFilterOpen]);

  useEffect(() => {
    if (isPopup && !document.documentElement.classList.contains("popup-show")) {
      document.documentElement.classList.add("popup-show", "lock");
    }
    if (!isPopup && document.documentElement.classList.contains("popup-show")) {
      document.documentElement.classList.remove("popup-show", "lock");
    }

    if (isBurgerOpen && !document.body.classList.contains("menu-open")) {
      document.body.classList.add("menu-open");
    }
    if (!isBurgerOpen && document.body.classList.contains("menu-open")) {
      document.body.classList.remove("menu-open");
    }

    if (isFilterOpen && !document.body.classList.contains("filter-open")) {
      document.body.classList.add("filter-open");
    }
    if (!isFilterOpen && document.body.classList.contains("filter-open")) {
      document.body.classList.remove("filter-open");
    }
    document.addEventListener('click', closeFilterOutsideClick);

    const inviteWishlistId = getInviteWishlistId();
    // console.log('userNotFirstTime: ', userNotFirstTime, 'inviteWishlistId: ', inviteWishlistId)

    // do not redirect to exlaining-steps if we go to my-events flow when user is not logged in
    const exceptionList = [
      '/my-events',
      '/create-event',
      '/all-categories-new-event',
      '/category-new-event',
      '/products-new-event',
      '/credentials-new-wishlist/',
    ]
    const isException = exceptionList.some(url => window.location.href.includes(url))
    // if (!userNotFirstTime && !inviteWishlistId && !isException) {
    if (!userNotFirstTime && !inviteWishlistId) {
      navigate("/explaining-steps");
    }
  }, [isPopup, isFilterOpen, isBurgerOpen, userNotFirstTime, navigate]);

  useEffect(() => {
    const containerElement = document.createElement("div");
    const isExistContainer = document.querySelector(".image-popup-container");

    if (isExistContainer) {
      containerRef.current = isExistContainer;
    } else {
      containerElement.classList.add("image-popup-container");
      containerRef.current = containerElement;
      document.body.appendChild(containerElement);
      // console.log("containerRef", containerRef);
    }
    return () => {
      const isExistContainer = document.querySelector(".image-popup-container");

      if (isExistContainer) {
        document.body.removeChild(isExistContainer);
      }
    };
  }, []);

  useEffect(() => {
    axios
      .post(
        `${API_DOMAIN}/api/ProductCategory/getAllCategories`,
        {}
      )
      .then((response) => {
        // console.log(response);
        setAllCategoriesList(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  function getInviteWishlistId() {
    const params = new URLSearchParams(window.location.search);

    // if (params.get("inviteWishlistId")) {
    const inviteWishlistId = params?.get("inviteWishlistId")
      ? params.get("inviteWishlistId")
      : localStorage.getItem("inviteWishlistId")
        ? localStorage.getItem("inviteWishlistId")
        : null;
    return inviteWishlistId;
  }
  useEffect(() => {

    // if (params.get("inviteWishlistId")) {
    const inviteWishlistId = getInviteWishlistId();
    const isUserLoggedIn = Boolean(currUserObject);


    if (inviteWishlistId) {
      console.log('Debug inviteWishlistId', inviteWishlistId)
      console.log('Debug isUserLoggedIn', isUserLoggedIn)

      if (!isUserLoggedIn) {
        // navigate("/credentials");
        // navigate(`choose-present-or-send-money/${inviteWishlistId}`);
        if (!userNotFirstTime) {
          navigate(`/explaining-steps-referal/${inviteWishlistId}`);
        }
        localStorage.setItem("inviteWishlistId", inviteWishlistId);
      } else {
        console.log("user logged in");
        console.log(inviteWishlistId);
        console.log("trying to send");
        axios
          .get(
            `${API_DOMAIN}/api/ProductCategory/AddBuyerToWhishlist?buyerId=${currUserObject.user.id}&wishlistId=${inviteWishlistId}`
          )
          .then((response) => {
            console.log("added buyer to wishlist", response.data.result);
            // navigate(window.location.pathname);
            navigate(`choose-present-or-send-money/${inviteWishlistId}`);
            localStorage.removeItem("inviteWishlistId");
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }

  }, [currUserObject]);

  useEffect(() => {
    // console.log(currUserObject);
  }, [currUserObject]);
  useEffect(() => {
    // console.log(currWishlist);
  }, [currWishlist]);

  useEffect(() => {
    // localStorage.setItem('filterObject',JSON.stringify(filterObject))
    const filterObjectLocal = localStorage.getItem('filterObject');
    const filterObjectParsed = filterObjectLocal ? JSON.parse(filterObjectLocal) : null;
    if (filterObjectParsed) {
      setFilterObject(filterObjectParsed)
    }


  }, [])
  useEffect(() => {
    localStorage.setItem('filterObject', JSON.stringify(filterObject))
    // const filterObjectLocal = localStorage.getItem('filterObject');
    // const filterObjectParsed = filterObjectLocal ? JSON.parse(filterObjectLocal) : null;
    // if(filterObjectParsed){
    //   setFilterObject(filterObjectParsed)
    // }


  }, [filterObject])


  // 
  // console.log('ShowCategMenuItem', showCategMenuItem)

  return (
    <>
      <>
        {userNotFirstTime && (
          <Header
            isLoggedIn={isLoggedIn}
            burgerToggle={burgerToggle}
            setMyEventsActiveTab={setMyEventsActiveTab}
            allCategoriesList={allCategoriesList}
            filterObject={filterObject}
            setFilterObject={setFilterObject}
            currUserObject={currUserObject}
            showCategMenuItem={showCategMenuItem}
          // choosedCategoriesList={choosedCategoriesList}
          />
        )}

        {showImagePopup &&
          createPortal(
            <ImagePopup imageUrl={popupImgUrl} onClose={closeImagePopup} />,
            containerRef.current
          )}

        <Routes>
          <Route
            path="/explaining-steps"
            element={
              <ExplainingSteps
                userNotFirstTime={userNotFirstTime}
                setUserNotFirstTime={setUserNotFirstTime}

              />
            }
          />
          <Route path="/explaining-steps-2" element={<ExplainingSteps2 />} />
          <Route path="/explaining-steps-referal/:wishlistId" element={<ExplainingStepsReferal
            // showCategMenuItem={showCategMenuItem}
            setShowCategMenuItem={setShowCategMenuItem}
            userNotFirstTime={userNotFirstTime}
            setUserNotFirstTime={setUserNotFirstTime}

          />} />
          <Route path="home" element={<Home />} />
          <Route path="/" element={<Home />} />

          <Route
            path="/credentials"
            element={
              <Credentials
                isLoggedInHandler={isLoggedInHandler}
                updatePopupState={updatePopupState}
                currUserObject={currUserObject}
                setCurrUserObject={setCurrUserObject}
              />
            }
          />

          {/* <Route
            path="/credentials-choose-present"
            element={
              <Credentials
                isLoggedInHandler={isLoggedInHandler}
                updatePopupState={updatePopupState}
                currUserObject={currUserObject}
                setCurrUserObject={setCurrUserObject}
                isRedirectPresent={true}
              />
            }
          /> */}
          <Route
            path="/credentials-new-wishlist/:wishlistId"
            element={
              <Credentials
                isLoggedInHandler={isLoggedInHandler}
                updatePopupState={updatePopupState}
                currUserObject={currUserObject}
                setCurrUserObject={setCurrUserObject}
                language={language}
                languageToggle={languageToggle}
              />
            }
          />
          <Route
            path="/create-event"
            element={
              <CreateEvent
                currUserObject={currUserObject}
                currWishlist={currWishlist}
                setCurrWishlist={setCurrWishlist}
              />
            }
          />
          <Route
            path="/create-event-second-step/:wishListId"
            element={
              <CreateEvent
                currUserObject={currUserObject}
                currWishlist={currWishlist}
                setCurrWishlist={setCurrWishlist}
                secondStep={true}
                languageToggle={languageToggle}
                language={language}
              />
            }
          />
          <Route
            path="/update-wishlist/:whishlistId"
            element={
              <UpdateEvent
                currUserObject={currUserObject}
                currWishlist={currWishlist}
                setCurrWishlist={setCurrWishlist}
              />
            }
          />
          <Route
            path="/event-created"
            element={
              <EventCreated setMyEventsActiveTab={setMyEventsActiveTab} />
            }
          />
          <Route
            path="/edit-profile"
            element={
              <EditProfile
                isLoggedInHandler={isLoggedInHandler}
                setCurrUserObject={setCurrUserObject}
              />
            }
          />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/terms" element={<TermsPage />} />
          <Route path="/conditions" element={<ConditionsPage />} />
          <Route path="/reminders" element={<Reminders />} />
          <Route path="/events-history" element={<EventsHistory />} />
          <Route
            path="/my-events"
            element={
              <MyEvents
                currUserObject={currUserObject}
                currWishlist={currWishlist}
                setCurrWishlist={setCurrWishlist}
                setWhishlistArray={setWhishlistArray}
                whishlistArray={whishlistArray}
                setFilterObject={setFilterObject}
                myEventsActiveTab={myEventsActiveTab}
                setMyEventsActiveTab={setMyEventsActiveTab}
                updateCurrWishCategories={updateCurrWishCategories}
                filterToggle={filterToggle}
              />
            }
          />
          <Route
            path="/all-categories-new-event"
            element={
              <WishlistCategories
                currentWishListCategories={currentWishListCategories}
                newEvent={true}
                setShowCategMenuItem={setShowCategMenuItem}
                setFilterObject={setFilterObject}
              />
            }
          />
          <Route path="/my-bank" element={
            <MyBank
              allCategoriesList={allCategoriesList}
              filterToggle={filterToggle}
              filterObject={filterObject}
              setFilterObject={setFilterObject}
              currUserObject={currUserObject}
              updatePopupState={updatePopupState}
              addItemHandler={addItemHandler}
              openImagePopup={openImagePopup}
              currWishlist={currWishlist}
              setCurrWishlist={setCurrWishlist}
              languageToggle={languageToggle}
              language={language}
            />
          } />

          <Route
            path="/my-bank-all-categories"
            element={
              <MyBankAllCategories
                allCategoriesList={allCategoriesList}
                setAllCategoriesList={setAllCategoriesList}
                newEvent={true}
                setFilterObject={setFilterObject}
              />
            }
          />
          <Route
            path="/my-bank-category/:categoryId"
            element={
              <MyBankCategory
                allCategoriesList={allCategoriesList}
                newEvent={false}
              />
            }
          />

          <Route
            path="/my-bank-products/:categoryId/:productsId/"
            element={
              <MyBankProducts
                updatePopupState={updatePopupState}
                addItemHandler={addItemHandler}
                openImagePopup={openImagePopup}
                currWishlist={currWishlist}
                setCurrWishlist={setCurrWishlist}
                currUserObject={currUserObject}
                filterToggle={filterToggle}
                allCategoriesList={allCategoriesList}
                newEvent={true}
                filterObject={filterObject}
                setFilterObject={setFilterObject}
                language={language}
                languageToggle={languageToggle}
              />
            }
          />
          <Route
            path="/my-bank-products/:productsId/"
            element={
              <MyBankProducts
                updatePopupState={updatePopupState}
                addItemHandler={addItemHandler}
                openImagePopup={openImagePopup}
                currWishlist={currWishlist}
                setCurrWishlist={setCurrWishlist}
                currUserObject={currUserObject}
                filterToggle={filterToggle}
                allCategoriesList={allCategoriesList}
                newEvent={true}
                filterObject={filterObject}
                setFilterObject={setFilterObject}
              />
            }
          />
          <Route
            path="/all-categories-my-bank"
            element={
              <AllCategories
                allCategoriesList={allCategoriesList}
                setAllCategoriesList={setAllCategoriesList}
                newEvent={true}
                setShowCategMenuItem={setShowCategMenuItem}
              />
            }
          />
          <Route
            path="/all-categories"
            element={
              <AllCategories
                allCategoriesList={allCategoriesList}
                setAllCategoriesList={setAllCategoriesList}
                newEvent={false}
                setShowCategMenuItem={setShowCategMenuItem}
              />
            }
          />
          <Route
            path="/category-new-event/:categoryId"
            element={
              <CategoryNewEvent
                allCategoriesList={allCategoriesList}
                newEvent={true}
                filterToggle={filterToggle}
                filterObject={filterObject}
                setFilterObject={setFilterObject}
              />
            }
          />
          <Route
            path="/category/:categoryId"
            element={
              <Category
                allCategoriesList={allCategoriesList}
                newEvent={false}
              />
            }
          />
          <Route
            path="/products/:categoryId/:productsId/"
            element={
              <Products
                updatePopupState={updatePopupState}
                addItemHandler={addItemHandler}
                openImagePopup={openImagePopup}
                currWishlist={currWishlist}
                setCurrWishlist={setCurrWishlist}
                currUserObject={currUserObject}
                filterToggle={filterToggle}
                allCategoriesList={allCategoriesList}
                newEvent={false}
                filterObject={filterObject}
                setFilterObject={setFilterObject}
                setShowCategMenuItem={setShowCategMenuItem}
                setChoosedCategoriesList={setChoosedCategoriesList}
              />
            }
          />
          {/* {Testing new products page with already selected categories from categories menu item} */}
          <Route
            path="/products-by-categories"
            element={
              <ProductsByCategories
                updatePopupState={updatePopupState}
                addItemHandler={addItemHandler}
                openImagePopup={openImagePopup}
                currWishlist={currWishlist}
                setCurrWishlist={setCurrWishlist}
                currUserObject={currUserObject}
                filterToggle={filterToggle}
                allCategoriesList={allCategoriesList}
                newEvent={false}
                filterObject={filterObject}
                setFilterObject={setFilterObject}
                setShowCategMenuItem={setShowCategMenuItem}
                setChoosedCategoriesList={setChoosedCategoriesList}
              />
            }
          />
          <Route
            path="/products-new-event/:categoryId/:productsId/"
            element={
              <ProductsNewEvent
                updatePopupState={updatePopupState}
                addItemHandler={addItemHandler}
                openImagePopup={openImagePopup}
                currWishlist={currWishlist}
                setCurrWishlist={setCurrWishlist}
                currUserObject={currUserObject}
                filterToggle={filterToggle}
                allCategoriesList={allCategoriesList}
                newEvent={true}
                filterObject={filterObject}
                setFilterObject={setFilterObject}
                setShowCategMenuItem={setShowCategMenuItem}
                setChoosedCategoriesList={setChoosedCategoriesList}
              />
            }
          />
          <Route
            path="/single-product-new-event/:productId"
            element={
              <ProductPageNewEvent
                updatePopupState={updatePopupState}
                addItemHandler={addItemHandler}
                openImagePopup={openImagePopup}
                newEvent={true}
                currUserObject={currUserObject}
              />
            }
          />
          <Route
            path="/single-product/:productId"
            element={
              <ProductPage
                updatePopupState={updatePopupState}
                addItemHandler={addItemHandler}
                openImagePopup={openImagePopup}
                newEvent={false}
              />
            }
          />
          <Route path="/send-present" element={<SendPresent />} />
          <Route
            path="/choose-present-or-send-money/:wishlistId"
            element={
              <ChoosePresentOrMoney
                currWishlist={currWishlist}
                setCurrWishlist={setCurrWishlist}
                currUserObject={currUserObject}
                setMyEventsActiveTab={setMyEventsActiveTab}
                setUserNotFirstTime={setUserNotFirstTime}
                isLoggedInHandler={isLoggedInHandler}
                updatePopupState={updatePopupState}
                setCurrUserObject={setCurrUserObject}
                languageToggle={languageToggle}
                language={language}
              />
            }
          />
          <Route path="/successful-payment" element={<SuccessfullPayment />} />
          <Route path="/successful-payment-referal" element={<SuccessfullPaymentReferal setUserNotFirstTime={setUserNotFirstTime} />} />
          <Route path="/successful-payment-my-bank" element={<SuccessfullPaymentMyBank />} />
          <Route
            path="/cart/:wishlistId"
            element={
              <Cart
                currWishlist={currWishlist}
                setCurrWishlist={setCurrWishlist}
                cartForFriendsList={cartForFriendsList}
                setCartForFriendsList={setCartForFriendsList}
                currUserObject={currUserObject}
              />
            }
          />
        </Routes>
        {isItemAdded.added && (
          <ItemAddedToCartPopup
            updatePopupState={updatePopupState}
            addItemHandler={addItemHandler}
            isItemAdded={isItemAdded}
            currWishlist={currWishlist}
          />
        )}
        {userNotFirstTime && isLoggedIn && isSidemenuVisible && <SideMenu />}
        {userNotFirstTime && <Footer />}
      </>
    </>
  );
}

export default App;
