import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import DatePicker from "react-datepicker";

import {
  blessingImg,
  blessingImgNoPeople,
  chooseAppBgImage,
  chooseAppBgImageNoPeople,
  mainBgImage,
  mainBgImageNoPeople,
  mainDecor,
  mobileTitleIconBlessing,
  prevBtnImage,
} from "../Assets/images";

import { API_DOMAIN } from "../Libs/config";

import {
  validateTextInput,
  inputMaxCount,
  validateFixedCountInput,
  validateEmailInput,
  clearError,
  validatePhoneNumber,
} from "../Libs/forms";
import axios from "axios";

function UpdateEvent({ currUserObject, currWishlist, setCurrWishlist }) {
  const navigate = useNavigate();

  const location = useLocation();
  const params = location.state;

  console.log(params, "params");

  const [isDecor, setDecor] = useState(true);

  const [createEventStep, setCreateEventStep] = useState(0);
  const [stepNumber, setStepNumber] = useState(1);
  const [isForm, setForm] = useState(true);
  const [progress, setProgress] = useState(5);
  const [mainBackgroundgImage, setMainBackgroundgImage] = useState({
    main: mainBgImage,
    noPeople: mainBgImageNoPeople,
  });
  const [selectedValues, setSelectedValues] = useState([]);
  const [step0Info, setStep0Info] = useState({
    fullname: "",
    birthDay: "",
    birthMonth: "",
    birthYear: "",
  });
  const [step1Info, setStep1Info] = useState({
    fullname2: "",
    address: "",
    telFirst: "",
    telSecond: "",
    addressHouse: "",
    addressApartment: "",
    addressEntrance: "",
    mailbox: "",
    postal: "",
    time: "",
  });
  const [resultInfo, setResultInfo] = useState({
    eventTypeName: params?.eventTypeName ? params.eventTypeName : "",
    eventTypeNameEng: params?.eventTypeNameEng ? params.eventTypeNameEng : "",
    fullname: "",
    birthdate: "",
    fullname2: "",
    birthdate2: "",
    address: "",
    addressDetails: "",
    postal: "",
    mailbox: "",
    time: "",
    number: "",
    appTypesList: [],
  });
  useEffect(() => {
    console.log("params.eventTypeDate", params);
    console.log(resultInfo);
  }, [resultInfo]);
  const [isReadyToSend, setReadyToSend] = useState(false);
  const [step0SelectedDate, setStep0SelectedDate] = useState({
    date: "",
    day: "",
    month: "",
    year: "",
  });
  const step0DayRef = useRef(null);
  const step0MonthRef = useRef(null);
  const step0YearRef = useRef(null);

  const [step1SelectedDate, setStep1SelectedDate] = useState({
    date: "",
    day: "",
    month: "",
    year: "",
  });
  const step1DayRef = useRef(null);
  const step1MonthRef = useRef(null);
  const step1YearRef = useRef(null);

  const [finalInviteUrl, setFinalInviteUrl] = useState(null);

  console.log("step0Info_: ", step0Info);

  const goBack = () => {
    navigate(-1);
  };

  const goToStep0 = () => {
    setCreateEventStep(0);
    setStepNumber(1);
    setProgress(5);
    setForm(true);
    setDecor(true);
    setReadyToSend(false);
    setMainBackgroundgImage({
      main: mainBgImage,
      noPeople: mainBgImageNoPeople,
    });
  };
  const handleStep0DateChange = (input, dayInput, monthInput, yearInput) => {
    const result = parseDate(input.value, dayInput, monthInput, yearInput);
    if (result) {
      setStep0SelectedDate({
        date: result.year + "-" + result.month + "-" + result.day,
        day: result.day,
        month: result.month,
        year: result.year,
      });
    }
  };

  useEffect(() => {
    // date is passed from my events component when editing existing event
    function getDateObject(dateString) {
      const dateObj = new Date(dateString);

      const result = {
        date: dateObj.toISOString().slice(0, 10),
        day: dateObj.getDate(),
        month: dateObj.getMonth() + 1,
        year: dateObj.getFullYear(),
      };

      return result;
    }

    if (params.eventTypeDate) {
      const date = getDateObject(params.eventTypeDate);
      console.log("eventTypeDate_", date);

      setStep0SelectedDate(date);
    }

    if (params.eventTypeName) {
      setResultInfo((prev) => ({
        ...prev,
        eventTypeName: params.eventTypeName,
      }));
      setStep0Info((prev) => ({ ...prev, fullname: params.eventTypeName }));
    }

    axios
      .get(
        `${API_DOMAIN}/api/ProductCategory/GetWishlistObject?id=${params.eventTypeId}`
      )
      .then((response) => {
        console.log("GetWishlistObject", response.data);
        if (response.data.result) {
          const whishlistUser = response.data.whishlistUser;

          setStep1Info((prev) => ({
            ...prev,
            fullname2: whishlistUser.firstName + " " + whishlistUser.lastName,
          }));
          const date = getDateObject(whishlistUser.dob);
          console.log("yyybackdate", date);
          setStep1SelectedDate(date);
          setStep1Info((prev) => ({
            ...prev,
            telFirst: whishlistUser.phoneNumber.slice(3),
            telSecond: whishlistUser.phoneNumber.slice(0, 3),
          }));
        } else {
          alert("Something went wrong");
        }
      })
      .catch((error) => {
        console.error(error);
        //setLoading(false);
        alert("Error, please try again");
      });

    return () => {};
  }, [params.eventTypeDate, params.eventTypeName]);

  const handleStep1DateChange = (input, dayInput, monthInput, yearInput) => {
    const result = parseDate(input.value, dayInput, monthInput, yearInput);
    if (result) {
      setStep1SelectedDate({
        date: result.year + "-" + result.month + "-" + result.day,
        day: result.day,
        month: result.month,
        year: result.year,
      });
    }
  };

  const parseDate = (value, dayInput, monthInput, yearInput) => {
    if (value) {
      const date = new Date(value);
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear().toString();

      clearError(dayInput);
      clearError(monthInput);
      clearError(yearInput);
      return { date, day, month, year };
    }
  };

  const submitStep0Form = (e) => {
    e.preventDefault();

    const form = e.target;

    const fullnameInput = form["fullname"];
    const birthdayInputYear = form["birthday-year"];
    const birthdayInputMonth = form["birthday-month"];
    const birthdayInputDay = form["birthday-day"];

    const isFullname = validateTextInput(fullnameInput, 3);
    const isBirthYear = validateTextInput(birthdayInputYear, 4);
    const isBirthMonth = validateTextInput(birthdayInputMonth, 1);
    const isBirthDay = validateTextInput(birthdayInputDay, 1);

    const isFormValid = isFullname && isBirthYear && isBirthMonth && isBirthDay;

    if (isFormValid) {
      setStep0Info({
        fullname: fullnameInput.value,
        birthDay: birthdayInputDay.value,
        birthMonth: birthdayInputMonth.value,
        birthYear: birthdayInputYear.value,
      });
      setResultInfo({
        eventTypeName: resultInfo?.eventTypeName,
        eventTypeNameEng: resultInfo?.eventTypeNameEng,
        fullname: fullnameInput.value,
        birthdate:
          birthdayInputYear.value +
          "-" +
          birthdayInputMonth.value +
          "-" +
          birthdayInputDay.value,
        fullname2: "",
        birthdate2: "",
        address: "",
        addressDetails: "",
        postal: "",
        mailbox: "",
        time: "",
        number: "",
        appTypesList: [],
      });
      goToStep1();
    }
  };

  const goToStep1 = () => {
    setCreateEventStep(1);
    setStepNumber(2);
    setProgress(30);
    setForm(false);
    setMainBackgroundgImage({
      main: mainBgImage,
      noPeople: mainBgImageNoPeople,
    });
    setReadyToSend(false);
    setDecor(false);
  };
  const submitStep1Form = (e) => {
    const form = e.target
      .closest(".credentials-section__content")
      .querySelector("form");

    const fullnameInput = document.getElementById(
      "form-credentials-section__fullname"
    );
    const telInputFirst = document.getElementById(
      "form-credentials-section__tel-1"
    );
    const telInputSecond = document.getElementById(
      "form-credentials-section__tel-2"
    );
    const birthdayInputYear = document.getElementById(
      "form-credentials-section__birthday-year"
    );
    const birthdayInputMonth = document.getElementById(
      "form-credentials-section__birthday-month"
    );
    const birthdayInputDay = document.getElementById(
      "form-credentials-section__birthday-day"
    );
    const addressInput = document.getElementById(
      "form-credentials-section__address"
    );
    const addressHouseInput = document.getElementById(
      "form-credentials-section__house-num"
    );
    const addressApartmentInput = document.getElementById(
      "form-credentials-section__apartment-num"
    );
    const addressEntranceInput = document.getElementById(
      "form-credentials-section__entrance"
    );
    const mailboxInput = document.getElementById(
      "form-credentials-section__mailbox"
    );
    const postalInput = document.getElementById(
      "form-credentials-section__postal-code"
    );
    const timeInput = document.getElementById("form-credentials-section__time");

    const isFullname = validateTextInput(fullnameInput, 3);
    const isTelFirst = validateFixedCountInput(telInputFirst, 7);
    const isTelSecond = validatePhoneNumber(telInputSecond);
    const isBirthYear = validateTextInput(birthdayInputYear, 4);
    const isBirthMonth = validateTextInput(birthdayInputMonth, 1);
    const isBirthDay = validateTextInput(birthdayInputDay, 1);
    const isAddress = validateTextInput(addressInput, 1);
    const isAddressHouse = validateTextInput(addressHouseInput, 1);
    const isAddressApartment = validateTextInput(addressApartmentInput, 1);
    const isAddressEntrance = validateTextInput(addressEntranceInput, 1);
    // const isMailboxInput = validateFixedCountInput(mailboxInput, 9);
    const isPostalInput = validateTextInput(postalInput, 1);
    const isTime = validateTextInput(timeInput, 1);

    const isFormValid =
      isFullname &&
      isTelFirst &&
      isTelSecond &&
      isBirthYear &&
      isBirthMonth &&
      isBirthDay &&
      isAddress &&
      isAddressHouse &&
      isAddressApartment &&
      isAddressEntrance &&
      // isMailboxInput &&
      isPostalInput &&
      isTime;

    if (isFormValid) {
      setStep1Info({
        fullname2: fullnameInput.value,
        address: addressInput.value,
        telFirst: telInputFirst.value,
        telSecond: telInputSecond.value,
        addressHouse: addressHouseInput.value,
        addressApartment: addressApartmentInput.value,
        addressEntrance: addressEntranceInput.value,
        mailbox: mailboxInput.value,
        postal: postalInput.value,
        time: timeInput.value,
      });
      setResultInfo({
        eventTypeName: resultInfo?.eventTypeName,
        eventTypeNameEng: resultInfo?.eventTypeNameEng,
        fullname: resultInfo.fullname,
        birthdate: resultInfo.birthdate,
        fullname2: fullnameInput.value,
        birthdate2:
          birthdayInputYear.value +
          "-" +
          birthdayInputMonth.value +
          "-" +
          birthdayInputDay.value,
        address: form["address"].value,
        addressHouse: addressHouseInput.value,
        addressApartment: addressApartmentInput.value,
        addressEntrance: addressEntranceInput.value,
        postal: form["postal-code"].value,
        mailbox: form["mailbox"].value,
        time: form["time"].value,
        number: telInputSecond.value + telInputFirst.value,
        appTypesList: [],
      });
      goToStep2();
    } else {
      const scrollCoord = form.querySelector(".input-error").offsetTop;
      form.parentElement.scrollTo({
        top: scrollCoord,
        left: 0,
        behavior: "smooth",
      });
    }
  };

  const goToStep2 = () => {
    setCreateEventStep(2);
    setStepNumber(3);
    setProgress(65);
    setForm(false);
    setMainBackgroundgImage({
      main: mainBgImage,
      noPeople: mainBgImageNoPeople,
    });
    setReadyToSend(false);
    setDecor(false);
  };

  const submitStep2Form = (e) => {
    const sendObj = {
      userId: currUserObject.user.id,
      description: resultInfo.fullname,
      productsIdList: [],
      // eventDate: "2023-07-15T09:43:52.718Z",
      eventDate: `${resultInfo.birthdate}T${resultInfo.time}`,
      name: resultInfo.fullname,
      houseNumber: resultInfo.addressHouse,
      apartmentNumber: resultInfo.addressApartment,
      entranceNumber: resultInfo.addressEntrance,
      mailbox: resultInfo.mailbox,
      postal: resultInfo.postal,
      arrivalTime: resultInfo.time,
      contactPhone: resultInfo.number,
      type: resultInfo?.eventTypeNameEng,
    };

    axios
      .post(
        `${API_DOMAIN}/api/ProductCategory/CreateWishlist`,
        sendObj
      )
      .then((response) => {
        console.log(response.data);
        if (response.data.result) {
          goToWhilistSelect(response.data.wishlistId);
        } else {
          //setLoading(false);
          alert("Something went wrong");
        }
      })
      .catch((error) => {
        console.error(error);
        //setLoading(false);
        alert("Error, please try again");
      });
  };

  const goToStep3 = () => {
    setCreateEventStep(3);
    setStepNumber(4);
    setProgress(100);
    setForm(false);
    setReadyToSend(false);

    setMainBackgroundgImage({
      main: chooseAppBgImage,
      noPeople: chooseAppBgImageNoPeople,
    });
    setDecor(false);
  };
  const submitStep3Form = (e) => {
    e.preventDefault();

    const form = e.target;

    // const isFormValid = Boolean(selectedValues.length);
    const isFormValid = true;
    if (isFormValid) {
      setResultInfo({
        eventTypeName: resultInfo?.eventTypeName,
        eventTypeNameEng: resultInfo?.eventTypeNameEng,
        fullname: resultInfo.fullname,
        birthdate: resultInfo.birthdate,
        fullname2: resultInfo.fullname2,
        birthdate2: resultInfo.birthdate2,
        address: resultInfo.address,
        addressDetails: resultInfo.addressDetails,
        postal: resultInfo.postal,
        mailbox: resultInfo.mailbox,
        time: resultInfo.time,
        number: resultInfo.number,
        appTypesList: [selectedValues],
      });

      const paramsObj = {
        userId: currUserObject.user.id,
        wishlistId: currWishlist.whishlistObject.id,
        isShareEmail: selectedValues.includes("מיילים"),
        description: currWishlist.whishlistObject.description,
      };

      axios
        .post(
          `${API_DOMAIN}/api/ProductCategory/UpdateWishlist`,
          paramsObj
        )
        .then((response) => {
          console.log(response.data);
          if (response.data.result) {
            console.log("data that we have got", response.data);
            setCurrWishlist({
              whishlistItemsList: response.data.wishlist.whishlistItemsList,
              whishlistObject: response.data.wishlist.whishlistObject,
              whishlistItemsDetailsList:
                response.data.wishlist.whishlistItemsDetailsList,
            });
            setReadyToSend(true);
          } else {
            //setLoading(false);
            alert("Something went wrong");
          }
        })
        .catch((error) => {
          console.error(error);
          //setLoading(false);
          alert("Error, please try again");
        });
    }
  };
  function goToWhilistSelect(whishlistId) {
    axios
      .get(
        `${API_DOMAIN}/api/ProductCategory/GetWishlistObject?id=${whishlistId}`
      )
      .then((response) => {
        console.log(response.data);
        if (response.data.result) {
          // setLoading(false);
          console.log("whishlist object", response.data);
          setCurrWishlist({
            whishlistItemsList: response.data.whishlistItemsList,
            whishlistObject: response.data.whishlistObject,
            whishlistItemsDetailsList: response.data.whishlistItemsDetailsList,
          });
          setFinalInviteUrl(
            `${window.location.origin}/?inviteWishlistId=${response.data.whishlistObject.id}`
          );
          goToStep3();
        } else {
          //setLoading(false);
          alert("Something went wrong");
        }
      })
      .catch((error) => {
        console.error(error);
        //setLoading(false);
        alert("Error, please try again");
      });
  }

  const handleCheckBoxChange = (event) => {
    const value = event.target.value;
    if (event.target.checked) {
      setSelectedValues([...selectedValues, value]);
    } else {
      setSelectedValues(selectedValues.filter((item) => item !== value));
    }
  };

  const facebookShare = (e) => {
    if (e.target.checked) {
      window.open(
        `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          finalInviteUrl
        )}`,
        "_blank"
      );
    }
  };
  const tiktokShare = (e) => {
    if (e.target.checked) {
      window.open(
        `tiktok://share?item_id=${encodeURIComponent(finalInviteUrl)}`,
        "_blank"
      );
    }
  };
  const whatsappShare = (e) => {
    if (e.target.checked) {
      window.open(
        `https://api.whatsapp.com/send?text=${encodeURIComponent(
          finalInviteUrl
        )}`,
        "_blank"
      );
    }
  };
  const instagramShare = (e) => {
    if (e.target.checked) {
      window.open(
        `https://www.instagram.com/share?url=${encodeURIComponent(
          finalInviteUrl
        )}`,
        "_blank"
      );
    }
  };

  const browserShare = (e) => {
    e.preventDefault();
    console.log(navigator.share);
    if (navigator.share) {
      navigator
        .share({
          title: "My wishlist",
          text: "That's invite to my wishlist. You are welcome!",
          url: finalInviteUrl,
        })
        .then(() => {
          console.log("Успешно отправлено");
        })
        .error((error) => {
          console.error(error);
        });
    }
  };

  useEffect(() => {
    if (isReadyToSend) {
      console.log(resultInfo);
      localStorage.setItem(`event-created`, JSON.stringify(resultInfo));
      console.log(JSON.parse(localStorage.getItem(`event-created`)));
      navigate("/event-created");
    }
  }, [params, resultInfo, isReadyToSend, navigate]);

  useEffect(() => {
    const currentUser = localStorage.getItem("currUserObject");
    if (!currentUser) {
      navigate("/credentials");
    }
  }, []);

  return (
    <main
      className={`credentials-page ${
        createEventStep === 0
          ? "create-event"
          : createEventStep === 1
          ? "blessing-form"
          : createEventStep === 2
          ? "blessing-page"
          : createEventStep === 3
          ? "choose-application"
          : ""
      } ${isForm ? "credentials-page_form" : ""}`}
      data-fullscreen
    >
      <section className="credentials-section">
        <div className="credentials-section__bg-image-ibg">
          <img
            src={mainBackgroundgImage.main}
            className="img-people"
            alt="bg"
          />
          <img
            src={mainBackgroundgImage.noPeople}
            className="img-no-people"
            alt="bg"
          />
        </div>
        <div className="credentials-section__content-window">
          <div className="credentials-section__steps">
            <div
              className={`credentials-section__steps-numbers numbers-steps step-${stepNumber}`}
            >
              <ul className="numbers-steps__list">
                <li className="numbers-steps__list-item">
                  <div
                    className="numbers-steps__step"
                    onClick={() =>
                      stepNumber !== 1 && stepNumber < 4 ? goToStep0() : null
                    }
                  >
                    1
                  </div>
                </li>
                <li className="numbers-steps__list-item">
                  <div
                    className="numbers-steps__step"
                    onClick={() =>
                      stepNumber > 2 && stepNumber < 4 ? goToStep1() : null
                    }
                  >
                    2
                  </div>
                </li>
                <li className="numbers-steps__list-item">
                  <div className="numbers-steps__step">3</div>
                </li>
                <li className="numbers-steps__list-item">
                  <div className="numbers-steps__step">4</div>
                </li>
              </ul>
            </div>
          </div>
          <div className="credentials-section__content">
            {createEventStep === 0 && (
              <>
                <button
                  type="button"
                  onClick={goBack}
                  className="credentials-section__prev-btn"
                >
                  <img src={prevBtnImage} alt="arrow left black" />
                </button>
                <h1 className="credentials-section__title credentials-section__title_icon-top">
                  מזל טוב
                </h1>
                <h2 className="credentials-section__subtitle">
                  בחרת בקטגורית {params?.eventTypeName} מלא/י שם ותאריך לידה
                </h2>
                <div className="credentials-section__form-wrapper">
                  <form
                    action="#"
                    onSubmit={submitStep0Form}
                    className="credentials-section__form form-credentials-section"
                  >
                    <label
                      htmlFor="form-credentials-section__fullname"
                      className="form-credentials-section__input-wrapper input-wrapper"
                    >
                      <span className="input-title">שם מלא*</span>
                      <input
                        autoComplete="off"
                        type="text"
                        name="fullname"
                        id="form-credentials-section__fullname"
                        className="input"
                        defaultValue={step0Info.fullname}
                        onInput={(e) => {
                          if (
                            e.target
                              .closest("label")
                              .classList.contains("input-error")
                          ) {
                            validateTextInput(e.target, 3);
                          }
                        }}
                      />
                      <span className="error-text">minimum 3 chars</span>
                    </label>
                    <div className="form-edit-profile__birthday birthday-field">
                      <p className="birthday-field__birthday-title form-title">
                        תאריך לידה
                      </p>
                      <div className="birthday-field__birthday-body">
                        <input
                          type="date"
                          className="birthday-field__hidden-main-field"
                          value={step0SelectedDate.date}
                          onChange={(e) => {
                            handleStep0DateChange(
                              e.target,
                              step0DayRef.current,
                              step0MonthRef.current,
                              step0YearRef.current
                            );
                          }}
                        />
                        <label
                          htmlFor="form-credentials-section__birthday-year"
                          className="birthday-field__input-wrapper input-wrapper"
                        >
                          <span className="input-title">שנה</span>
                          <input
                            autoComplete="off"
                            type="number"
                            name="birthday-year"
                            id="form-credentials-section__birthday-year"
                            value={step0SelectedDate.year}
                            onChange={(event) => {}}
                            ref={step0YearRef}
                            className="input"
                          />
                          <span
                            className="error-text"
                            style={{
                              position: "absolute",
                              top: "100%",
                              right: 0,
                              width: "auto",
                              whiteSpace: "nowrap",
                            }}
                          >
                            You must select a date
                          </span>
                        </label>
                        <label
                          htmlFor="form-credentials-section__birthday-month"
                          className="birthday-field__input-wrapper input-wrapper"
                        >
                          <span className="input-title">חודש</span>
                          <input
                            autoComplete="off"
                            type="number"
                            name="birthday-month"
                            id="form-credentials-section__birthday-month"
                            ref={step0MonthRef}
                            className="input"
                            value={step0SelectedDate.month}
                            onChange={(event) => {}}
                          />
                        </label>
                        <label
                          htmlFor="form-credentials-section__birthday-day"
                          className="birthday-field__input-wrapper input-wrapper"
                        >
                          <span className="input-title">יום</span>
                          <input
                            autoComplete="off"
                            type="number"
                            name="birthday-day"
                            ref={step0DayRef}
                            id="form-credentials-section__birthday-day"
                            className="input"
                            value={step0SelectedDate.day}
                            onChange={(event) => {}}
                          />
                        </label>
                      </div>
                    </div>
                    <div className="form-credentials-section__buttons-container">
                      <button type="submit" className="btn">
                        לשלב הבא
                      </button>
                    </div>
                  </form>
                </div>
              </>
            )}

            {createEventStep === 1 && (
              <>
                <button
                  type="button"
                  onClick={goToStep0}
                  className="credentials-section__prev-btn"
                >
                  <img src={prevBtnImage} alt="arrow left black" />
                </button>
                <h1 className="credentials-section__title">
                  <div className="mobile-icon">
                    <img src={mobileTitleIconBlessing} alt="icon" />
                  </div>
                  הזמנה
                </h1>
                <h2 className="credentials-section__subtitle">
                  {" "}
                  מלא/י את הפרטי האירוע
                </h2>
                <div className="credentials-section__form-wrapper">
                  <form
                    action="#"
                    className="credentials-section__form form-credentials-section"
                  >
                    <label
                      htmlFor="form-credentials-section__fullname"
                      className="form-credentials-section__input-wrapper input-wrapper"
                    >
                      <span className="input-title">שם מלא*</span>
                      <input
                        autoComplete="off"
                        type="text"
                        name="fullname"
                        id="form-credentials-section__fullname"
                        className="input"
                        defaultValue={step1Info.fullname2}
                        onInput={(e) => {
                          if (
                            e.target
                              .closest("label")
                              .classList.contains("input-error")
                          ) {
                            validateTextInput(e.target, 3);
                          }
                        }}
                      />
                      <span className="error-text">Minimum 3 chars</span>
                    </label>
                    <div className="form-edit-profile__birthday birthday-field">
                      <p className="birthday-field__birthday-title form-title">
                        תאריך לידה
                      </p>
                      <div className="birthday-field__birthday-body">
                        <input
                          type="date"
                          className="birthday-field__hidden-main-field"
                          value={step1SelectedDate.date}
                          onChange={(e) => {
                            handleStep1DateChange(
                              e.target,
                              step1DayRef.current,
                              step1MonthRef.current,
                              step1YearRef.current
                            );
                          }}
                        />
                        <label
                          htmlFor="form-credentials-section__birthday-year"
                          className="birthday-field__input-wrapper input-wrapper"
                        >
                          <span className="input-title">שנה</span>
                          <input
                            autoComplete="off"
                            type="number"
                            name="birthday-year"
                            id="form-credentials-section__birthday-year"
                            className="input"
                            value={step1SelectedDate.year}
                            onChange={(event) => {}}
                            ref={step1YearRef}
                          />
                          <span
                            className="error-text"
                            style={{
                              position: "absolute",
                              top: "100%",
                              right: 0,
                              width: "auto",
                              whiteSpace: "nowrap",
                            }}
                          >
                            You must select a date
                          </span>
                        </label>
                        <label
                          htmlFor="form-credentials-section__birthday-month"
                          className="birthday-field__input-wrapper input-wrapper"
                        >
                          <span className="input-title">חודש</span>
                          <input
                            autoComplete="off"
                            type="number"
                            name="birthday-month"
                            id="form-credentials-section__birthday-month"
                            className="input"
                            ref={step1MonthRef}
                            value={step1SelectedDate.month}
                            onChange={(event) => {}}
                          />
                        </label>
                        <label
                          htmlFor="form-credentials-section__birthday-day"
                          className="birthday-field__input-wrapper input-wrapper"
                        >
                          <span className="input-title">יום</span>
                          <input
                            autoComplete="off"
                            type="number"
                            name="birthday-day"
                            id="form-credentials-section__birthday-day"
                            className="input"
                            ref={step1DayRef}
                            value={step1SelectedDate.day}
                            onChange={(event) => {}}
                          />
                        </label>
                      </div>
                    </div>
                    <p className="form-title">כתובת</p>
                    <label
                      htmlFor="form-credentials-section__address"
                      className="form-credentials-section__input-wrapper input-wrapper"
                    >
                      <span className="input-title">כתובת*</span>
                      <input
                        autoComplete="off"
                        type="text"
                        name="address"
                        id="form-credentials-section__address"
                        className="input"
                        defaultValue={step1Info.address}
                        onInput={(e) => {
                          if (
                            e.target
                              .closest("label")
                              .classList.contains("input-error")
                          ) {
                            validateTextInput(e.target, 1);
                          }
                        }}
                      />
                      <span className="error-text">Minimum 1 char</span>
                    </label>
                    <div className="three-inputs">
                      <label
                        htmlFor="form-credentials-section__house-num"
                        className="birthday-field__input-wrapper input-wrapper"
                      >
                        <span className="input-title">מס' בית</span>
                        <input
                          autoComplete="off"
                          type="number"
                          name="house-num"
                          id="form-credentials-section__house-num"
                          className="input"
                          defaultValue={step1Info.addressHouse}
                          onInput={(e) => {
                            if (
                              e.target
                                .closest("label")
                                .classList.contains("input-error")
                            ) {
                              validateTextInput(e.target, 1);
                            }
                          }}
                        />
                        <span className="error-text">Minimum 1 char</span>
                      </label>
                      <label
                        htmlFor="form-credentials-section__apartment-num"
                        className="birthday-field__input-wrapper input-wrapper"
                      >
                        <span className="input-title">דירה</span>
                        <input
                          autoComplete="off"
                          type="number"
                          name="apartment-num"
                          id="form-credentials-section__apartment-num"
                          className="input"
                          defaultValue={step1Info.addressApartment}
                          onInput={(e) => {
                            if (
                              e.target
                                .closest("label")
                                .classList.contains("input-error")
                            ) {
                              validateTextInput(e.target, 1);
                            }
                          }}
                        />
                        <span className="error-text">Minimum 1 char</span>
                      </label>
                      <label
                        htmlFor="form-credentials-section__entrance"
                        className="birthday-field__input-wrapper input-wrapper"
                      >
                        <span className="input-title">כניסה</span>
                        <input
                          autoComplete="off"
                          type="text"
                          name="entrance"
                          id="form-credentials-section__entrance"
                          className="input"
                          defaultValue={step1Info.addressEntrance}
                          onInput={(e) => {
                            if (
                              e.target
                                .closest("label")
                                .classList.contains("input-error")
                            ) {
                              validateTextInput(e.target, 1);
                            }
                          }}
                        />
                        <span className="error-text">Minimum 1 char</span>
                      </label>
                    </div>
                    <div className="mail-wrapper">
                      <label
                        htmlFor="form-credentials-section__mailbox"
                        className="input-wrapper"
                      >
                        <span className="input-title">ת.ד</span>
                        <input
                          autoComplete="off"
                          type="number"
                          name="mailbox"
                          id="form-credentials-section__mailbox"
                          className="input"
                          defaultValue={step1Info.mailbox}
                          onInput={(e) => {
                            // if (
                            //   e.target
                            //     .closest("label")
                            //     .classList.contains("input-error")
                            // ) {
                            //   validateFixedCountInput(e.target, 9);
                            // }
                            // inputMaxCount(e.target, 9);
                          }}
                        />
                        <span className="error-text">9 numbers</span>
                      </label>
                      <label
                        htmlFor="form-credentials-section__postal-code"
                        className="input-wrapper"
                      >
                        <span className="input-title">מיקוד</span>
                        <input
                          autoComplete="off"
                          type="number"
                          name="postal-code"
                          id="form-credentials-section__postal-code"
                          className="input"
                          defaultValue={step1Info.postal}
                          onInput={(e) => {
                            if (
                              e.target
                                .closest("label")
                                .classList.contains("input-error")
                            ) {
                              validateTextInput(e.target, 1);
                            }
                          }}
                        />
                        <span className="error-text">Minimum 1 char</span>
                      </label>
                    </div>
                    <label
                      htmlFor="form-credentials-section__time"
                      className="form-credentials-section__input-wrapper input-wrapper"
                    >
                      <span className="input-title">שעת הגעה</span>
                      <input
                        autoComplete="off"
                        type="time"
                        name="time"
                        id="form-credentials-section__time"
                        className="input"
                        defaultValue={step1Info.time}
                        onChange={(e) => validateTextInput(e.target, 1)}
                      />
                      <span className="error-text">You must select time</span>
                    </label>
                    <div className="tel-wrapper">
                      <label
                        htmlFor="form-credentials-section__tel-1"
                        className="input-wrapper"
                      >
                        <span className="input-title">טלפון לתיאומים</span>
                        <input
                          autoComplete="off"
                          type="number"
                          name="tel-1"
                          defaultValue={step1Info.telFirst}
                          id="form-credentials-section__tel-1"
                          className="input"
                          onInput={(e) => {
                            inputMaxCount(e.target, 7);
                            if (
                              e.target
                                .closest("label")
                                .classList.contains("input-error")
                            ) {
                              validateFixedCountInput(e.target, 7);
                            }
                          }}
                        />
                        <span className="error-text">7 chars</span>
                      </label>
                      <label
                        htmlFor="form-credentials-section__tel-2"
                        className="input-wrapper"
                      >
                        <input
                          autoComplete="off"
                          type="number"
                          name="tel-2"
                          id="form-credentials-section__tel-2"
                          className="input"
                          defaultValue={step1Info.telSecond}
                          onInput={(e) => {
                            inputMaxCount(e.target, 3);

                            if (
                              e.target
                                .closest("label")
                                .classList.contains("input-error")
                            ) {
                              validatePhoneNumber(e.target);
                            }
                          }}
                        />
                        <span className="error-text">*05</span>
                      </label>
                    </div>
                  </form>
                </div>
                <div className="credentials-section__bottom-buttons">
                  <button onClick={submitStep1Form} className="btn">
                    לצפיה ואישור
                  </button>
                </div>
              </>
            )}

            {createEventStep === 2 && (
              <div>
                <button type="button" className="credentials-section__prev-btn">
                  <img
                    src={prevBtnImage}
                    onClick={goToStep1}
                    alt="arrow left black"
                  />
                </button>
                <h1 className="credentials-section__title">
                  <div className="mobile-icon">
                    <img src={mobileTitleIconBlessing} alt="icon" />
                  </div>
                  ברכה למוזמנים
                </h1>
                <h2 className="credentials-section__subtitle">
                  יום הולדת יש רק פעם בשנה אני מזמין/ה אתכם לחגוג איתי
                </h2>
                <div className="credentials-section__large-image-ibg">
                  <img src={blessingImg} className="img-people" alt="img" />
                  <img
                    src={blessingImgNoPeople}
                    className="img-no-people"
                    alt="img"
                  />
                </div>
                <div className="credentials-section__table-block">
                  <div className="credentials-section__table">
                    <div className="credentials-section__table-item">
                      <p className="credentials-section__table-item-name">
                        תאריך האירוע
                      </p>
                      <p className="credentials-section__table-item-value">
                        {resultInfo.birthdate2}
                      </p>
                    </div>
                    <div className="credentials-section__table-item">
                      <p className="credentials-section__table-item-name">
                        כתובת האירוע
                      </p>
                      <p className="credentials-section__table-item-value">
                        {resultInfo.address}
                      </p>
                    </div>
                    <div className="credentials-section__table-item">
                      <p className="credentials-section__table-item-name">
                        שעת הגעה
                      </p>
                      <p className="credentials-section__table-item-value">
                        {resultInfo.time}
                      </p>
                    </div>
                    <div className="credentials-section__table-item">
                      <p className="credentials-section__table-item-name">
                        טלפון לתיאומים
                      </p>
                      <p className="credentials-section__table-item-value">
                        {resultInfo.number}
                      </p>
                    </div>
                  </div>
                  <p className="credentials-section__under-table-text">
                    בא לכם לפנק אותי במתנה. חסכתי לכם כאב ראש מיותר בחרו מתוך
                    רשימת המתנות המועדפת שלי אז למה אתם מחכים?
                  </p>
                </div>
                <div className="credentials-section__bottom-buttons">
                  <button
                    type="button"
                    onClick={goToStep1}
                    className="btn-outline-white"
                  >
                    עריכה
                  </button>
                  <button
                    type="button"
                    onClick={submitStep2Form}
                    className="btn"
                  >
                    אישור
                  </button>
                </div>
              </div>
            )}

            {createEventStep === 3 && (
              <>
                {/* <button
                  type="button"
                  onClick={goToStep2}
                  className="credentials-section__prev-btn"
                >
                  <img src={prevBtnImage} alt="arrow left black" />
                </button> */}
                <h1 className="credentials-section__title">ברכה למוזמנים</h1>
                <div className="credentials-section__form-wrapper">
                  <form
                    action="#"
                    onSubmit={submitStep3Form}
                    className="credentials-section__form form-credentials-section"
                  >
                    <p className="form-title checks-title">
                      מאיזה אפליקציה תרצה להזמין חברים
                    </p>
                    <div className="form-credentials-section__checks">
                      <div className="checkbox">
                        <input
                          id="c_1"
                          className="checkbox__input"
                          type="checkbox"
                          value={"פייסבוק"}
                          checked={selectedValues.includes("פייסבוק")}
                          onChange={(e) => {
                            handleCheckBoxChange(e);
                            facebookShare(e);
                          }}
                          name="app-type"
                        />
                        <label htmlFor="c_1" className="checkbox__label">
                          <span className="checkbox__text">פייסבוק</span>
                        </label>
                      </div>
                      <div className="checkbox">
                        <input
                          id="c_2"
                          className="checkbox__input"
                          type="checkbox"
                          value={"מיילים"}
                          checked={selectedValues.includes("מיילים")}
                          onChange={handleCheckBoxChange}
                          name="app-type"
                        />
                        <label htmlFor="c_2" className="checkbox__label">
                          <span className="checkbox__text">מיילים</span>
                        </label>
                      </div>
                      <div className="checkbox">
                        <input
                          id="c_3"
                          className="checkbox__input"
                          type="checkbox"
                          value={"טיקטוק"}
                          checked={selectedValues.includes("טיקטוק")}
                          onChange={(e) => {
                            handleCheckBoxChange(e);
                            tiktokShare(e);
                          }}
                          name="app-type"
                        />
                        <label htmlFor="c_3" className="checkbox__label">
                          <span className="checkbox__text">טיקטוק</span>
                        </label>
                      </div>
                      <div className="checkbox">
                        <input
                          id="c_4"
                          className="checkbox__input"
                          type="checkbox"
                          value={"רשימת אנשי קשר בסמארטפון"}
                          checked={selectedValues.includes(
                            "רשימת אנשי קשר בסמארטפון"
                          )}
                          onChange={handleCheckBoxChange}
                          name="app-type"
                        />
                        <label htmlFor="c_4" className="checkbox__label">
                          <span className="checkbox__text">
                            רשימת אנשי קשר בסמארטפון
                          </span>
                        </label>
                      </div>
                      <div className="checkbox">
                        <input
                          id="c_5"
                          className="checkbox__input"
                          type="checkbox"
                          value={"וואץ אפ"}
                          checked={selectedValues.includes("וואץ אפ")}
                          onChange={(e) => {
                            handleCheckBoxChange(e);
                            whatsappShare(e);
                          }}
                          name="app-type"
                        />
                        <label htmlFor="c_5" className="checkbox__label">
                          <span className="checkbox__text">וואץ אפ</span>
                        </label>
                      </div>
                      <div className="checkbox">
                        <input
                          id="c_6"
                          className="checkbox__input"
                          type="checkbox"
                          value={"אינסטגרם"}
                          checked={selectedValues.includes("אינסטגרם")}
                          onChange={(e) => {
                            handleCheckBoxChange(e);
                            instagramShare(e);
                          }}
                          name="app-type"
                        />
                        <label htmlFor="c_6" className="checkbox__label">
                          <span className="checkbox__text">אינסטגרם</span>
                        </label>
                      </div>
                    </div>
                    <p className="credentials-section__text">
                      or use invite link
                      <br />
                      <a href={finalInviteUrl} onClick={(e) => browserShare(e)}>
                        {finalInviteUrl}
                      </a>
                    </p>

                    <p className="form-credentials-section__text-under-checks">
                      שים לב, רשימת המתנות שלך תהיה חשופה לעיני חבריך ברגע
                      שתזמין אותם לאתר
                    </p>
                    <div className="form-credentials-section__buttons-container">
                      <button type="submit" className="btn">
                        להזמנת חברים לרשימת המשאלות
                      </button>
                    </div>
                  </form>
                </div>
              </>
            )}

            <div className="credentials-section__progress">
              <div className="percent" style={{ right: progress + "%" }}>
                {`${progress}%`}
              </div>
              <div className="bar">
                <div className="background" />
                <div
                  className="percent-line"
                  style={{ width: progress + "%" }}
                />
              </div>
            </div>
            {isDecor && (
              <div className="credentials-section__decor">
                <img src={mainDecor} alt="decor" />
              </div>
            )}
          </div>
        </div>
      </section>
    </main>
  );
}

export default UpdateEvent;
