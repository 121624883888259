import React, { useEffect, useState } from "react";
// import CategorySingleCard from "../Components/CategorySingleCard";
import MyBankSingleCategory from "./MyBankSingleCategory";
import axios from "axios";
import { NavLink, useNavigate, useParams } from "react-router-dom/dist";
import { API_DOMAIN } from "../../Libs/config";

export default function MyBankCategory({ allCategoriesList, newEvent }) {
  const [categories, setCategories] = useState([]);
  const navigate = useNavigate();
  console.log("<Category newEvent", newEvent);
  const URLParams = new URLSearchParams(window.location.search);
  const currWishlistId = URLParams?.get("wishlistId");

  const { categoryId } = useParams();
  // const categoryId = 1;

  useEffect(() => {
    if (allCategoriesList) {
      setCategories(() => {
        const currentMainCategory = allCategoriesList.find(
          (category) => category.mainCategory.id === Number(categoryId)
        );

        return currentMainCategory.productCategory;
      });
    }
  }, [allCategoriesList]);

  useEffect(() => {
    const currentUser = localStorage.getItem("currUserObject");
    const isCatNewEvent = window.location.href.includes('category-new-event')
    if (!currentUser) {
      if(!isCatNewEvent){
        navigate("/credentials");
      }
    } else {
      // axios
      //   .get(
      //     `${API_DOMAIN}/api/ProductCategory/GetCategories`
      //   )
      //   .then((response) => {
      //     const getCategories = response.data.result.categories;
      //     const resultCategories = singleCategories.filter((obj) =>
      //       getCategories.some((item) => item.id === obj.id)
      //     );
      //     setCategories(resultCategories);
      //   })
      //   .catch((error) => {
      //     console.error(error);
      //     alert("Error, please try again");
      //   });
    }
  }, []);

  return (
    <main className="category">
      <section className="category__topbar topbar">
        <div className="topbar__bg-img-ibg">
          <img
            src="../img/common/topbar-category.png"
            alt="topbar"
            className="img-people"
          />
          <img
            src="../img/no-people-images/common/topbar-category.png"
            alt="topbar"
            className="img-no-people"
          />
        </div>
        <div className="topbar__container">
          <div className="topbar__title-wrapper">
            <h1 className="topbar__title">מתנות מומלצות ליום הולדת לנשים</h1>
          </div>
        </div>
      </section>

      <div className="category__breadcrumbs breadcrumbs">
        <div className="breadcrumbs__container">
          <ul className="breadcrumbs__list">
            <li className="breadcrumbs__item">
              <NavLink to="/">דף הבית</NavLink>
            </li>
            <li className="breadcrumbs__item">
              <NavLink to="/my-events">my events</NavLink>
            </li>
            <li className="breadcrumbs__item">
              <NavLink
                to={
                  newEvent
                    ? `/all-categories-new-events?wishlistId=${currWishlistId}`
                    : `/all-categories?wishlistId=${currWishlistId}`
                }
              >
                categories
              </NavLink>
            </li>
            <li className="breadcrumbs__item">
              <p>category</p>
            </li>
          </ul>
        </div>
      </div>

      <section className="category__main main-category">
        <div className="main-category__container">
          {/* <div className="main-category__filter-btn-container filter-btn-container">
            <button type="button" className="filter-btn">
              <img src="../img/svg/filter-icon.png" alt="filter icon" />
            </button>
          </div> */}

          <div className="main-category__steps-numbers numbers-steps step-2">
            <ul className="numbers-steps__list">
              <li className="numbers-steps__list-item">
                <div className="numbers-steps__step">1</div>
              </li>
              <li className="numbers-steps__list-item">
                <div className="numbers-steps__step">2</div>
              </li>
              <li className="numbers-steps__list-item">
                <div className="numbers-steps__step">3</div>
              </li>
              <li className="numbers-steps__list-item">
                <div className="numbers-steps__step">4</div>
              </li>
            </ul>
          </div>

          <h2 className="main-category__title">
            להתחיל לבחור את המתנות המושלמות למסיבה שלך
          </h2>

          <ul className="main-category__list">
            {/* {singleCategories &&
              singleCategories.map((category) => (
                <CategorySingleCard data={category} key={category.id} />
              ))} */}
            {categories &&
              categories.map((category) => (
                <MyBankSingleCategory
                  data={category}
                  key={category.id}
                  mainCategoryId={categoryId}
                  currWishlistId={currWishlistId}
                  newEvent={newEvent}
                />
              ))}
          </ul>
        </div>
      </section>
    </main>
  );
}
