import React, { useEffect, useState } from "react";
import { cart1, giftBasketTopbar, topbarBg } from "../Assets/images";
import SinglePurchase from "../Components/purchase-single";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { API_DOMAIN } from "../Libs/config";

function Cart({
  currWishlist,
  setCurrWishlist,
  cartForFriendsList,
  setCartForFriendsList,
  currUserObject,
}) {
  const { wishlistId } = useParams();

  const [sortFilter, setSortFilter] = useState({
    isOpen: false,
    filter: "date",
    sortType: "asc",
  });
  const [cartType, setCartType] = useState("purchased");

  const handleSortDrop = () => {
    const newValue = !sortFilter.isOpen;
    setSortFilter({
      isOpen: newValue,
      filter: sortFilter.filter,
      sortType: sortFilter.sortType,
    });
  };

  const setFilterPrice = () => {
    setSortFilter({
      isOpen: false,
      filter: "price",
      sortType: sortFilter.sortType,
    });
  };
  const setFilterDate = () => {
    setSortFilter({
      isOpen: false,
      filter: "date",
      sortType: sortFilter.sortType,
    });
  };
  const setFilterStatus = () => {
    setSortFilter({
      isOpen: false,
      filter: "status",
      sortType: sortFilter.sortType,
    });
  };

  useEffect(() => {
    //return;
    const sortType = sortFilter.sortType;
    const currentActiveTab = cartType;
    const sortBy = sortFilter.filter;
    console.log(sortFilter);

    setCurrWishlist((prevObj) => {
      const newObj = prevObj;
      const prevArr = prevObj.whishlistItemsDetailsList;
      let filteredArr = [];
      if (prevArr && prevArr.length) {
        filteredArr = prevArr;

        filteredArr.sort((a, b) => {
          const aValue = String(a[sortBy]);
          const bValue = String(b[sortBy]);
          if (sortType === "asc") {
            return aValue.localeCompare(bValue);
          } else {
            return bValue.localeCompare(aValue);
          }
        });
        console.log(filteredArr);
      }

      // newObj.whishlistItemsDetailsList = filteredArr;
      return { ...newObj, whishlistItemsDetailsList: filteredArr };
    });
  }, [sortFilter]);

  function isStatusRelevantByCartType(productStatus) {
    const conditionPurchased = /buy by me|buy by buyer/; // Regular expression for condition when selectedStatus is "purchased"
    const conditionNotPurchased = /not buy/; // Regular expression for condition when selectedStatus is "unpurchased"
    if (cartType == "purchased") {
      return conditionPurchased.test(productStatus);
    } else {
      return conditionNotPurchased.test(productStatus);
    }
  }

  const navigate = useNavigate();

  useEffect(() => {
    const currentUser = localStorage.getItem("currUserObject");
    if (!currentUser) {
      navigate("/credentials");
    } else {
      if (!currWishlist.whishlistObject) {
        console.log("no current wishlist");
        axios
          .get(
            `${API_DOMAIN}/api/ProductCategory/GetWishlistObject?id=${wishlistId}`
          )
          .then((response) => {
            console.log(response.data);
            if (response.data.result) {
              // setLoading(false);
              console.log("whishlist object", response.data);
              setCurrWishlist({
                whishlistItemsList: response.data.whishlistItemsList,
                whishlistObject: response.data.whishlistObject,
                whishlistItemsDetailsList:
                  response.data.whishlistItemsDetailsList,
              });
            } else {
              //setLoading(false);
              alert("Something went wrong");
            }
          })
          .catch((error) => {
            console.error(error);
            //setLoading(false);
            alert("Error, please try again");
          });
      }
    }
  }, []);

  return (
    <main className="gift-basket">
      <section className="gift-basket__topbar topbar">
        <div className="topbar__bg-img-ibg">
          <img src={topbarBg} alt="topbar" />
        </div>
        <div className="topbar__container">
          <div className="topbar__title-wrapper">
            <h1 className="topbar__title">
              סל המתנות שלי
              <span className="icon">
                <img src={giftBasketTopbar} alt="gift basket topbar" />
              </span>
            </h1>
          </div>
        </div>
      </section>
      <div className="gift-basket__breadcrumbs breadcrumbs">
        <div className="breadcrumbs__container">
          <ul className="breadcrumbs__list">
            <li className="breadcrumbs__item">
              <NavLink to="/">דף הבית</NavLink>
            </li>
            <li className="breadcrumbs__item">
              <NavLink to="/my-events">my events</NavLink>
            </li>
            <li className="breadcrumbs__item">
              <p>מתנות מומלצות ליום הולדת לנשים</p>
            </li>
          </ul>
        </div>
      </div>
      <section className="gift-basket__main main-gift-basket">
        <div className="main-gift-basket__container">
          <div className="main-gift-basket__navigation">
            <ul className="main-gift-basket__nav-list">
              <li
                className={`main-gift-basket__nav-item red ${
                  cartType === "purchased" ? "active" : ""
                }`}
              >
                <button onClick={() => setCartType("purchased")}>
                  <span className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="19.091"
                      height="17.645"
                      viewBox="0 0 19.091 17.645"
                    >
                      <path
                        id="heart"
                        d="M31.335,33.9l6.867,6.94,7.376-7.454a4.943,4.943,0,0,0,0-6.938,4.818,4.818,0,0,0-6.865,0l-.508.514-.509-.514a4.818,4.818,0,0,0-6.865,0,4.943,4.943,0,0,0,0,6.938l.506.511Z"
                        transform="translate(-28.66 -24.26)"
                        fill="none"
                        stroke="#a7a7a7"
                        strokeMiterlimit={10}
                        strokeWidth="1.5"
                      />
                    </svg>
                  </span>
                  <span className="text">המתנות שלי</span>
                </button>
              </li>
              <li
                className={`main-gift-basket__nav-item green ${
                  cartType === "unpurchased" ? "active" : ""
                }`}
              >
                <button onClick={() => setCartType("unpurchased")}>
                  <span className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="19.091"
                      height="17.645"
                      viewBox="0 0 19.091 17.645"
                    >
                      <path
                        id="heart"
                        d="M31.335,33.9l6.867,6.94,7.376-7.454a4.943,4.943,0,0,0,0-6.938,4.818,4.818,0,0,0-6.865,0l-.508.514-.509-.514a4.818,4.818,0,0,0-6.865,0,4.943,4.943,0,0,0,0,6.938l.506.511Z"
                        transform="translate(-28.66 -24.26)"
                        fill="none"
                        stroke="#a7a7a7"
                        strokeMiterlimit={10}
                        strokeWidth="1.5"
                      />
                    </svg>
                  </span>
                  <span className="text">מתנות שלא נרכשו</span>
                </button>
              </li>
            </ul>
          </div>
          <div className="main-gift-basket__header">
            <p className="main-gift-basket__header-product-name">שם המוצר</p>
            <p className="main-gift-basket__header-product-price">מחיר</p>
            <div className="main-gift-basket__header-product-filter">
              <div className="header-filter__drop">
                <button
                  type="button"
                  onClick={handleSortDrop}
                  className="header-filter__button"
                >
                  <p className="text">
                    {sortFilter.filter === "date"
                      ? "תאריך"
                      : sortFilter.filter === "price"
                      ? "מחיר"
                      : sortFilter.filter === "status"
                      ? "סטָטוּס"
                      : ""}
                  </p>
                </button>
                {sortFilter.isOpen && (
                  <div className="header-filter__body">
                    <ul className="header-filter__list">
                      <li className="header-filter__item">
                        <button type="button" onClick={setFilterPrice}>
                          מחיר
                        </button>
                      </li>
                      <li className="header-filter__item">
                        <button type="button" onClick={setFilterDate}>
                          תאריך
                        </button>
                      </li>
                      <li className="header-filter__item">
                        <button type="button" onClick={setFilterStatus}>
                          סטָטוּס
                        </button>
                      </li>
                    </ul>
                  </div>
                )}
              </div>

              <button
                type="button"
                onClick={() =>
                  setSortFilter({
                    isOpen: false,
                    filter: sortFilter.filter,
                    sortType: "desc",
                  })
                }
                className="arrow bottom"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17.117"
                  height="9.115"
                  viewBox="0 0 17.117 9.115"
                >
                  <g
                    id="Сгруппировать_912"
                    data-name="Сгруппировать 912"
                    transform="translate(19.135 -7.296) rotate(90)"
                  >
                    <g
                      id="Сгруппировать_540"
                      data-name="Сгруппировать 540"
                      transform="translate(14.998 17.723) rotate(180)"
                    >
                      <line
                        id="Линия_434"
                        data-name="Линия 434"
                        y1="7.147"
                        x2="6.289"
                        transform="translate(0)"
                        fill="none"
                        stroke="#000"
                        strokeLinecap="round"
                        strokeWidth={2}
                      />
                      <path
                        id="Контур_6897"
                        data-name="Контур 6897"
                        d="M0,0,6.289,6.861"
                        transform="translate(0 7.432)"
                        fill="none"
                        stroke="#000"
                        strokeLinecap="round"
                        strokeWidth={2}
                      />
                    </g>
                  </g>
                </svg>
              </button>
              <button
                type="button"
                onClick={() =>
                  setSortFilter({
                    isOpen: false,
                    filter: sortFilter.filter,
                    sortType: "asc",
                  })
                }
                className="arrow top"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17.117"
                  height="9.115"
                  viewBox="0 0 17.117 9.115"
                >
                  <g
                    id="Сгруппировать_913"
                    data-name="Сгруппировать 913"
                    transform="translate(19.708 -6.837) rotate(90)"
                  >
                    <g
                      id="Сгруппировать_539"
                      data-name="Сгруппировать 539"
                      transform="translate(8.25 4.002)"
                    >
                      <line
                        id="Линия_434"
                        data-name="Линия 434"
                        y1="7.147"
                        x2="6.289"
                        transform="translate(0 0)"
                        fill="none"
                        stroke="#000"
                        strokeLinecap="round"
                        strokeWidth={2}
                      />
                      <path
                        id="Контур_6897"
                        data-name="Контур 6897"
                        d="M0,0,6.289,6.861"
                        transform="translate(0 7.432)"
                        fill="none"
                        stroke="#000"
                        strokeLinecap="round"
                        strokeWidth={2}
                      />
                    </g>
                  </g>
                </svg>
              </button>
            </div>
          </div>

          <div className="main-gift-basket__purchased">
            <div className="main-gift-basket__body">
              <ul className="main-gift-basket__my-purchases-list">
                {currWishlist &&
                  currWishlist.whishlistItemsDetailsList &&
                  currWishlist.whishlistItemsDetailsList.map((product, index) =>
                    isStatusRelevantByCartType(product.status) ? (
                      <li
                        key={index}
                        className="main-gift-basket__my-purchases-item"
                      >
                        <SinglePurchase
                          data={product}
                          currUserObject={currUserObject}
                          currWishlist={currWishlist}
                          setCurrWishlist={setCurrWishlist}
                        />
                      </li>
                    ) : (
                      ""
                    )
                  )}
              </ul>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Cart;
