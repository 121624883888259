import React from "react";
import { NavLink } from "react-router-dom";
import { API_DOMAIN_IMG } from "../Libs/config";
import { eventTypeImgNoPeople1 } from "../Assets/images";


export default function CategoryCard({
  // data: { imgUrl, imgNoPeopleUrl, icon, title, btnText, link, id },
  data: { id, categoryIcon, categoryImage, categoryName },
  wishlistId,
  newEvent
}) {

  const URLParams = new URLSearchParams(window.location.search);
  const currWishlistId = Boolean(URLParams?.get("isBirthdayEvent"));

  return (
    <li className="main-all-categories__list-item">
      <div className="main-all-categories__single-event-type single-event-type">
        <NavLink
          to={newEvent ? `/category-new-event/${id}${wishlistId ? "?wishlistId=" + wishlistId : ""}${currWishlistId ? '&currWishlistId=' +currWishlistId : ""}`
                      : `/category/${id}${wishlistId ? "?wishlistId=" + wishlistId : ""}`}
          state={{
            id: id,
          }}
          className="single-event-type__content"
        >
          <div className="single-event-type__image-ibg">
            <img src={`${API_DOMAIN_IMG}${categoryImage}`} alt="" className="img-people" />
            <img src={eventTypeImgNoPeople1} alt="" className="img-no-people" />
          </div>
          <div className="single-event-type__text-content">
            <div className="single-event-type__icon">
              <img src={`${API_DOMAIN_IMG}${categoryIcon}`} alt="" />{" "}
            </div>
            <h3 className="single-event-type__title">{categoryName}</h3>
            <div className="single-event-type__btn-container btn-container btn-container_center">
              <button className="btn">Choose</button>
            </div>
          </div>
        </NavLink>
      </div>
    </li>
  );
}
