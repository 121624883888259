import React from "react";
import { blessingBg, blessingBgNoPeople } from "../Assets/images";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { NavLink } from "react-router-dom";

function SuccessfullPaymentReferal({ setUserNotFirstTime }) {
  const navigate = useNavigate();


  const URLParams = new URLSearchParams(window.location.search);
  const didRegister = URLParams?.get("didRegister");
  const inviteWishlistId = URLParams?.get("inviteWishlistId");
  console.log('didRegister', didRegister)
  useEffect(() => {
    const currentUser = localStorage.getItem("currUserObject");
    if (!currentUser) {
      navigate("/credentials");
    }
  }, []);

  const updateNotFirstTime = () => {
    setUserNotFirstTime(true)
    localStorage.setItem("userNotFirstTime", true);

    // navigates to my-events and opens the first tab with categories
    navigate('/my-events', { state: { openCategories: true }})
    window.location.reload()
  }

  return (
    <main className="successful-payment" data-fullscreen>
      <section className="successful-payment__main main-successful-payment">
        <div className="main-successful-payment__bg-image-ibg">
          <img src={blessingBg} className="img-people" alt="bg" />
          <img src={blessingBgNoPeople} className="img-no-people" alt="bg" />
        </div>
        <div className="main-successful-payment__content-window">
          <h1 className="main-successful-payment__title">להתראות</h1>
          <h2 className="main-successful-payment__subtitle">
            סיימת את התהליך קניית המתנה
          </h2>
          <button style={{ paddingLeft: '2em' }} onClick={updateNotFirstTime} className="main-successful-payment__subtitle btn"
            // to={`${didRegister == "true" ? `/explaining-steps-referal/?inviteWishlistId=${inviteWishlistId}` : '/'}`}
            // to={`/my-events`}
          >
            Home
          </button>
        </div>
      </section>
    </main>
  );
}

export default SuccessfullPaymentReferal;
