import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { API_DOMAIN_IMG } from "../Libs/config";
import axios from "axios";
import { API_DOMAIN } from "../Libs/config";
import { useParams } from "react-router-dom";



export default function CategoryNewEventSingleCard({
  data: {
    categoryIcon,
    categoryImage,
    creationDate,
    description,
    id,
    niceName,
  },
  mainCategoryId,
  currWishlistId,
  newEvent,
  allCategoriesList,
  filterFunction,
  setFilterObject,
  filterObject
}) {

  const { categoryId } = useParams();

  const [manufacturers, setManufacturers] = useState([]);
  const [products, setProducts] = useState([]);
  const [productsAll, setProductsAll] = useState([]);
  const navigate = useNavigate();

  const navigateToProducts = (e) => {
    const { target } = e;
    if (target.closest('.single-category-type__footer')) return

    if (newEvent) {
      navigate(`/products-new-event/${mainCategoryId}/${id}?wishlistId=${currWishlistId}`)
    } else {
      navigate(`/products/${mainCategoryId}/${id}?wishlistId=${currWishlistId}`)
    }
  }


  useEffect(() => {
    if (productsAll.length) {
      const manufacturersArr = productsAll.reduce((result, current) => {
        const { manufacturer } = current;
        const isDuplicate = result.some(
          (resultManufacturer) => resultManufacturer === manufacturer
        );

        if (!isDuplicate && manufacturer !== "") {
          result.push(manufacturer);
        }

        return result;
      }, []);
      // console.log('manufacturersArr:', manufacturersArr)
      setManufacturers(manufacturersArr);
      setFilterObject((prevObj) => {
        const newObj = { ...prevObj, manufacturers: manufacturersArr, lastChangedType: "" };
        return newObj;
      });
    }
  }, [productsAll]);

  const getProductsByCategoriesList = (idList) => {

    // console.log('getProductsByCategoriesList: ', idList,idList.length)
    if (idList.length) {
      // console.log('getProductsByCategoriesList: inside if', idList)
      setProducts([]);
      setProductsAll([]);
      let finalArray = [];
      let i = 0;
      idList.forEach(async (item) => {
        await axios
          .get(
            `${API_DOMAIN}/api/ProductCategory/GetProductsByCategoryId?categoryId=${item.productsId}`
          )
          .then((response) => {
            // console.log(response.data.result.products);
            const allProducts = response.data.result.products;
            finalArray = [...finalArray, ...allProducts];

            i++;
            if (i === idList.length) {
              // console.log(finalArray);
              setProducts(finalArray);
              setProductsAll(finalArray);
            }
          })
          .catch((error) => {
            console.error(error);
            i++;
            alert("Error, please try again");
          });
      });
    }
  };

  useEffect(() => {
    // console.log('filterObject.choosedCategoriesList', filterObject.choosedCategoriesList)
    getProductsByCategoriesList([{ categoryId, productsId: id }]);
    setFilterObject((prevObj) => {
      const newObj = { ...prevObj, manufacturers: [], lastChangedType: "category" };
      return newObj;
    });
    // setFilterObject((prevObj) => {
    //   const newObj = {
    //     ...prevObj,
    //     choosedCategoriesList: [
    //       { categoryId, productsId: id },
    //     ],
    //   };
    //   return newObj;
    // });
    document.querySelectorAll(".manufacturer-check").forEach((item) => {
      if (item.checked) item.checked = false;
    });

    return () => {

    }
  }, [])

  return (
    <li className="main-category__list-item">
      <div className="main-category__single-category-type single-category-type">
        <div
          onClick={navigateToProducts}
          className="single-category-type__content active"
        >
          <div className="single-category-type__image-ibg">
            {/* <img src={imgUrl} alt="1" className="img-people" />
            <img src={imgNoPeople} alt="1" className="img-no-people" /> */}
            <img src={`${API_DOMAIN_IMG}${categoryImage}`} alt="" />
          </div>
          <div className="single-category-type__text-content" style={{paddingBottom:"1vw"}}>
            <div className="single-category-type__icon"><img src={`${API_DOMAIN_IMG}${categoryIcon}`} alt="" /> </div>
            <h3 className="single-category-type__title">{niceName}</h3>
            <p className="single-category-type__text">{description}</p>
            <div className="single-category-type__btn-container btn-container btn-container_center">
              <button className="btn">Choose</button>
            </div>
            {/* <div className="single-category-type__footer">
              <div className="single-category-type__dropdown">
                <button
                  type="button"
                  className="single-category-type__dropdown-btn"
                >
                  <img src="../img/svg/filter-icon-black.svg" alt="filter" />
                  ביחרו קטגוריות למתנות
                  <img
                    src="../img/svg/arrow-bottom-black.svg"
                    alt="arrow bottom"
                  />
                </button>
                <div className="single-category-type__dropdown-body">
                  <ul className="single-category-type__dropdown-list">
          
                    {manufacturers &&
                      manufacturers.map((manufacturer, index) => (
                        <div key={index} className="checkbox">
                          <input
                            id={`manufacturer_${index}`}
                            className="checkbox__input manufacturer-check"
                            type="checkbox"
                            defaultValue={index}
                            name="form[]"
                            onChange={(e) =>
                              filterFunction(e, null, manufacturer)
                            }
                          />
                          <label
                            htmlFor={`manufacturer_${index}`}
                            className="checkbox__label"
                          >
                            <span className="checkbox__text" style={{ color: "#000" }}>{manufacturer}</span>
                          </label>
                        </div>
                      ))}
                  </ul>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </li>
  );
}
