import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { API_DOMAIN_IMG } from "../Libs/config";
export default function CategorySingleCard({
  // data: {
  //   imgUrl,
  //   imgNoPeople,
  //   icon,
  //   title,
  //   btnText,
  //   link,
  //   id,
  //   text,
  //   categoriesTitle,
  // },
  data: {
    categoryIcon,
    categoryImage,
    creationDate,
    description,
    id,
    niceName,
  },
  mainCategoryId,
  currWishlistId,
  newEvent,
  allCategoriesList
}) {

  const navigate = useNavigate();

  const navigateToProducts = (e) => {
    const { target } = e;
    if (target.closest('.single-category-type__footer')) return

    if (newEvent) {
      navigate(`/products-new-event/${mainCategoryId}/${id}?wishlistId=${currWishlistId}`)
    } else {
      navigate(`/products/${mainCategoryId}/${id}?wishlistId=${currWishlistId}`)
    }
  }


  console.log('allCategoriesList_CategorySingleCard', allCategoriesList)
  return (
    <li className="main-category__list-item">
      <div className="main-category__single-category-type single-category-type">
        <div
          // to={newEvent ? `/products-new-event/${mainCategoryId}/${id}?wishlistId=${currWishlistId}` :
          //   `/products/${mainCategoryId}/${id}?wishlistId=${currWishlistId}`}
          onClick={navigateToProducts}
          className="single-category-type__content active"
        >
          <div className="single-category-type__image-ibg">
            {/* <img src={imgUrl} alt="1" className="img-people" />
            <img src={imgNoPeople} alt="1" className="img-no-people" /> */}
            <img src={`${API_DOMAIN_IMG}${categoryImage}`} alt="" />
          </div>
          <div className="single-category-type__text-content">
            <div className="single-category-type__icon"><img src={`${API_DOMAIN_IMG}${categoryIcon}`} alt="" /> </div>
            <h3 className="single-category-type__title">{niceName}</h3>
            <p className="single-category-type__text">{description}</p>
            <div className="single-category-type__btn-container btn-container btn-container_center">
              <button className="btn">Choose</button>
            </div>
            <div className="single-category-type__footer">
              <div className="single-category-type__dropdown">
                <button
                  type="button"
                  className="single-category-type__dropdown-btn"
                >
                  <img src="../img/svg/filter-icon-black.svg" alt="filter" />
                  ביחרו קטגוריות למתנות
                  <img
                    src="../img/svg/arrow-bottom-black.svg"
                    alt="arrow bottom"
                  />
                </button>
                <div className="single-category-type__dropdown-body">
                  <ul className="single-category-type__dropdown-list">
                    {
                      allCategoriesList &&
                      allCategoriesList[0].productCategory.map((cat) => {

                        return (
                          <li
                            key={cat.id}
                            className={`single-category-type__dropdown-list-item `}
                          >
                            {/* <p>{cat.niceName}</p> */}
                            <p>{cat.description}</p>
                          </li>
                        );
                      })
                    }
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
}
