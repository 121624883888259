import React from "react";
import { NavLink } from "react-router-dom";
import { API_DOMAIN_IMG } from "../../Libs/config";
import { blessingImgNoPeople } from "../../Assets/images";

export default function MyBankCategoryCard({
  // data: { imgUrl, imgNoPeopleUrl, icon, title, btnText, link, id },
  data: { id, categoryIcon, categoryImage, categoryName },
  wishlistId,
  newEvent
}) {

  console.log('MyBankCategoryCard: id', id)
  return (
    <li className="main-all-categories__list-item">
      <div className="main-all-categories__single-event-type single-event-type">
        <NavLink
          to={`/my-bank-category/${id}?wishlistId=${wishlistId}`}
          state={{
            id: id,
          }}
          className="single-event-type__content"
        >
          <div className="single-event-type__image-ibg"> 
            <img src={`${API_DOMAIN_IMG}${categoryImage}`} alt="" className="img-people" />
            <img src={blessingImgNoPeople} alt="" className="img-no-people" />
          </div>
          <div className="single-event-type__text-content">
            <div className="single-event-type__icon">
              <img src={`${API_DOMAIN_IMG}${categoryIcon}`} alt="" />{" "}
            </div>
            <h3 className="single-event-type__title">{categoryName}</h3>
            <div className="single-event-type__btn-container btn-container btn-container_center">
              <button className="btn">Choose</button>
            </div>
          </div>
        </NavLink>
      </div>
    </li>
  );
}
