import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";

export default function ItemAddedToCartPopup({
  updatePopupState,
  addItemHandler,
  isItemAdded,
  currWishlist,
}) {
  const URLParams = new URLSearchParams(window.location.search);
  const currWishlistId = URLParams?.get("wishlistId");

  useEffect(() => {
    return () => {
      updatePopupState(false);
    };
  }, []);

  const closePopupHandler = () => {
    addItemHandler((prevObj) => {
      const newObj = { ...prevObj, added: false };
      return newObj;
    });
    updatePopupState(false);
  };

  return (
    <div
      id="item-added-to-cart-popup"
      aria-hidden="true"
      className="popup popup_show"
    >
      <div className="popup__wrapper">
        <div className="popup__content">
          <div className="popup__bg-ibg">
            <img src="/img/popups/popup-green-bg.png" alt="green hearts" />
          </div>
          <button
            data-close
            type="button"
            onClick={closePopupHandler}
            className="popup__close"
          ></button>
          <div className="popup__body">
            <div className="popup__image-ibg_contain">
              <img
                src="/img/popups/item-added-to-cart.svg"
                alt="item added to cart"
              />
            </div>
            <h3 className="popup__title">
              המוצר התווסף לסל בקופה יש ל {isItemAdded.prodPrice} שקל ובסל
              המוצרים יש לך {isItemAdded.wishlistPrice}
            </h3>
            <p className="popup__price">
              <span className="text">{isItemAdded.prodPrice}</span>
              <span className="money-icon">₪</span>
            </p>
            <div className="popup__buttons-container btn-container-center">
              <button
                type="button"
                data-close
                onClick={closePopupHandler}
                className="btn-white gift"
              >
                להמשיך לבחור מתנות
                <span className="icon">
                  <img src="/img/svg/gift-black.svg" alt="" />
                </span>
              </button>
              <NavLink
                to={`/cart/${currWishlist.whishlistObject.id}`}
                onClick={closePopupHandler}
                data-close
                className="btn-white favorites"
                data-count="2"
              >
                לצפיה בסל המתנות שלי
                <span className="icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="19.846"
                    height="24.798"
                    viewBox="0 0 19.846 24.798"
                  >
                    <g
                      id="Сгруппировать_74057"
                      data-name="Сгруппировать 74057"
                      transform="translate(0.5 0.5)"
                    >
                      <path
                        id="Artboard_2"
                        data-name="Artboard 2"
                        d="M84.786,18.61a7.137,7.137,0,0,1,1.2.467,2.838,2.838,0,0,1,1.406,2.495c.014.656,0,1.314,0,2H90a1.672,1.672,0,0,1,1.764,1.55q.562,4.176,1.116,8.353c.31,2.332.627,4.663.93,7a1.654,1.654,0,0,1-1.319,1.9.426.426,0,0,0-.082.038H76.418A1.88,1.88,0,0,1,75.31,41.7a1.6,1.6,0,0,1-.3-1.14q.439-3.333.886-6.671.4-3.047.81-6.1c.124-.93.243-1.859.372-2.784a1.661,1.661,0,0,1,1.612-1.432c.821-.015,1.641,0,2.463-.006.292-.1.292-.193.292-.282a20.1,20.1,0,0,1,.043-2.111,2.906,2.906,0,0,1,2.455-2.53.644.644,0,0,0,.105-.04Z"
                        transform="translate(-74.992 -18.61)"
                        fill="none"
                        stroke="#707070"
                        strokeWidth="1"
                      ></path>
                      <path
                        id="heart"
                        d="M30.6,30.513l4.251,4.3,4.566-4.615a3.06,3.06,0,0,0,0-4.3,2.983,2.983,0,0,0-4.25,0l-.315.318L34.54,25.9a2.983,2.983,0,0,0-4.25,0,3.06,3.06,0,0,0,0,4.3l.313.316Z"
                        transform="translate(-25.26 -15.21)"
                        fill="#ec1b53"
                      ></path>
                    </g>
                  </svg>
                </span>
              </NavLink>
              <a href="#" className="btn-white money">
                לתשלום
                <span className="icon">
                  <img src="/img/svg/money-icon.png" alt="money icon" />
                </span>
              </a>
              <NavLink
                to={`/create-event-second-step/${currWishlistId}`}
                onClick={closePopupHandler}
                data-close
                className="btn-white continue-edit"
              >
                Continue event creation
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
