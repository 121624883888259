import React, { useEffect, useRef, useState } from "react";
import { Routes, Route, NavLink } from "react-router-dom";

import logoImg from "../Assets/img/logo.png";
import {
  americanExpress,
  bit,
  mastercard,
  mastercardBlue,
  paybox,
  paypal,
  pci,
  visa,
} from "../Assets/images";

function Footer() {
  return (
    <footer className="footer">
      <div className="footer__top top-footer">
        <div className="top-footer__container">
          <div className="top-footer__body">
            <div className="top-footer__titles">
              <h2 className="top-footer__title">?רוצים לקבל ניוזלטר</h2>
              <h3 className="top-footer__subtitle">
                מלאו פרטיכם וקבלו מאיתנו את המוצרים הכי עדכניים
              </h3>
            </div>
            <div className="top-footer__form-block">
              <form action="#" className="top-footer__form">
                <label
                  htmlFor="top-footer__email"
                  className="top-footer__input-wrapper"
                >
                  <input
                    autoComplete="off"
                    type="email"
                    name="email"
                    id="top-footer__email"
                    placeholder="מלאו את האימייל"
                  />
                  <button type="submit" className="btn">
                    שלחו
                  </button>
                </label>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="footer__container">
        <div className="footer__body">
          <div className="footer__col">
            <h3 className="footer__title">מה האירוע שלך?</h3>
            <ul className="footer__links-list">
              <li className="footer__links-item">
                <a className="footer__link" href="#">
                  יום הולדת לנשים
                </a>
              </li>
              <li className="footer__links-item">
                <a className="footer__link" href="#">
                  יום הולדת לגברים
                </a>
              </li>
              <li className="footer__links-item">
                <a className="footer__link" href="#">
                  מסיבת אירוסין
                </a>
              </li>
              <li className="footer__links-item">
                <a className="footer__link" href="#">
                  חינה
                </a>
              </li>
              <li className="footer__links-item">
                <a className="footer__link" href="#">
                  מסיבת רווקות
                </a>
              </li>
              <li className="footer__links-item">
                <a className="footer__link" href="#">
                  חנוכת בית
                </a>
              </li>
              <li className="footer__links-item">
                <a className="footer__link" href="#">
                  יום הודלת לנער/ה
                </a>
              </li>
              <li className="footer__links-item">
                <a className="footer__link" href="#">
                  יום הולדת לילד/ה
                </a>
              </li>
              <li className="footer__links-item">
                <a className="footer__link" href="#">
                  מסיבה לתינוק וליולדת
                </a>
              </li>
              <li className="footer__links-item">
                <a className="footer__link" href="#">
                  גיוס קל
                </a>
              </li>
            </ul>
          </div>
          <div className="footer__col">
            <h3 className="footer__title">מפת אתר</h3>
            <ul className="footer__links-list">
              <li className="footer__links-item">
                <a className="footer__link" href="#">
                  דף הבית
                </a>
              </li>
              <li className="footer__links-item">
                <a className="footer__link" href="#">
                  ?איך זה עובד
                </a>
              </li>
              <li className="footer__links-item">
                <a className="footer__link" href="#">
                  אירוע חדש
                </a>
              </li>
              <li className="footer__links-item">
                <a className="footer__link" href="#">
                  אירועים שלי
                </a>
              </li>
              <li className="footer__links-item">
                <a className="footer__link" href="#">
                  הקופה שלי
                </a>
              </li>
              <li className="footer__links-item">
                <a className="footer__link" href="#">
                  האירועים שהוזמנתי
                </a>
              </li>
              <li className="footer__links-item">
                <a className="footer__link" href="#">
                  תנאי שימוש
                </a>
              </li>
              <li className="footer__links-item">
                <a className="footer__link" href="#">
                  תקנון
                </a>
              </li>
              <li className="footer__links-item">
                <a className="footer__link" href="#">
                  צור קשר
                </a>
              </li>
              <li className="footer__links-item">
                <a className="footer__link" href="#">
                  פרטים אישיים
                </a>
              </li>
            </ul>
          </div>
          <div className="footer__col footer__col_big">
            <div className="footer__col-body">
              <div className="footer__col-item">
                <h3 className="footer__title">אתם מזמנים</h3>
                <ul className="footer__links-list">
                  <li className="footer__links-item">
                    <a className="footer__link" href="#">
                      האירועים שלי
                    </a>
                  </li>
                  <li className="footer__links-item">
                    <a className="footer__link" href="#">
                      היסטוריית אירועים
                    </a>
                  </li>
                  <li className="footer__links-item">
                    <a className="footer__link" href="#">
                      סל המתנות שלי
                    </a>
                  </li>
                </ul>
              </div>
              <div className="footer__col-item">
                <h3 className="footer__title">אתם מוזמנים</h3>
                <ul className="footer__links-list">
                  <li className="footer__links-item">
                    <a className="footer__link" href="#">
                      האירועים שהוזמנתי
                    </a>
                  </li>
                  <li className="footer__links-item">
                    <a className="footer__link" href="#">
                      סל המתנות שלי
                    </a>
                  </li>
                </ul>
              </div>
              <div className="footer__col-item footer__col-item_100">
                <h3 className="footer__title">סוג תשלום</h3>
                <ul className="footer__payments-list">
                  <li className="footer__payments-list-item">
                    <img src={visa} alt="visa" />
                  </li>
                  <li className="footer__payments-list-item">
                    <img src={americanExpress} alt="american express" />
                  </li>
                  <li className="footer__payments-list-item">
                    <img src={mastercardBlue} alt="mastercard blue" />
                  </li>
                  <li className="footer__payments-list-item">
                    <img src={mastercard} alt="mastercard" />
                  </li>
                  <li className="footer__payments-list-item">
                    <img src={pci} alt="pci" />
                  </li>
                  <li className="footer__payments-list-item">
                    <img src={paypal} alt="paypal" />
                  </li>
                  <li className="footer__payments-list-item">
                    <img src={paybox} alt="paybox" />
                  </li>
                  <li className="footer__payments-list-item">
                    <img src={bit} alt="bit" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="footer__col footer__col_socials">
            <h3 className="footer__title">צור קשר</h3>
            <div className="footer__icon-links-block">
              <a href="#" className="footer__link-icon">
                <span className="icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16.599"
                    height="19.988"
                    viewBox="0 0 16.599 19.988"
                  >
                    <g id="Places" transform="translate(0.6 0.6)">
                      <path
                        id="Контур_114"
                        data-name="Контур 114"
                        d="M7.681,10.983a3.242,3.242,0,0,0,3.26-3.235A3.341,3.341,0,0,0,7.681,4.4a3.242,3.242,0,0,0-3.26,3.235A3.422,3.422,0,0,0,7.681,10.983ZM2.208,2.2a7.773,7.773,0,0,1,10.945,0,7.628,7.628,0,0,1,0,10.86l-5.473,5.43-5.473-5.43A7.844,7.844,0,0,1,2.208,2.2Z"
                        transform="translate(-0.025 0.05)"
                        fill="none"
                        stroke="#ec1b53"
                        strokeWidth="1.2"
                        fillRule="evenodd"
                      />
                    </g>
                  </svg>
                </span>
                <span className="text">דרך מנחם בגין 42, רמת גן</span>
              </a>
              <a href="tel:03-0000000" className="footer__link-icon">
                <span className="icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="23.567"
                    height="23.568"
                    viewBox="0 0 23.567 23.568"
                  >
                    <g id="call" transform="translate(0.616 0.671)">
                      <g
                        id="Сгруппировать_6"
                        data-name="Сгруппировать 6"
                        transform="translate(0 0)"
                      >
                        <g
                          id="Сгруппировать_5"
                          data-name="Сгруппировать 5"
                          transform="translate(0 0)"
                        >
                          <path
                            id="Контур_1"
                            data-name="Контур 1"
                            d="M21.848,17.717l-2.47-1.647-2.2-1.464a.973.973,0,0,0-1.306.212l-1.359,1.747a.968.968,0,0,1-1.235.256,15.82,15.82,0,0,1-4.418-3.4A15.933,15.933,0,0,1,5.46,9a.968.968,0,0,1,.256-1.235L7.463,6.405A.973.973,0,0,0,7.675,5.1L6.256,2.971,4.564.433A.973.973,0,0,0,3.255.139L1.3,1.309A2.384,2.384,0,0,0,.221,2.71C-.312,4.655-.42,8.947,6.457,15.824s11.168,6.769,13.113,6.236a2.383,2.383,0,0,0,1.4-1.083l1.17-1.951A.973.973,0,0,0,21.848,17.717Z"
                            transform="translate(0 0)"
                            fill="none"
                            stroke="#ec1b53"
                            strokeWidth="1.2"
                          />
                          <path
                            id="Контур_2"
                            data-name="Контур 2"
                            d="M282.866,62.56a6.538,6.538,0,0,1,6.53,6.53.384.384,0,1,0,.768,0,7.307,7.307,0,0,0-7.3-7.3.384.384,0,1,0,0,.768Z"
                            transform="translate(-270.19 -59.103)"
                            fill="#ec1b53"
                          />
                          <path
                            id="Контур_3"
                            data-name="Контур 3"
                            d="M282.865,115.524a4.231,4.231,0,0,1,4.226,4.226.384.384,0,1,0,.768,0,5,5,0,0,0-4.994-4.994.384.384,0,0,0,0,.768Z"
                            transform="translate(-270.189 -109.762)"
                            fill="#ec1b53"
                          />
                          <path
                            id="Контур_4"
                            data-name="Контур 4"
                            d="M282.866,168.491a1.923,1.923,0,0,1,1.921,1.921.384.384,0,0,0,.768,0,2.692,2.692,0,0,0-2.689-2.689.384.384,0,0,0,0,.768Z"
                            transform="translate(-270.19 -160.424)"
                            fill="#ec1b53"
                          />
                        </g>
                      </g>
                    </g>
                  </svg>
                </span>
                <span className="text">03-0000000</span>
              </a>
              <a href="mailto:office@yyy.co.il" className="footer__link-icon">
                <span className="icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="23.896"
                    height="15.49"
                    viewBox="0 0 23.896 15.49"
                  >
                    <g id="Messages" transform="translate(0.6 0.6)">
                      <path
                        id="Контур_50"
                        data-name="Контур 50"
                        d="M19.859,2.382H2.837l8.511,5.954ZM0,2.382A2.647,2.647,0,0,1,2.837,0H19.859A2.647,2.647,0,0,1,22.7,2.382v9.527a2.647,2.647,0,0,1-2.837,2.382H2.837A2.647,2.647,0,0,1,0,11.908Z"
                        fill="none"
                        stroke="#ec1b53"
                        strokeWidth="1.2"
                        fillRule="evenodd"
                      />
                    </g>
                  </svg>
                </span>
                <span className="text">office@yyy.co.il</span>
              </a>
              <p className="footer__link-icon">
                <span className="icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <g
                      id="Сгруппировать_74424"
                      data-name="Сгруппировать 74424"
                      transform="translate(-961 -141.59)"
                    >
                      <g
                        id="Контур_39351"
                        data-name="Контур 39351"
                        transform="translate(961 141.59)"
                        fill="none"
                      >
                        <path
                          d="M12,0A12,12,0,1,1,0,12,12,12,0,0,1,12,0Z"
                          stroke="none"
                        />
                        <path
                          d="M 12 1.200000762939453 C 9.115209579467773 1.200000762939453 6.403099060058594 2.323400497436523 4.363250732421875 4.363250732421875 C 2.323400497436523 6.403099060058594 1.200000762939453 9.115209579467773 1.200000762939453 12 C 1.200000762939453 14.88479042053223 2.323400497436523 17.59690093994141 4.363250732421875 19.63674926757812 C 6.403099060058594 21.67659950256348 9.115209579467773 22.79999923706055 12 22.79999923706055 C 14.88479042053223 22.79999923706055 17.59690093994141 21.67659950256348 19.63674926757812 19.63674926757812 C 21.67659950256348 17.59690093994141 22.79999923706055 14.88479042053223 22.79999923706055 12 C 22.79999923706055 9.115209579467773 21.67659950256348 6.403099060058594 19.63674926757812 4.363250732421875 C 17.59690093994141 2.323400497436523 14.88479042053223 1.200000762939453 12 1.200000762939453 M 12 0 C 18.62742042541504 0 24 5.372579574584961 24 12 C 24 18.62742042541504 18.62742042541504 24 12 24 C 5.372579574584961 24 0 18.62742042541504 0 12 C 0 5.372579574584961 5.372579574584961 0 12 0 Z"
                          stroke="none"
                          fill="#ec1b53"
                        />
                      </g>
                      <line
                        id="Линия_466"
                        data-name="Линия 466"
                        y1="5"
                        x2="6"
                        transform="translate(972.5 151.09)"
                        fill="none"
                        stroke="#ec1b53"
                        strokeLinecap="round"
                        strokeWidth="1.2"
                      />
                      <line
                        id="Линия_467"
                        data-name="Линия 467"
                        y2="5"
                        transform="translate(972.5 151.09)"
                        fill="none"
                        stroke="#ec1b53"
                        strokeLinecap="round"
                        strokeWidth="1.2"
                      />
                    </g>
                  </svg>
                </span>
                <span className="text">
                  שעות פעילות: א'-ה' 9:00-19:00 ו' וערבי חג 9:00-15:00 טלפון:
                  03-6005141
                </span>
              </p>
            </div>
            <ul className="footer__socials-list">
              <li className="footer__socials-item">
                <a href="#" className="footer__socials-link">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                  >
                    <g
                      id="Сгруппировать_792"
                      data-name="Сгруппировать 792"
                      transform="translate(0 0.383)"
                    >
                      <g
                        id="Прямоугольник_1902"
                        data-name="Прямоугольник 1902"
                        transform="translate(0 -0.383)"
                        fill="none"
                        stroke="#fff"
                        strokeWidth="1"
                      >
                        <rect width="32" height="32" rx="10" stroke="none" />
                        <rect
                          x="0.5"
                          y="0.5"
                          width="31"
                          height="31"
                          rx="9.5"
                          fill="none"
                        />
                      </g>
                      <g
                        id="Эллипс_357"
                        data-name="Эллипс 357"
                        transform="translate(10 9.617)"
                        fill="none"
                        stroke="#fff"
                        strokeWidth="1"
                      >
                        <circle cx="6.5" cy="6.5" r="6.5" stroke="none" />
                        <circle cx="6.5" cy="6.5" r="6" fill="none" />
                      </g>
                      <g
                        id="Эллипс_358"
                        data-name="Эллипс 358"
                        transform="translate(22 4.617)"
                        fill="none"
                        stroke="#fff"
                        strokeWidth="3"
                      >
                        <circle cx="1.5" cy="1.5" r="1.5" stroke="none" />
                        <circle cx="1.5" cy="1.5" fill="none" />
                      </g>
                    </g>
                  </svg>
                </a>
              </li>
              <li className="footer__socials-item">
                <a href="#" className="footer__socials-link">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="13.901"
                    height="26.799"
                    viewBox="0 0 13.901 26.799"
                  >
                    <path
                      id="facebook"
                      d="M16.732,4.284h2.355V.182A30.413,30.413,0,0,0,15.656,0c-3.4,0-5.722,2.136-5.722,6.062V9.675H6.187V14.26H9.934V25.8h4.594V14.261h3.6L18.7,9.676H14.528V6.516c0-1.325.358-2.233,2.2-2.233Z"
                      transform="translate(-5.687 0.5)"
                      fill="none"
                      stroke="#fff"
                      strokeWidth="1"
                    />
                  </svg>
                </a>
              </li>
              <li className="footer__socials-item">
                <a href="#" className="footer__socials-link">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25.027"
                    height="25.028"
                    viewBox="0 0 25.027 25.028"
                  >
                    <g id="call" transform="translate(0.418 0.465)">
                      <g
                        id="Сгруппировать_6"
                        data-name="Сгруппировать 6"
                        transform="translate(0 0)"
                      >
                        <g
                          id="Сгруппировать_5"
                          data-name="Сгруппировать 5"
                          transform="translate(0 0)"
                        >
                          <path
                            id="Контур_1"
                            data-name="Контур 1"
                            d="M23.675,19.2,21,17.414l-2.379-1.586a1.054,1.054,0,0,0-1.415.23L15.731,17.95a1.049,1.049,0,0,1-1.339.278,17.143,17.143,0,0,1-4.787-3.689A17.265,17.265,0,0,1,5.916,9.752a1.049,1.049,0,0,1,.278-1.339L8.087,6.941a1.054,1.054,0,0,0,.23-1.415L6.779,3.219,4.946.469A1.054,1.054,0,0,0,3.527.15L1.413,1.419A2.583,2.583,0,0,0,.24,2.937C-.338,5.045-.455,9.695,7,17.147s12.1,7.336,14.21,6.757a2.583,2.583,0,0,0,1.518-1.173l1.268-2.114A1.054,1.054,0,0,0,23.675,19.2Z"
                            transform="translate(0 0)"
                            fill="none"
                            stroke="#fff"
                            strokeWidth="0.8"
                          />
                          <path
                            id="Контур_2"
                            data-name="Контур 2"
                            d="M282.781,62.39a5.092,5.092,0,0,1,5.086,5.086.3.3,0,1,0,.6,0,5.691,5.691,0,0,0-5.685-5.685.3.3,0,0,0,0,.6Z"
                            transform="translate(-266.117 -59.698)"
                            fill="#fff"
                          />
                          <path
                            id="Контур_3"
                            data-name="Контур 3"
                            d="M282.78,115.354a3.3,3.3,0,0,1,3.291,3.291.3.3,0,1,0,.6,0,3.894,3.894,0,0,0-3.889-3.889.3.3,0,0,0,0,.6Z"
                            transform="translate(-267.968 -108.86)"
                            fill="#fff"
                          />
                          <path
                            id="Контур_4"
                            data-name="Контур 4"
                            d="M282.781,168.321a1.5,1.5,0,0,1,1.5,1.5.3.3,0,0,0,.6,0,2.1,2.1,0,0,0-2.094-2.094.3.3,0,0,0,0,.6Z"
                            transform="translate(-268.562 -159.458)"
                            fill="#fff"
                          />
                        </g>
                      </g>
                    </g>
                  </svg>
                </a>
              </li>
              <li className="footer__socials-item">
                <a href="#" className="footer__socials-link">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="31.496"
                    height="24"
                    viewBox="0 0 31.496 24"
                  >
                    <g id="Messages" transform="translate(0.4)">
                      <rect
                        id="Прямоугольник_117"
                        data-name="Прямоугольник 117"
                        width="31"
                        height="24"
                        fill="none"
                      />
                      <path
                        id="Контур_50"
                        data-name="Контур 50"
                        d="M26.859,3.382c-1.182-.575-21.881-1.089-23.022,0s5.755,8.454,11.511,8.454S28.041,3.957,26.859,3.382ZM0,3.382A3.642,3.642,0,0,1,3.837,0H26.859A3.642,3.642,0,0,1,30.7,3.382V16.908a3.642,3.642,0,0,1-3.837,3.382H3.837A3.642,3.642,0,0,1,0,16.908Z"
                        transform="translate(0 1.681)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeWidth="0.8"
                        fillRule="evenodd"
                      />
                    </g>
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="footer__bottom">
          <p className="footer__copyright">
            כל הזכויות שמורות ל <span>YYY</span>
          </p>
          <p className="footer__power-by">
            Power By <span>WebXP</span>
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
