import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import {
  blessingBg,
  blessingBgNoPeople,
  logoImg,
  logoWithBg,
} from "../Assets/images";

function EventCreated({ setMyEventsActiveTab }) {
  const navigate = useNavigate();
  useEffect(() => {
    const currentUser = localStorage.getItem("currUserObject");
    if (!currentUser) {
      navigate("/credentials-new-wishlist/");
    }
  }, []);

  return (
    <main className="event-created" data-fullscreen>
      <section className="event-created__main main-event-created">
        <div className="main-event-created__bg-image-ibg">
          <img src={blessingBg} className="img-people" alt="bg" />
          <img src={blessingBgNoPeople} className="img-no-people" alt="bg" />
        </div>
        <div className="main-event-created__content-window">
          <h1 className="main-event-created__title">
            תהליך ההזמנה לאירוע שלך הסתיים צוות אתר מתרגש יחד איתך{" "}
          </h1>
          <div className="main-event-created__logo">
            <img src={logoWithBg} alt="Logo" />
          </div>
          <div className="main-event__btn-wrapper">

            <div className="main-event-created__btn-container btn-container_center">
              <NavLink
                to="/my-events"
                onClick={() => {
                  ///window.location.reload();
                  setMyEventsActiveTab(0);

                }}
                className="btn"
              >
                יש לך אירוע נוסף
              </NavLink>
            </div>
            <div className="main-event-created__btn-container btn-container_center">
              <NavLink
                to="/my-events"
                onClick={() => {
                  //window.location.reload();
                  setMyEventsActiveTab(1);
                }}
                className="btn-white"
              >
                אין לי אירוע
              </NavLink>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default EventCreated;
