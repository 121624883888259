import React, { useEffect, useState, useRef, useCallback } from "react";
import { ReactDOM } from "react";
import Product from "../Components/Product";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";
import ImagePopup from "../Components/ImagePopup";
import MyBankProduct from "../Components/MyBank/MyBankProduct";
import MyBankCreateOrder from "../Components/MyBank/MyBankCreateOrder";
import SendMoney from "../Present-Or-Money/send-money";
import { API_DOMAIN } from "../Libs/config";
import {
  NavLink,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom/dist";
import {
  homeBg,
  homeBgNoPeople,
  orangeBgImage,
  orangeBgImageNoPeople,
  present1,
  topbarBgGreen,
} from "../Assets/images";
import axios from "axios";
import ProductsFilter from "../Components/products-filter";

// Initialize Swiper modules
SwiperCore.use([Navigation]);
const breakpoints = {
  768: {
    slidesPerView: 1,
    spaceBetween: 0,
  },
  1024: {
    slidesPerView: "auto", // Display all slides on desktop
    spaceBetween: 20,
  },
};

export default function MyBankProducts({
  updatePopupState,
  addItemHandler,
  openImagePopup,
  currWishlist,
  setCurrWishlist,
  currUserObject,
  filterToggle,
  allCategoriesList,
  newEvent,
  filterObject,
  setFilterObject,
  language,
  languageToggle
}) {

  const [categories, setCategories] = useState([])

  const [presentType, setPresentType] = useState(null);
  const [isOrange, setOrange] = useState(true);
  const [isGreenMobile, setGreenMobile] = useState(true);
  const [choosedPresent, setChoosedPresent] = useState({});
  const [isChoosed, setChoosed] = useState(false);
  const [stepNumber, setStepNumber] = useState(1);
  const [isForm, setForm] = useState(true);
  const [resultObject, setResultObject] = useState({});
  const [wishListInfo, setWishListInfo] = useState(null);
  const [mainBgImage, setMainBgImage] = useState({
    main: "",
    noPeople: "",
  });

  const [progress, setProgress] = useState(0);
  const [sendChoosedPresentStep, setSendChoosedPresentStep] = useState({
    blessingWrite: false,
    shipping: false,
    terms: false,
    moneyPresent: false,
    payment: false,
    successfulPayment: false,
  });
  const [sendMoneyStep, setMoneyStep] = useState({
    howMuchForPresent: false,
    blessingWrite: false,
    moneyPresent: false,
    payment: false,
    successfulPayment: false,
  });




  const location = useLocation();
  const navigate = useNavigate();
  const { categoryId, productsId } = useParams();

  const [manufacturers, setManufacturers] = useState([]);
  const [rangePriceMinMax, setRangePriceMinMax] = useState({
    min: null,
    max: null,
  });

  const [isNewEvent, setNewEvent] = useState(false);

  const URLParams = new URLSearchParams(window.location.search);
  const currWishlistId = URLParams?.get("wishlistId");
  const [products, setProducts] = useState([]);
  const [productsAll, setProductsAll] = useState([]);
  const [visibleProducts, setVisibleProducts] = useState([]);
  const [nextIndex, setNextIndex] = useState(0);
  const mainSwiperRef = useRef(null);
  const thumbnailSwiperRef = useRef(null);
  const mainProductsRelated = useRef(null);
  const mainProductsRecent = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeIndexRecent, setActiveIndexRecent] = useState(0);
  const [slidesPerView, setSlidesPerView] = useState(3);
  const [isMobile, setIsMobile] = useState(false);
  const [relatedPrevActive, setRelatedPrevActive] = useState(true);
  const [recentPrevActive, setRecentPrevActive] = useState(true);
  const [columns, setColumns] = useState(
    JSON.parse(localStorage.getItem("productColumns")) || 3
  );
  let isSliderSaved = localStorage.getItem("productSlider");
  isSliderSaved = isSliderSaved ? JSON.parse(isSliderSaved) : true;
  const [isSlider, setIsSlider] = useState(isSliderSaved);
  const sliderMobile = useRef(null);
  // const containerRef = useRef(null)
  // const [showImagePopup, setShowImagePopup] = useState(false);
  const [mobileArrowPrev, setMobileArrowPrev] = useState(true);


  const handleUpdateWishlistIdLocal = () => {
    const savedLocal = JSON.parse(localStorage.getItem('currWishlistId'))
    const oldData = savedLocal ? savedLocal : [];
    localStorage.setItem('currWishlistId', JSON.stringify([...oldData, { currWishlistId, categoryId, productsId }]))
  }

  // console.log("mobileArrowPrev: ", mobileArrowPrev);
  const goToChoosedPresentForm = (present) => {
    console.log('goToChoosedPresentForm: present', present)

    const currentUser = JSON.parse(localStorage.getItem("currUserObject"));

    setPresentType("present");
    setChoosed(true);
    setStepNumber(1);
    setProgress(5);
    setMainBgImage({
      main: homeBg,
      noPeople: homeBgNoPeople,
    });
    setSendChoosedPresentStep({
      blessingWrite: true,
      shipping: false,
    });
    console.log(present);
    setChoosedPresent(present);
    setResultObject((prevObj) => {
      const newObj = {
        ...prevObj,
        // wishlistItemsId: [present.wishListItemId],
        presentType: "present",
        buyerId: currUserObject.user.id,
        // ownerId: wishListInfo?.whishlistUser?.id,
        ownerId: currentUser.user.id,
        productId: present.id
      };
      return newObj;
    });

    window.scrollTo(0, 0);
  };

  const toggleProductsLayout = (state) => {
    setColumns(state);
    localStorage.setItem("productColumns", state);
  };
  const toggleSliderLayoutMobile = (state) => {
    setIsSlider(state);
    localStorage.setItem("productSlider", state);
  };
  console.log(
    "toggleSliderLayoutMobile: isSlider,columns,isMobile",
    isSlider,
    columns,
    isMobile
  );

  useEffect(() => {
    // console.log(mainProductsRelated.current?.swiper?.realIndex == 0, 'mainProductsRelated.current?.swiper.realIndex ')
    if (mainProductsRelated.current?.swiper?.realIndex == 0) {
      setRelatedPrevActive(true);
    } else {
      setRelatedPrevActive(false);
    }
  }, [mainProductsRelated.current?.swiper?.realIndex]);

  useEffect(() => {
    // console.log(mainProductsRelated.current?.swiper?.realIndex == 0, 'mainProductsRelated.current?.swiper.realIndex ')
    if (sliderMobile.current?.swiper?.realIndex == 0) {
      setMobileArrowPrev(true);
    } else {
      setMobileArrowPrev(false);
    }
    console.log(
      "sliderMobile.current?.swiper?.realIndex == 0",
      sliderMobile.current?.swiper?.realIndex == 0
    );
  }, [sliderMobile.current?.swiper?.realIndex]);

  useEffect(() => {
    // console.log(mainProductsRecent.current?.swiper?.realIndex == 0, 'mainProductsRecent.current?.swiper.realIndex ')
    if (mainProductsRecent.current?.swiper?.realIndex == 0) {
      setRecentPrevActive(true);
    } else {
      setRecentPrevActive(false);
    }
  }, [mainProductsRecent.current?.swiper?.realIndex]);

  useEffect(() => {
    if (mainSwiperRef.current && mainSwiperRef.current.swiper) {
      mainSwiperRef.current.swiper.on("slideChange", () => {
        setActiveIndex(mainSwiperRef.current.swiper?.realIndex);
      });
    }
  }, []);

  useEffect(() => {
    if (window.innerWidth < 768) {
      setIsMobile(true);
      setSlidesPerView(1);
    } else {
      setSlidesPerView(3);
      setIsMobile(false);
    }
    const handleResize = () => {
      setSlidesPerView(1);
      if (window.innerWidth < 768) {
        setIsMobile(true);
      } else {
        setSlidesPerView(3);
        setIsMobile(false);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const currentUser = localStorage.getItem("currUserObject");
    const isProdsNewEvent = window.location.href.includes('products-new-event')
    if (!currentUser && !isProdsNewEvent) {
      navigate("/credentials");
      // if (!isProdsNewEvent) {
      //   navigate("/credentials");
      // } else {
      //   setNewEvent(true);
      // }
    } else {
      getProductsByCategoriesList([
        { categoryId: categoryId, productsId: productsId },
      ]);
      // setChoosedCategoriesList([
      //   { categoryId: categoryId, productsId: productsId },
      // ]);
      setFilterObject((prevObj) => {
        const newObj = {
          ...prevObj,
          choosedCategoriesList: [...prevObj.choosedCategoriesList,
            { categoryId: categoryId, productsId: productsId },
          ],
        };
        return newObj;
      });

      if (!currWishlist.whishlistObject) {
        console.log("no current wishlist");
        axios
          .get(
            `${API_DOMAIN}/api/ProductCategory/GetWishlistObject?id=${currWishlistId}`
          )
          .then((response) => {
            console.log(response.data);
            if (response.data.result) {
              // setLoading(false);
              console.log("whishlist object", response.data);
              setCurrWishlist({
                whishlistItemsList: response.data.whishlistItemsList,
                whishlistObject: response.data.whishlistObject,
                whishlistItemsDetailsList:
                  response.data.whishlistItemsDetailsList,
              });
            } else {
              //setLoading(false);
              alert("Something went wrong");
            }
          })
          .catch((error) => {
            console.error(error);
            //setLoading(false);
            alert("Error, please try again");
          });
      }

      if (window.location.href.includes("new-event")) {
        setNewEvent(true);
      }
    }
  }, []);

  const handleScroll = useCallback(() => {
    const anchor = document.querySelector(".main-products__list-view-point");
    if (anchor) {
      if (
        // window.innerHeight + document.documentElement.scrollTop ===
        // document.documentElement.offsetHeight
        anchor.getBoundingClientRect().top < window.innerHeight &&
        anchor.getBoundingClientRect().top > 0
      ) {
        if (nextIndex < products.length) {
          const nextProducts = products.slice(nextIndex, nextIndex + 20);
          setVisibleProducts((prevProducts) => [
            ...prevProducts,
            ...nextProducts,
          ]);
          setNextIndex(nextIndex + 20);
        }
      }
    }
  }, [products, nextIndex, isSlider, isMobile]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  const handleThumbnailClick = (index) => {
    const swiperInstance = mainSwiperRef.current?.swiper;
    // const currentIndex = swiperInstance?.realIndex;
    // const lastIndex = swiperInstance.slides.length - 1;

    if (mainSwiperRef?.current && mainSwiperRef.current?.swiper) {
      swiperInstance.slideTo(index);
    }
  };

  const handlePrevNext = (direction) => {
    if (mainSwiperRef.current && mainSwiperRef.current.swiper) {
      const swiperInstance = mainSwiperRef.current.swiper;
      const swiperInstanceThumb = thumbnailSwiperRef.current.swiper;
      const currentIndex = swiperInstance?.realIndex;
      const lastIndex = swiperInstance.slides.length - 1;

      let newIndex;
      if (direction === "prev") {
        newIndex = currentIndex > 0 ? currentIndex - 1 : lastIndex;
      } else if (direction === "next") {
        newIndex = currentIndex < lastIndex ? currentIndex + 1 : 0;
      }

      if (newIndex !== undefined) {
        swiperInstance.slideTo(newIndex);
        swiperInstanceThumb.slideTo(newIndex);
      }
    }
  };

  const handleSlideStep = (swiper, direction) => {
    if (swiper && swiper.slideTo) {
      const currentIndex = swiper?.realIndex;
      const lastIndex = swiper.slides.length - 1;
      console.log("handleSlideStep: ", currentIndex, lastIndex);

      let newIndex;
      if (direction === "prev") {
        newIndex = currentIndex > 0 ? currentIndex - 1 : lastIndex;
      } else if (direction === "next") {
        newIndex = currentIndex < lastIndex ? currentIndex + 1 : 0;
      }

      if (newIndex !== undefined) {
        swiper.slideTo(newIndex);
      }
    }
  };

  const getProductsByCategoriesList = (idList) => {
    if (idList.length) {
      setProducts([]);
      setProductsAll([]);
      let finalArray = [];
      let i = 0;
      idList.forEach((item) => {
        axios
          .get(
            `${API_DOMAIN}/api/ProductCategory/GetProductsByCategoryId?categoryId=${item.productsId}`
          )
          .then((response) => {
            console.log(response.data.result.products);
            const allProducts = response.data.result.products;
            finalArray = [...finalArray, ...allProducts];

            i++;
            if (i === idList.length) {
              console.log(finalArray);
              setProducts(finalArray);
              setProductsAll(finalArray);
            }
          })
          .catch((error) => {
            console.error(error);
            i++;
            alert("Error, please try again");
          });
      });
    }
  };

  console.log('filterObject__', filterObject)
  const handleFilterChecks = (
    e,
    choosedCategory = null,
    manufacturer = null
  ) => {
    if (e.target.id.includes("category-check")) {
      if (e.target.checked) {
        setFilterObject((prevObj) => {
          const newObj = {
            ...prevObj,
            choosedCategoriesList: [
              ...prevObj.choosedCategoriesList,
              choosedCategory,
            ],
            lastChangedType: "category",
          };
          return newObj;
        });

        const headerCheck = document.getElementById(
          `header-category-check_${choosedCategory.categoryId}_${choosedCategory.productsId}`
        );
        if (headerCheck) {
          headerCheck.checked = true;
        }
      } else {
        let newCategoriesList = [...filterObject.choosedCategoriesList];
        filterObject.choosedCategoriesList.filter((item) => {
          if (
            item.categoryId === choosedCategory.categoryId &&
            item.productsId === choosedCategory.productsId
          ) {
            newCategoriesList.splice(newCategoriesList.indexOf(item), 1);
          }
          return newCategoriesList;
        });

        setFilterObject((prevObj) => {
          const newObj = {
            ...prevObj,
            choosedCategoriesList: [...newCategoriesList],
            lastChangedType: "category",
          };
          return newObj;
        });

        // getProductsByCategoriesList(prevCategoriesList);
        // setChoosedCategoriesList(prevCategoriesList);
        const headerCheck = document.getElementById(
          `header-category-check_${choosedCategory.categoryId}_${choosedCategory.productsId}`
        );
        if (headerCheck) {
          headerCheck.checked = false;
        }
      }
    } else if (e.target.id.includes("manufacturer_")) {
      if (e.target.checked) {
        setFilterObject((prevObj) => {
          const newObj = {
            ...prevObj,
            manufacturersList: [...prevObj.manufacturersList, manufacturer],
            priceRange: null,
            lastChangedType: "manufacturer",
          };
          return newObj;
        });
      } else {
        setFilterObject((prevObj) => {
          let newArr = [...prevObj.manufacturersList];
          newArr = newArr.filter((item) => item !== manufacturer);
          console.log(newArr);
          const newObj = {
            ...prevObj,
            manufacturersList: newArr,
            priceRange: null,
            lastChangedType: "manufacturer",
          };
          return newObj;
        });
      }
    }
  };

  const handleFilterProducts = (array) => {
    setProducts(() => {
      const newArr = array.filter((product) => {
        if (!filterObject.manufacturersList.length) {
          if (!filterObject.priceRange) {
            return productsAll;
          } else {
            return (
              product.price >= filterObject.priceRange[0] &&
              product.price <= filterObject.priceRange[1]
            );
          }
        } else if (!filterObject.priceRange) {
          return filterObject.manufacturersList.includes(product.manufacturer);
        } else {
          return (
            filterObject.manufacturersList.includes(product.manufacturer) &&
            product.price >= filterObject.priceRange[0] &&
            product.price <= filterObject.priceRange[1]
          );
        }
      });

      return newArr;
    });
  };

  function minMaxPriceHandler(products) {
    const pricesArr = products.map((product) => Number(product.price));
    const pricesSorted = pricesArr.sort((a, b) => a - b);
    // console.log(pricesSorted, 'priceArr')

    // const min = floorToTenThousands(pricesSorted[0]);
    // const max = ceilToHundredThousands(pricesSorted[pricesSorted.length - 1]);
    const min = pricesSorted[0];
    const max = pricesSorted[pricesSorted.length - 1];

    return { minPrice: min, maxPrice: max };
  }

  useEffect(() => {
    console.log('filterObject:', filterObject);
    if (filterObject.lastChangedType === "category") {
      getProductsByCategoriesList(filterObject.choosedCategoriesList);
      setFilterObject((prevObj) => {
        const newObj = { ...prevObj, manufacturers: [], lastChangedType: "" };
        return newObj;
      });
      document.querySelectorAll(".manufacturer-check").forEach((item) => {
        if (item.checked) item.checked = false;
      });
    } else if (filterObject.lastChangedType !== "") {
      handleFilterProducts(productsAll);
    }
  }, [filterObject]);

  useEffect(() => {
    console.log(products);
    if (products.length) {
      const initialProducts = products.slice(0, 20);
      setVisibleProducts(initialProducts);
      setNextIndex(20);

      if (filterObject.lastChangedType !== "price") {
        const { minPrice, maxPrice } = minMaxPriceHandler(products);
        console.log(minPrice, " ", maxPrice);
        setRangePriceMinMax({ min: minPrice, max: maxPrice });
        setFilterObject((prevObj) => {
          const newObj = {
            ...prevObj,
            priceRange: [minPrice, maxPrice],
            lastChangedType: "",
          };
          return newObj;
        });
      }
    }
  }, [products]);

  useEffect(() => {
    if (productsAll.length) {
      const manufacturersArr = productsAll.reduce((result, current) => {
        const { manufacturer } = current;
        const isDuplicate = result.some(
          (resultManufacturer) => resultManufacturer === manufacturer
        );

        if (!isDuplicate && manufacturer !== "") {
          result.push(manufacturer);
        }

        return result;
      }, []);

      setManufacturers(manufacturersArr);
    }
  }, [productsAll]);

  useEffect(() => {
    console.log(manufacturers);
  }, [manufacturers]);



  // useEffect(() => {
  //   const 
  
  //   return () => {
  //     second
  //   }
  // }, [third])
  


  return (
    <>
      {!isChoosed && (
        <main className={`products ${isNewEvent ? "new-event-type" : ""}`}>
          <section className="products__topbar topbar">
            <div className="topbar__bg-img-ibg">
              <img
                src="/img/common/topbar-products.png"
                alt="topbar"
                className="img-people"
              />
              <img
                src="/img/no-people-images/common/topbar-products.png"
                alt="topbar"
                className="img-no-people"
              />
            </div>
            <div className="topbar__container">
              <div className="topbar__title-wrapper">
                {isNewEvent && (
                  <button
                    type="button"
                    className="btn topbar__btn"
                    onClick={(e) => {
                      handleUpdateWishlistIdLocal()
                      navigate(`/credentials-new-wishlist/${currWishlistId}`, { state: { currWishlistId } });
                      // navigate(`/create-event-second-step/${currWishlistId}`);
                    }}
                  >
                    Continue event creation
                  </button>
                )}
                {!isNewEvent && (
                  <h1
                    className="topbar__title"
                  // onClick={(e) => {
                  //   navigate(`/create-event-second-step/${currWishlistId}`);
                  // }}
                  >
                    להמשיך ליצירת אירוע
                  </h1>
                )}
              </div>
            </div>
          </section>

          <div className="products__breadcrumbs breadcrumbs">
            <div className="breadcrumbs__container">
              <ul className="breadcrumbs__list">
                <li className="breadcrumbs__item">
                  <NavLink to="/">דף הבית</NavLink>
                </li>
                <li className="breadcrumbs__item">
                  <NavLink to="/my-events">my events</NavLink>
                </li>
                <li className="breadcrumbs__item">
                  <NavLink to={`/all-categories?wishlistId=${currWishlistId}`}>
                    categories
                  </NavLink>
                </li>
                <li className="breadcrumbs__item">
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate(-1);
                    }}
                  >
                    category
                  </a>
                </li>
                <li className="breadcrumbs__item">
                  <p>products</p>
                </li>
              </ul>
            </div>
          </div>

          <section className="products__main main-products">
            <div className="main-products__container">
              {products?.length > 0 && (
                <div className="main-products__filter-btn-container filter-btn-container">
                  <button
                    type="button"
                    className="filter-btn"
                    onClick={filterToggle}
                  >
                    <img src="/img/svg/filter-icon.png" alt="filter icon" />
                  </button>
                </div>
              )}

              <div className="main-products__steps">
                <div className="main-products__steps-numbers numbers-steps step-3">
                  <ul className="numbers-steps__list">
                    <li className="numbers-steps__list-item">
                      <div className="numbers-steps__step">1</div>
                    </li>
                    <li className="numbers-steps__list-item">
                      <div className="numbers-steps__step">2</div>
                    </li>
                    <li className="numbers-steps__list-item">
                      <div className="numbers-steps__step">3</div>
                    </li>
                    <li className="numbers-steps__list-item">
                      <div className="numbers-steps__step">4</div>
                    </li>
                  </ul>
                </div>
              </div>

              <p className="main-products__pretitle">
                להתחיל לבחור את המתנות המושלמות למסיבה שלך
              </p>
              <h2 className="main-products__title">מוצרים נילווים לאופנה</h2>

              <div className="main-products__view-type">
                <button
                  type="button"
                  onClick={() => toggleProductsLayout(3)}
                  className={`main-products__view-type-btn ${columns == 3 ? "active" : ""
                    }`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="19"
                    viewBox="0 0 14 19"
                  >
                    <g
                      id="_3_בשורה"
                      data-name="3 בשורה"
                      transform="translate(-148 -309)"
                    >
                      <rect
                        id="Прямоугольник_1938"
                        data-name="Прямоугольник 1938"
                        width="4"
                        height="4"
                        transform="translate(148 309)"
                      />
                      <rect
                        id="Прямоугольник_1939"
                        data-name="Прямоугольник 1939"
                        width="4"
                        height="4"
                        transform="translate(153 309)"
                      />
                      <rect
                        id="Прямоугольник_1940"
                        data-name="Прямоугольник 1940"
                        width="4"
                        height="4"
                        transform="translate(158 309)"
                      />
                      <rect
                        id="Прямоугольник_1944"
                        data-name="Прямоугольник 1944"
                        width="4"
                        height="4"
                        transform="translate(148 314)"
                      />
                      <rect
                        id="Прямоугольник_1945"
                        data-name="Прямоугольник 1945"
                        width="4"
                        height="4"
                        transform="translate(153 314)"
                      />
                      <rect
                        id="Прямоугольник_1946"
                        data-name="Прямоугольник 1946"
                        width="4"
                        height="4"
                        transform="translate(158 314)"
                      />
                      <rect
                        id="Прямоугольник_1950"
                        data-name="Прямоугольник 1950"
                        width="4"
                        height="4"
                        transform="translate(148 319)"
                      />
                      <rect
                        id="Прямоугольник_1951"
                        data-name="Прямоугольник 1951"
                        width="4"
                        height="4"
                        transform="translate(153 319)"
                      />
                      <rect
                        id="Прямоугольник_1952"
                        data-name="Прямоугольник 1952"
                        width="4"
                        height="4"
                        transform="translate(158 319)"
                      />
                      <rect
                        id="Прямоугольник_1957"
                        data-name="Прямоугольник 1957"
                        width="4"
                        height="4"
                        transform="translate(148 324)"
                      />
                      <rect
                        id="Прямоугольник_1958"
                        data-name="Прямоугольник 1958"
                        width="4"
                        height="4"
                        transform="translate(153 324)"
                      />
                      <rect
                        id="Прямоугольник_1959"
                        data-name="Прямоугольник 1959"
                        width="4"
                        height="4"
                        transform="translate(158 324)"
                      />
                    </g>
                  </svg>
                </button>
                <button
                  type="button"
                  onClick={() => toggleProductsLayout(4)}
                  className={`main-products__view-type-btn ${columns == 4 ? "active" : ""
                    }`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="19"
                    height="19"
                    viewBox="0 0 19 19"
                  >
                    <g
                      id="_4_בשורה"
                      data-name="4 בשורה"
                      transform="translate(-148 -309)"
                    >
                      <rect
                        id="Прямоугольник_1938"
                        data-name="Прямоугольник 1938"
                        width="4"
                        height="4"
                        transform="translate(148 309)"
                        fill="#d8d8d8"
                      />
                      <rect
                        id="Прямоугольник_1939"
                        data-name="Прямоугольник 1939"
                        width="4"
                        height="4"
                        transform="translate(153 309)"
                        fill="#d8d8d8"
                      />
                      <rect
                        id="Прямоугольник_1940"
                        data-name="Прямоугольник 1940"
                        width="4"
                        height="4"
                        transform="translate(158 309)"
                        fill="#d8d8d8"
                      />
                      <rect
                        id="Прямоугольник_1941"
                        data-name="Прямоугольник 1941"
                        width="4"
                        height="4"
                        transform="translate(163 309)"
                        fill="#d8d8d8"
                      />
                      <rect
                        id="Прямоугольник_1944"
                        data-name="Прямоугольник 1944"
                        width="4"
                        height="4"
                        transform="translate(148 314)"
                        fill="#d8d8d8"
                      />
                      <rect
                        id="Прямоугольник_1945"
                        data-name="Прямоугольник 1945"
                        width="4"
                        height="4"
                        transform="translate(153 314)"
                        fill="#d8d8d8"
                      />
                      <rect
                        id="Прямоугольник_1946"
                        data-name="Прямоугольник 1946"
                        width="4"
                        height="4"
                        transform="translate(158 314)"
                        fill="#d8d8d8"
                      />
                      <rect
                        id="Прямоугольник_1947"
                        data-name="Прямоугольник 1947"
                        width="4"
                        height="4"
                        transform="translate(163 314)"
                        fill="#d8d8d8"
                      />
                      <rect
                        id="Прямоугольник_1950"
                        data-name="Прямоугольник 1950"
                        width="4"
                        height="4"
                        transform="translate(148 319)"
                        fill="#d8d8d8"
                      />
                      <rect
                        id="Прямоугольник_1951"
                        data-name="Прямоугольник 1951"
                        width="4"
                        height="4"
                        transform="translate(153 319)"
                        fill="#d8d8d8"
                      />
                      <rect
                        id="Прямоугольник_1952"
                        data-name="Прямоугольник 1952"
                        width="4"
                        height="4"
                        transform="translate(158 319)"
                        fill="#d8d8d8"
                      />
                      <rect
                        id="Прямоугольник_1953"
                        data-name="Прямоугольник 1953"
                        width="4"
                        height="4"
                        transform="translate(163 319)"
                        fill="#d8d8d8"
                      />
                      <rect
                        id="Прямоугольник_1957"
                        data-name="Прямоугольник 1957"
                        width="4"
                        height="4"
                        transform="translate(148 324)"
                        fill="#d8d8d8"
                      />
                      <rect
                        id="Прямоугольник_1958"
                        data-name="Прямоугольник 1958"
                        width="4"
                        height="4"
                        transform="translate(153 324)"
                        fill="#d8d8d8"
                      />
                      <rect
                        id="Прямоугольник_1959"
                        data-name="Прямоугольник 1959"
                        width="4"
                        height="4"
                        transform="translate(158 324)"
                        fill="#d8d8d8"
                      />
                      <rect
                        id="Прямоугольник_1960"
                        data-name="Прямоугольник 1960"
                        width="4"
                        height="4"
                        transform="translate(163 324)"
                        fill="#d8d8d8"
                      />
                    </g>
                  </svg>
                </button>
              </div>
              <div className="main-products__mobile-header">
                <h2 className="main-products__header-title">
                  התחל/' לבחור מתנות לסל
                </h2>

                <div className="main-products__view-type-mobile">
                  <button
                    onClick={() => toggleSliderLayoutMobile(false)}
                    className={`main-products__view-type-mobile-btn ${isSlider ? "" : "active"
                      }`}
                  >
                    <svg
                      fill="#000"
                      width="64px"
                      height="64px"
                      viewBox="0 0 52 52"
                      data-name="Layer 1"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        <path d="M50,15.52H2a2,2,0,0,1-2-2V2A2,2,0,0,1,2,0H50a2,2,0,0,1,2,2V13.52A2,2,0,0,1,50,15.52Zm-46-4H48V4H4Z"></path>
                        <path d="M50,33.76H2a2,2,0,0,1-2-2V20.24a2,2,0,0,1,2-2H50a2,2,0,0,1,2,2V31.76A2,2,0,0,1,50,33.76Zm-46-4H48V22.24H4Z"></path>
                        <path d="M50,52H2a2,2,0,0,1-2-2V38.48a2,2,0,0,1,2-2H50a2,2,0,0,1,2,2V50A2,2,0,0,1,50,52ZM4,48H48V40.48H4Z"></path>
                      </g>
                    </svg>
                  </button>
                  <button
                    onClick={() => toggleSliderLayoutMobile(true)}
                    className={`main-products__view-type-mobile-btn ${isSlider ? "active" : ""
                      }`}
                  >
                    <svg
                      width="64px"
                      height="64px"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        <path
                          d="M18 7V17C18 17.62 17.98 18.17 17.91 18.66C17.62 21.29 16.38 22 13 22H11C7.62 22 6.38 21.29 6.09 18.66C6.02 18.17 6 17.62 6 17V7C6 6.38 6.02 5.83 6.09 5.34C6.38 2.71 7.62 2 11 2H13C16.38 2 17.62 2.71 17.91 5.34C17.98 5.83 18 6.38 18 7Z"
                          stroke="#000"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                        <path
                          d="M6 17.0001C6 17.6201 6.02 18.1701 6.09 18.6601C5.95 18.6701 5.82 18.6701 5.67 18.6701H5.33C2.67 18.6701 2 18.0001 2 15.3301V8.67008C2 6.00008 2.67 5.33008 5.33 5.33008H5.67C5.82 5.33008 5.95 5.33008 6.09 5.34008C6.02 5.83008 6 6.38008 6 7.00008V17.0001Z"
                          stroke="#000"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                        <path
                          d="M22 8.67008V15.3301C22 18.0001 21.33 18.6701 18.67 18.6701H18.33C18.18 18.6701 18.05 18.6701 17.91 18.6601C17.98 18.1701 18 17.6201 18 17.0001V7.00008C18 6.38008 17.98 5.83008 17.91 5.34008C18.05 5.33008 18.18 5.33008 18.33 5.33008H18.67C21.33 5.33008 22 6.00008 22 8.67008Z"
                          stroke="#000"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                      </g>
                    </svg>
                  </button>
                </div>
              </div>
              {isMobile && isSlider ? (
                <Swiper
                  ref={sliderMobile}
                  style={{ position: "relative", marginTop: "30px" }}
                  slidesPerView={1}
                  onSlideChange={(swiper) => {
                    setActiveIndexRecent(swiper?.realIndex);
                  }}
                  className="custom-product-shadow"
                >
                  {products.map((product, index) => (
                    <SwiperSlide
                      key={product.id}
                      className={`main-single-product__thumbs-slide swiper-slide ${index === activeIndex ? "active" : ""
                        }`}
                      onClick={() => handleThumbnailClick(index)}
                    // style={{padding:"0.75rem"}}
                    >
                      <MyBankProduct
                        key={product.id}
                        data={product}
                        updatePopupState={updatePopupState}
                        addItemHandler={addItemHandler}
                        openImagePopup={openImagePopup}
                        currWishlist={currWishlist}
                        setCurrWishlist={setCurrWishlist}
                        currUserObject={currUserObject}
                        goToChoosedPresentForm={goToChoosedPresentForm}
                      />
                    </SwiperSlide>
                  ))}

                  <div
                    className="main-products__list-nav"
                    style={{
                      position: "absolute",
                      display: "block",
                      zIndex: 11,
                      width: "100%",
                      top: "50%",
                    }}
                  >
                    <div
                      className="main-products__list-prev"
                      style={{ display: `${mobileArrowPrev ? "none" : "flex"}` }}
                      onClick={() =>
                        handleSlideStep(sliderMobile.current?.swiper, "prev")
                      }
                    >
                      <img src="../img/svg/arrow-left-black.svg" alt="" />
                    </div>
                    <div
                      className="main-products__list-next"
                      style={{
                        display: `${sliderMobile.current?.swiper?.slides.length - 1 ==
                          sliderMobile.current?.swiper?.realIndex
                          ? "none"
                          : "flex"
                          }`,
                      }}
                      onClick={() =>
                        handleSlideStep(sliderMobile.current?.swiper, "next")
                      }
                    >
                      <img src="../img/svg/arrow-left-black.svg" alt="" />
                    </div>
                  </div>
                </Swiper>
              ) : (
                <div className="main-products__list-wrapper swiper ">
                  <ul
                    className={`main-products__list products-list swiper-wrapper ${isSlider == false && isMobile == true ? "column" : ""
                      } ${columns == 4 ? "four-columns" : ""}`}
                  >

                    {visibleProducts &&
                      visibleProducts.map((product) => (
                        <MyBankProduct
                          key={product.id}
                          data={product}
                          updatePopupState={updatePopupState}
                          addItemHandler={addItemHandler}
                          openImagePopup={openImagePopup}
                          currWishlist={currWishlist}
                          setCurrWishlist={setCurrWishlist}
                          currUserObject={currUserObject}
                          goToChoosedPresentForm={goToChoosedPresentForm}
                        />
                      ))}
                  </ul>
                  <div
                    className="main-products__list-view-point"
                    style={{
                      height: 1,
                      width: 1,
                      opacity: 0,
                      visibility: "hidden",
                    }}
                  ></div>
                </div>
              )}
            </div>
          </section>

          <section className="products__main main-products main-products_related">
            <div className="main-products__container">
              <h2 className="main-products__title">מוצרים נילווים</h2>
              <h3 className="main-products__subtitle">שיתאימו לך בול</h3>

              <div className="main-products__slider-block">
                <div className="main-products__slider swiper">
                  <div className="main-products__wrapper swiper-wrapper">
                    <Swiper
                      style={{
                        position: "relative",
                        marginTop: "30px",
                        padding: "0.52083vw",
                      }}
                      ref={mainProductsRelated}
                      slidesPerView={slidesPerView}
                      spaceBetween={40}
                      onSlideChange={(swiper) => {
                        setActiveIndexRecent(swiper?.realIndex);
                      }}
                    >
                      {/* {productsList.map((product, index) => (
                      <SwiperSlide
                        key={product.id}
                        className={`main-single-product__thumbs-slide swiper-slide ${index === activeIndex ? "active" : ""
                          }`}
                        onClick={() => handleThumbnailClick(index)}
                      >
                        <Product
                          key={product.id}
                          data={product}
                          updatePopupState={updatePopupState}
                          addItemHandler={addItemHandler}
                          openImagePopup={openImagePopup}
                        />
                      </SwiperSlide>
                    ))} */}
                    </Swiper>
                  </div>

                  <div className="main-products__slider-nav">
                    <div
                      className="main-products__slider-prev"
                      style={{
                        display: `${relatedPrevActive ? "none" : "flex"}`,
                      }}
                      onClick={() =>
                        handleSlideStep(
                          mainProductsRelated.current.swiper,
                          "prev"
                        )
                      }
                    >
                      <img src="../img/svg/arrow-left-black.svg" alt="" />
                    </div>
                    <div
                      className="main-products__slider-next"
                      style={{
                        display: `${mainProductsRelated.current?.swiper.slides.length - 3 ==
                          mainProductsRelated.current?.swiper?.realIndex
                          ? "none"
                          : "flex"
                          }`,
                      }}
                      onClick={() =>
                        handleSlideStep(
                          mainProductsRelated.current.swiper,
                          "next"
                        )
                      }
                    >
                      <img src="../img/svg/arrow-left-black.svg" alt="" />
                    </div>
                  </div>
                </div>
              </div>

              <div className="main-products__btn-container btn-container_center">
                <a href="#" className="btn-outline-white">
                  לא מצאתי רוצה לראות עוד מוצרים
                </a>
              </div>
            </div>
          </section>

          <section className="products__main main-products main-products_recent">
            <div className="main-products__container">
              <h2 className="main-products__title">מוצרים אחרונים שחיפשתי</h2>
              <h3 className="main-products__subtitle">שיתאימו לך בול</h3>

              <div className="main-products__slider-block">
                <div className="main-products__slider swiper">
                  <div className="main-products__wrapper swiper-wrapper">
                    <Swiper
                      style={{
                        position: "relative",
                        marginTop: "30px",
                        padding: "0.52083vw",
                      }}
                      ref={mainProductsRecent}
                      slidesPerView={slidesPerView}
                      spaceBetween={40}
                      onSlideChange={(swiper) => {
                        setActiveIndexRecent(swiper?.realIndex);
                      }}
                    >
                      {/* {productsList.map((product, index) => (
                      <SwiperSlide
                        key={product.id}
                        className={`main-single-product__thumbs-slide swiper-slide ${index === activeIndex ? "active" : ""
                          }`}
                        onClick={() => handleThumbnailClick(index)}
                      >
                        <MyBankProduct
                          key={product.id}
                          data={product}
                          updatePopupState={updatePopupState}
                          addItemHandler={addItemHandler}
                          openImagePopup={openImagePopup}
                          goToChoosedPresentForm={goToChoosedPresentForm}
                        />
                      </SwiperSlide>
                    ))} */}
                    </Swiper>
                  </div>

                  <div className="main-products__slider-nav">
                    <div
                      className="main-products__slider-prev"
                      style={{ display: `${recentPrevActive ? "none" : "flex"}` }}
                      onClick={() =>
                        handleSlideStep(mainProductsRecent.current.swiper, "prev")
                      }
                    >
                      <img src="../img/svg/arrow-left-black.svg" alt="" />
                    </div>
                    <div
                      className="main-products__slider-next"
                      style={{
                        display: `${mainProductsRecent.current?.swiper.slides.length - 3 ==
                          mainProductsRecent.current?.swiper?.realIndex
                          ? "none"
                          : "flex"
                          }`,
                      }}
                      onClick={() =>
                        handleSlideStep(mainProductsRecent.current.swiper, "next")
                      }
                    >
                      <img src="../img/svg/arrow-left-black.svg" alt="" />
                    </div>
                  </div>
                </div>
              </div>

              <div className="main-products__btn-container btn-container_center">
                <a href="#" className="btn-outline-white">
                  לא מצאתי רוצה לראות עוד מוצרים
                </a>
              </div>
            </div>
          </section>
        </main>
      )}

      {isChoosed && (
        <main
          className={`credentials-page ${isForm ? "credentials-page_form" : ""
            } ${isOrange ? "credentials-page_orange" : ""} ${sendChoosedPresentStep.blessingWrite || sendMoneyStep.blessingWrite
              ? "blessing-write-title"
              : sendChoosedPresentStep.shipping
                ? "shipping-type"
                : sendMoneyStep.howMuchForPresent
                  ? "how-much"
                  : sendChoosedPresentStep.moneyPresent ||
                    sendMoneyStep.moneyPresent
                    ? "money-present"
                    : sendChoosedPresentStep.payment || sendMoneyStep.payment
                      ? "payment-page"
                      : sendChoosedPresentStep.terms
                        ? "terms-form"
                        : ""
            }`}
          data-fullscreen
        >
          <section
            className={`credentials-section ${isGreenMobile ? "green-mobile" : ""
              }`}
          >
            <div className="credentials-section__bg-image-ibg">
              <img src={mainBgImage.main} className="img-people" alt="bg" />
              <img
                src={mainBgImage.noPeople}
                className="img-no-people"
                alt="bg"
              />
            </div>
            <div className="credentials-section__content-window">
              {presentType === "present" && (
                <MyBankCreateOrder
                  sendChoosedPresentStep={sendChoosedPresentStep}
                  setSendChoosedPresentStep={setSendChoosedPresentStep}
                  progress={progress}
                  setProgress={setProgress}
                  stepNumber={stepNumber}
                  setStepNumber={setStepNumber}
                  setMainBgImage={setMainBgImage}
                  setOrange={setOrange}
                  setGreenMobile={setGreenMobile}
                  setForm={setForm}
                  setChoosed={setChoosed}
                  resultObject={resultObject}
                  setResultObject={setResultObject}
                  choosedPresent={choosedPresent}
                  wishListInfo={wishListInfo}
                  isOrange={isOrange}
                  languageToggle={languageToggle}
                  language={language}
                />
              )}
              {presentType === "money" && (
                <SendMoney
                  sendMoneyStep={sendMoneyStep}
                  setSendMoneyStep={setMoneyStep}
                  progress={progress}
                  setProgress={setProgress}
                  stepNumber={stepNumber}
                  setStepNumber={setStepNumber}
                  setMainBgImage={setMainBgImage}
                  setOrange={setOrange}
                  setGreenMobile={setGreenMobile}
                  setForm={setForm}
                  setChoosed={setChoosed}
                  resultObject={resultObject}
                  setResultObject={setResultObject}
                  choosedPresent={choosedPresent}
                  wishListInfo={wishListInfo}
                  isOrange={isOrange}
                />
              )}

            </div>
          </section>
        </main>
      )}
      <ProductsFilter
        filterToggle={filterToggle}
        allCategoriesList={allCategoriesList}
        // choosedCategoriesList={choosedCategoriesList}
        // setChoosedCategoriesList={setChoosedCategoriesList}
        filterObject={filterObject}
        setFilterObject={setFilterObject}
        filterFunction={handleFilterChecks}
        manufacturers={manufacturers}
        rangePriceMinMax={rangePriceMinMax}
      />
    </>
  );
}
