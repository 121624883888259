import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  bit,
  blessingWriteTitleImg,
  creditCards,
  homeBg,
  homeBgNoPeople,
  moneyPresentImg,
  orangeBgImage,
  orangeBgImageNoPeople,
  paybox,
  paypalLong,
  prevBtnImage,
  shippingBg,
  shippingBgNoPeople,
} from "../Assets/images";
import { validateTextInput } from "../Libs/forms";
import axios from "axios";
import { API_DOMAIN } from "../Libs/config";

function SendMoney({
  sendMoneyStep,
  setSendMoneyStep,
  progress,
  setProgress,
  stepNumber,
  setStepNumber,
  setMainBgImage,
  setOrange,
  setGreenMobile,
  setForm,
  setChoosed,
  resultObject,
  setResultObject,
  isOrange,
  choosedPresent,
  wishListInfo,
  wishlistId,
  setNotLoggedIn,
  didRegister,
  setGiftMoneyAmout,
  languageToggle,
  language
}) {
  const navigate = useNavigate();

  const [moneyInputValue, setMoneyInputValue] = useState("");

  const [writeBlessingStepInfo, setWriteBlessingStepInfo] = useState({});
  const [moneyPresentStepInfo, setMoneyPresentStepInfo] = useState({});
  const [isMinDigitError, setIsMinDigitError] = useState(false)

  const сhangeMoneyInputValue = (event) => {
    const { value } = event.target;
    if (value.length == 1 && value >= 5) { // using 6 here even thought need 5 digits, because we add comma  to the string
      setIsMinDigitError(false)
    }
    const cleanedValue = value?.replace(/\D/g, "");
    const formattedValue = cleanedValue?.replace(/(\d{3})(?=\d)/g, "$1,");
    setMoneyInputValue(formattedValue);

  };

  console.log('wishListInfo:', wishListInfo)

  const goBackToChoosePresent = () => {
    setChoosed(false);
    setSendMoneyStep({
      howMuch: false,
      blessingWrite: false,
    });
    setResultObject((prevObj) => {
      const newObj = prevObj;
      delete newObj["moneyPresentValue"];
      return newObj;
    });
    window.scrollTo(0, 0);

  };

  const goToHowMuchStep = () => {
    setStepNumber(1);
    setProgress(5);
    setMainBgImage({
      main: orangeBgImage,
      noPeople: orangeBgImageNoPeople,
    });
    setSendMoneyStep({
      howMuchForPresent: true,
      blessingWrite: false,
      moneyPresent: false,
      payment: false,
    });
    window.scrollTo(0, 0);

  };

  const goToWriteBlessing = () => {


    // prevent going to the other step if input has less then 5 digits
    const inputValueNumbers = moneyInputValue?.replace(',', '');
    if (inputValueNumbers.length == 1 && inputValueNumbers < 5) {
      setIsMinDigitError(true)
      return
    }

    const currUserObject = JSON.parse(localStorage.getItem('currUserObject'))
    if (!currUserObject) {
      setNotLoggedIn(true)
      // navigate('/credentials')
    }
    localStorage.setItem('giftMoneyPrice', JSON.stringify(moneyInputValue))

    setResultObject((prevObj) => {
      const newObj = { ...prevObj, moneyPresentValue: `${moneyInputValue}₪` };
      return newObj;
    });

    setSendMoneyStep({
      howMuchForPresent: false,
      blessingWrite: true,
      moneyPresent: false,
      payment: false,
    });
    setStepNumber(2);
    setMainBgImage({
      main: homeBg,
      noPeople: homeBgNoPeople,
    });
    setOrange(true);
    setForm(true);
    setGreenMobile(true);
    setProgress(30);
    window.scrollTo(0, 0);

  };
  const submitBlessingTextForm = (e) => {
    console.log('submitBlessingTextForm')
    e.preventDefault();

    const form = e.target;
    const blessingNameInput = form["blessing-name"];
    const blessingMessageInput = form["blessing-message"];

    const isBlessingName = validateTextInput(blessingNameInput, 1);
    const isBlessingMessage = validateTextInput(blessingMessageInput, 1);

    const isFormValid = isBlessingName && isBlessingMessage;
    if (isFormValid) {
      setResultObject((prevObject) => {
        const newObject = {
          ...prevObject,
          blessingTitle: blessingNameInput.value,
          blessingText: blessingMessageInput.value,
        };
        return newObject;
      });
    }
    setWriteBlessingStepInfo({
      blessingTitle: blessingNameInput.value,
      blessingText: blessingMessageInput.value,
    });
    goToMoneyPresentStep(); 

  };

  const goToMoneyPresentStep = () => {

    const inputValueNumbers = moneyInputValue?.replace(',', '');
    if (inputValueNumbers.length == 1 && inputValueNumbers < 5) {
      setIsMinDigitError(true)
      return
    }
    const currUserObject = JSON.parse(localStorage.getItem('currUserObject'))
    if (!currUserObject) {
      setNotLoggedIn(true)
      // navigate('/credentials')
    }
    localStorage.setItem('giftMoneyPrice', JSON.stringify(moneyInputValue))

    setSendMoneyStep({
      howMuchForPresent: false,
      blessingWrite: false,
      moneyPresent: true,
      payment: false,
    });
    setStepNumber(3);
    setMainBgImage({
      main: shippingBg,
      noPeople: shippingBgNoPeople,
    });
    setOrange(true);
    setForm(true);
    setGreenMobile(true);
    setProgress(65);
    window.scrollTo(0, 0);

  };
  const submitMoneyPresentForm = async (e) => {
    console.log('submitMoneyPresentForm')

    const form = e.target.closest(".money-present").querySelector("form");
    const presentBox = form["present-box"];
    const sendByRegisteredMail = form["send-by-registered-mail"];
    const notificationsAgree = form["notifications-agree"];
    const regulationsConfirm = form["regulations-confirm"];
    const payWithBit = form["pay-with-bit"];
    const greetingCard = form["greeting-card"];
    const price = JSON.parse(localStorage.getItem('giftMoneyPrice'))
    const currUserObject = JSON.parse(localStorage.getItem('currUserObject'))
    const { user: { id } } = currUserObject;
    console.log('submitMoneyPresentForm: id', id, sendByRegisteredMail)
    const newResultObject = {
      ...resultObject,
      presentBox: presentBox.checked,
      sendByRegisteredMail: sendByRegisteredMail.checked,
      notificationsAgree: notificationsAgree.checked,
      regulationsConfirm: regulationsConfirm.checked,
      payWithBit: payWithBit.checked,
      greetingCard: greetingCard.checked,
      myBankUserId: wishlistId,
      giftGiverUserId: id,
      // ["price"]: resultObject["moneyPresentValue"]
      ["price"]: price
    };

    console.log('moneyPresentValue: moneyInpnewResultObjectutValue', newResultObject)

    setResultObject(newResultObject)


    const sendObj = {
      ["price"]: Number(price?.replace(/[^\d.-]/g, '')),
      wishlistId: Number(wishlistId),
      giftGiverUserId: id,
      myBankUserId: wishlistId
    }

    try {
      const response = await axios
        .post(
          `${API_DOMAIN}/api/ProductCategory/giftMoney`,
          sendObj
        )
      console.log(response.data);

    } catch (error) {
      console.error(error);

    }
    setMoneyPresentStepInfo({
      presentBox: presentBox.checked,
      sendByRegisteredMail: sendByRegisteredMail.checked,
      notificationsAgree: notificationsAgree.checked,
      regulationsConfirm: regulationsConfirm.checked,
      payWithBit: payWithBit.checked,
      greetingCard: greetingCard.checked,
    });
    goToPayment();
  };

  const goToPayment = () => {
    setSendMoneyStep({
      howMuchForPresent: false,
      blessingWrite: false,
      moneyPresent: false,
      payment: true,
    });
    setStepNumber(4);
    setMainBgImage({
      main: orangeBgImage,
      noPeople: orangeBgImageNoPeople,
    });
    setOrange(true);
    setForm(true);
    setGreenMobile(true);
    setProgress(100);
    window.scrollTo(0, 0);

  };
  const submitPaymentForm = async (e) => {
    e.preventDefault();
    console.log('submitPaymentForm: send-money')
    const price = JSON.parse(localStorage.getItem('giftMoneyPrice'))
    localStorage.removeItem('giftMoneyPrice');

    const form = e.target;
    const eventType = form["event-type"];
    const billetNumber = form["billet-number"];
    const customerId = form["id"];
    const cardPeriod = form["period"];
    const cardMonth = form["month"];
    const cardCvv = form["cvv"];
    const paymentType = form["payment"]
    let currentUser = localStorage.getItem("currUserObject");
    if (currentUser) {
      currentUser = JSON.parse(currentUser);
    }
    console.log('paymentType', paymentType)
    const newObject = {
      ...resultObject,
      eventType: eventType.value,
      billetNumber: billetNumber.value,
      customerId: customerId.value,
      cardPeriod: cardPeriod.value,
      cardMonth: cardMonth.value,
      cardCvv: cardCvv.value,
      paymentType: paymentType.value,
      status: "processing",
      wishlistId: wishlistId,
      "wishlistItemsId": [
        Number(wishlistId)
      ],
      buyerId: currentUser?.user.id,
      "isCashGift": true,

    };
    setResultObject(newObject);

    // try {
    //   const response = await axios
    //     .post(
    //       `${API_DOMAIN}/api/ProductCategory/CreateOrder`,
    //       newObject
    //     )
    //   if (response.result) {
    //     console.log('must_navigate')
    //   } else {
    //     alert(response.message)
    //   }


    //   console.log(response.data);

    // } catch (error) {
    //   console.error(error);

    // }
    navigate(`/successful-payment-referal/?didRegister=${didRegister}&inviteWishlistId=${wishlistId}`);

    window.scrollTo(0, 0);

  };

  return (
    <>
      <div className="credentials-section__steps">
        <div
          className={`credentials-section__steps-numbers numbers-steps ${isOrange ? "numbers-steps_orange" : ""
            } step-${stepNumber}`}
        >
          <ul className="numbers-steps__list">
            <li className="numbers-steps__list-item">
              <div
                className="numbers-steps__step"
                onClick={() => (stepNumber !== 1 ? goToHowMuchStep() : null)}
              >
                1
              </div>
            </li>
            <li className="numbers-steps__list-item">
              <div
                className="numbers-steps__step"
                onClick={() => (stepNumber > 2 ? goToWriteBlessing() : null)}
              >
                2
              </div>
            </li>
            <li className="numbers-steps__list-item">
              <div
                className="numbers-steps__step"
                onClick={() => (stepNumber > 3 ? goToMoneyPresentStep() : null)}
              >
                3
              </div>
            </li>
            <li className="numbers-steps__list-item">
              <div className="numbers-steps__step">4</div>
            </li>
          </ul>
        </div>
      </div>
      <div className="credentials-section__content">
        {sendMoneyStep.howMuchForPresent && (
          <>
            <button type="button" className="credentials-section__prev-btn">
              <img
                src={prevBtnImage}
                onClick={goBackToChoosePresent}
                alt="arrow left black"
              />
            </button>
            <h1 className="credentials-section__title">
              כמה אתה רוצה לתת מתנה?
            </h1>
            <div className="credentials-section__form-wrapper">
              <form
                action="#"
                className="credentials-section__form form-credentials-section"
              >
                <label
                  htmlFor="form-credentials-section__how-much"
                  className="form-credentials-section__how-much-wrapper digits-parent"
                >
                  <input
                    autoComplete="off"
                    type="text"
                    name="price"
                    className="how-much-input"
                    id="form-credentials-section__how-much"
                    value={moneyInputValue}
                    onChange={сhangeMoneyInputValue}
                    pattern="[0-9]*"
                  />
                  <span className="money-icon">₪</span>
                  {isMinDigitError ? (<span className="min-digits-error">Must be at list 5</span>) : ""}

                </label>
                <div className="form-credentials-section__buttons-container send-money">
                  <button
                    type="button"
                    onClick={goToWriteBlessing}
                    className="btn-orange"
                  >
                    {languageToggle ? languageToggle.sendMoney[language].btn1 : "אני רוצה להוסיף כרטיס ברכה"}
                  </button>
                  <button
                    type="button"
                    onClick={goToMoneyPresentStep}
                    className="btn"
                  >
                    {languageToggle ? languageToggle.sendMoney[language].btn2 : "העבר אותי לתשלום"}

                  </button>
                </div>
              </form>
            </div>
          </>
        )}
        {sendMoneyStep.blessingWrite && (
          <>
            <button
              type="button"
              onClick={goToHowMuchStep}
              className="credentials-section__prev-btn"
            >
              <img src={prevBtnImage} alt="arrow left black" />
            </button>
            <div className="credentials-section__form-wrapper">
              <form
                action="#"
                onSubmit={submitBlessingTextForm}
                className="credentials-section__form form-credentials-section"
              >
                <div className="form-credentials-section__form-body">
                  <div className="form-credentials-section__top-block">
                    <h1 className="credentials-section__title">
                      ברכה למזמינים
                    </h1>
                    <label
                      htmlFor="form-credentials-section__blessing-name"
                      className="form-credentials-section__input-wrapper input-wrapper"
                    >
                      <input
                        autoComplete="off"
                        type="text"
                        name="blessing-name"
                        id="form-credentials-section__blessing-name"
                        placeholder="כותרת הברכה"
                        className="input"
                        defaultValue={
                          writeBlessingStepInfo.blessingTitle
                            ? writeBlessingStepInfo.blessingTitle
                            : ""
                        }
                        onInput={(e) => {
                          if (
                            e.target
                              .closest("label")
                              .classList.contains("input-error")
                          ) {
                            validateTextInput(e.target, 1);
                          }
                        }}
                      />
                    </label>
                    <label
                      htmlFor="form-credentials-section__blessing-message"
                      className="form-credentials-section__input-wrapper input-wrapper"
                    >
                      <textarea
                        autoComplete="off"
                        name="blessing-message"
                        placeholder="תוכן הברכה"
                        className="input"
                        id="form-credentials-section__blessing-message"
                        defaultValue={
                          writeBlessingStepInfo.blessingText
                            ? writeBlessingStepInfo.blessingText
                            : ""
                        }
                        onInput={(e) => {
                          if (
                            e.target
                              .closest("label")
                              .classList.contains("input-error")
                          ) {
                            validateTextInput(e.target, 1);
                          }
                        }}
                      />
                    </label>
                  </div>
                  <div className="form-credentials-section__big-image-ibg">
                    <img src={blessingWriteTitleImg} alt="" />
                  </div>
                  <div className="form-credentials-section__bottom-block">
                    <div className="form-credentials-section__buttons-container">
                      <button type="submit" className="btn">
                        {languageToggle ? languageToggle.sendMoney[language].btn3 : "אישור"}


                      </button>
                    </div>
                    <div className="credentials-section__progress">
                      <div
                        className="percent"
                        style={{ right: progress + "%" }}
                      >
                        {progress}%
                      </div>
                      <div className="bar">
                        <div className="background" />
                        <div
                          className="percent-line"
                          style={{ width: progress + "%" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </>
        )}
        {sendMoneyStep.moneyPresent && (
          <>
            <button
              type="button"
              onClick={goToWriteBlessing}
              className="credentials-section__prev-btn"
            >
              <img src={prevBtnImage} alt="arrow left black" />
            </button>
            <h1 className="credentials-section__title">סיכום הזמנה</h1>
            <div className="credentials-section__content-block">
              <div className="credentials-section__table-block">
                <div className="credentials-section__table">
                  <div className="credentials-section__table-item">
                    <p className="credentials-section__table-item-name">
                      : שם הרוכש
                    </p>
                    <p className="credentials-section__table-item-value">
                      {/* ישראלה כהן */}
                      {wishListInfo?.whishlistUser?.firstName}{" "}
                      {wishListInfo?.whishlistUser?.lastName}
                    </p>
                  </div>
                  <div className="credentials-section__table-item">
                    <p className="credentials-section__table-item-name">
                      : תאריך לידה
                    </p>
                    <p className="credentials-section__table-item-value">
                      30.7.1971
                    </p>
                  </div>
                  <div className="credentials-section__table-item">
                    <p className="credentials-section__table-item-name">
                      : נייד{" "}
                    </p>
                    <p className="credentials-section__table-item-value">
                      {/* 054-85456987 */}
                      {wishListInfo?.whishlistUser?.phoneNumber}
                    </p>
                  </div>
                  <div className="credentials-section__table-item">
                    <p className="credentials-section__table-item-name">
                      : מייל
                    </p>
                    <p className="credentials-section__table-item-value">
                      {/* israelac@gmail.com */}
                      {wishListInfo?.whishlistUser?.email}
                    </p>
                  </div>
                </div>
              </div>
              <div className="credentials-section__form-wrapper">
                <form
                  action="#"
                  className="credentials-section__form form-credentials-section"
                >
                  <div className="long-checkbox long-checkbox_with-image">
                    <input
                      id="c_0"
                      className="long-checkbox__input"
                      type="checkbox"
                      defaultValue={1}
                      defaultChecked
                      name="product"
                    />
                    <label htmlFor="c_0" className="long-checkbox__label">
                      <span className="long-checkbox__text">
                        <span className="image">
                          {/* <img src={moneyPresentImg} alt="" /> */}
                          <img src={choosedPresent.image} alt="" />
                          <a href="#" className="loupe-btn">
                            <svg
                              id="Сгруппировать_369"
                              data-name="Сгруппировать 369"
                              xmlns="http://www.w3.org/2000/svg"
                              width="16.346"
                              height="16.765"
                              viewBox="0 0 16.346 16.765"
                            >
                              <g
                                id="Сгруппировать_368"
                                data-name="Сгруппировать 368"
                              >
                                <path
                                  id="Контур_3180"
                                  data-name="Контур 3180"
                                  d="M356.417,355.722l-4.139-4.449a.686.686,0,0,0-1.021,0,.817.817,0,0,0,0,1.1l4.139,4.449a.687.687,0,0,0,1.021,0A.817.817,0,0,0,356.417,355.722Z"
                                  transform="translate(-340.283 -340.282)"
                                />
                                <g
                                  id="Сгруппировать_367"
                                  data-name="Сгруппировать 367"
                                >
                                  <g
                                    id="Сгруппировать_366"
                                    data-name="Сгруппировать 366"
                                  >
                                    <path
                                      id="Контур_3179"
                                      data-name="Контур 3179"
                                      d="M6.67,0a6.67,6.67,0,1,0,6.67,6.67A6.677,6.677,0,0,0,6.67,0Zm0,12.108A5.438,5.438,0,1,1,12.108,6.67,5.445,5.445,0,0,1,6.67,12.108Z"
                                    />
                                  </g>
                                </g>
                              </g>
                            </svg>
                          </a>
                        </span>
                        <span className="text-content">
                          {/* <span className="text">נרכשה שימלה ורודה</span> */}
                          <span className="text">
                            נרכשה {choosedPresent.productName}{" "}
                          </span>
                          <span className="price">
                            {choosedPresent.price}
                            <span className="money-icon">₪</span>
                          </span>
                        </span>
                      </span>
                    </label>
                  </div>
                  <div className="long-checkbox">
                    <input
                      id="c_1"
                      className="long-checkbox__input"
                      type="checkbox"
                      defaultValue={1}
                      defaultChecked={moneyPresentStepInfo.presentBox}
                      name="present-box"
                    />
                    <label htmlFor="c_1" className="long-checkbox__label">
                      <span className="long-checkbox__text">
                        לארוז באריזת מתנה
                      </span>
                    </label>
                  </div>
                  <div className="long-checkbox">
                    <input
                      id="c_2"
                      className="long-checkbox__input"
                      type="checkbox"
                      defaultValue={2}
                      defaultChecked={moneyPresentStepInfo.sendByRegisteredMail}
                      name="send-by-registered-mail"
                    />
                    <label htmlFor="c_2" className="long-checkbox__label">
                      <span className="long-checkbox__text">
                        לשלוח בדואר רשום אלי
                      </span>
                    </label>
                  </div>
                  <div className="long-checkbox">
                    <input
                      id="c_3"
                      className="long-checkbox__input"
                      type="checkbox"
                      defaultValue={2}
                      defaultChecked={moneyPresentStepInfo.notificationsAgree}
                      name="notifications-agree"
                    />
                    <label htmlFor="c_3" className="long-checkbox__label">
                      <span className="long-checkbox__text">
                        אני מאשר/ת קבלת הודעות על מבצעים והטבות
                      </span>
                    </label>
                  </div>
                  <div className="long-checkbox">
                    <input
                      id="c_4"
                      className="long-checkbox__input"
                      type="checkbox"
                      defaultValue={2}
                      defaultChecked={moneyPresentStepInfo.regulationsConfirm}
                      name="regulations-confirm"
                    />
                    <label htmlFor="c_4" className="long-checkbox__label">
                      <span className="long-checkbox__text">
                        הנני מאשר שקראתי את התקנון והבנתי אותו
                        <a href="#">קרא תקנון</a>
                      </span>
                    </label>
                  </div>
                  <div className="long-checkbox">
                    <input
                      id="c_5"
                      className="long-checkbox__input"
                      type="checkbox"
                      defaultValue={2}
                      defaultChecked={moneyPresentStepInfo.payWithBit}
                      name="pay-with-bit"
                    />
                    <label htmlFor="c_5" className="long-checkbox__label">
                      <span className="long-checkbox__text flex">
                        תשלום באמצעות
                        <div className="icon">
                          <img src={bit} alt="" />
                        </div>
                      </span>
                    </label>
                  </div>
                  <div className="long-checkbox">
                    <input
                      id="c_6"
                      className="long-checkbox__input"
                      type="checkbox"
                      defaultValue={1}
                      defaultChecked={moneyPresentStepInfo.greetingCard}
                      name="greeting-card"
                    />
                    <label htmlFor="c_6" className="long-checkbox__label">
                      <span className="long-checkbox__text flex flex-btn">
                        לצרף כרטיס ברכה
                        <a href="#" className="btn">
                          לצפיה
                        </a>
                      </span>
                    </label>
                  </div>
                </form>
              </div>
            </div>
            <div className="form-credentials-section__buttons-container">
              <button
                type="button"
                onClick={submitMoneyPresentForm}
                className="btn"
              >
                {languageToggle ? languageToggle.sendMoney[language].btn4 : "המשךз"}
              </button>
            </div>
          </>
        )}
        {sendMoneyStep.payment && (
          <>
            <button
              type="button"
              onClick={goToMoneyPresentStep}
              className="credentials-section__prev-btn"
            >
              <img src={prevBtnImage} alt="arrow left black" />
            </button>
            <h1 className="credentials-section__title">
              <div className="mobile-icon">
                <img src={creditCards} alt="icon" />
              </div>
              אמצעי תשלום
            </h1>
            <h2 className="credentials-section__subtitle">
              תשלום באמצעות כרטיס אשראי
            </h2>
            <div className="credentials-section__form-wrapper">
              <form
                action="#"
                onSubmit={submitPaymentForm}
                className="credentials-section__form form-credentials-section"
              >
                <div className="form-credentials-section__select-wrapper">
                  <span className="input-title">סוג אירוע</span>
                  <select
                    name="event-type"
                    defaultValue={"empty"}
                    className="input"
                  >
                    <option value="empty" />
                    <option value={123}>123</option>
                    <option value={123}>123</option>
                    <option value={123}>123</option>
                  </select>
                </div>
                <label
                  htmlFor="form-credentials-section__billet-number"
                  className="form-credentials-section__input-wrapper input-wrapper"
                >
                  <span className="input-title">מספר כרטיס</span>
                  <input
                    autoComplete="off"
                    type="text"
                    name="billet-number"
                    id="form-credentials-section__billet-number"
                    className="input"
                  />
                </label>
                <label
                  htmlFor="form-credentials-section__id"
                  className="form-credentials-section__input-wrapper input-wrapper"
                >
                  <span className="input-title">מספר ת.ז של בעל הכרטיס</span>
                  <input
                    autoComplete="off"
                    type="text"
                    name="id"
                    id="form-credentials-section__id"
                    className="input"
                  />
                </label>
                <div className="card-info">
                  <p className="form-title">תוקף</p>
                  <div className="card-info__body">
                    <label
                      htmlFor="form-credentials-section__period"
                      className="form-credentials-section__input-wrapper input-wrapper"
                    >
                      <span className="input-title">שנה</span>
                      <input
                        autoComplete="off"
                        type="number"
                        name="period"
                        id="form-credentials-section__period"
                        className="input"
                      />
                    </label>
                    <label
                      htmlFor="form-credentials-section__month"
                      className="form-credentials-section__input-wrapper input-wrapper"
                    >
                      <span className="input-title">חודש</span>
                      <input
                        autoComplete="off"
                        type="number"
                        name="month"
                        id="form-credentials-section__month"
                        className="input"
                      />
                    </label>
                    <label
                      htmlFor="form-credentials-section__id"
                      className="form-credentials-section__input-wrapper input-wrapper"
                    >
                      <span className="input-title">CVV</span>
                      <input
                        autoComplete="off"
                        type="number"
                        name="cvv"
                        id="form-credentials-section__cvv"
                        className="input"
                      />
                    </label>
                  </div>
                </div>
                <div className="choose-payment">
                  <p className="form-title">סוג תשלום</p>
                  <div className="choose-payment__body">
                    <div className="long-checkbox">
                      <input
                        id="payment_1"
                        className="long-checkbox__input"
                        type="radio"
                        defaultValue={1}
                        name="payment"
                      />
                      <label
                        htmlFor="payment_1"
                        className="long-checkbox__label"
                      >
                        <span className="long-checkbox__text">
                          <img src={bit} alt="" />
                        </span>
                      </label>
                    </div>
                    <div className="long-checkbox">
                      <input
                        id="payment_2"
                        className="long-checkbox__input"
                        type="radio"
                        defaultValue={2}
                        name="payment"
                      />
                      <label
                        htmlFor="payment_2"
                        className="long-checkbox__label"
                      >
                        <span className="long-checkbox__text">
                          <img src={paybox} alt="" />
                        </span>
                      </label>
                    </div>
                    <div className="long-checkbox">
                      <input
                        id="payment_3"
                        className="long-checkbox__input"
                        type="radio"
                        defaultValue={3}
                        name="payment"
                      />
                      <label
                        htmlFor="payment_3"
                        className="long-checkbox__label"
                      >
                        <span className="long-checkbox__text">
                          <img src={paypalLong} alt="" />
                        </span>
                      </label>
                    </div>
                    <p className="form-credentials-section__text-under-checks">
                      {" "}
                      אנחנו נשתמש בפרטים האישיים כדי לעבד את ההזמנה, להציע לך
                      תמיכה בתהליך באתר זה וכדי .לבצע פעולות נוספות כפי שמפורט
                      במדיניות פרטיות
                    </p>
                  </div>
                </div>
                <div className="form-credentials-section__buttons-container">
                  <button type="submit" className="btn">
                  {languageToggle ? languageToggle.sendMoney[language].btn5 : "המשך"}
                  </button>
                </div>
              </form>
            </div>
          </>
        )}

        {!sendMoneyStep.blessingWrite && (
          <div className="credentials-section__progress">
            <div className="percent" style={{ right: progress + "%" }}>
              {progress}%
            </div>
            <div className="bar">
              <div className="background" />
              <div className="percent-line" style={{ width: progress + "%" }} />
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default SendMoney;
