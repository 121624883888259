import React, { useState, useCallback, useEffect, useRef } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import MyBankCard from "../Components/MyBank/MyBankCard";
import ProductsFilter from "../Components/products-filter";
import axios from "axios";
import MyBankCreateOrder from "../Components/MyBank/MyBankCreateOrder";
import SendMoney from "../Present-Or-Money/send-money";
import SinglePurchase from "../Components/purchase-single";
import NotEnoughMoneyPopup from "../Popups/NotEnoughMoneyPopup";
import { API_DOMAIN } from "../Libs/config";
import MyBankSinglePurchase from "../Components/MyBank/MyBankSinglePurchase";
import { SwiperSlide, Swiper } from "swiper/react";
import arrowLeftBlack from '../Assets/img/svg/arrow-left-black.svg';

import {
  homeBg,
  homeBgNoPeople,
  orangeBgImage,
  orangeBgImageNoPeople,
  present1,
  topbarBgGreen,
  topbarBg,
  giftBasketTopbar
} from "../Assets/images";
import MyBankProduct from "../Components/MyBank/MyBankProduct";
import { useSyncExternalStore } from "react";

const currEntWishlistObg = {
  "result": true,
  "whishlistItemsList": [
    {
      "id": 632,
      "wishlistId": 138,
      "productsId": 317,
      "productName": "הרמס וויאג (א) אדט 100מל",
      "providerId": "00b80d62-0640-42d1-ad2b-5c7fda22f6ab",
      "providerName": "וסילי גרידנב",
      "status": "not buy",
      "buyerId": null,
      "ownerId": "642793b5-beb8-4ede-acce-0e74fd4f47f5",
      "productImage": null
    },
    {
      "id": 637,
      "wishlistId": 138,
      "productsId": 1,
      "productName": "קוקו שאנל מאדמוזל דאו ספריי (א) 100מל",
      "providerId": "00b80d62-0640-42d1-ad2b-5c7fda22f6ab",
      "providerName": "וסילי גרידנב",
      "status": "buy by me",
      "buyerId": "642793b5-beb8-4ede-acce-0e74fd4f47f5",
      "ownerId": "642793b5-beb8-4ede-acce-0e74fd4f47f5",
      "productImage": null
    },
    {
      "id": 638,
      "wishlistId": 138,
      "productsId": 3,
      "productName": "גיבנשי לינטרדיט רוז (א) אדפ 50מל",
      "providerId": "00b80d62-0640-42d1-ad2b-5c7fda22f6ab",
      "providerName": "וסילי גרידנב",
      "status": "not buy",
      "buyerId": null,
      "ownerId": "642793b5-beb8-4ede-acce-0e74fd4f47f5",
      "productImage": null
    },
    {
      "id": 658,
      "wishlistId": 138,
      "productsId": 2,
      "productName": "נינה ריצי לאקסטז (א) אדפ 80מל",
      "providerId": "00b80d62-0640-42d1-ad2b-5c7fda22f6ab",
      "providerName": "וסילי גרידנב",
      "status": "not buy",
      "buyerId": null,
      "ownerId": "642793b5-beb8-4ede-acce-0e74fd4f47f5",
      "productImage": null
    }
  ],
  "whishlistObject": {
    "id": 138,
    "userId": "642793b5-beb8-4ede-acce-0e74fd4f47f5",
    "creationDate": "2023-08-17T13:49:35.7436332",
    "eventDate": "2023-09-08T11:58:00",
    "name": "חינה 7777",
    "description": "חינה 7777",
    "buyerIdList": null,
    "isActive": false,
    "houseNumber": "2",
    "apartmentNumber": "2",
    "entranceNumber": "2",
    "mailbox": "",
    "postal": "77777777",
    "arrivalTime": "11:58",
    "contactPhone": "0585444533",
    "type": "Henna",
    "city": "אבו ג'ווייעד (שבט)",
    "street": "אבו ג'ווייעד (שבט)",
    "isRemovable": false
  },
  "whishlistItemsDetailsList": [
    {
      "wishlistId": 138,
      "wishListItemId": 632,
      "status": "not buy",
      "productsId": 317,
      "productName": "הרמס וויאג (א) אדט 100מל",
      "providerId": "00b80d62-0640-42d1-ad2b-5c7fda22f6ab",
      "providerName": "וסילי גרידנב",
      "image": "https://morneta.co.il/wp-content/uploads/2022/11/50038286.jpg",
      "price": 1528.00,
      "date": "0001-01-01T00:00:00",
      "isRemovable": true
    },
    {
      "wishlistId": 138,
      "wishListItemId": 637,
      "status": "buy by me",
      "productsId": 1,
      "productName": "קוקו שאנל מאדמוזל דאו ספריי (א) 100מל",
      "providerId": "00b80d62-0640-42d1-ad2b-5c7fda22f6ab",
      "providerName": "וסילי גרידנב",
      "image": "https://morneta.co.il/wp-content/uploads/2023/01/13299312.jpg",
      "price": 243.00,
      "date": "0001-01-01T00:00:00",
      "isRemovable": false
    },
    {
      "wishlistId": 138,
      "wishListItemId": 638,
      "status": "not buy",
      "productsId": 3,
      "productName": "גיבנשי לינטרדיט רוז (א) אדפ 50מל",
      "providerId": "00b80d62-0640-42d1-ad2b-5c7fda22f6ab",
      "providerName": "וסילי גרידנב",
      "image": "https://morneta.co.il/wp-content/uploads/2022/11/13222140.jpg",
      "price": 255.00,
      "date": "0001-01-01T00:00:00",
      "isRemovable": true
    },
    {
      "wishlistId": 138,
      "wishListItemId": 658,
      "status": "not buy",
      "productsId": 2,
      "productName": "נינה ריצי לאקסטז (א) אדפ 80מל",
      "providerId": "00b80d62-0640-42d1-ad2b-5c7fda22f6ab",
      "providerName": "וסילי גרידנב",
      "image": "https://morneta.co.il/wp-content/uploads/2022/12/admin-ajax.jpg",
      "price": 169.00,
      "date": "0001-01-01T00:00:00",
      "isRemovable": true
    }
  ],
  "whishlistUser": {
    "rawPass": "123123",
    "firstName": "Vasily",
    "lastName": "Gridnev",
    "companyName": "",
    "bankAccountId": "",
    "isEnable": true,
    "dob": "2023-08-03T08:51:32.0173766",
    "otpCode": "72737",
    "otpDate": "2023-08-16T16:09:20.8873683",
    "birthdate": "2023-07-31T00:00:00",
    "mailBox": null,
    "postal": "",
    "houseNumber": "3",
    "apartmentNumber": "",
    "entranceNumber": "",
    "email": "brand@brand.com",
    "city": "אילת",
    "street": "רות המואביה",
    "id": "642793b5-beb8-4ede-acce-0e74fd4f47f5",
    "userName": "0585444533",
    "normalizedUserName": "0585444533",
    "normalizedEmail": null,
    "emailConfirmed": true,
    "passwordHash": "AQAAAAEAACcQAAAAEGaFasN/Y+ivL5foVCf34kmlaaNfv9PbdDdgkTqe31/yQo7OOf6lJnr/D99ekMJQQQ==",
    "securityStamp": "AHWKIVRRKY7DWZ5SBTC32K2JT7NULNWN",
    "concurrencyStamp": "37f48cca-f64d-42e9-9623-e23cc56327b0",
    "phoneNumber": "0585444533",
    "phoneNumberConfirmed": false,
    "twoFactorEnabled": false,
    "lockoutEnd": null,
    "lockoutEnabled": true,
    "accessFailedCount": 0
  },
  "isWishlistRemovable": false
}


export default function MyBank({
  allCategoriesList,
  filterToggle,
  setFilterObject,
  filterObject,
  currUserObject,
  updatePopupState,
  addItemHandler,
  openImagePopup,
  currWishlist,
  setCurrWishlist,
  languageToggle,
  language,
}) {

  const location = useLocation()
  const navigate = useNavigate();
  const purchasedState = location.state?.purchased || false;
  console.log('purchasedState', purchasedState)
  const [sortFilter, setSortFilter] = useState({
    isOpen: false,
    filter: "date",
    sortType: "asc",
  });
  const [cartType, setCartType] = useState("purchased");
  const [activeTab, setActiveTab] = useState(0)
  const [categories, setCategories] = useState([])
  const [manufacturers, setManufacturers] = useState([]);
  const [productsAll, setProductsAll] = useState([]);
  const [products, setProducts] = useState([]);
  const [visibleProducts, setVisibleProducts] = useState([]);
  const [nextIndex, setNextIndex] = useState(0);
  const [presentType, setPresentType] = useState(null);
  const [isOrange, setOrange] = useState(true);
  const [isGreenMobile, setGreenMobile] = useState(true);
  const [choosedPresent, setChoosedPresent] = useState({});
  const [isChoosed, setChoosed] = useState(false);
  const [stepNumber, setStepNumber] = useState(1);
  const [isForm, setForm] = useState(true);
  const [resultObject, setResultObject] = useState({});
  const [wishListInfo, setWishListInfo] = useState(null);
  const [mainBgImage, setMainBgImage] = useState({
    main: "",
    noPeople: "",
  });

  const [rangePriceMinMax, setRangePriceMinMax] = useState({
    min: null,
    max: null,
  });
  const [progress, setProgress] = useState(0);
  const [sendChoosedPresentStep, setSendChoosedPresentStep] = useState({
    blessingWrite: false,
    shipping: false,
    terms: false,
    moneyPresent: false,
    payment: false,
    successfulPayment: false,
  });
  const [sendMoneyStep, setMoneyStep] = useState({
    howMuchForPresent: false,
    blessingWrite: false,
    moneyPresent: false,
    payment: false,
    successfulPayment: false,
  });

  const [myBank, setMyBank] = useState(0)
  const [userData, setUserData] = useState({})
  const [notEnoughMoneyPopup, setNotEnoughMoneyPopup] = useState(false)
  const [boughtForBankMoney, setBoughtForBankMoney] = useState([])
  const [activeHeroSection, setActiveHeroSection] = useState(2)

  // sliders state
  const [activeIndex, setActiveIndex] = useState(0);
  const [slidesPerView, setSlidesPerView] = useState(3);
  const [isMobile, setIsMobile] = useState(false);
  const [relatedPrevActive, setRelatedPrevActive] = useState(true);
  const [recentPrevActive, setRecentPrevActive] = useState(true);
  const [activeIndexRecent, setActiveIndexRecent] = useState(0);

  const mainSwiperRef = useRef(null);
  const thumbnailSwiperRef = useRef(null);
  const mainProductsRelated = useRef(null);
  const mainProductsRecent = useRef(null);

  const handleThumbnailClick = (index) => {
    const swiperInstance = mainSwiperRef.current?.swiper;
    // const currentIndex = swiperInstance?.realIndex;
    // const lastIndex = swiperInstance.slides.length - 1;

    if (mainSwiperRef?.current && mainSwiperRef.current?.swiper) {
      swiperInstance.slideTo(index);
    }
  };


  const handleSlideStep = (swiper, direction) => {
    if (swiper && swiper.slideTo) {
      const currentIndex = swiper?.realIndex;
      const lastIndex = swiper?.slides.length - 1;
      console.log("handleSlideStep: ", currentIndex, lastIndex);

      let newIndex;
      if (direction === "prev") {
        newIndex = currentIndex > 0 ? currentIndex - 1 : lastIndex;
      } else if (direction === "next") {
        newIndex = currentIndex < lastIndex ? currentIndex + 1 : 0;
      }

      if (newIndex !== undefined) {
        swiper.slideTo(newIndex);
      }
    }
  };

  useEffect(() => {
    // console.log(mainProductsRelated.current?.swiper?.realIndex == 0, 'mainProductsRelated.current?.swiper.realIndex ')
    if (mainProductsRelated.current?.swiper?.realIndex == 0) {
      setRelatedPrevActive(true);
    } else {
      setRelatedPrevActive(false);
    }
  }, [mainProductsRelated.current?.swiper?.realIndex]);

  useEffect(() => {
    // console.log(mainProductsRecent.current?.swiper?.realIndex == 0, 'mainProductsRecent.current?.swiper.realIndex ')
    if (mainProductsRecent.current?.swiper?.realIndex == 0) {
      setRecentPrevActive(true);
    } else {
      setRecentPrevActive(false);
    }
  }, [mainProductsRecent.current?.swiper?.realIndex]);

  // returns my-bank page view to categories, closing MakeOrder flow with the present and opens purchased presents with the new one just bought
  const resetMyBankOrderFlow = () => {
    setChoosed('')
    setActiveTab(1)
  }

  useEffect(() => {
    const currentUser = localStorage.getItem("currUserObject");
    if (!currentUser) {
      navigate("/credentials");
    }
    console.log('location_my_bank_debug', location, location.state, location.state?.purchased)
    // if /my-bank is opened from success popup after buying a product, we show second tab as active (purchased) products
    setActiveTab(location.state?.purchased ? 1 : 0)
  }, []);

  console.log('visibleProducts:', products)

  const handleSortDrop = () => {
    const newValue = !sortFilter.isOpen;
    setSortFilter({
      isOpen: newValue,
      filter: sortFilter.filter,
      sortType: sortFilter.sortType,
    });
  };

  const setFilterPrice = () => {
    setSortFilter({
      isOpen: false,
      filter: "price",
      sortType: sortFilter.sortType,
    });
  };
  const setFilterDate = () => {
    setSortFilter({
      isOpen: false,
      filter: "date",
      sortType: sortFilter.sortType,
    });
  };
  const setFilterStatus = () => {
    setSortFilter({
      isOpen: false,
      filter: "status",
      sortType: sortFilter.sortType,
    });
  };


  const handleCardClick = (e, id) => {
    const { target } = e;
    console.log(target);

    if (
      target.closest(".single-products-card__link.btn") ||
      target.closest(".single-products-card__text-content")
    )
      return;
    // Handle card click to open one root
    navigate(`/single-product/${id}`);
  };


  const goToChoosedPresentForm = (present) => {


    console.log('goToChoosedPresentForm', present)

    if (present?.price > myBank) {
      updatePopupState(true)
      setNotEnoughMoneyPopup(true)
      return
    }

    const currUserObject = JSON.parse(localStorage.getItem('currUserObject'))
    const { user: { id } } = currUserObject;
    setPresentType("present");
    setChoosed(true);
    setStepNumber(1);
    setProgress(5);
    setMainBgImage({
      main: homeBg,
      noPeople: homeBgNoPeople,
    });
    setSendChoosedPresentStep({
      blessingWrite: true,
      shipping: false,
    });
    console.log('goToChoosedPresentForm: present', present);
    setChoosedPresent(present);
    setResultObject((prevObj) => {
      const newObj = {
        ...prevObj,
        // wishlistItemsId: [present.id],
        presentType: "present",
        buyerId: userData?.user.id,
        // buyerId: currUserObject.user.id,
        // ownerId: wishListInfo?.whishlistUser?.id,
        ownerId: id,
        productId: present.id
      };
      console.log('goToChoosedPresentForm: ', 'newObj:', newObj, 'present: ', present)
      return newObj;
    });
    window.scrollTo(0, 0);
  };
  const goToMoneyForm = () => {
    setPresentType("money");
    setChoosed(true);
    setStepNumber(1);
    setProgress(5);
    setMainBgImage({
      main: orangeBgImage,
      noPeople: orangeBgImageNoPeople,
    });
    setMoneyStep({
      howMuchForPresent: true,
      blessingWrite: false,
    });
    setResultObject((prevObj) => {
      const newObj = { ...prevObj, presentType: "money" };
      return newObj;
    });
    window.scrollTo(0, 0);

  };


  const handleFilterChecks = (
    e,
    choosedCategory = null,
    manufacturer = null
  ) => {
    console.log('handleFilterChecks:', e.target, choosedCategory, manufacturer)

    if (e.target.id.includes("category-check")) {
      if (e.target.checked) {
        setFilterObject((prevObj) => {
          const newObj = {
            ...prevObj,
            choosedCategoriesList: [
              ...prevObj.choosedCategoriesList,
              choosedCategory,
            ],
            lastChangedType: "category",
          };
          return newObj;
        });

        const headerCheck = document.getElementById(
          `header-category-check_${choosedCategory.categoryId}_${choosedCategory.productsId}`
        );
        if (headerCheck) {
          headerCheck.checked = true;
        }
      } else {
        let newCategoriesList = [...filterObject.choosedCategoriesList];
        filterObject.choosedCategoriesList.filter((item) => {
          if (
            item.categoryId === choosedCategory.categoryId &&
            item.productsId === choosedCategory.productsId
          ) {
            newCategoriesList.splice(newCategoriesList.indexOf(item), 1);
          }
          return newCategoriesList;
        });

        setFilterObject((prevObj) => {
          const newObj = {
            ...prevObj,
            choosedCategoriesList: [...newCategoriesList],
            lastChangedType: "category",
          };
          return newObj;
        });

        // getProductsByCategoriesList(prevCategoriesList);
        // setChoosedCategoriesList(prevCategoriesList);
        const headerCheck = document.getElementById(
          `header-category-check_${choosedCategory.categoryId}_${choosedCategory.productsId}`
        );
        if (headerCheck) {
          headerCheck.checked = false;
        }
      }
    } else if (e.target.id.includes("manufacturer_")) {
      if (e.target.checked) {
        setFilterObject((prevObj) => {
          const newObj = {
            ...prevObj,
            manufacturersList: [...prevObj.manufacturersList, manufacturer],
            priceRange: null,
            lastChangedType: "manufacturer",
          };
          return newObj;
        });
      } else {
        setFilterObject((prevObj) => {
          let newArr = [...prevObj.manufacturersList];
          newArr = newArr.filter((item) => item !== manufacturer);
          console.log(newArr);
          const newObj = {
            ...prevObj,
            manufacturersList: newArr,
            priceRange: null,
            lastChangedType: "manufacturer",
          };
          return newObj;
        });
      }
    }
  };

  const handleFilterProducts = (array) => {
    setProducts(() => {
      const newArr = array.filter((product) => {
        if (!filterObject.manufacturersList.length) {
          if (!filterObject.priceRange) {
            return productsAll;
          } else {
            return (
              product.price >= filterObject.priceRange[0] &&
              product.price <= filterObject.priceRange[1]
            );
          }
        } else if (!filterObject.priceRange) {
          return filterObject.manufacturersList.includes(product.manufacturer);
        } else {
          return (
            filterObject.manufacturersList.includes(product.manufacturer) &&
            product.price >= filterObject.priceRange[0] &&
            product.price <= filterObject.priceRange[1]
          );
        }
      });

      return newArr;
    });
  };

  function minMaxPriceHandler(products) {
    const pricesArr = products.map((product) => Number(product.price));
    const pricesSorted = pricesArr.sort((a, b) => a - b);
    // console.log(pricesSorted, 'priceArr')

    // const min = floorToTenThousands(pricesSorted[0]);
    // const max = ceilToHundredThousands(pricesSorted[pricesSorted.length - 1]);
    const min = pricesSorted[0];
    const max = pricesSorted[pricesSorted.length - 1];

    return { minPrice: min, maxPrice: max };
  }

  const getProductsByCategoriesList = (idList) => {
    if (idList.length) {
      setProducts([]);
      setProductsAll([]);
      let finalArray = [];
      let i = 0;
      idList.forEach((item) => {
        axios
          .get(
            `${API_DOMAIN}/api/ProductCategory/GetProductsByCategoryId?categoryId=${item.productsId}`
          )
          .then((response) => {
            console.log(response.data.result.products);
            const allProducts = response.data.result.products;
            finalArray = [...finalArray, ...allProducts];

            i++;
            if (i === idList.length) {
              console.log(finalArray);
              setProducts(finalArray);
              setProductsAll(finalArray);
            }
          })
          .catch((error) => {
            console.error(error);
            i++;
            alert("Error, please try again");
          });
      });
    }
  };

  // Creating order with the gift money that are saved in myBank variable.


  const getPurchasedProductsWithBankMoney = async () => {
    try {
      const currUserObject = JSON.parse(localStorage.getItem('currUserObject'))
      const { user: { id } } = currUserObject;
      // const params = { userId: id }
      const response = await axios.post(`${API_DOMAIN}/api/ProductCategory/getMyBankOrders?userId=${id}`)
      if (response.status === 200) {
        console.log('getPurchasedProductsWithBankMoney: ', response.data)
        setBoughtForBankMoney(response.data)
      }
    } catch (error) {
      console.log(error)
      alert('Something went wrong', error)
    }
  }

  useEffect(() => {
    getPurchasedProductsWithBankMoney()

    return () => {

    }
  }, [])



  useEffect(() => {
    const getUserData = async () => {
      const currUserObject = JSON.parse(localStorage.getItem('currUserObject'))
      const { user: { id } } = currUserObject;
      try {
        const response = await axios.get(`${API_DOMAIN}/api/Authenticate/getUserData?userId=${id}`)
        console.log('getUserData: ', response)
        if (response.status === 200) {
          const { data: { user: { myBank } } } = response;
          // console.log('getUserData_myBank', myBank)
          setUserData(response.data)
          setMyBank(myBank ? myBank : 0)
        }
        // const { user: { myBank } }
      } catch (error) {
        console.log(error)
        alert('Something went wrong')
      }
    }

    getUserData()

    return () => {

    }
  }, [])



  useEffect(() => {
    console.log('filterObject:', filterObject);
    if (filterObject.lastChangedType === "category") {
      getProductsByCategoriesList(filterObject.choosedCategoriesList);
      setFilterObject((prevObj) => {
        const newObj = { ...prevObj, manufacturers: [], lastChangedType: "" };
        return newObj;
      });
      document.querySelectorAll(".manufacturer-check").forEach((item) => {
        if (item.checked) item.checked = false;
      });
    } else if (filterObject.lastChangedType !== "") {
      handleFilterProducts(productsAll);
    }

  }, [filterObject]);

  useEffect(() => {
    let finalArray = [];

    axios
      .get(
        `${API_DOMAIN}/api/ProductCategory/GetProductsByCategoryId?categoryId=${6}`
      )
      .then((response) => {
        console.log(response.data.result.products);
        const allProducts = response.data.result.products;
        finalArray = [...finalArray, ...allProducts];

        console.log(finalArray);
        setProducts(finalArray);
        setProductsAll(finalArray);
      })
      .catch((error) => {
        console.error(error);
        alert("Error, please try again");
      });

    return () => {

    }
  }, [])




  useEffect(() => {
    console.log(products);
    if (products.length) {
      const initialProducts = products.slice(0, 20);
      setVisibleProducts(initialProducts);
      setNextIndex(20);

      if (filterObject.lastChangedType !== "price") {
        const { minPrice, maxPrice } = minMaxPriceHandler(products);
        console.log(minPrice, " ", maxPrice);
        setRangePriceMinMax({ min: minPrice, max: maxPrice });
        setFilterObject((prevObj) => {
          const newObj = {
            ...prevObj,
            priceRange: [minPrice, maxPrice],
            lastChangedType: "",
          };
          return newObj;
        });
      }
    }
  }, [products]);

  useEffect(() => {
    if (productsAll.length) {
      const manufacturersArr = productsAll.reduce((result, current) => {
        const { manufacturer } = current;
        const isDuplicate = result.some(
          (resultManufacturer) => resultManufacturer === manufacturer
        );

        if (!isDuplicate && manufacturer !== "") {
          result.push(manufacturer);
        }

        return result;
      }, []);

      setManufacturers(manufacturersArr);
    }
  }, [productsAll]);

  useEffect(() => {
    console.log(manufacturers);
  }, [manufacturers]);

  useEffect(() => {
    //return;
    const sortType = sortFilter.sortType;
    const currentActiveTab = cartType;
    const sortBy = sortFilter.filter;
    console.log(sortFilter);

    setCurrWishlist((prevObj) => {
      const newObj = prevObj;
      const prevArr = prevObj.whishlistItemsDetailsList;
      let filteredArr = [];
      if (prevArr && prevArr.length) {
        filteredArr = prevArr;

        filteredArr.sort((a, b) => {
          const aValue = String(a[sortBy]);
          const bValue = String(b[sortBy]);
          if (sortType === "asc") {
            return aValue.localeCompare(bValue);
          } else {
            return bValue.localeCompare(aValue);
          }
        });
        console.log(filteredArr);
      }

      // newObj.whishlistItemsDetailsList = filteredArr;
      return { ...newObj, whishlistItemsDetailsList: filteredArr };
    });
  }, [sortFilter]);

  useEffect(() => {
    setCurrWishlist(currEntWishlistObg)

    return () => {

    }
  }, [])





  return (
    <>
      {!isChoosed && (
        <main className="my-bank" data-fullscreen>
          <section className="my-bank__topbar topbar">
            <div className="topbar__bg-img-ibg">
              <img src="img/common/topbar-bg-green.png" alt="topbar" />
            </div>
            <div className="topbar__container">
              <div className="topbar__title-wrapper">
                <h1 className="topbar__title">
                  הקופה שלי
                  <span className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="156.143"
                      height="156.143"
                      viewBox="0 0 156.143 156.143"
                    >
                      <g id="a" transform="translate(-51.41 -86.97)">
                        <g id="b" transform="translate(52.91 88.559)">
                          <circle
                            id="Эллипс_423"
                            data-name="Эллипс 423"
                            cx="49.772"
                            cy="49.772"
                            r="49.772"
                            transform="translate(26.8 26.711)"
                            fill="#fffcef"
                            opacity="0.1"
                          />
                          <circle
                            id="Эллипс_424"
                            data-name="Эллипс 424"
                            cx="9.189"
                            cy="9.189"
                            r="9.189"
                            transform="translate(13.904 120.773)"
                            fill="#3c9e1c"
                            opacity="0.7"
                          />
                          <circle
                            id="Эллипс_425"
                            data-name="Эллипс 425"
                            cx="15.314"
                            cy="15.314"
                            r="15.314"
                            transform="translate(114.736 7.69)"
                            fill="#fff"
                            opacity="0.4"
                          />
                          <circle
                            id="Эллипс_426"
                            data-name="Эллипс 426"
                            cx="76.5"
                            cy="76.5"
                            r="76.5"
                            transform="translate(0 0.054)"
                            fill="none"
                            stroke="#fff"
                            strokeLinecap="round"
                            strokeMiterlimit="10"
                            strokeWidth="3"
                            strokeDasharray="5 5"
                          />
                        </g>
                        <g
                          id="Сгруппировать_1514"
                          data-name="Сгруппировать 1514"
                          transform="translate(52.91 88.47)"
                        >
                          <path
                            id="Контур_38461"
                            data-name="Контур 38461"
                            d="M206.053,165.042a76.542,76.542,0,1,1-11.445-40.291,76.57,76.57,0,0,1,11.445,40.291h0Z"
                            transform="translate(-52.91 -88.47)"
                            fill="none"
                            stroke="#fff"
                            strokeLinecap="round"
                            strokeMiterlimit="10"
                            strokeWidth="3"
                            strokeDasharray="0 20"
                            fillRule="evenodd"
                            opacity="0"
                          />
                          <line
                            id="Линия_534"
                            data-name="Линия 534"
                            y2="55.91"
                            transform="translate(33.112 51.048)"
                            fill="none"
                            stroke="#fff"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="3"
                          />
                          <path
                            id="Контур_38462"
                            data-name="Контур 38462"
                            d="M215.95,490.488h74.4a4.862,4.862,0,0,0,4.862-4.862V471.04"
                            transform="translate(-175.548 -376.237)"
                            fill="none"
                            stroke="#fff"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeDashoffset="1905"
                            strokeWidth="3"
                            strokeDasharray="0 0 0 0 710 60 197.5 0"
                          />
                          <path
                            id="Контур_38463"
                            data-name="Контур 38463"
                            d="M295.213,343.348V328.762a4.862,4.862,0,0,0-4.862-4.862h-74.4"
                            transform="translate(-175.548 -265.559)"
                            fill="none"
                            stroke="#fff"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="3"
                          />
                          <path
                            id="Контур_38464"
                            data-name="Контур 38464"
                            d="M294.044,300.549v-1.217a4.865,4.865,0,0,0-4.862-4.862H220.86"
                            transform="translate(-179.241 -243.422)"
                            fill="none"
                            stroke="#fff"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeDashoffset="950"
                            strokeWidth="3"
                            strokeDasharray="0 0 0 0 877.5 40 0 60"
                          />
                          <path
                            id="Контур_38465"
                            data-name="Контур 38465"
                            d="M285.491,271.119V269.9a4.865,4.865,0,0,0-4.862-4.862H215.95"
                            transform="translate(-175.548 -221.285)"
                            fill="none"
                            stroke="#fff"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeDashoffset="1077.5"
                            strokeWidth="3"
                            strokeDasharray="0 0 0 0 78 467 0 560"
                          />
                          <path
                            id="Контур_38466"
                            data-name="Контур 38466"
                            d="M484.157,384.974H472a4.861,4.861,0,0,0-4.862,4.862v7.293A4.861,4.861,0,0,0,472,401.991h12.137A4.861,4.861,0,0,0,489,397.112c-.022-6.686-.062-17.872-.062-17.872"
                            transform="translate(-364.492 -307.186)"
                            fill="none"
                            stroke="#fff"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeDashoffset="497.5"
                            strokeWidth="3"
                            strokeDasharray="0 0 0 0 438.76 40 0 60"
                          />
                          <path
                            id="Контур_38467"
                            data-name="Контур 38467"
                            d="M193.813,527.383a7.3,7.3,0,0,1-7.293-7.293"
                            transform="translate(-153.411 -413.133)"
                            fill="none"
                            stroke="#fff"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="3"
                          />
                          <path
                            id="Контур_38468"
                            data-name="Контур 38468"
                            d="M193.813,279.626a7.293,7.293,0,1,1,0-14.586"
                            transform="translate(-153.411 -221.285)"
                            fill="none"
                            stroke="#fff"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="3"
                          />
                          <path
                            id="Контур_38469"
                            data-name="Контур 38469"
                            d="M540.672,382.76a4.864,4.864,0,0,1-4.862,4.862"
                            transform="translate(-416.145 -309.834)"
                            fill="none"
                            stroke="#fff"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="3"
                          />
                          <path
                            id="Контур_38470"
                            data-name="Контур 38470"
                            d="M540.672,368a4.864,4.864,0,0,0-4.862-4.862"
                            transform="translate(-416.145 -295.076)"
                            fill="none"
                            stroke="#fff"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="3"
                          />
                          <path
                            id="Контур_38471"
                            data-name="Контур 38471"
                            d="M491.861,426.9a2.431,2.431,0,1,1-2.431,2.431,2.43,2.43,0,0,1,2.431-2.431Z"
                            transform="translate(-381.258 -343.036)"
                            fill="none"
                            stroke="#fff"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="3"
                          />
                          <path
                            id="Контур_38472"
                            data-name="Контур 38472"
                            d="M282.546,217.331l-13.267-22.981a4.859,4.859,0,0,0-6.639-1.779L219.68,217.331"
                            transform="translate(-178.061 -174.79)"
                            fill="none"
                            stroke="#fff"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeDashoffset="851.26"
                            strokeWidth="3"
                            strokeDasharray="0 0 0 0 716.76 134.76 0 40"
                          />
                          <line
                            id="Линия_535"
                            data-name="Линия 535"
                            x2="46.186"
                            transform="translate(53.479 104.526)"
                            fill="none"
                            stroke="#fff"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="3"
                            strokeDasharray="0 0 0 0 0 40 628.76 560"
                          />
                        </g>
                      </g>
                    </svg>
                  </span>
                </h1>
              </div>
            </div>
          </section>

          <div className="my-bank__breadcrumbs breadcrumbs">
            <div className="breadcrumbs__container">
              <ul className="breadcrumbs__list">
                <li className="breadcrumbs__item">
                  <NavLink to="/">דף הבית</NavLink>
                </li>
                <li className="breadcrumbs__item">
                  <p>my bank</p>
                </li>
              </ul>
            </div>
          </div>

          <section className={`my-bank__main main-products main-my-bank ${activeTab == 1 ? 'mb-0' : ''}`}>
            <div className="main-products__container main-my-bank__container desktop">
              <div className="main-choose-event__navigation my-bank">
                <ul className="main-choose-event__nav-list">
                  <li className="main-choose-event__nav-item">
                    <button
                      onClick={() => setActiveTab(0)}
                      type="button"
                      className={`main-choose-event__button ${activeTab === 0 ? "active" : ""
                        }`}
                    >
                      My bank
                    </button>
                  </li>
                  <li className="main-choose-event__nav-item">
                    <button
                      onClick={() => setActiveTab(1)}
                      type="button"
                      className={`main-choose-event__button ${activeTab === 1 ? "active" : ""
                        }`}
                    >
                      My purchased
                    </button>
                  </li>
                </ul>
              </div>

              {
                activeTab === 0 && (
                  <>
                    <div className="main-products__filter-btn-container filter-btn-container">
                      <button type="button" className="filter-btn" onClick={filterToggle}>
                        <img src="img/svg/filter-icon.png" alt="filter icon" />
                      </button>
                    </div>
                    <h2 className="main-products__title">
                      {currUserObject.user.firstName + " " + currUserObject.user.lastName}  חגית עטיה נשאר לך  {myBank}₪
                    </h2>
                    <h3 className="main-products__pretitle">
                      בחר/י את המוצרים ממוצרים נילווים לאופנה למימוש
                    </h3>

                    <NavLink to={`/my-bank-all-categories/?wishlistId=${13}`} className="btn main-cat desktop">To main categories</NavLink>
                    <div className="main-products__list-wrapper swiper">
                      <ul className="main-products__list products-list swiper-wrapper">

                        {visibleProducts &&
                          visibleProducts.map((product) => (
                            <MyBankProduct
                              key={product.id}
                              data={product}
                              updatePopupState={updatePopupState}
                              addItemHandler={addItemHandler}
                              openImagePopup={openImagePopup}
                              currWishlist={currWishlist}
                              setCurrWishlist={setCurrWishlist}
                              currUserObject={currUserObject}
                              goToChoosedPresentForm={goToChoosedPresentForm}
                            />
                          ))}

                      </ul>

                      <div className="main-products__list-nav">
                        <div className="main-products__list-prev">
                          <img src="img/svg/arrow-left-black.svg" alt="" />
                        </div>
                        <div className="main-products__list-next">
                          <img src="img/svg/arrow-left-black.svg" alt="" />
                        </div>
                      </div>
                    </div>
                  </>
                )
              }

            </div>
            <div className="main-my-bank__container mobile">
              <div className="main-my-bank__bottom-btns">
                <button
                  onClick={() => setActiveTab(0)}

                  className="btn btn-white">Buy</button>
                <button
                  onClick={() => setActiveTab(1)}
                  className="btn green">Purchased</button>
              </div>
              <div className="main-products__filter-btn-container filter-btn-container">
                <button type="button" className="filter-btn" onClick={filterToggle}>
                  <img src="img/svg/filter-icon.png" alt="filter icon" />
                </button>
              </div>
              <div className="main-my-bank__body" hidden={activeHeroSection !== 1}>
                <div className="main-my-bank__icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="156.143"
                    height="156.143"
                    viewBox="0 0 156.143 156.143"
                  >
                    <g id="a" transform="translate(-51.41 -86.97)">
                      <g id="b" transform="translate(52.91 88.559)">
                        <circle
                          id="Эллипс_423"
                          data-name="Эллипс 423"
                          cx="49.772"
                          cy="49.772"
                          r="49.772"
                          transform="translate(26.8 26.711)"
                          fill="#fffcef"
                          opacity="0.1"
                        />
                        <circle
                          id="Эллипс_424"
                          data-name="Эллипс 424"
                          cx="9.189"
                          cy="9.189"
                          r="9.189"
                          transform="translate(13.904 120.773)"
                          fill="#3c9e1c"
                          opacity="0.7"
                        />
                        <circle
                          id="Эллипс_425"
                          data-name="Эллипс 425"
                          cx="15.314"
                          cy="15.314"
                          r="15.314"
                          transform="translate(114.736 7.69)"
                          fill="#fff"
                          opacity="0.4"
                        />
                        <circle
                          id="Эллипс_426"
                          data-name="Эллипс 426"
                          cx="76.5"
                          cy="76.5"
                          r="76.5"
                          transform="translate(0 0.054)"
                          fill="none"
                          stroke="#fff"
                          strokeLinecap="round"
                          strokeMiterlimit="10"
                          strokeWidth="3"
                          strokeDasharray="5 5"
                        />
                      </g>
                      <g
                        id="Сгруппировать_1514"
                        data-name="Сгруппировать 1514"
                        transform="translate(52.91 88.47)"
                      >
                        <path
                          id="Контур_38461"
                          data-name="Контур 38461"
                          d="M206.053,165.042a76.542,76.542,0,1,1-11.445-40.291,76.57,76.57,0,0,1,11.445,40.291h0Z"
                          transform="translate(-52.91 -88.47)"
                          fill="none"
                          stroke="#fff"
                          strokeLinecap="round"
                          strokeMiterlimit="10"
                          strokeWidth="3"
                          strokeDasharray="0 20"
                          fillRule="evenodd"
                          opacity="0"
                        />
                        <line
                          id="Линия_534"
                          data-name="Линия 534"
                          y2="55.91"
                          transform="translate(33.112 51.048)"
                          fill="none"
                          stroke="#fff"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="3"
                        />
                        <path
                          id="Контур_38462"
                          data-name="Контур 38462"
                          d="M215.95,490.488h74.4a4.862,4.862,0,0,0,4.862-4.862V471.04"
                          transform="translate(-175.548 -376.237)"
                          fill="none"
                          stroke="#fff"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeDashoffset="1905"
                          strokeWidth="3"
                          strokeDasharray="0 0 0 0 710 60 197.5 0"
                        />
                        <path
                          id="Контур_38463"
                          data-name="Контур 38463"
                          d="M295.213,343.348V328.762a4.862,4.862,0,0,0-4.862-4.862h-74.4"
                          transform="translate(-175.548 -265.559)"
                          fill="none"
                          stroke="#fff"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="3"
                        />
                        <path
                          id="Контур_38464"
                          data-name="Контур 38464"
                          d="M294.044,300.549v-1.217a4.865,4.865,0,0,0-4.862-4.862H220.86"
                          transform="translate(-179.241 -243.422)"
                          fill="none"
                          stroke="#fff"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeDashoffset="950"
                          strokeWidth="3"
                          strokeDasharray="0 0 0 0 877.5 40 0 60"
                        />
                        <path
                          id="Контур_38465"
                          data-name="Контур 38465"
                          d="M285.491,271.119V269.9a4.865,4.865,0,0,0-4.862-4.862H215.95"
                          transform="translate(-175.548 -221.285)"
                          fill="none"
                          stroke="#fff"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeDashoffset="1077.5"
                          strokeWidth="3"
                          strokeDasharray="0 0 0 0 78 467 0 560"
                        />
                        <path
                          id="Контур_38466"
                          data-name="Контур 38466"
                          d="M484.157,384.974H472a4.861,4.861,0,0,0-4.862,4.862v7.293A4.861,4.861,0,0,0,472,401.991h12.137A4.861,4.861,0,0,0,489,397.112c-.022-6.686-.062-17.872-.062-17.872"
                          transform="translate(-364.492 -307.186)"
                          fill="none"
                          stroke="#fff"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeDashoffset="497.5"
                          strokeWidth="3"
                          strokeDasharray="0 0 0 0 438.76 40 0 60"
                        />
                        <path
                          id="Контур_38467"
                          data-name="Контур 38467"
                          d="M193.813,527.383a7.3,7.3,0,0,1-7.293-7.293"
                          transform="translate(-153.411 -413.133)"
                          fill="none"
                          stroke="#fff"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="3"
                        />
                        <path
                          id="Контур_38468"
                          data-name="Контур 38468"
                          d="M193.813,279.626a7.293,7.293,0,1,1,0-14.586"
                          transform="translate(-153.411 -221.285)"
                          fill="none"
                          stroke="#fff"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="3"
                        />
                        <path
                          id="Контур_38469"
                          data-name="Контур 38469"
                          d="M540.672,382.76a4.864,4.864,0,0,1-4.862,4.862"
                          transform="translate(-416.145 -309.834)"
                          fill="none"
                          stroke="#fff"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="3"
                        />
                        <path
                          id="Контур_38470"
                          data-name="Контур 38470"
                          d="M540.672,368a4.864,4.864,0,0,0-4.862-4.862"
                          transform="translate(-416.145 -295.076)"
                          fill="none"
                          stroke="#fff"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="3"
                        />
                        <path
                          id="Контур_38471"
                          data-name="Контур 38471"
                          d="M491.861,426.9a2.431,2.431,0,1,1-2.431,2.431,2.43,2.43,0,0,1,2.431-2.431Z"
                          transform="translate(-381.258 -343.036)"
                          fill="none"
                          stroke="#fff"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="3"
                        />
                        <path
                          id="Контур_38472"
                          data-name="Контур 38472"
                          d="M282.546,217.331l-13.267-22.981a4.859,4.859,0,0,0-6.639-1.779L219.68,217.331"
                          transform="translate(-178.061 -174.79)"
                          fill="none"
                          stroke="#fff"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeDashoffset="851.26"
                          strokeWidth="3"
                          strokeDasharray="0 0 0 0 716.76 134.76 0 40"
                        />
                        <line
                          id="Линия_535"
                          data-name="Линия 535"
                          x2="46.186"
                          transform="translate(53.479 104.526)"
                          fill="none"
                          stroke="#fff"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="3"
                          strokeDasharray="0 0 0 0 0 40 628.76 560"
                        />
                      </g>
                    </g>
                  </svg>
                </div>

                <h2 className="main-my-bank__title">הקופה שלי</h2>
                <h3 className="main-my-bank__subtitle">
                  תוכל לממש את כספך בכל זמן ובכל מתנה שתחלום
                </h3>
                <div className="main-my-bank__price">
                  <p className="main-my-bank__price-text">עד עכשיו צברת</p>
                  <div className="main-my-bank__price-value">
                    <span className="text">{myBank} </span>
                    <span className="money-icon"> ₪</span>
                  </div>
                </div>
                <div className="main-my-bank__btn-container">
                  <a href="#" className="btn-white round">
                    רוצה לבחור מתנה
                  </a>

                </div>

                <div className="main-my-bank__bottom-nav-buttons">
                  <button onClick={() => setActiveHeroSection(2)}>2</button>
                  <button onClick={() => setActiveHeroSection(1)}>1</button>
                </div>


                <div className="main-products__list-nav">
                  <div className="main-products__list-prev">
                    <img src="img/svg/arrow-left-black.svg" alt="" />
                  </div>
                  <div className="main-products__list-next">
                    <img src="img/svg/arrow-left-black.svg" alt="" />
                  </div>
                </div>
              </div>


              <div className="main-my-bank__body empty" hidden={activeHeroSection !== 2}>
                <div className="main-my-bank__icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="156.143"
                    height="156.143"
                    viewBox="0 0 156.143 156.143"
                  >
                    <g id="a" transform="translate(-51.41 -86.97)">
                      <g id="b" transform="translate(52.91 88.559)">
                        <circle
                          id="Эллипс_423"
                          data-name="Эллипс 423"
                          cx="49.772"
                          cy="49.772"
                          r="49.772"
                          transform="translate(26.8 26.711)"
                          fill="#fffcef"
                          opacity="0.1"
                        />
                        <circle
                          id="Эллипс_424"
                          data-name="Эллипс 424"
                          cx="9.189"
                          cy="9.189"
                          r="9.189"
                          transform="translate(13.904 120.773)"
                          fill="#3c9e1c"
                          opacity="0.7"
                        />
                        <circle
                          id="Эллипс_425"
                          data-name="Эллипс 425"
                          cx="15.314"
                          cy="15.314"
                          r="15.314"
                          transform="translate(114.736 7.69)"
                          fill="#fff"
                          opacity="0.4"
                        />
                        <circle
                          id="Эллипс_426"
                          data-name="Эллипс 426"
                          cx="76.5"
                          cy="76.5"
                          r="76.5"
                          transform="translate(0 0.054)"
                          fill="none"
                          stroke="#fff"
                          strokeLinecap="round"
                          strokeMiterlimit="10"
                          strokeWidth="3"
                          strokeDasharray="5 5"
                        />
                      </g>
                      <g
                        id="Сгруппировать_1514"
                        data-name="Сгруппировать 1514"
                        transform="translate(52.91 88.47)"
                      >
                        <path
                          id="Контур_38461"
                          data-name="Контур 38461"
                          d="M206.053,165.042a76.542,76.542,0,1,1-11.445-40.291,76.57,76.57,0,0,1,11.445,40.291h0Z"
                          transform="translate(-52.91 -88.47)"
                          fill="none"
                          stroke="#fff"
                          strokeLinecap="round"
                          strokeMiterlimit="10"
                          strokeWidth="3"
                          strokeDasharray="0 20"
                          fillRule="evenodd"
                          opacity="0"
                        />
                        <line
                          id="Линия_534"
                          data-name="Линия 534"
                          y2="55.91"
                          transform="translate(33.112 51.048)"
                          fill="none"
                          stroke="#fff"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="3"
                        />
                        <path
                          id="Контур_38462"
                          data-name="Контур 38462"
                          d="M215.95,490.488h74.4a4.862,4.862,0,0,0,4.862-4.862V471.04"
                          transform="translate(-175.548 -376.237)"
                          fill="none"
                          stroke="#fff"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeDashoffset="1905"
                          strokeWidth="3"
                          strokeDasharray="0 0 0 0 710 60 197.5 0"
                        />
                        <path
                          id="Контур_38463"
                          data-name="Контур 38463"
                          d="M295.213,343.348V328.762a4.862,4.862,0,0,0-4.862-4.862h-74.4"
                          transform="translate(-175.548 -265.559)"
                          fill="none"
                          stroke="#fff"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="3"
                        />
                        <path
                          id="Контур_38464"
                          data-name="Контур 38464"
                          d="M294.044,300.549v-1.217a4.865,4.865,0,0,0-4.862-4.862H220.86"
                          transform="translate(-179.241 -243.422)"
                          fill="none"
                          stroke="#fff"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeDashoffset="950"
                          strokeWidth="3"
                          strokeDasharray="0 0 0 0 877.5 40 0 60"
                        />
                        <path
                          id="Контур_38465"
                          data-name="Контур 38465"
                          d="M285.491,271.119V269.9a4.865,4.865,0,0,0-4.862-4.862H215.95"
                          transform="translate(-175.548 -221.285)"
                          fill="none"
                          stroke="#fff"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeDashoffset="1077.5"
                          strokeWidth="3"
                          strokeDasharray="0 0 0 0 78 467 0 560"
                        />
                        <path
                          id="Контур_38466"
                          data-name="Контур 38466"
                          d="M484.157,384.974H472a4.861,4.861,0,0,0-4.862,4.862v7.293A4.861,4.861,0,0,0,472,401.991h12.137A4.861,4.861,0,0,0,489,397.112c-.022-6.686-.062-17.872-.062-17.872"
                          transform="translate(-364.492 -307.186)"
                          fill="none"
                          stroke="#fff"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeDashoffset="497.5"
                          strokeWidth="3"
                          strokeDasharray="0 0 0 0 438.76 40 0 60"
                        />
                        <path
                          id="Контур_38467"
                          data-name="Контур 38467"
                          d="M193.813,527.383a7.3,7.3,0,0,1-7.293-7.293"
                          transform="translate(-153.411 -413.133)"
                          fill="none"
                          stroke="#fff"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="3"
                        />
                        <path
                          id="Контур_38468"
                          data-name="Контур 38468"
                          d="M193.813,279.626a7.293,7.293,0,1,1,0-14.586"
                          transform="translate(-153.411 -221.285)"
                          fill="none"
                          stroke="#fff"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="3"
                        />
                        <path
                          id="Контур_38469"
                          data-name="Контур 38469"
                          d="M540.672,382.76a4.864,4.864,0,0,1-4.862,4.862"
                          transform="translate(-416.145 -309.834)"
                          fill="none"
                          stroke="#fff"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="3"
                        />
                        <path
                          id="Контур_38470"
                          data-name="Контур 38470"
                          d="M540.672,368a4.864,4.864,0,0,0-4.862-4.862"
                          transform="translate(-416.145 -295.076)"
                          fill="none"
                          stroke="#fff"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="3"
                        />
                        <path
                          id="Контур_38471"
                          data-name="Контур 38471"
                          d="M491.861,426.9a2.431,2.431,0,1,1-2.431,2.431,2.43,2.43,0,0,1,2.431-2.431Z"
                          transform="translate(-381.258 -343.036)"
                          fill="none"
                          stroke="#fff"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="3"
                        />
                        <path
                          id="Контур_38472"
                          data-name="Контур 38472"
                          d="M282.546,217.331l-13.267-22.981a4.859,4.859,0,0,0-6.639-1.779L219.68,217.331"
                          transform="translate(-178.061 -174.79)"
                          fill="none"
                          stroke="#fff"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeDashoffset="851.26"
                          strokeWidth="3"
                          strokeDasharray="0 0 0 0 716.76 134.76 0 40"
                        />
                        <line
                          id="Линия_535"
                          data-name="Линия 535"
                          x2="46.186"
                          transform="translate(53.479 104.526)"
                          fill="none"
                          stroke="#fff"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="3"
                          strokeDasharray="0 0 0 0 0 40 628.76 560"
                        />
                      </g>
                    </g>
                  </svg>
                </div>

                <h2 className="main-my-bank__title">הקופה שלי</h2>
                <h3 className="main-my-bank__subtitle">קופת המתנות שלך ריקה</h3>
                <div className="main-my-bank__sad-emogi">
                  <img src="img/svg/sad-emoji.svg" alt="" />
                </div>
                <div className="main-my-bank__price">
                  <p className="main-my-bank__price-text">עד עכשיו צברת</p>
                  <div className="main-my-bank__price-value">
                    <span className="text">{myBank} </span>
                    <span className="money-icon"> ₪</span>
                  </div>
                </div>
                <div className="main-my-bank__btn-container mobile-bank">
                  {/* <a href="#" className="btn-white">
                    רוצה לבחור מתנה
                  </a> */}
                  <NavLink to={`/my-bank-all-categories/?wishlistId=${13}`} className="btn main-cat mobile">To main categories</NavLink>
                </div>

                <div className="main-my-bank__bottom-nav-buttons">
                  <button onClick={() => setActiveHeroSection(2)}>2</button>
                  <button onClick={() => setActiveHeroSection(1)}>1</button>
                </div>
                {activeTab === 0 && (
                  <div className="main-products__list-wrapper my-bank-mobile">
                    <div className="main-products__filter-btn-container filter-btn-container mobile">
                      <button type="button" className="filter-btn" onClick={filterToggle}>
                        <img src="img/svg/filter-icon.png" alt="filter icon" />
                      </button>
                    </div>
                    <ul className="main-products__list products-list column">
                      {visibleProducts &&
                        visibleProducts.map((product) => (
                          <MyBankProduct
                            key={product.id}
                            data={product}
                            updatePopupState={updatePopupState}
                            addItemHandler={addItemHandler}
                            openImagePopup={openImagePopup}
                            currWishlist={currWishlist}
                            setCurrWishlist={setCurrWishlist}
                            currUserObject={currUserObject}
                            goToChoosedPresentForm={goToChoosedPresentForm}
                          />
                        ))}

                    </ul>
                  </div>
                )}
                {activeTab === 1 && (
                  <div className="main-gift-basket__container desktop">

                    <div className="main-gift-basket__header">
                      <p className="main-gift-basket__header-product-name">שם המוצר</p>
                      <p className="main-gift-basket__header-product-price">מחיר</p>
                      <p className="main-gift-basket__header-product-status">Order status</p>
                      <div className="main-gift-basket__header-product-filter">
                        <div className="header-filter__drop">
                          <button
                            type="button"
                            onClick={handleSortDrop}
                            className="header-filter__button"
                          >
                            <p className="text">
                              {sortFilter.filter === "date"
                                ? "תאריך"
                                : sortFilter.filter === "price"
                                  ? "מחיר"
                                  : sortFilter.filter === "status"
                                    ? "סטָטוּס"
                                    : ""}
                            </p>
                          </button>
                          {sortFilter.isOpen && (
                            <div className="header-filter__body">
                              <ul className="header-filter__list">
                                <li className="header-filter__item">
                                  <button type="button" onClick={setFilterPrice}>
                                    מחיר
                                  </button>
                                </li>
                                <li className="header-filter__item">
                                  <button type="button" onClick={setFilterDate}>
                                    תאריך
                                  </button>
                                </li>
                                <li className="header-filter__item">
                                  <button type="button" onClick={setFilterStatus}>
                                    סטָטוּס
                                  </button>
                                </li>
                              </ul>
                            </div>
                          )}
                        </div>

                        <button
                          type="button"
                          onClick={() =>
                            setSortFilter({
                              isOpen: false,
                              filter: sortFilter.filter,
                              sortType: "desc",
                            })
                          }
                          className="arrow bottom"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="17.117"
                            height="9.115"
                            viewBox="0 0 17.117 9.115"
                          >
                            <g
                              id="Сгруппировать_912"
                              data-name="Сгруппировать 912"
                              transform="translate(19.135 -7.296) rotate(90)"
                            >
                              <g
                                id="Сгруппировать_540"
                                data-name="Сгруппировать 540"
                                transform="translate(14.998 17.723) rotate(180)"
                              >
                                <line
                                  id="Линия_434"
                                  data-name="Линия 434"
                                  y1="7.147"
                                  x2="6.289"
                                  transform="translate(0)"
                                  fill="none"
                                  stroke="#000"
                                  strokeLinecap="round"
                                  strokeWidth={2}
                                />
                                <path
                                  id="Контур_6897"
                                  data-name="Контур 6897"
                                  d="M0,0,6.289,6.861"
                                  transform="translate(0 7.432)"
                                  fill="none"
                                  stroke="#000"
                                  strokeLinecap="round"
                                  strokeWidth={2}
                                />
                              </g>
                            </g>
                          </svg>
                        </button>
                        <button
                          type="button"
                          onClick={() =>
                            setSortFilter({
                              isOpen: false,
                              filter: sortFilter.filter,
                              sortType: "asc",
                            })
                          }
                          className="arrow top"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="17.117"
                            height="9.115"
                            viewBox="0 0 17.117 9.115"
                          >
                            <g
                              id="Сгруппировать_913"
                              data-name="Сгруппировать 913"
                              transform="translate(19.708 -6.837) rotate(90)"
                            >
                              <g
                                id="Сгруппировать_539"
                                data-name="Сгруппировать 539"
                                transform="translate(8.25 4.002)"
                              >
                                <line
                                  id="Линия_434"
                                  data-name="Линия 434"
                                  y1="7.147"
                                  x2="6.289"
                                  transform="translate(0 0)"
                                  fill="none"
                                  stroke="#000"
                                  strokeLinecap="round"
                                  strokeWidth={2}
                                />
                                <path
                                  id="Контур_6897"
                                  data-name="Контур 6897"
                                  d="M0,0,6.289,6.861"
                                  transform="translate(0 7.432)"
                                  fill="none"
                                  stroke="#000"
                                  strokeLinecap="round"
                                  strokeWidth={2}
                                />
                              </g>
                            </g>
                          </svg>
                        </button>
                      </div>
                    </div>

                    <div className="main-gift-basket__purchased">
                      <div className="main-gift-basket__body">
                        <ul className="main-gift-basket__my-purchases-list">
                          {boughtForBankMoney &&
                            boughtForBankMoney.map((product, index) => (
                              <li
                                key={index}
                                className="main-gift-basket__my-purchases-item"
                              >
                                <MyBankSinglePurchase
                                  data={product}
                                  currUserObject={currUserObject}
                                  currWishlist={currWishlist}
                                  setCurrWishlist={setCurrWishlist}
                                />
                              </li>)
                            )}
                        </ul>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {activeTab === 1 && (
              <div className="main-gift-basket__container mobile">

                <div className="main-gift-basket__header">
                  <p className="main-gift-basket__header-product-name">שם המוצר</p>
                  <p className="main-gift-basket__header-product-price">מחיר</p>
                  <p className="main-gift-basket__header-product-status">Order status</p>
                  <div className="main-gift-basket__header-product-filter">
                    <div className="header-filter__drop">
                      <button
                        type="button"
                        onClick={handleSortDrop}
                        className="header-filter__button"
                      >
                        <p className="text">
                          {sortFilter.filter === "date"
                            ? "תאריך"
                            : sortFilter.filter === "price"
                              ? "מחיר"
                              : sortFilter.filter === "status"
                                ? "סטָטוּס"
                                : ""}
                        </p>
                      </button>
                      {sortFilter.isOpen && (
                        <div className="header-filter__body">
                          <ul className="header-filter__list">
                            <li className="header-filter__item">
                              <button type="button" onClick={setFilterPrice}>
                                מחיר
                              </button>
                            </li>
                            <li className="header-filter__item">
                              <button type="button" onClick={setFilterDate}>
                                תאריך
                              </button>
                            </li>
                            <li className="header-filter__item">
                              <button type="button" onClick={setFilterStatus}>
                                סטָטוּס
                              </button>
                            </li>
                          </ul>
                        </div>
                      )}
                    </div>

                    <button
                      type="button"
                      onClick={() =>
                        setSortFilter({
                          isOpen: false,
                          filter: sortFilter.filter,
                          sortType: "desc",
                        })
                      }
                      className="arrow bottom"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="17.117"
                        height="9.115"
                        viewBox="0 0 17.117 9.115"
                      >
                        <g
                          id="Сгруппировать_912"
                          data-name="Сгруппировать 912"
                          transform="translate(19.135 -7.296) rotate(90)"
                        >
                          <g
                            id="Сгруппировать_540"
                            data-name="Сгруппировать 540"
                            transform="translate(14.998 17.723) rotate(180)"
                          >
                            <line
                              id="Линия_434"
                              data-name="Линия 434"
                              y1="7.147"
                              x2="6.289"
                              transform="translate(0)"
                              fill="none"
                              stroke="#000"
                              strokeLinecap="round"
                              strokeWidth={2}
                            />
                            <path
                              id="Контур_6897"
                              data-name="Контур 6897"
                              d="M0,0,6.289,6.861"
                              transform="translate(0 7.432)"
                              fill="none"
                              stroke="#000"
                              strokeLinecap="round"
                              strokeWidth={2}
                            />
                          </g>
                        </g>
                      </svg>
                    </button>
                    <button
                      type="button"
                      onClick={() =>
                        setSortFilter({
                          isOpen: false,
                          filter: sortFilter.filter,
                          sortType: "asc",
                        })
                      }
                      className="arrow top"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="17.117"
                        height="9.115"
                        viewBox="0 0 17.117 9.115"
                      >
                        <g
                          id="Сгруппировать_913"
                          data-name="Сгруппировать 913"
                          transform="translate(19.708 -6.837) rotate(90)"
                        >
                          <g
                            id="Сгруппировать_539"
                            data-name="Сгруппировать 539"
                            transform="translate(8.25 4.002)"
                          >
                            <line
                              id="Линия_434"
                              data-name="Линия 434"
                              y1="7.147"
                              x2="6.289"
                              transform="translate(0 0)"
                              fill="none"
                              stroke="#000"
                              strokeLinecap="round"
                              strokeWidth={2}
                            />
                            <path
                              id="Контур_6897"
                              data-name="Контур 6897"
                              d="M0,0,6.289,6.861"
                              transform="translate(0 7.432)"
                              fill="none"
                              stroke="#000"
                              strokeLinecap="round"
                              strokeWidth={2}
                            />
                          </g>
                        </g>
                      </svg>
                    </button>
                  </div>
                </div>

                <div className="main-gift-basket__purchased">
                  <div className="main-gift-basket__body">
                    <ul className="main-gift-basket__my-purchases-list">
                      {boughtForBankMoney &&
                        boughtForBankMoney.map((product, index) => (
                          <>
                            {/* isStatusRelevantByCartType(product.status) ? ( */}
                            <li
                              key={index}
                              className="main-gift-basket__my-purchases-item"
                            >
                              <MyBankSinglePurchase
                                data={product}
                                currUserObject={currUserObject}
                                currWishlist={currWishlist}
                                setCurrWishlist={setCurrWishlist}
                              />
                            </li>
                            {/* ) : (
                            ""
                          ) */}
                          </>
                        )

                        )}
                    </ul>
                  </div>
                </div>
              </div>
            )}
          </section>

          {activeTab == 0 && (
            <>
              <section className="products__main main-products main-products_related">
                <div className="main-products__container">
                  <h2 className="main-products__title">מוצרים נילווים</h2>
                  <h3 className="main-products__subtitle">שיתאימו לך בול</h3>

                  <div className="main-products__slider-block">
                    <div className="main-products__slider swiper">
                      <div className="main-products__wrapper swiper-wrapper">
                        <Swiper
                          style={{
                            position: "relative",
                            marginTop: "30px",
                            padding: "0.52083vw",
                          }}
                          ref={mainProductsRelated}
                          slidesPerView={slidesPerView}
                          spaceBetween={40}
                          onSlideChange={(swiper) => {
                            setActiveIndexRecent(swiper?.realIndex);
                          }}
                        >
                          {visibleProducts &&
                            visibleProducts.map((product, index) => (
                              <SwiperSlide
                                key={product.id}
                                className={`main-single-product__thumbs-slide swiper-slide ${index === activeIndex ? "active" : ""
                                  }`}
                                onClick={() => handleThumbnailClick(index)}
                              // style={{padding:"0.75rem"}}
                              >
                                <MyBankProduct
                                  key={product.id}
                                  data={product}
                                  updatePopupState={updatePopupState}
                                  addItemHandler={addItemHandler}
                                  openImagePopup={openImagePopup}
                                  currWishlist={currWishlist}
                                  setCurrWishlist={setCurrWishlist}
                                  currUserObject={currUserObject}
                                  goToChoosedPresentForm={goToChoosedPresentForm}
                                />
                              </SwiperSlide>
                            ))}
                        </Swiper>
                      </div>



                    </div>

                    <div className="main-products__slider-nav">
                      <div
                        className="main-products__slider-prev"
                        style={{
                          display: `${relatedPrevActive ? "none" : "flex"}`,
                        }}
                        onClick={() =>
                          handleSlideStep(
                            mainProductsRelated.current.swiper,
                            "prev"
                          )
                        }
                      >
                        <img src={arrowLeftBlack} alt="" />
                      </div>
                      <div
                        className="main-products__slider-next"
                        style={{
                          display: `${mainProductsRelated.current?.swiper?.slides.length - 3 ==
                            mainProductsRelated.current?.swiper?.realIndex
                            ? "none"
                            : "flex"
                            }`,
                        }}
                        onClick={() =>
                          handleSlideStep(
                            mainProductsRelated.current.swiper,
                            "next"
                          )
                        }
                      >
                        <img src={arrowLeftBlack} alt="" />
                      </div>
                    </div>

                  </div>

                  <div className="main-products__btn-container btn-container_center">
                    <a href="#" className="btn-outline-white">
                      לא מצאתי רוצה לראות עוד מוצרים
                    </a>
                  </div>
                </div>
              </section>

              <section className="products__main main-products main-products_recent">
                <div className="main-products__container">
                  <h2 className="main-products__title">מוצרים אחרונים שחיפשתי</h2>
                  <h3 className="main-products__subtitle">שיתאימו לך בול</h3>

                  <div className="main-products__slider-block">
                    <div className="main-products__slider swiper">
                      <div className="main-products__wrapper swiper-wrapper">
                        <Swiper
                          style={{
                            position: "relative",
                            marginTop: "30px",
                            padding: "0.52083vw",
                          }}
                          ref={mainProductsRecent}
                          slidesPerView={slidesPerView}
                          spaceBetween={40}
                          onSlideChange={(swiper) => {
                            setActiveIndexRecent(swiper?.realIndex);
                          }}
                        >
                          {visibleProducts &&
                            visibleProducts.map((product, index) => (
                              <SwiperSlide
                                key={product.id}
                                className={`main-single-product__thumbs-slide swiper-slide ${index === activeIndex ? "active" : ""
                                  }`}
                                onClick={() => handleThumbnailClick(index)}
                              // style={{padding:"0.75rem"}}
                              >
                                <MyBankProduct
                                  key={product.id}
                                  data={product}
                                  updatePopupState={updatePopupState}
                                  addItemHandler={addItemHandler}
                                  openImagePopup={openImagePopup}
                                  currWishlist={currWishlist}
                                  setCurrWishlist={setCurrWishlist}
                                  currUserObject={currUserObject}
                                  goToChoosedPresentForm={goToChoosedPresentForm}
                                />
                              </SwiperSlide>
                            ))}

                        </Swiper>
                      </div>


                    </div>
                    <div className="main-products__slider-nav">
                      <div
                        className="main-products__slider-prev"
                        style={{ display: `${recentPrevActive ? "none" : "flex"}` }}
                        onClick={() =>
                          handleSlideStep(mainProductsRecent.current.swiper, "prev")
                        }
                      >
                        <img src={arrowLeftBlack} alt="" />
                      </div>
                      <div
                        className="main-products__slider-next"
                        style={{
                          display: `${mainProductsRecent.current?.swiper?.slides.length - 3 ==
                            mainProductsRecent.current?.swiper?.realIndex
                            ? "none"
                            : "flex"
                            }`,
                        }}
                        onClick={() =>
                          handleSlideStep(mainProductsRecent.current.swiper, "next")
                        }
                      >
                        <img src={arrowLeftBlack} alt="" />
                      </div>
                    </div>
                  </div>

                  <div className="main-products__btn-container btn-container_center">
                    <a href="#" className="btn-outline-white">
                      לא מצאתי רוצה לראות עוד מוצרים
                    </a>
                  </div>

                </div>
              </section>
            </>
          )}
        </main>
      )}

      {
        isChoosed && (
          <main
            className={`credentials-page ${isForm ? "credentials-page_form" : ""
              } ${isOrange ? "credentials-page_orange" : ""} ${sendChoosedPresentStep.blessingWrite || sendMoneyStep.blessingWrite
                ? "blessing-write-title"
                : sendChoosedPresentStep.shipping
                  ? "shipping-type"
                  : sendMoneyStep.howMuchForPresent
                    ? "how-much"
                    : sendChoosedPresentStep.moneyPresent ||
                      sendMoneyStep.moneyPresent
                      ? "money-present"
                      : sendChoosedPresentStep.payment || sendMoneyStep.payment
                        ? "payment-page"
                        : sendChoosedPresentStep.terms
                          ? "terms-form"
                          : ""
              }`}
            data-fullscreen
          >
            <section
              className={`credentials-section ${isGreenMobile ? "green-mobile" : ""
                }`}
            >
              <div className="credentials-section__bg-image-ibg">
                <img src={mainBgImage.main} className="img-people" alt="bg" />
                <img
                  src={mainBgImage.noPeople}
                  className="img-no-people"
                  alt="bg"
                />
              </div>
              <div className="credentials-section__content-window">
                {presentType === "present" && (
                  <MyBankCreateOrder
                    sendChoosedPresentStep={sendChoosedPresentStep}
                    setSendChoosedPresentStep={setSendChoosedPresentStep}
                    progress={progress}
                    setProgress={setProgress}
                    stepNumber={stepNumber}
                    setStepNumber={setStepNumber}
                    setMainBgImage={setMainBgImage}
                    setOrange={setOrange}
                    setGreenMobile={setGreenMobile}
                    setForm={setForm}
                    setChoosed={setChoosed}
                    resultObject={resultObject}
                    setResultObject={setResultObject}
                    choosedPresent={choosedPresent}
                    wishListInfo={wishListInfo}
                    isOrange={isOrange}
                    languageToggle={languageToggle}
                    language={language}
                    resetMyBankOrderFlow={resetMyBankOrderFlow}
                  />
                )}
                {presentType === "money" && (
                  <SendMoney
                    sendMoneyStep={sendMoneyStep}
                    setSendMoneyStep={setMoneyStep}
                    progress={progress}
                    setProgress={setProgress}
                    stepNumber={stepNumber}
                    setStepNumber={setStepNumber}
                    setMainBgImage={setMainBgImage}
                    setOrange={setOrange}
                    setGreenMobile={setGreenMobile}
                    setForm={setForm}
                    setChoosed={setChoosed}
                    resultObject={resultObject}
                    setResultObject={setResultObject}
                    choosedPresent={choosedPresent}
                    wishListInfo={wishListInfo}
                    isOrange={isOrange}
                  />
                )}

              </div>
            </section>
          </main>
        )
      }
      <ProductsFilter
        filterToggle={filterToggle}
        allCategoriesList={allCategoriesList}
        // choosedCategoriesList={choosedCategoriesList}
        // setChoosedCategoriesList={setChoosedCategoriesList}
        filterObject={filterObject}
        setFilterObject={setFilterObject}
        filterFunction={handleFilterChecks}
        manufacturers={manufacturers}
        rangePriceMinMax={rangePriceMinMax}
      />

      {notEnoughMoneyPopup ? (
        <NotEnoughMoneyPopup
          updatePopupState={updatePopupState}
          title="Not enough money"
          setPopup={setNotEnoughMoneyPopup}
        />
      ) : ""}
    </>
  );
}
