import React, { useEffect, useState } from "react";

import {
  stepsStart,
  steps1,
  steps2,
  steps3,
  steps4,
  stepsFinal,
  stepsStartNoPeople,
  steps1NoPeople,
  steps2NoPeople,
  steps3NoPeople,
  steps4NoPeople,
  stepsFinalNoPeople,
  logoImg2,
} from "../Assets/images";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import Swiper, { EffectFade, Navigation, Pagination } from "swiper";
import axios from "axios";
import { API_DOMAIN } from "../Libs/config";
function ExplainingSteps({ setUserNotFirstTime, userNotFirstTime }) {

  const navigate = useNavigate()
  const params = useParams();
  const { wishlistId } = params;
  const [wishlistUserName, setWishlistUserName] = useState('')

  const updateUserFirstTime = () => {
    setUserNotFirstTime(true);
    localStorage.setItem("userNotFirstTime", true);
    redirectionChoice()
  };

  const redirectionChoice = () => {
    if (userNotFirstTime) {
      navigate('/')
    } else {
      console.log('redirectionChoice', userNotFirstTime)
      navigate('/my-events', { state: { openCategories: true } })
      // navigate('/my-events')
    }
  }



  useEffect(() => {
    if (document.querySelector(".steps-modal__images-slider")) {
      const stepsImagesSlider = new Swiper(".steps-modal__images-slider", {
        modules: [Pagination, EffectFade],
        observer: true,
        observeParents: true,
        slidesPerView: 1,
        spaceBetween: 0,
        speed: 800,
        allowTouchMove: false,

        effect: "fade",
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        },

        pagination: {
          el: ".steps-modal__pagination",
          clickable: true,
        },

        navigation: {
          prevEl: "",
          nextEl: ".step-slider-next",
        },
        on: {
          slideChange: () => {
            if (stepsImagesSlider) {
              if (
                stepsImagesSlider.isEnd &&
                !document
                  .querySelector(
                    ".steps-modal__text-content .steps-modal__skip"
                  )
                  .classList.contains("disabled")
              ) {
                document
                  .querySelector(
                    ".steps-modal__text-content .steps-modal__skip"
                  )
                  .classList.add("disabled");
              } else if (
                !stepsImagesSlider.isEnd &&
                document
                  .querySelector(
                    ".steps-modal__text-content .steps-modal__skip"
                  )
                  .classList.contains("disabled")
              ) {
                document
                  .querySelector(
                    ".steps-modal__text-content .steps-modal__skip"
                  )
                  .classList.remove("disabled");
              }
            }
          },
        },
      });
    }
    if (document.querySelector(".steps-modal__text-slider")) {
      new Swiper(".steps-modal__text-slider", {
        modules: [Pagination],
        observer: true,
        observeParents: true,
        slidesPerView: 1,
        spaceBetween: 30,
        speed: 600,
        allowTouchMove: false,

        pagination: {
          el: ".steps-modal__pagination",
          clickable: true,
        },

        navigation: {
          prevEl: "",
          nextEl: ".step-slider-next",
        },
      });
    }
  }, []);
  return (
    <main className="steps" data-fullscreen>
      <section className="steps-modal">
        <div className="steps-modal__body">
          <div className="steps-modal__content">
            <div className="steps-modal__images-wrapper">
              <div className="steps-modal__images-slider swiper">
                <div className="steps-modal__images-slider-wrapper swiper-wrapper">
                  <div className="steps-modal__images-slider-slide swiper-slide">
                    <div className="steps-modal__image-ibg">
                      <img
                        src={stepsStart}
                        className="img-people"
                        alt="start"
                      />
                      <img
                        src={stepsStartNoPeople}
                        className="img-no-people"
                        alt="start"
                      />
                    </div>
                  </div>
                  <div className="steps-modal__images-slider-slide swiper-slide">
                    <div className="steps-modal__image-ibg">
                      <img src={steps1} className="img-people" alt={1} />
                      <img
                        src={steps1NoPeople}
                        className="img-no-people"
                        alt={1}
                      />
                    </div>
                  </div>
                  <div className="steps-modal__images-slider-slide swiper-slide">
                    <div className="steps-modal__image-ibg">
                      <img src={steps2} className="img-people" alt={2} />
                      <img
                        src={steps2NoPeople}
                        className="img-no-people"
                        alt={2}
                      />
                    </div>
                  </div>
                  <div className="steps-modal__images-slider-slide swiper-slide">
                    <div className="steps-modal__image-ibg">
                      <img src={steps3} className="img-people" alt={3} />
                      <img
                        src={steps3NoPeople}
                        className="img-no-people"
                        alt={3}
                      />
                    </div>
                  </div>
                  <div className="steps-modal__images-slider-slide swiper-slide">
                    <div className="steps-modal__image-ibg">
                      <img src={steps4} className="img-people" alt={4} />
                      <img
                        src={steps4NoPeople}
                        className="img-no-people"
                        alt={4}
                      />
                    </div>
                  </div>
                  <div className="steps-modal__images-slider-slide swiper-slide">
                    <div className="steps-modal__image-ibg">
                      <img
                        src={stepsFinal}
                        className="img-people"
                        alt="final"
                      />
                      <img
                        src={stepsFinalNoPeople}
                        className="img-no-people"
                        alt="final"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="steps-modal__text-content">
              <div className="steps-modal__text-slider swiper">
                <div className="steps-modal__text-slider-wrapper swiper-wrapper">
                  <div className="steps-modal__text-slider-slide swiper-slide">
                    <div className="steps-modal__start-step start-step-content">
                      <h1 className="start-step-content__title">
                      <span className="yellow">שלום, חגית עטיה הגעת</span><br/>
                      <span className="black">לרשימת המתנות המועדפות</span><br/>
                      <span className="yellow">של  באתר</span> </h1>
                      <div className="final-step-content__image" >
                        <img src={logoImg2} alt="wishlist logo" />
                      </div>
                      <div className="start-step-content__content">
                        {/* <p>
                          אנחנו ב-
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="82.548"
                            height="28.531"
                            viewBox="0 0 82.548 28.531"
                          >
                            <g
                              id="Сгруппировать_854"
                              data-name="Сгруппировать 854"
                              transform="translate(0 0)"
                            >
                              <path
                                id="Контур_6494"
                                data-name="Контур 6494"
                                d="M721.666,247.121l.629,1.092a2.282,2.282,0,0,1,0,2.283l-4.425,7.657a2.55,2.55,0,0,0,2.2-1.274l3.25-5.632a3.489,3.489,0,0,0,0-3.492l-9.18-15.894H718a4.8,4.8,0,0,1,4.146,2.4l4.457,7.719a1.2,1.2,0,0,0,2.073,0l4.552-7.885a2.534,2.534,0,0,0-2.2,1.274l-3.471,6.014-1.29-2.232,2.833-4.906a4.757,4.757,0,0,1,4.123-2.382H737.1l-6.485,11.232a3.425,3.425,0,0,1-5.934,0l-4.459-7.721a2.548,2.548,0,0,0-2.2-1.274l7.272,12.58a5.672,5.672,0,0,1,0,5.667l-3.273,5.667a4.761,4.761,0,0,1-4.126,2.382H714Z"
                                transform="translate(-714 -231.86)"
                                fill="#ed178e"
                              />
                              <path
                                id="Контур_6495"
                                data-name="Контур 6495"
                                d="M721.666,247.121l.629,1.092a2.282,2.282,0,0,1,0,2.283l-4.425,7.657a2.55,2.55,0,0,0,2.2-1.274l3.25-5.632a3.489,3.489,0,0,0,0-3.492l-9.18-15.894H718a4.8,4.8,0,0,1,4.146,2.4l4.457,7.719a1.2,1.2,0,0,0,2.073,0l4.552-7.885a2.534,2.534,0,0,0-2.2,1.274l-3.471,6.014-1.29-2.232,2.833-4.906a4.757,4.757,0,0,1,4.123-2.382H737.1l-6.485,11.232a3.425,3.425,0,0,1-5.934,0l-4.459-7.721a2.548,2.548,0,0,0-2.2-1.274l7.272,12.58a5.672,5.672,0,0,1,0,5.667l-3.273,5.667a4.761,4.761,0,0,1-4.126,2.382H714Z"
                                transform="translate(-683.753 -231.86)"
                                fill="#f8962f"
                              />
                              <path
                                id="Контур_6496"
                                data-name="Контур 6496"
                                d="M721.666,247.121l.629,1.092a2.282,2.282,0,0,1,0,2.283l-4.425,7.657a2.55,2.55,0,0,0,2.2-1.274l3.25-5.632a3.489,3.489,0,0,0,0-3.492l-9.18-15.894H718a4.8,4.8,0,0,1,4.146,2.4l4.457,7.719a1.2,1.2,0,0,0,2.073,0l4.552-7.885a2.534,2.534,0,0,0-2.2,1.274l-3.471,6.014-1.29-2.232,2.833-4.906a4.757,4.757,0,0,1,4.123-2.382H737.1l-6.485,11.232a3.425,3.425,0,0,1-5.934,0l-4.459-7.721a2.548,2.548,0,0,0-2.2-1.274l7.272,12.58a5.672,5.672,0,0,1,0,5.667l-3.273,5.667a4.761,4.761,0,0,1-4.126,2.382H714Z"
                                transform="translate(-654.549 -231.86)"
                                fill="#60b046"
                              />
                            </g>
                          </svg>{" "}
                          דואגים שתקבלו וגם תתנו את המתנה המתאימה והמדויקת ביותר
                          <br />
                          בזמן קצר וללא כאב ראש
                        </p> */}
                      </div>
                      <h2 className="start-step-content__subtitle">
                        ?רוצים לדעת איך
                      </h2>
                      <div className="start-step-content__btn-container btn-container">
                        <button type="button" className="btn step-slider-next">
                          בואו נתחיל
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="steps-modal__text-slider-slide swiper-slide">
                    <div className="steps-modal__step-content step-content">
                      <div className="step-content__body">
                        <div className="step-content__number-text">
                          <p className="number">01</p>
                          <p className="text">שלב</p>
                        </div>
                        <h2 className="step-content__title">
                          <span className="yellow">
                            בוחרים מתנה
                          </span>
                          <span className="black">
                            <br />מהרשימה
                          </span>

                        </h2>
                      </div>
                      <div className="step-content__btn-container btn-container">
                        <button type="button" className="btn step-slider-next">
                          המשך
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="steps-modal__text-slider-slide swiper-slide">
                    <div className="steps-modal__step-content step-content">
                      <div className="step-content__body">
                        <div className="step-content__number-text">
                          <p className="number">02</p>
                          <p className="text">שלב</p>
                        </div>
                        <h2 className="step-content__title">
                          <span className="yellow">נרשמים לאתר</span>
                          <br />
                          <span className="yellow">ומשלמים</span>

                        </h2>
                      </div>
                      <div className="step-content__btn-container btn-container">
                        <button type="button" className="btn step-slider-next">
                          המשך
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="steps-modal__text-slider-slide swiper-slide">
                    <div className="steps-modal__step-content step-content">
                      <div className="step-content__body">
                        <div className="step-content__number-text">
                          <p className="number">03</p>
                          <p className="text">שלב</p>
                        </div>
                        <h2 className="step-content__title">
                          <span className="yellow">
                            עם כרטיס ברכה
                          </span>
                          <br />
                          <span className="yellow">
                            שולחים לחבר
                          </span>
                        </h2>
                      </div>
                      <div className="step-content__btn-container btn-container">
                        <button type="button" className="btn step-slider-next">
                          המשך
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="steps-modal__text-slider-slide swiper-slide">
                    <div className="steps-modal__step-content step-content">
                      <div className="step-content__body">
                        <div className="step-content__number-text">
                          <p className="number">04</p>
                          <p className="text">שלב</p>
                        </div>
                        <h2 className="step-content__title">
                          <span className="yellow">
                            אם לא מצאת/ה<br />משהו מהרשימה<br />תמיד תוכל/י<br />להשאיר מני<br />מזומני בקופה
                          </span>
                        </h2>
                      </div>
                      <div className="step-content__btn-container btn-container">
                        <button type="button" className="btn step-slider-next">
                          המשך
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="steps-modal__text-slider-slide swiper-slide">
                    <div className="steps-modal__final-step-content final-step-content">
                      <h2 className="final-step-content__title">
                        ברוכים הבאים
                      </h2>
                      <h3 className="final-step-content__subtitle">לאתר</h3>
                      <div className="final-step-content__image">
                        <img src={logoImg2} alt="wishlist logo" />
                      </div>
                      <div className="final-step-content__btn-container btn-container">
                        <button
                          // to={`${userNotFirstTime ? '/' : '/my-events'}`}
                          // to={`/credentials`}
                          onClick={updateUserFirstTime}
                          className="btn"
                        >
                          הבנתי, אני רוצה להתחיל
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="steps-modal__pagination" />
              <div className="steps-modal__skip-container">
                <button
                  // to="/credentials"
                  // to={`${userNotFirstTime ? '/' : '/my-events' }`}

                  onClick={updateUserFirstTime}
                  className="steps-modal__skip"
                >
                  דלג/י
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default ExplainingSteps;
