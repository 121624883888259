import React, { useEffect, useState } from "react";
import {
  bell,
  eventTypeImg1,
  eventTypeImgNoPeople1,
  topbarBg,
  topbarBgGreen,
} from "../Assets/images";
import SingleReminder from "../Components/single-reminder";
import { NavLink, useNavigate } from "react-router-dom";

function Reminders() {
  const [activeTab, setActiveTab] = useState(0);

  const invitedRemindersList = [
    {
      img: eventTypeImg1,
      imgNoPeople: eventTypeImgNoPeople1,
      title: "תזכורת ליום הולדת ",
      higlightedTitleText: "לישראלה ישראלי",
      date: "ל 5/7/22 בשעה 17:00",
      place: "ברחוב הפרחים 3, כפר יונה",
    },
    {
      img: eventTypeImg1,
      imgNoPeople: eventTypeImgNoPeople1,
      title: "תזכורת ליום הולדת ",
      higlightedTitleText: "לישראלה ישראלי",
      date: "ל 5/7/22 בשעה 17:00",
      place: "ברחוב הפרחים 3, כפר יונה",
    },
    {
      img: eventTypeImg1,
      imgNoPeople: eventTypeImgNoPeople1,
      title: "תזכורת ליום הולדת ",
      higlightedTitleText: "לישראלה ישראלי",
      date: "ל 5/7/22 בשעה 17:00",
      place: "ברחוב הפרחים 3, כפר יונה",
    },
  ];
  const customersRemindersList = [
    {
      img: eventTypeImg1,
      imgNoPeople: eventTypeImgNoPeople1,
      title: "תזכורת ליום הולדת ",
      higlightedTitleText: "לישראלה ישראלי",
      date: "ל 5/7/22 בשעה 17:00",
      place: "ברחוב הפרחים 3, כפר יונה",
    },
    {
      img: eventTypeImg1,
      imgNoPeople: eventTypeImgNoPeople1,
      title: "תזכורת ליום הולדת ",
      higlightedTitleText: "לישראלה ישראלי",
      date: "ל 5/7/22 בשעה 17:00",
      place: "ברחוב הפרחים 3, כפר יונה",
    },
    {
      img: eventTypeImg1,
      imgNoPeople: eventTypeImgNoPeople1,
      title: "תזכורת ליום הולדת ",
      higlightedTitleText: "לישראלה ישראלי",
      date: "ל 5/7/22 בשעה 17:00",
      place: "ברחוב הפרחים 3, כפר יונה",
    },
    {
      img: eventTypeImg1,
      imgNoPeople: eventTypeImgNoPeople1,
      title: "תזכורת ליום הולדת ",
      higlightedTitleText: "לישראלה ישראלי",
      date: "ל 5/7/22 בשעה 17:00",
      place: "ברחוב הפרחים 3, כפר יונה",
    },
  ];

  const navigate = useNavigate();
  useEffect(() => {
    const currentUser = localStorage.getItem("currUserObject");
    if (!currentUser) {
      navigate("/credentials");
    }
  }, []);

  return (
    <main className={`reminders ${activeTab === 0 ? "reminders_pink" : ""}`}>
      <section className="reminders__topbar topbar">
        <div className="topbar__bg-img-ibg">
          <img
            src={
              activeTab === 0 ? topbarBg : activeTab === 1 ? topbarBgGreen : ""
            }
            alt="topbar"
          />
        </div>
        <div className="topbar__container">
          <div className="topbar__title-wrapper">
            <h1 className="topbar__title">
              התראות/תזכורות
              <span className="icon">
                <img src={bell} alt="party icon" />
              </span>
            </h1>
          </div>
        </div>
      </section>
      <div className="reminders__breadcrumbs breadcrumbs">
        <div className="breadcrumbs__container">
          <ul className="breadcrumbs__list">
            <li className="breadcrumbs__item">
              <NavLink to="/">דף הבית</NavLink>
            </li>
            <li className="breadcrumbs__item">
              <p>reminders</p>
            </li>
          </ul>
        </div>
      </div>
      <section className="reminders__main main-reminders">
        <div className="main-reminders__container">
          <div className="main-reminders__header">
            <div className="main-reminders__navigation">
              <ul className="main-reminders__nav-list">
                <li className="main-reminders__nav-item">
                  <button
                    type="button"
                    className={`main-reminders__button ${
                      activeTab === 0 ? "active" : ""
                    }`}
                    onClick={() => {
                      setActiveTab(0);
                    }}
                  >
                    <div className="icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="17.2"
                        height="14.064"
                        viewBox="0 0 17.2 14.064"
                      >
                        <g
                          id="Сгруппировать_1698"
                          data-name="Сгруппировать 1698"
                          transform="translate(3.241)"
                        >
                          <path
                            id="Контур_38613"
                            data-name="Контур 38613"
                            d="M303.744,277.839a1.523,1.523,0,0,1-1.4-1.6v-.065h-3.283a.585.585,0,0,1-.443-.2.711.711,0,0,1-.14-.5l.058-.793v-.006a.577.577,0,0,1,.119-.313l.019-.022c1.143-1.324,1.492-2.481,1.492-4.95a4.431,4.431,0,0,1,1.049-2.916,3.5,3.5,0,0,1,1.421-1.006l.046-.017-.007-.055a1.467,1.467,0,0,1-.013-.191,1.1,1.1,0,1,1,2.173,0,1.467,1.467,0,0,1-.013.191l-.007.055.046.017a3.5,3.5,0,0,1,1.421,1.006,4.431,4.431,0,0,1,1.049,2.916c0,2.469.349,3.625,1.492,4.95l.019.022a.578.578,0,0,1,.119.312s0,0,0,.006l.058.794a.711.711,0,0,1-.14.5.584.584,0,0,1-.443.2h-3.283v.065a1.523,1.523,0,0,1-1.4,1.6Z"
                            transform="translate(-298.385 -263.862)"
                            fill="none"
                          />
                          <path
                            id="Контур_38614"
                            data-name="Контур 38614"
                            d="M404.311,371.539a.274.274,0,0,0,.051,0,.285.285,0,0,0,.28-.234,15.562,15.562,0,0,0,.2-2.753,3.054,3.054,0,0,1,.9-2.174,3.1,3.1,0,0,1,.591-.463.284.284,0,0,0-.293-.487,3.673,3.673,0,0,0-.7.548,3.619,3.619,0,0,0-1.067,2.576,15.249,15.249,0,0,1-.19,2.652.284.284,0,0,0,.23.33Z"
                            transform="translate(-401.496 -362.763)"
                            fill="#fff"
                          />
                          <path
                            id="Контур_38615"
                            data-name="Контур 38615"
                            d="M384.214,549.543a.281.281,0,0,0,.145.04.284.284,0,0,0,.245-.139c.1-.163.187-.331.267-.5a.284.284,0,1,0-.512-.246c-.073.152-.154.3-.243.453a.285.285,0,0,0,.1.39Z"
                            transform="translate(-381.829 -540.045)"
                            fill="#fff"
                          />
                          <path
                            id="Контур_38616"
                            data-name="Контур 38616"
                            d="M306.986,273.368a.044.044,0,0,0,0-.006l-.058-.792s0-.006,0-.009a.648.648,0,0,0-.136-.352l-.018-.021c-1.137-1.314-1.484-2.463-1.484-4.919a4.509,4.509,0,0,0-1.072-2.972,3.6,3.6,0,0,0-1.452-1.025,1.55,1.55,0,0,0,.013-.2,1.161,1.161,0,1,0-2.3,0,1.508,1.508,0,0,0,.013.2,3.6,3.6,0,0,0-1.452,1.025,4.509,4.509,0,0,0-1.072,2.972c0,2.455-.347,3.6-1.484,4.918l-.018.021a.648.648,0,0,0-.136.352v.009l-.058.792s0,0,0,.006a.781.781,0,0,0,.157.553.644.644,0,0,0,.487.224h3.245a1.481,1.481,0,1,0,2.937,0h3.245a.643.643,0,0,0,.487-.224.782.782,0,0,0,.157-.553Zm-6.134-10.3a.784.784,0,1,1,1.552,0c0,.03,0,.06,0,.089a3.234,3.234,0,0,0-.772-.093h0a3.232,3.232,0,0,0-.772.093C300.854,263.13,300.852,263.1,300.852,263.071Zm-2.511,4.2a4.049,4.049,0,0,1,.963-2.669,3.068,3.068,0,0,1,2.324-1.105h0a3.068,3.068,0,0,1,2.324,1.105,4.048,4.048,0,0,1,.963,2.669c0,2.435.35,3.7,1.423,5.02h-9.42c1.073-1.317,1.423-2.584,1.423-5.02Zm3.287,8.116a1.184,1.184,0,0,1-1.095-1.241h2.189a1.183,1.183,0,0,1-1.095,1.241Zm4.929-1.759a.291.291,0,0,1-.216.089h-9.427a.29.29,0,0,1-.216-.089.336.336,0,0,1-.055-.232l.049-.676h9.871l.049.676a.338.338,0,0,1-.055.232Z"
                            transform="translate(-296.27 -261.75)"
                            fill="#fff"
                          />
                        </g>
                        <path
                          id="Контур_38617"
                          data-name="Контур 38617"
                          d="M692.6,454.12a.281.281,0,0,1-.139-.036.284.284,0,0,1-.109-.387,2.681,2.681,0,0,0,0-2.673.284.284,0,1,1,.5-.278,3.249,3.249,0,0,1,0,3.229.284.284,0,0,1-.248.145Z"
                          transform="translate(-678.818 -445.328)"
                          fill="#fff"
                        />
                        <path
                          id="Контур_38618"
                          data-name="Контур 38618"
                          d="M717.57,435.863a.28.28,0,0,1-.162-.051.284.284,0,0,1-.071-.4,3.587,3.587,0,0,0,0-4.214.284.284,0,1,1,.466-.325,4.155,4.155,0,0,1,0,4.864.284.284,0,0,1-.234.122Z"
                          transform="translate(-702.785 -426.277)"
                          fill="#fff"
                        />
                        <path
                          id="Контур_38619"
                          data-name="Контур 38619"
                          d="M743.038,417.221a.284.284,0,0,1-.223-.46,4.536,4.536,0,0,0,0-5.807.284.284,0,0,1,.446-.352,5.1,5.1,0,0,1,0,6.511.283.283,0,0,1-.223.108Z"
                          transform="translate(-727.234 -406.825)"
                          fill="#fff"
                        />
                        <path
                          id="Контур_38620"
                          data-name="Контур 38620"
                          d="M284.308,454.12a.281.281,0,0,0,.139-.036.284.284,0,0,0,.109-.387,2.681,2.681,0,0,1,0-2.673.284.284,0,1,0-.5-.278,3.249,3.249,0,0,0,0,3.229.285.285,0,0,0,.248.145Z"
                          transform="translate(-280.895 -445.328)"
                          fill="#fff"
                        />
                        <path
                          id="Контур_38621"
                          data-name="Контур 38621"
                          d="M251.15,435.863a.28.28,0,0,0,.162-.051.284.284,0,0,0,.071-.4,3.587,3.587,0,0,1,0-4.214.284.284,0,0,0-.466-.325,4.156,4.156,0,0,0,0,4.864.284.284,0,0,0,.234.122Z"
                          transform="translate(-248.735 -426.277)"
                          fill="#fff"
                        />
                        <path
                          id="Контур_38622"
                          data-name="Контур 38622"
                          d="M216.646,417.221a.284.284,0,0,0,.223-.46,4.536,4.536,0,0,1,0-5.807.284.284,0,1,0-.446-.352,5.1,5.1,0,0,0,0,6.511.283.283,0,0,0,.223.108Z"
                          transform="translate(-215.25 -406.825)"
                          fill="#fff"
                        />
                      </svg>
                    </div>
                    התרעות למוזמנים
                  </button>
                </li>
                <li className="main-reminders__nav-item">
                  <button
                    type="button"
                    className={`main-reminders__button ${
                      activeTab === 1 ? "active" : ""
                    }`}
                    onClick={() => {
                      setActiveTab(1);
                    }}
                  >
                    <div className="icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="17.2"
                        height="14.064"
                        viewBox="0 0 17.2 14.064"
                      >
                        <g
                          id="Сгруппировать_1698"
                          data-name="Сгруппировать 1698"
                          transform="translate(3.241)"
                        >
                          <path
                            id="Контур_38613"
                            data-name="Контур 38613"
                            d="M303.744,277.839a1.523,1.523,0,0,1-1.4-1.6v-.065h-3.283a.585.585,0,0,1-.443-.2.711.711,0,0,1-.14-.5l.058-.793v-.006a.577.577,0,0,1,.119-.313l.019-.022c1.143-1.324,1.492-2.481,1.492-4.95a4.431,4.431,0,0,1,1.049-2.916,3.5,3.5,0,0,1,1.421-1.006l.046-.017-.007-.055a1.467,1.467,0,0,1-.013-.191,1.1,1.1,0,1,1,2.173,0,1.467,1.467,0,0,1-.013.191l-.007.055.046.017a3.5,3.5,0,0,1,1.421,1.006,4.431,4.431,0,0,1,1.049,2.916c0,2.469.349,3.625,1.492,4.95l.019.022a.578.578,0,0,1,.119.312s0,0,0,.006l.058.794a.711.711,0,0,1-.14.5.584.584,0,0,1-.443.2h-3.283v.065a1.523,1.523,0,0,1-1.4,1.6Z"
                            transform="translate(-298.385 -263.862)"
                            fill="none"
                          />
                          <path
                            id="Контур_38614"
                            data-name="Контур 38614"
                            d="M404.311,371.539a.274.274,0,0,0,.051,0,.285.285,0,0,0,.28-.234,15.562,15.562,0,0,0,.2-2.753,3.054,3.054,0,0,1,.9-2.174,3.1,3.1,0,0,1,.591-.463.284.284,0,0,0-.293-.487,3.673,3.673,0,0,0-.7.548,3.619,3.619,0,0,0-1.067,2.576,15.249,15.249,0,0,1-.19,2.652.284.284,0,0,0,.23.33Z"
                            transform="translate(-401.496 -362.763)"
                            fill="#fff"
                          />
                          <path
                            id="Контур_38615"
                            data-name="Контур 38615"
                            d="M384.214,549.543a.281.281,0,0,0,.145.04.284.284,0,0,0,.245-.139c.1-.163.187-.331.267-.5a.284.284,0,1,0-.512-.246c-.073.152-.154.3-.243.453a.285.285,0,0,0,.1.39Z"
                            transform="translate(-381.829 -540.045)"
                            fill="#fff"
                          />
                          <path
                            id="Контур_38616"
                            data-name="Контур 38616"
                            d="M306.986,273.368a.044.044,0,0,0,0-.006l-.058-.792s0-.006,0-.009a.648.648,0,0,0-.136-.352l-.018-.021c-1.137-1.314-1.484-2.463-1.484-4.919a4.509,4.509,0,0,0-1.072-2.972,3.6,3.6,0,0,0-1.452-1.025,1.55,1.55,0,0,0,.013-.2,1.161,1.161,0,1,0-2.3,0,1.508,1.508,0,0,0,.013.2,3.6,3.6,0,0,0-1.452,1.025,4.509,4.509,0,0,0-1.072,2.972c0,2.455-.347,3.6-1.484,4.918l-.018.021a.648.648,0,0,0-.136.352v.009l-.058.792s0,0,0,.006a.781.781,0,0,0,.157.553.644.644,0,0,0,.487.224h3.245a1.481,1.481,0,1,0,2.937,0h3.245a.643.643,0,0,0,.487-.224.782.782,0,0,0,.157-.553Zm-6.134-10.3a.784.784,0,1,1,1.552,0c0,.03,0,.06,0,.089a3.234,3.234,0,0,0-.772-.093h0a3.232,3.232,0,0,0-.772.093C300.854,263.13,300.852,263.1,300.852,263.071Zm-2.511,4.2a4.049,4.049,0,0,1,.963-2.669,3.068,3.068,0,0,1,2.324-1.105h0a3.068,3.068,0,0,1,2.324,1.105,4.048,4.048,0,0,1,.963,2.669c0,2.435.35,3.7,1.423,5.02h-9.42c1.073-1.317,1.423-2.584,1.423-5.02Zm3.287,8.116a1.184,1.184,0,0,1-1.095-1.241h2.189a1.183,1.183,0,0,1-1.095,1.241Zm4.929-1.759a.291.291,0,0,1-.216.089h-9.427a.29.29,0,0,1-.216-.089.336.336,0,0,1-.055-.232l.049-.676h9.871l.049.676a.338.338,0,0,1-.055.232Z"
                            transform="translate(-296.27 -261.75)"
                            fill="#fff"
                          />
                        </g>
                        <path
                          id="Контур_38617"
                          data-name="Контур 38617"
                          d="M692.6,454.12a.281.281,0,0,1-.139-.036.284.284,0,0,1-.109-.387,2.681,2.681,0,0,0,0-2.673.284.284,0,1,1,.5-.278,3.249,3.249,0,0,1,0,3.229.284.284,0,0,1-.248.145Z"
                          transform="translate(-678.818 -445.328)"
                          fill="#fff"
                        />
                        <path
                          id="Контур_38618"
                          data-name="Контур 38618"
                          d="M717.57,435.863a.28.28,0,0,1-.162-.051.284.284,0,0,1-.071-.4,3.587,3.587,0,0,0,0-4.214.284.284,0,1,1,.466-.325,4.155,4.155,0,0,1,0,4.864.284.284,0,0,1-.234.122Z"
                          transform="translate(-702.785 -426.277)"
                          fill="#fff"
                        />
                        <path
                          id="Контур_38619"
                          data-name="Контур 38619"
                          d="M743.038,417.221a.284.284,0,0,1-.223-.46,4.536,4.536,0,0,0,0-5.807.284.284,0,0,1,.446-.352,5.1,5.1,0,0,1,0,6.511.283.283,0,0,1-.223.108Z"
                          transform="translate(-727.234 -406.825)"
                          fill="#fff"
                        />
                        <path
                          id="Контур_38620"
                          data-name="Контур 38620"
                          d="M284.308,454.12a.281.281,0,0,0,.139-.036.284.284,0,0,0,.109-.387,2.681,2.681,0,0,1,0-2.673.284.284,0,1,0-.5-.278,3.249,3.249,0,0,0,0,3.229.285.285,0,0,0,.248.145Z"
                          transform="translate(-280.895 -445.328)"
                          fill="#fff"
                        />
                        <path
                          id="Контур_38621"
                          data-name="Контур 38621"
                          d="M251.15,435.863a.28.28,0,0,0,.162-.051.284.284,0,0,0,.071-.4,3.587,3.587,0,0,1,0-4.214.284.284,0,0,0-.466-.325,4.156,4.156,0,0,0,0,4.864.284.284,0,0,0,.234.122Z"
                          transform="translate(-248.735 -426.277)"
                          fill="#fff"
                        />
                        <path
                          id="Контур_38622"
                          data-name="Контур 38622"
                          d="M216.646,417.221a.284.284,0,0,0,.223-.46,4.536,4.536,0,0,1,0-5.807.284.284,0,1,0-.446-.352,5.1,5.1,0,0,0,0,6.511.283.283,0,0,0,.223.108Z"
                          transform="translate(-215.25 -406.825)"
                          fill="#fff"
                        />
                      </svg>
                    </div>
                    התרעות למזמינים
                  </button>
                </li>
              </ul>
            </div>
            <div className="main-reminders__filter">
              <p className="text">מיין</p>
              <button type="button" className="arrow bottom">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17.117"
                  height="9.115"
                  viewBox="0 0 17.117 9.115"
                >
                  <g
                    id="Сгруппировать_912"
                    data-name="Сгруппировать 912"
                    transform="translate(19.135 -7.296) rotate(90)"
                  >
                    <g
                      id="Сгруппировать_540"
                      data-name="Сгруппировать 540"
                      transform="translate(14.998 17.723) rotate(180)"
                    >
                      <line
                        id="Линия_434"
                        data-name="Линия 434"
                        y1="7.147"
                        x2="6.289"
                        transform="translate(0)"
                        fill="none"
                        stroke="#000"
                        strokeLinecap="round"
                        strokeWidth={2}
                      />
                      <path
                        id="Контур_6897"
                        data-name="Контур 6897"
                        d="M0,0,6.289,6.861"
                        transform="translate(0 7.432)"
                        fill="none"
                        stroke="#000"
                        strokeLinecap="round"
                        strokeWidth={2}
                      />
                    </g>
                  </g>
                </svg>
              </button>
              <button type="button" className="arrow top">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17.117"
                  height="9.115"
                  viewBox="0 0 17.117 9.115"
                >
                  <g
                    id="Сгруппировать_913"
                    data-name="Сгруппировать 913"
                    transform="translate(19.708 -6.837) rotate(90)"
                  >
                    <g
                      id="Сгруппировать_539"
                      data-name="Сгруппировать 539"
                      transform="translate(8.25 4.002)"
                    >
                      <line
                        id="Линия_434"
                        data-name="Линия 434"
                        y1="7.147"
                        x2="6.289"
                        transform="translate(0 0)"
                        fill="none"
                        stroke="#000"
                        strokeLinecap="round"
                        strokeWidth={2}
                      />
                      <path
                        id="Контур_6897"
                        data-name="Контур 6897"
                        d="M0,0,6.289,6.861"
                        transform="translate(0 7.432)"
                        fill="none"
                        stroke="#000"
                        strokeLinecap="round"
                        strokeWidth={2}
                      />
                    </g>
                  </g>
                </svg>
              </button>
            </div>
          </div>

          {activeTab === 0 && (
            <div className="main-reminders__body">
              <ul className="main-reminders__list">
                {invitedRemindersList.map((reminder, index) => (
                  <li key={index} className="main-reminders__item">
                    <SingleReminder
                      img={reminder.img}
                      imgNoPeople={reminder.imgNoPeople}
                      title={reminder.title}
                      higlightedTitleText={reminder.higlightedTitleText}
                      date={reminder.date}
                      place={reminder.place}
                    />
                  </li>
                ))}
              </ul>
            </div>
          )}
          {activeTab === 1 && (
            <div className="main-reminders__body">
              <ul className="main-reminders__list">
                {customersRemindersList.map((reminder, index) => (
                  <li key={index} className="main-reminders__item">
                    <SingleReminder
                      img={reminder.img}
                      imgNoPeople={reminder.imgNoPeople}
                      title={reminder.title}
                      higlightedTitleText={reminder.higlightedTitleText}
                      date={reminder.date}
                      place={reminder.place}
                    />
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </section>
    </main>
  );
}

export default Reminders;
