import React, { useEffect, useRef, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import {
  bellOutline,
  discoBall,
  homeBg,
  homeBgNoPeople,
  logoImg,
} from "../Assets/images";

function Home() {
  const navigate = useNavigate();
  useEffect(() => {
    const currentUser = localStorage.getItem("currUserObject");
    const params = new URLSearchParams(window.location.search);
    const inviteWishlistId = Boolean(params?.get("inviteWishlistId"));
    if (!currentUser && !inviteWishlistId) {
      navigate("/credentials");
    }
  }, []);

  const openMyEvents2Tab = (e) => {
    e.preventDefault()
    navigate('/my-events', { state: { openTabNumber: 1 }})
  }

  const openMyEvents4Tab = (e) => {
    e.preventDefault();
    navigate('/my-events', { state: { openTabNumber: 3 }})
  }

  return (
    <main className="home-page credentials-page" data-fullscreen>
      <section className="credentials-section">
        <div className="credentials-section__bg-image-ibg">
          <img src={homeBg} className="img-people" alt="bg" />
          <img src={homeBgNoPeople} className="img-no-people" alt="bg" />
        </div>
        <div className="credentials-section__content-window">
          <div className="credentials-section__content">
            <div className="home-page__logo">
              <img src={logoImg} alt="img" />
            </div>
            <ul className="home-page__links-list">
              <li className="home-page__links-item">
                <NavLink to="/cart" className="home-page__link">
                  <span className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="19.846"
                      height="24.798"
                      viewBox="0 0 19.846 24.798"
                    >
                      <g
                        id="Сгруппировать_74057"
                        data-name="Сгруппировать 74057"
                        transform="translate(0.5 0.5)"
                      >
                        <path
                          id="Artboard_2"
                          data-name="Artboard 2"
                          d="M84.786,18.61a7.137,7.137,0,0,1,1.2.467,2.838,2.838,0,0,1,1.406,2.495c.014.656,0,1.314,0,2H90a1.672,1.672,0,0,1,1.764,1.55q.562,4.176,1.116,8.353c.31,2.332.627,4.663.93,7a1.654,1.654,0,0,1-1.319,1.9.426.426,0,0,0-.082.038H76.418A1.88,1.88,0,0,1,75.31,41.7a1.6,1.6,0,0,1-.3-1.14q.439-3.333.886-6.671.4-3.047.81-6.1c.124-.93.243-1.859.372-2.784a1.661,1.661,0,0,1,1.612-1.432c.821-.015,1.641,0,2.463-.006.292-.1.292-.193.292-.282a20.1,20.1,0,0,1,.043-2.111,2.906,2.906,0,0,1,2.455-2.53.644.644,0,0,0,.105-.04Z"
                          transform="translate(-74.992 -18.61)"
                          fill="none"
                          stroke="#707070"
                          strokeWidth={1}
                        />
                        <path
                          id="heart"
                          d="M30.6,30.513l4.251,4.3,4.566-4.615a3.06,3.06,0,0,0,0-4.3,2.983,2.983,0,0,0-4.25,0l-.315.318L34.54,25.9a2.983,2.983,0,0,0-4.25,0,3.06,3.06,0,0,0,0,4.3l.313.316Z"
                          transform="translate(-25.26 -15.21)"
                          fill="#ec1b53"
                        />
                      </g>
                    </svg>
                  </span>
                  <span className="text">סל המתנות שלי</span>
                </NavLink>
              </li>
              <li className="home-page__links-item">
                <NavLink to="/reminders" className="home-page__link">
                  <span className="icon">
                    <img src={bellOutline} alt="" />
                  </span>
                  <span className="text">ההודעות שלי</span>
                </NavLink>
              </li>
              <li className="home-page__links-item">
                <NavLink to="/my-bank" className="home-page__link">
                  <span className="icon">
                    <img src={bellOutline} alt="" />
                  </span>
                  <span className="text">כמה כסף יש לי בקופה</span>
                </NavLink>
              </li>
              <li className="home-page__links-item">
                <NavLink
                  to="/my-events"
                  className="home-page__link">
                  <span className="icon">
                    <svg
                      id="Компонент_565_1"
                      data-name="Компонент 565 – 1"
                      xmlns="http://www.w3.org/2000/svg"
                      width="38.082"
                      height="38.945"
                      viewBox="0 0 38.082 38.945"
                    >
                      <g
                        id="Контур_10101"
                        data-name="Контур 10101"
                        transform="translate(0 0.863)"
                        fill="#fff"
                      >
                        <path
                          d="M 19.04082870483398 37.58165740966797 C 16.5376091003418 37.58165740966797 14.10953903198242 37.09150695800781 11.82405853271484 36.12482833862305 C 9.616289138793945 35.1910285949707 7.633398532867432 33.85409927368164 5.930478572845459 32.15118026733398 C 4.227558612823486 30.4482593536377 2.890628814697266 28.46536827087402 1.956828713417053 26.25759887695312 C 0.9901487231254578 23.97211837768555 0.4999987185001373 21.54404830932617 0.4999987185001373 19.04082870483398 C 0.4999987185001373 16.5376091003418 0.9901487231254578 14.10953903198242 1.956828713417053 11.82405853271484 C 2.890628814697266 9.616289138793945 4.227558612823486 7.633398532867432 5.930478572845459 5.930478572845459 C 7.633398532867432 4.227558612823486 9.616289138793945 2.890628814697266 11.82405853271484 1.956828713417053 C 14.10953903198242 0.9901487231254578 16.5376091003418 0.4999987185001373 19.04082870483398 0.4999987185001373 C 21.54404830932617 0.4999987185001373 23.97211837768555 0.9901487231254578 26.25759887695312 1.956828713417053 C 28.46536827087402 2.890628814697266 30.4482593536377 4.227558612823486 32.15118026733398 5.930478572845459 C 33.85409927368164 7.633398532867432 35.1910285949707 9.616289138793945 36.12482833862305 11.82405853271484 C 37.09150695800781 14.10953903198242 37.58165740966797 16.5376091003418 37.58165740966797 19.04082870483398 C 37.58165740966797 21.54404830932617 37.09150695800781 23.97211837768555 36.12482833862305 26.25759887695312 C 35.1910285949707 28.46536827087402 33.85409927368164 30.4482593536377 32.15118026733398 32.15118026733398 C 30.4482593536377 33.85409927368164 28.46536827087402 35.1910285949707 26.25759887695312 36.12482833862305 C 23.97211837768555 37.09150695800781 21.54404830932617 37.58165740966797 19.04082870483398 37.58165740966797 Z"
                          stroke="none"
                        />
                        <path
                          d="M 19.04082870483398 1 C 14.22194862365723 1 9.691497802734375 2.876567840576172 6.284038543701172 6.284038543701172 C 2.876567840576172 9.691497802734375 1 14.22194862365723 1 19.04082870483398 C 1 23.85970878601074 2.876567840576172 28.39015960693359 6.284038543701172 31.7976188659668 C 9.691497802734375 35.2050895690918 14.22194862365723 37.08165740966797 19.04082870483398 37.08165740966797 C 23.85970878601074 37.08165740966797 28.39015960693359 35.2050895690918 31.7976188659668 31.7976188659668 C 35.2050895690918 28.39015960693359 37.08165740966797 23.85970878601074 37.08165740966797 19.04082870483398 C 37.08165740966797 14.22194862365723 35.2050895690918 9.691497802734375 31.7976188659668 6.284038543701172 C 28.39015960693359 2.876567840576172 23.85970878601074 1 19.04082870483398 1 M 19.04082870483398 0 C 29.55678939819336 0 38.08165740966797 8.524868011474609 38.08165740966797 19.04082870483398 C 38.08165740966797 29.55678939819336 29.55678939819336 38.08165740966797 19.04082870483398 38.08165740966797 C 8.524868011474609 38.08165740966797 0 29.55678939819336 0 19.04082870483398 C 0 8.524868011474609 8.524868011474609 0 19.04082870483398 0 Z"
                          stroke="none"
                          fill="#707070"
                        />
                      </g>
                      <text
                        id="_"
                        data-name="+"
                        transform="translate(18.695 29)"
                        fill="#3c9e1c"
                        fontSize={28}
                        fontFamily="Assistant-Bold, Assistant"
                        fontWeight={700}
                      >
                        <tspan x="7.322" y={0}>
                          +
                        </tspan>
                      </text>
                    </svg>
                  </span>
                  <span className="text">פתיחת אירוע חדש</span>
                </NavLink>
              </li>
              <li className="home-page__links-item">
                <button to="/my-events"
                  onClick={openMyEvents2Tab}
                 className="home-page__link">
                  <span className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="44.82"
                      height="40.153"
                      viewBox="0 0 44.82 40.153"
                    >
                      <g
                        id="Artboard_3"
                        data-name="Artboard 3"
                        transform="translate(0 0.5)"
                      >
                        <g
                          id="Сгруппировать_74363"
                          data-name="Сгруппировать 74363"
                          transform="translate(6.231)"
                        >
                          <g
                            id="Сгруппировать_74361"
                            data-name="Сгруппировать 74361"
                          >
                            <path
                              id="Контур_38424"
                              data-name="Контур 38424"
                              d="M33.36,33.64C37.725,38,42.347,40.454,43.688,39.1"
                              transform="translate(-17.129 -10.75)"
                              fill="none"
                              stroke="#707070"
                              strokeMiterlimit={10}
                              strokeWidth={1}
                            />
                            <path
                              id="Контур_38425"
                              data-name="Контур 38425"
                              d="M32.4,31.948c-4.365-4.365-6.814-8.987-5.465-10.328"
                              transform="translate(-16.172 -9.058)"
                              fill="none"
                              stroke="#707070"
                              strokeMiterlimit={10}
                              strokeWidth={1}
                            />
                            <path
                              id="Контур_38426"
                              data-name="Контур 38426"
                              d="M14.633,45.712a1.822,1.822,0,0,0,.275,1.968,1.856,1.856,0,0,0,1.968.275s22.108-8.549,23.551-9.993l.61-.61c1.34-1.34-1.108-5.963-5.465-10.328s-8.987-6.814-10.328-5.465l-.61.61c-1.443,1.443-9.993,23.551-9.993,23.551Z"
                              transform="translate(-14.469 -8.997)"
                              fill="none"
                              stroke="#707070"
                              strokeMiterlimit={10}
                              strokeWidth={1}
                            />
                            <line
                              id="Линия_526"
                              data-name="Линия 526"
                              y2="8.592"
                              transform="translate(17.641 24.058)"
                              fill="none"
                              stroke="#707070"
                              strokeMiterlimit={10}
                              strokeWidth={1}
                            />
                            <line
                              id="Линия_527"
                              data-name="Линия 527"
                              y2="14.607"
                              transform="translate(13.344 19.762)"
                              fill="none"
                              stroke="#707070"
                              strokeMiterlimit={10}
                              strokeWidth={1}
                            />
                            <line
                              id="Линия_528"
                              data-name="Линия 528"
                              y2="20.948"
                              transform="translate(9.049 15.14)"
                              fill="none"
                              stroke="#707070"
                              strokeMiterlimit={10}
                              strokeWidth={1}
                            />
                            <line
                              id="Линия_529"
                              data-name="Линия 529"
                              y2="12.648"
                              transform="translate(4.752 25.158)"
                              fill="none"
                              stroke="#707070"
                              strokeMiterlimit={10}
                              strokeWidth={1}
                            />
                            <circle
                              id="Эллипс_414"
                              data-name="Эллипс 414"
                              cx="2.578"
                              cy="2.578"
                              r="2.578"
                              transform="translate(18.5)"
                              fill="none"
                              stroke="#707070"
                              strokeLinejoin="round"
                              strokeWidth={1}
                            />
                            <path
                              id="Контур_38427"
                              data-name="Контур 38427"
                              d="M39,34.011c3.334-4.133,8.592-7.767,12.888-5.155,3.626,2.2,2.217,5.619-.069,5.98s-3.583-1.65-3.368-2.543"
                              transform="translate(-17.924 -9.953)"
                              fill="none"
                              stroke="#707070"
                              strokeLinejoin="round"
                              strokeWidth={1}
                            />
                            <path
                              id="Контур_38428"
                              data-name="Контур 38428"
                              d="M56.178,15.2l-.868,3,1.985,2.475-3.351.232-1.675,2.44L50.98,20.565l-2.87-1.143L50.567,17.6l.1-3.351,2.543,1.907Z"
                              transform="translate(-19.205 -8.021)"
                              fill="none"
                              stroke="#707070"
                              strokeLinejoin="round"
                              strokeWidth={1}
                            />
                            <path
                              id="Контур_38429"
                              data-name="Контур 38429"
                              d="M30.674,24.161c2.268-4.322,3.695-11.041-.378-12.192-2.543-.713-4.3.644-4.3,2.578a2.143,2.143,0,0,0,3.437,1.718"
                              transform="translate(-16.094 -7.672)"
                              fill="none"
                              stroke="#707070"
                              strokeLinejoin="round"
                              strokeWidth={1}
                            />
                            <path
                              id="Контур_38430"
                              data-name="Контур 38430"
                              d="M44.16,7.2A8.715,8.715,0,0,1,40.8,19.057"
                              transform="translate(-18.176 -7.028)"
                              fill="none"
                              stroke="#707070"
                              strokeLinejoin="round"
                              strokeWidth={1}
                            />
                          </g>
                        </g>
                      </g>
                    </svg>
                  </span>
                  <span className="text">האירועים שלי</span>
                </button>
              </li>
              <li className="home-page__links-item">
                <button
                //  to="/my-events" 
                onClick={openMyEvents4Tab}
                 className="home-page__link">
                  <span className="icon">
                    <img src={discoBall} alt="" />
                  </span>
                  <span className="text">האירועים שהוזמנתי</span>
                </button>
              </li>
              <li className="home-page__links-item">
                <NavLink to="/edit-profile" className="home-page__link">
                  <span className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24.067"
                      height="24.067"
                      viewBox="0 0 24.067 24.067"
                    >
                      <g
                        id="Компонент_165_29"
                        data-name="Компонент 165 – 29"
                        transform="translate(0.5 0.5)"
                      >
                        <path
                          id="Объединение_74"
                          data-name="Объединение 74"
                          d="M0,23.067V20.184c0-3.172,5.19-5.767,11.533-5.767s11.533,2.6,11.533,5.767v2.883Zm5.766-17.3a5.767,5.767,0,1,1,5.767,5.766A5.767,5.767,0,0,1,5.766,5.767Z"
                          fill="none"
                          stroke="#707070"
                          strokeWidth={1}
                        />
                      </g>
                    </svg>
                  </span>
                  <span className="text">פרטים אישיים</span>
                </NavLink>
              </li>
              <li className="home-page__links-item">
                <NavLink to="/credentials" className="home-page__link">
                  <span className="icon">
                    <svg
                      width="64px"
                      height="64px"
                      viewBox="0 0 48 48"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                      <g
                        id="SVGRepo_tracerCarrier"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <g id="SVGRepo_iconCarrier">
                        <rect
                          width={48}
                          height={48}
                          fill="white"
                          fillOpacity="0.01"
                        />
                        <path
                          d="M23.9917 6L6 6L6 42H24"
                          stroke="#000000"
                          strokeWidth={4}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M25 33L16 24L25 15"
                          stroke="#000000"
                          strokeWidth={4}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M42 23.9917L16 23.9917"
                          stroke="#000000"
                          strokeWidth={4}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                    </svg>
                  </span>
                  <span className="text">כניסהוהרשמה</span>
                </NavLink>
              </li>
              <li className="home-page__links-item">
                <NavLink to="/contact-us" className="home-page__link">
                  <span className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={27}
                      height={26}
                      viewBox="0 0 27 26"
                    >
                      <g
                        id="Сгруппировать_1499"
                        data-name="Сгруппировать 1499"
                        transform="translate(1177 -2115)"
                      >
                        <g
                          id="Эллипс_411"
                          data-name="Эллипс 411"
                          transform="translate(-1177 2115)"
                          fill="none"
                          stroke="#707070"
                          strokeWidth="1.5"
                        >
                          <circle cx="4.5" cy="4.5" r="4.5" stroke="none" />
                          <circle cx="4.5" cy="4.5" r="3.75" fill="none" />
                        </g>
                        <g
                          id="Эллипс_412"
                          data-name="Эллипс 412"
                          transform="translate(-1159 2122)"
                          fill="none"
                          stroke="#707070"
                          strokeWidth="1.5"
                        >
                          <circle cx="4.5" cy="4.5" r="4.5" stroke="none" />
                          <circle cx="4.5" cy="4.5" r="3.75" fill="none" />
                        </g>
                        <g
                          id="Эллипс_413"
                          data-name="Эллипс 413"
                          transform="translate(-1175 2132)"
                          fill="none"
                          stroke="#707070"
                          strokeWidth="1.5"
                        >
                          <circle cx="4.5" cy="4.5" r="4.5" stroke="none" />
                          <circle cx="4.5" cy="4.5" r="3.75" fill="none" />
                        </g>
                        <line
                          id="Линия_524"
                          data-name="Линия 524"
                          x2={10}
                          y2={4}
                          transform="translate(-1168.5 2120.5)"
                          fill="none"
                          stroke="#707070"
                          strokeWidth="1.5"
                        />
                        <line
                          id="Линия_525"
                          data-name="Линия 525"
                          y1={6}
                          x2={9}
                          transform="translate(-1166.5 2128.5)"
                          fill="none"
                          stroke="#707070"
                          strokeWidth="1.5"
                        />
                      </g>
                    </svg>
                  </span>
                  <span className="text">צור קשר</span>
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Home;
