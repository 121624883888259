import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

function SideMenu() {

  const [activeLinkId, setActiveLinkId] = useState(null)

  const onMouseOverLink = (id) => {
    setActiveLinkId(id)
  }

  const onMouseLeaveLink = () => {
    setActiveLinkId(null)
  }

  return (
    <div className="side-menu">
      <div className="side-menu__body">
        <ul className="side-menu__links-list">
          <li className="side-menu__item">
            <NavLink to="choose-event-type"
              className={`side-menu__link  ${activeLinkId === 0 ? "active red" : ""}`}
              onMouseEnter={() => onMouseOverLink(0)}
              onMouseLeave={onMouseLeaveLink}
            >
              <svg
                id="Компонент_565_1"
                data-name="Компонент 565 – 1"
                xmlns="http://www.w3.org/2000/svg"
                width="38.082"
                height="38.945"
                viewBox="0 0 38.082 38.945"
              >
                <g
                  id="Контур_10101"
                  data-name="Контур 10101"
                  transform="translate(0 0.863)"
                  fill="#fff"
                >
                  <path
                    d="M 19.04082870483398 37.58165740966797 C 16.5376091003418 37.58165740966797 14.10953903198242 37.09150695800781 11.82405853271484 36.12482833862305 C 9.616289138793945 35.1910285949707 7.633398532867432 33.85409927368164 5.930478572845459 32.15118026733398 C 4.227558612823486 30.4482593536377 2.890628814697266 28.46536827087402 1.956828713417053 26.25759887695312 C 0.9901487231254578 23.97211837768555 0.4999987185001373 21.54404830932617 0.4999987185001373 19.04082870483398 C 0.4999987185001373 16.5376091003418 0.9901487231254578 14.10953903198242 1.956828713417053 11.82405853271484 C 2.890628814697266 9.616289138793945 4.227558612823486 7.633398532867432 5.930478572845459 5.930478572845459 C 7.633398532867432 4.227558612823486 9.616289138793945 2.890628814697266 11.82405853271484 1.956828713417053 C 14.10953903198242 0.9901487231254578 16.5376091003418 0.4999987185001373 19.04082870483398 0.4999987185001373 C 21.54404830932617 0.4999987185001373 23.97211837768555 0.9901487231254578 26.25759887695312 1.956828713417053 C 28.46536827087402 2.890628814697266 30.4482593536377 4.227558612823486 32.15118026733398 5.930478572845459 C 33.85409927368164 7.633398532867432 35.1910285949707 9.616289138793945 36.12482833862305 11.82405853271484 C 37.09150695800781 14.10953903198242 37.58165740966797 16.5376091003418 37.58165740966797 19.04082870483398 C 37.58165740966797 21.54404830932617 37.09150695800781 23.97211837768555 36.12482833862305 26.25759887695312 C 35.1910285949707 28.46536827087402 33.85409927368164 30.4482593536377 32.15118026733398 32.15118026733398 C 30.4482593536377 33.85409927368164 28.46536827087402 35.1910285949707 26.25759887695312 36.12482833862305 C 23.97211837768555 37.09150695800781 21.54404830932617 37.58165740966797 19.04082870483398 37.58165740966797 Z"
                    stroke="none"
                  />
                  <path
                    d="M 19.04082870483398 1 C 14.22194862365723 1 9.691497802734375 2.876567840576172 6.284038543701172 6.284038543701172 C 2.876567840576172 9.691497802734375 1 14.22194862365723 1 19.04082870483398 C 1 23.85970878601074 2.876567840576172 28.39015960693359 6.284038543701172 31.7976188659668 C 9.691497802734375 35.2050895690918 14.22194862365723 37.08165740966797 19.04082870483398 37.08165740966797 C 23.85970878601074 37.08165740966797 28.39015960693359 35.2050895690918 31.7976188659668 31.7976188659668 C 35.2050895690918 28.39015960693359 37.08165740966797 23.85970878601074 37.08165740966797 19.04082870483398 C 37.08165740966797 14.22194862365723 35.2050895690918 9.691497802734375 31.7976188659668 6.284038543701172 C 28.39015960693359 2.876567840576172 23.85970878601074 1 19.04082870483398 1 M 19.04082870483398 0 C 29.55678939819336 0 38.08165740966797 8.524868011474609 38.08165740966797 19.04082870483398 C 38.08165740966797 29.55678939819336 29.55678939819336 38.08165740966797 19.04082870483398 38.08165740966797 C 8.524868011474609 38.08165740966797 0 29.55678939819336 0 19.04082870483398 C 0 8.524868011474609 8.524868011474609 0 19.04082870483398 0 Z"
                    stroke="none"
                    fill="#707070"
                  />
                </g>
                <text
                  id="_"
                  data-name="+"
                  transform="translate(18.695 29)"
                  fill="#3c9e1c"
                  fontSize={28}
                  fontFamily="Assistant-Bold, Assistant"
                  fontWeight={700}
                >
                  <tspan x="7.322" y={0}>
                    +
                  </tspan>
                </text>
              </svg>
              <div className="side-menu__toaster">
              פתיחת אירוע חדש
              </div>
            </NavLink>
          </li>
          <li className="side-menu__item">
            <a href="#" data-count={2}
              className={`side-menu__link  ${activeLinkId === 1 ? "active" : ""}`}
              onMouseEnter={() => onMouseOverLink(1)}
              onMouseLeave={onMouseLeaveLink}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="44.82"
                height="40.153"
                viewBox="0 0 44.82 40.153"
              >
                <g
                  id="Artboard_3"
                  data-name="Artboard 3"
                  transform="translate(0 0.5)"
                >
                  <g
                    id="Сгруппировать_74363"
                    data-name="Сгруппировать 74363"
                    transform="translate(6.231)"
                  >
                    <g id="Сгруппировать_74361" data-name="Сгруппировать 74361">
                      <path
                        id="Контур_38424"
                        data-name="Контур 38424"
                        d="M33.36,33.64C37.725,38,42.347,40.454,43.688,39.1"
                        transform="translate(-17.129 -10.75)"
                        fill="none"
                        stroke="#707070"
                        strokeMiterlimit={10}
                        strokeWidth={1}
                      />
                      <path
                        id="Контур_38425"
                        data-name="Контур 38425"
                        d="M32.4,31.948c-4.365-4.365-6.814-8.987-5.465-10.328"
                        transform="translate(-16.172 -9.058)"
                        fill="none"
                        stroke="#707070"
                        strokeMiterlimit={10}
                        strokeWidth={1}
                      />
                      <path
                        id="Контур_38426"
                        data-name="Контур 38426"
                        d="M14.633,45.712a1.822,1.822,0,0,0,.275,1.968,1.856,1.856,0,0,0,1.968.275s22.108-8.549,23.551-9.993l.61-.61c1.34-1.34-1.108-5.963-5.465-10.328s-8.987-6.814-10.328-5.465l-.61.61c-1.443,1.443-9.993,23.551-9.993,23.551Z"
                        transform="translate(-14.469 -8.997)"
                        fill="none"
                        stroke="#707070"
                        strokeMiterlimit={10}
                        strokeWidth={1}
                      />
                      <line
                        id="Линия_526"
                        data-name="Линия 526"
                        y2="8.592"
                        transform="translate(17.641 24.058)"
                        fill="none"
                        stroke="#707070"
                        strokeMiterlimit={10}
                        strokeWidth={1}
                      />
                      <line
                        id="Линия_527"
                        data-name="Линия 527"
                        y2="14.607"
                        transform="translate(13.344 19.762)"
                        fill="none"
                        stroke="#707070"
                        strokeMiterlimit={10}
                        strokeWidth={1}
                      />
                      <line
                        id="Линия_528"
                        data-name="Линия 528"
                        y2="20.948"
                        transform="translate(9.049 15.14)"
                        fill="none"
                        stroke="#707070"
                        strokeMiterlimit={10}
                        strokeWidth={1}
                      />
                      <line
                        id="Линия_529"
                        data-name="Линия 529"
                        y2="12.648"
                        transform="translate(4.752 25.158)"
                        fill="none"
                        stroke="#707070"
                        strokeMiterlimit={10}
                        strokeWidth={1}
                      />
                      <circle
                        id="Эллипс_414"
                        data-name="Эллипс 414"
                        cx="2.578"
                        cy="2.578"
                        r="2.578"
                        transform="translate(18.5)"
                        fill="none"
                        stroke="#707070"
                        strokeLinejoin="round"
                        strokeWidth={1}
                      />
                      <path
                        id="Контур_38427"
                        data-name="Контур 38427"
                        d="M39,34.011c3.334-4.133,8.592-7.767,12.888-5.155,3.626,2.2,2.217,5.619-.069,5.98s-3.583-1.65-3.368-2.543"
                        transform="translate(-17.924 -9.953)"
                        fill="none"
                        stroke="#707070"
                        strokeLinejoin="round"
                        strokeWidth={1}
                      />
                      <path
                        id="Контур_38428"
                        data-name="Контур 38428"
                        d="M56.178,15.2l-.868,3,1.985,2.475-3.351.232-1.675,2.44L50.98,20.565l-2.87-1.143L50.567,17.6l.1-3.351,2.543,1.907Z"
                        transform="translate(-19.205 -8.021)"
                        fill="none"
                        stroke="#707070"
                        strokeLinejoin="round"
                        strokeWidth={1}
                      />
                      <path
                        id="Контур_38429"
                        data-name="Контур 38429"
                        d="M30.674,24.161c2.268-4.322,3.695-11.041-.378-12.192-2.543-.713-4.3.644-4.3,2.578a2.143,2.143,0,0,0,3.437,1.718"
                        transform="translate(-16.094 -7.672)"
                        fill="none"
                        stroke="#707070"
                        strokeLinejoin="round"
                        strokeWidth={1}
                      />
                      <path
                        id="Контур_38430"
                        data-name="Контур 38430"
                        d="M44.16,7.2A8.715,8.715,0,0,1,40.8,19.057"
                        transform="translate(-18.176 -7.028)"
                        fill="none"
                        stroke="#707070"
                        strokeLinejoin="round"
                        strokeWidth={1}
                      />
                    </g>
                  </g>
                </g>
              </svg>
              <div className="side-menu__toaster">
              האירועים שלי
              </div>
            </a>
          </li>
          <li className="side-menu__item">
            <a href="#"
              className={`side-menu__link  ${activeLinkId === 2 ? "active green" : ""}`}
              onMouseEnter={() => onMouseOverLink(2)}
              onMouseLeave={onMouseLeaveLink}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="43.485"
                height="43.268"
                viewBox="0 0 43.485 43.268"
              >
                <g
                  id="Artboard_3"
                  data-name="Artboard 3"
                  transform="translate(0.699 0.5)"
                >
                  <g id="New_Symbol_2" transform="translate(-44.16 -36.43)">
                    <g
                      id="Сгруппировать_74376"
                      data-name="Сгруппировать 74376"
                      transform="translate(44.16 36.43)"
                    >
                      <path
                        id="Контур_39505"
                        data-name="Контур 39505"
                        d="M238.65,72.914c0-2.767,1.327-4.6,2.2-4.6"
                        transform="translate(-214.206 -64.303)"
                        fill="none"
                        stroke="#3c9e1c"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={1}
                      />
                      <path
                        id="Контур_39506"
                        data-name="Контур 39506"
                        d="M132.134,99.84l1.077,2.182,2.407.349-1.742,1.7.411,2.4-2.153-1.131-2.153,1.131.411-2.4-1.742-1.7,2.407-.349Z"
                        transform="translate(-118.031 -91.871)"
                        fill="none"
                        stroke="#ffaf00"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={1}
                      />
                      <path
                        id="Контур_39507"
                        data-name="Контур 39507"
                        d="M157,236.993l-.256-1.5,1.741-1.7-2.407-.349L155,231.27l-1.077,2.182-2.407.349.814.794"
                        transform="translate(-138.027 -206.782)"
                        fill="none"
                        stroke="#ffaf00"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={1}
                      />
                      <g
                        id="Сгруппировать_74372"
                        data-name="Сгруппировать 74372"
                        transform="translate(14.103 0)"
                      >
                        <line
                          id="Линия_588"
                          data-name="Линия 588"
                          y2="1.171"
                          transform="translate(2.164)"
                          fill="none"
                          stroke="#ffaf00"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={1}
                        />
                        <line
                          id="Линия_589"
                          data-name="Линия 589"
                          x1="1.173"
                          transform="translate(3.016 2.164)"
                          fill="none"
                          stroke="#ffaf00"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={1}
                        />
                        <line
                          id="Линия_590"
                          data-name="Линия 590"
                          y1="1.171"
                          transform="translate(2.025 3.016)"
                          fill="none"
                          stroke="#ffaf00"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={1}
                        />
                        <line
                          id="Линия_591"
                          data-name="Линия 591"
                          x2="1.171"
                          transform="translate(0 2.023)"
                          fill="none"
                          stroke="#ffaf00"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={1}
                        />
                      </g>
                      <g
                        id="Сгруппировать_74373"
                        data-name="Сгруппировать 74373"
                        transform="translate(1.772 9.774)"
                      >
                        <line
                          id="Линия_592"
                          data-name="Линия 592"
                          y2="1.171"
                          transform="translate(2.164)"
                          fill="none"
                          stroke="#707070"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={1}
                        />
                        <line
                          id="Линия_593"
                          data-name="Линия 593"
                          x1="1.171"
                          transform="translate(3.016 2.164)"
                          fill="none"
                          stroke="#707070"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={1}
                        />
                        <line
                          id="Линия_594"
                          data-name="Линия 594"
                          y1="1.173"
                          transform="translate(2.023 3.016)"
                          fill="none"
                          stroke="#707070"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={1}
                        />
                        <line
                          id="Линия_595"
                          data-name="Линия 595"
                          x2="1.171"
                          transform="translate(0 2.025)"
                          fill="none"
                          stroke="#707070"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={1}
                        />
                      </g>
                      <path
                        id="Контур_39508"
                        data-name="Контур 39508"
                        d="M319.912,137.721s.752-1.44,2.03-.989,1.469,3.2-3.135,4.119c-3.011-3.6-1.136-5.625.143-5.174S319.912,137.721,319.912,137.721Z"
                        transform="translate(-282.873 -123.148)"
                        fill="none"
                        stroke="#3c9e1c"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={1}
                      />
                      <line
                        id="Линия_596"
                        data-name="Линия 596"
                        x1="7.568"
                        transform="translate(0 20.457)"
                        fill="none"
                        stroke="#707070"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={1}
                        strokeDasharray="0 0 10.66 21.31"
                      />
                      <path
                        id="Контур_39509"
                        data-name="Контур 39509"
                        d="M90.445,229.19,70.494,239.448,50.21,229.14"
                        transform="translate(-49.45 -204.92)"
                        fill="none"
                        stroke="#3c9e1c"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={1}
                        strokeDasharray="0 2 0 2"
                      />
                      <path
                        id="Контур_39510"
                        data-name="Контур 39510"
                        d="M99.138,57.83A1.339,1.339,0,1,0,97.8,59.17,1.34,1.34,0,0,0,99.138,57.83Z"
                        transform="translate(-89.887 -53.969)"
                        fill="none"
                        stroke="#3c9e1c"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={1}
                      />
                      <path
                        id="Контур_39511"
                        data-name="Контур 39511"
                        d="M226.479,232.92a1.34,1.34,0,1,0-1.34,1.34A1.34,1.34,0,0,0,226.479,232.92Z"
                        transform="translate(-201.223 -207.053)"
                        fill="none"
                        stroke="#3c9e1c"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={1}
                      />
                      <g
                        id="Сгруппировать_74375"
                        data-name="Сгруппировать 74375"
                        transform="translate(0 4.215)"
                      >
                        <g
                          id="Сгруппировать_74374"
                          data-name="Сгруппировать 74374"
                          transform="translate(34.105)"
                        >
                          <line
                            id="Линия_597"
                            data-name="Линия 597"
                            y2="1.171"
                            transform="translate(2.164)"
                            fill="none"
                            stroke="#707070"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={1}
                          />
                          <line
                            id="Линия_598"
                            data-name="Линия 598"
                            x1="1.171"
                            transform="translate(3.018 2.164)"
                            fill="none"
                            stroke="#707070"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={1}
                          />
                          <line
                            id="Линия_599"
                            data-name="Линия 599"
                            y1="1.171"
                            transform="translate(2.025 3.016)"
                            fill="none"
                            stroke="#707070"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={1}
                          />
                          <line
                            id="Линия_600"
                            data-name="Линия 600"
                            x2="1.173"
                            transform="translate(0 2.023)"
                            fill="none"
                            stroke="#707070"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={1}
                          />
                        </g>
                        <path
                          id="Контур_39512"
                          data-name="Контур 39512"
                          d="M137.15,199.2h30.4"
                          transform="translate(-125.463 -182.958)"
                          fill="none"
                          stroke="#707070"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={1}
                          strokeDasharray="0 0 10.66 21.31"
                        />
                        <line
                          id="Линия_601"
                          data-name="Линия 601"
                          x1="7.733"
                          y1="3.975"
                          transform="translate(34.355 33.904)"
                          fill="none"
                          stroke="#707070"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={1}
                        />
                        <line
                          id="Линия_602"
                          data-name="Линия 602"
                          y1="3.975"
                          x2="7.732"
                          transform="translate(0 33.904)"
                          fill="none"
                          stroke="#707070"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={1}
                        />
                        <path
                          id="Контур_39513"
                          data-name="Контур 39513"
                          d="M86.248,199.2,65.2,210.019,44.16,199.2"
                          transform="translate(-44.16 -182.958)"
                          fill="none"
                          stroke="#707070"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={1}
                        />
                        <line
                          id="Линия_603"
                          data-name="Линия 603"
                          x1="1.242"
                          y1="0.907"
                          transform="translate(31.582 8.575)"
                          fill="none"
                          stroke="#707070"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={1}
                        />
                        <line
                          id="Линия_604"
                          data-name="Линия 604"
                          x1="2.771"
                          y1="2.022"
                          transform="translate(39.317 14.22)"
                          fill="none"
                          stroke="#707070"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={1}
                        />
                        <line
                          id="Линия_605"
                          data-name="Линия 605"
                          x1="10.507"
                          y2="7.667"
                          transform="translate(0 8.575)"
                          fill="none"
                          stroke="#707070"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={1}
                        />
                        <path
                          id="Контур_39514"
                          data-name="Контур 39514"
                          d="M173.59,86.356l2.7-2a3.519,3.519,0,0,1,3.806-.22"
                          transform="translate(-157.323 -81.96)"
                          fill="none"
                          stroke="#707070"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={1}
                        />
                        <path
                          id="Контур_39515"
                          data-name="Контур 39515"
                          d="M286.8,170.52a12.516,12.516,0,0,0-3.539,7.635"
                          transform="translate(-253.21 -157.883)"
                          fill="none"
                          stroke="#707070"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={1}
                        />
                        <path
                          id="Контур_39516"
                          data-name="Контур 39516"
                          d="M252.687,174.05c0,.948-1.067.553-1.067,1.5s1.147.948,1.147,1.9-1.147.949-1.147,1.9,1.147.949,1.147,1.9"
                          transform="translate(-225.546 -160.969)"
                          fill="none"
                          stroke="#707070"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={1}
                        />
                        <path
                          id="Контур_39517"
                          data-name="Контур 39517"
                          d="M184.09,141.44s3.127,1.274,2.874,8.78"
                          transform="translate(-166.503 -132.458)"
                          fill="none"
                          stroke="#707070"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={1}
                        />
                        <path
                          id="Контур_39518"
                          data-name="Контур 39518"
                          d="M145.5,211.3s.757.255.757,2.648"
                          transform="translate(-132.764 -193.538)"
                          fill="none"
                          stroke="#707070"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={1}
                        />
                        <path
                          id="Контур_39519"
                          data-name="Контур 39519"
                          d="M44.16,199.2v21.636H86.248V199.2"
                          transform="translate(-44.16 -182.958)"
                          fill="none"
                          stroke="#707070"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={1}
                        />
                      </g>
                      <path
                        id="Контур_39520"
                        data-name="Контур 39520"
                        d="M145.5,328.98c1.443,0,1.443,1.913,2.886,1.913s1.443-1.913,2.884-1.913,1.443,1.913,2.886,1.913,1.443-1.913,2.886-1.913,1.443,1.913,2.886,1.913,1.443-1.913,2.886-1.913"
                        transform="translate(-132.764 -292.212)"
                        fill="none"
                        stroke="#ffaf00"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={1}
                      />
                      <path
                        id="Контур_39521"
                        data-name="Контур 39521"
                        d="M101.415,172.482s-.185-1.59,1.107-1.929,2.97,1.8-.274,5.09c-4.447-1.268-4.039-3.954-2.747-4.295S101.415,172.482,101.415,172.482Z"
                        transform="translate(-91.782 -153.665)"
                        fill="none"
                        stroke="#3c9e1c"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={1}
                      />
                      <path
                        id="Контур_39522"
                        data-name="Контур 39522"
                        d="M245.882,156.71l1.482,2.179H244.4Z"
                        transform="translate(-219.234 -141.593)"
                        fill="none"
                        stroke="#ffaf00"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={1}
                      />
                      <path
                        id="Контур_39523"
                        data-name="Контур 39523"
                        d="M231.657,59.607c0-3.6-1.844-6.517-4.119-6.517s-4.119,2.917-4.119,6.517,1.844,6.517,4.119,6.517S231.657,63.2,231.657,59.607Z"
                        transform="translate(-200.89 -50.996)"
                        fill="none"
                        stroke="#ffaf00"
                        strokeMiterlimit={10}
                        strokeWidth={1}
                      />
                    </g>
                  </g>
                </g>
              </svg>
              <div className="side-menu__toaster">
              האירועים שהוזמנתי
              </div>
            </a>
          </li>
          <li className="side-menu__item">
            <NavLink to="/cart-purchased"
              className={`side-menu__link  ${activeLinkId === 3 ? "active orange" : ""}`}
              onMouseEnter={() => onMouseOverLink(3)}
              onMouseLeave={onMouseLeaveLink}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="49.535"
                height="48.361"
                viewBox="0 0 49.535 48.361"
              >
                <g
                  id="Компонент_490_4"
                  data-name="Компонент 490 – 4"
                  transform="translate(0.543)"
                >
                  <g id="Artboard_1" data-name="Artboard 1">
                    <path
                      id="Контур_10114"
                      data-name="Контур 10114"
                      d="M16.611,65.664h4.351c-3.184-3.186-1.814-14.021,0-15.664H16.611A2.611,2.611,0,0,0,14,52.611V63.053A2.611,2.611,0,0,0,16.611,65.664Z"
                      transform="translate(26.733 -17.803)"
                      fill="#fff"
                      stroke="#707070"
                      strokeWidth={1}
                    />
                    <path
                      id="Контур_10117"
                      data-name="Контур 10117"
                      d="M46.2,62.015H61.6c.168-7.425,0-9.294,0-9.294L35.334,50.355,25.129,42.392A2.546,2.546,0,0,0,23.783,42a2.8,2.8,0,0,0-2.618,2.006,3.22,3.22,0,0,0,.843,3.372L33.153,57.55a6.182,6.182,0,0,0,2.181,1.305l8.016,2.7A8.9,8.9,0,0,0,46.2,62.015Z"
                      transform="translate(-21 -16.764)"
                      fill="#fff"
                      stroke="#707070"
                      strokeWidth={1}
                    />
                    <rect
                      id="Прямоугольник_2304"
                      data-name="Прямоугольник 2304"
                      width="0.735"
                      height="5.47"
                      transform="translate(13.885 5.47)"
                      fill="#707070"
                    />
                    <path
                      id="Контур_10116"
                      data-name="Контур 10116"
                      d="M49.437,52.208c-1.143-.993-8.042-.163-14.665-.368a69.458,69.458,0,0,1-10.291-.958,2.554,2.554,0,0,1-1.74-2.4,1.74,1.74,0,0,1,1.74-1.74H36.664c5.038,0,10.688,3.315,12.063,5.1Z"
                      transform="translate(-9.984 -16.154)"
                      fill="#fff"
                      stroke="#707070"
                      strokeWidth={1}
                    />
                    <rect
                      id="Прямоугольник_2305"
                      data-name="Прямоугольник 2305"
                      width="0.684"
                      height="5.47"
                      transform="translate(17.988)"
                      fill="#707070"
                    />
                    <rect
                      id="Прямоугольник_2306"
                      data-name="Прямоугольник 2306"
                      width="0.653"
                      height="5.47"
                      transform="translate(26.192 16.409)"
                      fill="#707070"
                    />
                    <rect
                      id="Прямоугольник_2307"
                      data-name="Прямоугольник 2307"
                      width="0.735"
                      height="2.735"
                      transform="translate(28.927 23.247)"
                      fill="#707070"
                    />
                    <rect
                      id="Прямоугольник_2308"
                      data-name="Прямоугольник 2308"
                      width="1.367"
                      height="1.367"
                      transform="translate(17.988 6.837)"
                      fill="#707070"
                    />
                    <g
                      id="Сгруппировать_74169"
                      data-name="Сгруппировать 74169"
                      transform="translate(-26.863 25.051) rotate(-34)"
                    >
                      <g
                        id="Эллипс_466"
                        data-name="Эллипс 466"
                        transform="translate(32.401 17.843)"
                        fill="#fff"
                        stroke="#707070"
                        strokeWidth={1}
                      >
                        <ellipse
                          cx={5}
                          cy="2.5"
                          rx={5}
                          ry="2.5"
                          stroke="none"
                        />
                        <ellipse cx={5} cy="2.5" rx="4.5" ry={2} fill="none" />
                      </g>
                      <g
                        id="Эллипс_467"
                        data-name="Эллипс 467"
                        transform="translate(32.401 13.843)"
                        fill="#fff"
                        stroke="#707070"
                        strokeWidth={1}
                      >
                        <ellipse
                          cx={5}
                          cy="2.5"
                          rx={5}
                          ry="2.5"
                          stroke="none"
                        />
                        <ellipse cx={5} cy="2.5" rx="4.5" ry={2} fill="none" />
                      </g>
                      <path
                        id="Контур_39382"
                        data-name="Контур 39382"
                        d="M32.791,16.343v3.2"
                        fill="none"
                        stroke="#707070"
                        strokeLinecap="round"
                        strokeWidth={1}
                      />
                      <path
                        id="Контур_39383"
                        data-name="Контур 39383"
                        d="M32.791,16.343v3.2"
                        transform="translate(9.126 1.134)"
                        fill="none"
                        stroke="#707070"
                        strokeLinecap="round"
                        strokeWidth={1}
                      />
                    </g>
                    <g
                      id="Сгруппировать_74170"
                      data-name="Сгруппировать 74170"
                      transform="matrix(0.454, 0.891, -0.891, 0.454, 27.209, -33.108)"
                    >
                      <g
                        id="Эллипс_466-2"
                        data-name="Эллипс 466"
                        transform="translate(32.401 17.843)"
                        fill="#fff"
                        stroke="#707070"
                        strokeWidth={1}
                      >
                        <ellipse
                          cx={5}
                          cy="2.5"
                          rx={5}
                          ry="2.5"
                          stroke="none"
                        />
                        <ellipse cx={5} cy="2.5" rx="4.5" ry={2} fill="none" />
                      </g>
                      <g
                        id="Эллипс_467-2"
                        data-name="Эллипс 467"
                        transform="translate(32.401 13.843)"
                        fill="#fff"
                        stroke="#707070"
                        strokeWidth={1}
                      >
                        <ellipse
                          cx={5}
                          cy="2.5"
                          rx={5}
                          ry="2.5"
                          stroke="none"
                        />
                        <ellipse cx={5} cy="2.5" rx="4.5" ry={2} fill="none" />
                      </g>
                      <path
                        id="Контур_39382-2"
                        data-name="Контур 39382"
                        d="M32.791,16.343v3.2"
                        fill="none"
                        stroke="#707070"
                        strokeLinecap="round"
                        strokeWidth={1}
                      />
                      <path
                        id="Контур_39383-2"
                        data-name="Контур 39383"
                        d="M32.791,16.343v3.2"
                        transform="translate(9.126 1.134)"
                        fill="none"
                        stroke="#707070"
                        strokeLinecap="round"
                        strokeWidth={1}
                      />
                    </g>
                    <path
                      id="Контур_39384"
                      data-name="Контур 39384"
                      d="M34.125,33.839l-8.3.872-1.121,2.864,15.382-.761s-1.609-1.169-3.053-2.1A5.467,5.467,0,0,0,34.125,33.839Z"
                      fill="#fff"
                    />
                  </g>
                  <circle
                    id="Эллипс_465"
                    data-name="Эллипс 465"
                    cx={2}
                    cy={2}
                    r={2}
                    transform="translate(30 2.408)"
                    fill="#3c9e1c"
                  />
                </g>
              </svg>
              <div className="side-menu__toaster">
              1000 ₪ 
              </div>
            </NavLink>

          </li>
          <li className="side-menu__item">
            <NavLink to="/events-history"
              className={`side-menu__link  ${activeLinkId === 4 ? "active orange behind" : ""}`}
              onMouseEnter={() => onMouseOverLink(4)}
              onMouseLeave={onMouseLeaveLink}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="38.684"
                height="37.078"
                viewBox="0 0 38.684 37.078"
              >
                <g
                  id="Компонент_493_12"
                  data-name="Компонент 493 – 12"
                  transform="translate(0.523 0.539)"
                >
                  <path
                    id="Контур_39385"
                    data-name="Контур 39385"
                    d="M38.729,34.345a1.8,1.8,0,0,0-.812-.88c-4.5-2.267-3.6-3.909-4.311-11.258-.646-6.508-2.541-11.14-8.039-12.137a.267.267,0,0,1-.194-.154A.241.241,0,0,1,25.4,9.68a2.821,2.821,0,0,0,.541-1.759,2.623,2.623,0,0,0-1.408-2.449,2.991,2.991,0,0,0-2.964.072A2.6,2.6,0,0,0,20.3,8.057a2.733,2.733,0,0,0,.541,1.622.241.241,0,0,1,.035.231.264.264,0,0,1-.18.16c-5.519,1-7.414,5.629-8.06,12.137-.708,7.349.187,8.991-4.311,11.258a1.773,1.773,0,0,0-.791.88,2.258,2.258,0,0,0,.178,2.232,2.579,2.579,0,0,0,2.071,1.11H36.459a2.581,2.581,0,0,0,2.086-1.1A2.258,2.258,0,0,0,38.729,34.345Z"
                    transform="translate(-7.317 -5.114)"
                    fill="#fff"
                    stroke="#707070"
                    strokeWidth={1}
                  />
                  <path
                    id="Контур_39386"
                    data-name="Контур 39386"
                    d="M18.807,22.45a2.924,2.924,0,0,1-3.182,2.577A2.937,2.937,0,0,1,12.43,22.45Z"
                    transform="translate(0.183 10.986)"
                    fill="#fff"
                    stroke="#707070"
                    strokeWidth={1}
                  />
                </g>
              </svg>
              <div className="side-menu__toaster">
              הודעות והתרעות
              </div>
            </NavLink>

          </li>
          <li className="side-menu__item">
            <a href="#"
              // className={`side-menu__link  ${activeLinkId === 5 ? "active" : ""}`}
              // onMouseEnter={() => onMouseOverLink(5)}
              // onMouseLeave={onMouseLeaveLink}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={48}
                height={48}
                viewBox="0 0 48 48"
              >
                <g
                  id="Сгруппировать_1598"
                  data-name="Сгруппировать 1598"
                  transform="translate(7010 -924)"
                >
                  <g
                    id="Прямоугольник_161047"
                    data-name="Прямоугольник 161047"
                    transform="translate(-7010 924)"
                    fill="#3c9e1c"
                    stroke="#fff"
                    strokeWidth={1}
                  >
                    <rect width={48} height={48} rx={24} stroke="none" />
                    <rect
                      x="0.5"
                      y="0.5"
                      width={47}
                      height={47}
                      rx="23.5"
                      fill="none"
                    />
                  </g>
                  <path
                    id="whatsapp"
                    d="M21.731,3.672a12.522,12.522,0,0,0-19.7,15.106L.25,25.265l6.637-1.741a12.506,12.506,0,0,0,5.983,1.524h.005A12.525,12.525,0,0,0,21.731,3.672ZM12.875,22.934h0a10.393,10.393,0,0,1-5.3-1.45l-.38-.226L3.256,22.291l1.051-3.84-.248-.394a10.406,10.406,0,1,1,8.815,4.876Zm5.708-7.793c-.313-.157-1.851-.913-2.138-1.018s-.5-.156-.7.157-.808,1.018-.991,1.226-.365.235-.678.078a8.544,8.544,0,0,1-2.516-1.553,9.436,9.436,0,0,1-1.74-2.167c-.182-.313,0-.466.137-.638a8.849,8.849,0,0,0,.782-1.07.575.575,0,0,0-.026-.548c-.078-.157-.7-1.7-.964-2.323-.254-.61-.512-.527-.7-.537s-.391-.011-.6-.011a1.149,1.149,0,0,0-.834.392,3.509,3.509,0,0,0-1.095,2.61A6.085,6.085,0,0,0,7.79,12.974,13.944,13.944,0,0,0,13.134,17.7a17.905,17.905,0,0,0,1.783.659,4.288,4.288,0,0,0,1.97.124A3.223,3.223,0,0,0,19,16.993a2.614,2.614,0,0,0,.182-1.488c-.078-.13-.287-.209-.6-.365Zm0,0"
                    transform="translate(-6997.25 934.735)"
                    fill="#fff"
                    fillRule="evenodd"
                  />
                </g>
              </svg>
              {/* <div className="side-menu__toaster">
                toaster
              </div> */}
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default SideMenu;
