import React, { useEffect, useRef, useState } from "react";
import { topbarBgGreen } from "../Assets/images";

import {
  validateTextInput,
  validateEmailInput,
  validateFixedCountInput,
  validatePhoneNumber,
  inputMaxCount,
  clearError,
  validateFullPhoneNumber,
} from "../Libs/forms";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import { citiesText } from "../Libs/citiesText";
import { useClickOutsideMulti } from "../hooks/useClickOutside";
import { API_DOMAIN } from "../Libs/config";

let streetsArr = null,
  citiesArr = null;

function EditProfile({ isLoggedInHandler, setCurrUserObject }) {
  const navigate = useNavigate();

  const currentUser = JSON.parse(localStorage.getItem("currUserObject"));

  const [profileInfo, setProfileInfo] = useState({
    fullname: "",
    phoneNumber: "",
    // telSecond: "",
    birthDay: "",
    birthMonth: "",
    birthdayYear: "",
    email: "",
    city: "",
    street: "",
    building: "",
    // addressHouse: "",
    addressApartments: "",
    addressEntrance: "",
    postal: "",
    mailbox: "",
  });

  const [citiesObject, setCitiesObject] = useState([]);
  const [citiesWholeObject, setCitiesWholeObject] = useState([]);
  const [formCitiesOpen, setFormCitiesOpen] = useState(false);
  const [formStreetsOpen, setFormStreetsOpen] = useState(false);
  const [streets, setStreets] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedStreet, setSelectedStreet] = useState(null);

  const [selectedDate, setSelectedDate] = useState({
    date: `${profileInfo.birthDay}-${profileInfo.birthMonth}-${profileInfo.birthdayYear}`,
    day: profileInfo.birthDay,
    month: profileInfo.birthMonth,
    year: profileInfo.birthdayYear,
  });
  const dayRef = useRef(null);
  const monthRef = useRef(null);
  const yearRef = useRef(null);
  const citiesLabelRef = useRef(null);
  const streetsLabelRef = useRef(null);

  const handleDateChange = (input, dayInput, monthInput, yearInput) => {
    const result = parseDate(input.value, dayInput, monthInput, yearInput);
    setSelectedDate({
      date: result.date,
      day: result.day,
      month: result.month,
      year: result.year,
    });
  };
  const parseDate = (value, dayInput, monthInput, yearInput) => {
    if (value !== "") {
      const date = new Date(value);
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear().toString();

      clearError(dayInput);
      clearError(monthInput);
      clearError(yearInput);
      return { date, day, month, year };
    } else {
      const date = "";
      const day = "";
      const month = "";
      const year = "";
      return { date, day, month, year };
    }
  };

  const submitProfileForm = (e) => {
    e.preventDefault();

    const form = e.target;

    const fullnameInput = form["fullname"];
    const telInputFirst = form["tel-1"];
    // const telInputSecond = form["tel-2"];
    const emailInput = form["email"];
    const birthdayInputYear = form["birthday-year"];
    const birthdayInputMonth = form["birthday-month"];
    const birthdayInputDay = form["birthday-day"];
    const cityInput = form["city"];
    const streetInput = form["street"];
    const buildingInput = form["house-num"];
    const addressApartmentInput = form["apartment-num"];
    const addressEntranceInput = form["entrance"];
    const mailboxInput = form["mailbox"];
    const postalInput = form["postal-code"];

    const isFullname = validateTextInput(fullnameInput, 3);
    const isTelFirst = validateFullPhoneNumber(telInputFirst);
    // const isTelFirst = validateFixedCountInput(telInputFirst, 7);
    // const isTelSecond = validatePhoneNumber(telInputSecond);
    const isEmail = validateEmailInput(emailInput);
    const isBirthYear = validateTextInput(birthdayInputYear, 4);
    const isBirthMonth = validateTextInput(birthdayInputMonth, 1);
    const isBirthDay = validateTextInput(birthdayInputDay, 1);
    const isCity = validateTextInput(cityInput, 1);
    const isStreet = validateTextInput(streetInput, 1);
    const isBuilding = validateTextInput(buildingInput, 1);
    const isAddressApartment = validateTextInput(addressApartmentInput, 1);
    const isAddressEntrance = validateTextInput(addressEntranceInput, 1);
    // const isMailboxInput = validateTextInput(mailboxInput, 1);
    const isPostalInput = validateTextInput(postalInput, 1);

    const isFormValid =
      isFullname &&
      isTelFirst &&
      // isTelSecond &&
      isBirthYear &&
      isBirthMonth &&
      isBirthDay &&
      isEmail &&
      isCity &&
      isStreet &&
      isBuilding &&
      isAddressApartment &&
      isAddressEntrance &&
      // isMailboxInput &&
      isPostalInput;

    if (isFormValid) {
      const result = {
        email: emailInput.value,
        firstName: fullnameInput.value.split(" ")[0],
        lastName: fullnameInput.value.split(" ")[1],
        phoneNumber: telInputFirst.value,
        companyName: currentUser.user.companyName,
        bankAccountId: currentUser.user.bankAccountId,
        postal: form["postal-code"].value,
        mailbox: form["mailbox"].value,
        birthdate:
          birthdayInputDay.value +
          "-" +
          birthdayInputMonth.value +
          "-" +
          birthdayInputYear.value,
        entranceNumber: addressEntranceInput.value,
        apartmentNumber: addressApartmentInput.value,
        houseNumber: buildingInput.value,
        userId: currentUser.user.id,
        city: cityInput.value,
        street: streetInput.value,

        // isRemember: form["isRemember"].checked,
      };

      axios
        .post(
          `${API_DOMAIN}/api/Authenticate/UpdateUserDetails`,
          result
        )
        .then((response) => {
          console.log(response.data);
          if (response.data.result) {
            console.log("data that we have got", response.data);
            // setCurrUserObject(response.data);
            // localStorage.setItem(
            //   "currUserObject",
            //   JSON.stringify(response.data)
            // );
            setCurrUserObject(response.data);
            localStorage.setItem(
              "currUserObject",
              JSON.stringify(response.data)
            );
            alert("Thank you your data was saved!");
          } else {
            //setLoading(false);
            alert("Something went wrong");
          }
        })
        .catch((error) => {
          console.error(error);
          //setLoading(false);
          alert("Error, please try again");
        });

      console.log(result);
    }
  };

  const logout = () => {
    localStorage.removeItem("currUserObject");
    navigate("/credentials");
    window.location.reload();
    isLoggedInHandler(false);
  };

  const handleCitiesOpen = () => {
    const newValue = !formCitiesOpen;
    setFormCitiesOpen(newValue);
  };
  const handleSearch = (input, searchList) => {
    const searchText = input.value.toLowerCase();

    for (let i = 0; i < searchList.length; i++) {
      const item = searchList[i];
      const itemName = item.textContent.toLowerCase();

      if (itemName.includes(searchText)) {
        const parentLi = item.closest("li");
        if (parentLi) {
          parentLi.style.display = "inline";
        }
      } else {
        const parentLi = item.closest("li");
        if (parentLi) {
          parentLi.style.display = "none";
        }
      }
    }
  };
  const handleStreetsOpen = () => {
    const newValue = !formStreetsOpen;
    setFormStreetsOpen(newValue);
  };

  function parseCSVData(csvData) {
    const results = [];
    const lines = csvData.split("\n");
    const headers = lines[0].replace("\r", "").split(",");

    for (let i = 1; i < lines.length; i++) {
      const currentLine = lines[i].split(",");
      if (currentLine.length !== headers.length) {
        continue;
      }

      const entry = {};
      for (let j = 0; j < headers.length; j++) {
        entry[headers[j]] = currentLine[j].trim();
      }
      results.push(entry);
    }

    return results;
  }

  const { addClickOutsideRef, removeClickOutsideRef } = useClickOutsideMulti();
  const onClickOutsideCitiesHandler = () => {
    // setCitiesObject([]);
    setFormCitiesOpen(false);
  };

  const onClickOutsideStreetsHandler = () => {
    // setStreets([]);
    setFormStreetsOpen(false);
  };

  function citySelectHandler(city) {
    setSelectedCity(city["city_name"]);
    // const cityId = city["city_code"];
    // console.log('citiesWholeObject',citiesWholeObject)
    // const streetsArray = citiesWholeObject
    //   .filter((obj) => obj["city_code"].trim() === cityId.trim())
    //   .map((obj) => obj["street_name"]);
    // setCitiesObject([]);
    setFormCitiesOpen(false);

    const streetsArray = citiesWholeObject
      .filter((obj) => obj["city_name"].trim() === city["city_name"].trim())
      .map((obj) => obj["street_name"]);

    console.log(streetsArray);
    setSelectedStreet("");
    setStreets(streetsArray);
    // clearError(document.getElementById("form-credentials-section__city"));
  }
  function streetSelectHandler(street) {
    // setSelectedStreet(street["street_name"]);
    setSelectedStreet(street);
    setFormStreetsOpen(false);
    // clearError(document.getElementById("form-credentials-section__street"));
    // setStreets([]);
  }

  useEffect(() => {
    const ourObject = parseCSVData(citiesText);
    console.log("citiesList", ourObject);
    let citiesWholeList = ourObject.map(({ city_code, city_name }) => ({
      city_code,
      city_name,
    }));
    let streetsWholeList = ourObject.map(({ street_code, street_name }) => ({
      street_code,
      street_name,
    }));

    citiesArr = citiesWholeList.reduce((result, current) => {
      const { city_code, city_name } = current;
      const isDuplicate = result.some(
        (city) => city.city_code === city_code && city.city_name === city_name
      );

      if (!isDuplicate) {
        result.push({ city_code, city_name });
      }

      return result;
    }, []);

    console.log("citiesArr", citiesArr);
    setCitiesWholeObject(ourObject);

    const streetsArrSorted = streetsWholeList.reduce((result, current) => {
      const { street_code, street_name } = current;
      const streetKey = `${street_code}_${street_name}`;

      if (!result.hasOwnProperty(streetKey)) {
        result[streetKey] = { street_code, street_name };
      }

      return result;
    }, {});

    const uniqueStreets = Object.values(streetsArrSorted);
    streetsArr = uniqueStreets;
    console.log("streetsDistinctList", uniqueStreets);
  }, []);
  useEffect(() => {
    addClickOutsideRef(citiesLabelRef, onClickOutsideCitiesHandler);
    addClickOutsideRef(streetsLabelRef, onClickOutsideStreetsHandler);

    // Cleanup the click outside handlers and their refs
    return () => {
      removeClickOutsideRef(citiesLabelRef);
      removeClickOutsideRef(streetsLabelRef);
    };
  }, [addClickOutsideRef, removeClickOutsideRef]);

  useEffect(() => {
    if (!currentUser) {
      navigate("/credentials");
    } else {
      console.log("our current user", currentUser.user);
      setProfileInfo({
        fullname: currentUser.user.firstName + " " + currentUser.user.lastName,
        phoneNumber: currentUser.user.phoneNumber,
        // telSecond: currentUser.user.phoneNumber.slice(0, 3),
        birthDay: currentUser.user.birthdate
          ? currentUser.user.birthdate.slice(8, 10)
          : "",
        birthMonth: currentUser.user.birthdate
          ? currentUser.user.birthdate.slice(5, 7)
          : "",
        birthdayYear: currentUser.user.birthdate
          ? currentUser.user.birthdate.slice(0, 4)
          : "",
        email: currentUser.user.email,
        city: currentUser.user.city,
        street: currentUser.user.street,
        building: currentUser.user.building,
        // addressHouse: currentUser.user.houseNumber,
        addressApartments: currentUser.user.apartmentNumber,
        addressEntrance: currentUser.user.entranceNumber,
        postal: currentUser.user.postal,
        mailbox: currentUser.user.mailBox,
        houseNumber: currentUser.user.houseNumber,
      });
      setSelectedCity(currentUser.user.city);
      setSelectedStreet(currentUser.user.street);
    }
  }, []);
  useEffect(() => {
    setSelectedDate({
      date: `${profileInfo.birthDay}-${profileInfo.birthMonth}-${profileInfo.birthdayYear}`,
      day: profileInfo.birthDay,
      month: profileInfo.birthMonth,
      year: profileInfo.birthdayYear,
    });
  }, [profileInfo]);

  return (
    <main className="edit-profile">
      <section className="edit-profile__topbar topbar">
        <div className="topbar__bg-img-ibg">
          <img src={topbarBgGreen} alt="topbar" />
        </div>
        <div className="topbar__container">
          <div className="topbar__title-wrapper">
            <h1 className="topbar__title">
              פרטים אישיים
              <div className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="65.067"
                  height="65.067"
                  viewBox="0 0 65.067 65.067"
                >
                  <path
                    id="Объединение_77"
                    data-name="Объединение 77"
                    d="M0,63.067V55.185c0-8.674,14.19-15.769,31.533-15.769s31.533,7.095,31.533,15.769v7.882Zm15.765-47.3A15.767,15.767,0,1,1,31.533,31.534,15.768,15.768,0,0,1,15.765,15.769Z"
                    transform="translate(1 1)"
                    fill="none"
                    stroke="#fff"
                    strokeWidth={2}
                  />
                </svg>
              </div>
            </h1>
          </div>
        </div>
      </section>
      <div className="edit-profile__breadcrumbs breadcrumbs">
        <div className="breadcrumbs__container">
          <ul className="breadcrumbs__list">
            <li className="breadcrumbs__item">
              <NavLink to="/">דף הבית</NavLink>
            </li>
            <li className="breadcrumbs__item">
              <p>edit profile</p>
            </li>
          </ul>
        </div>
      </div>
      <section className="edit-profile__main main-edit-profile">
        <div className="main-edit-profile__container">
          <h2 className="main-edit-profile__title">
            <span className="text">לעריכת הברכה</span>
            <span className="icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={20}
                height="19.121"
                viewBox="0 0 20 19.121"
              >
                <g id="edit-3" transform="translate(-2 -1.879)">
                  <path
                    id="Контур_8553"
                    data-name="Контур 8553"
                    d="M12,20h9"
                    fill="none"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                  />
                  <path
                    id="Контур_8554"
                    data-name="Контур 8554"
                    d="M16.5,3.5a2.121,2.121,0,1,1,3,3L7,19,3,20l1-4Z"
                    fill="none"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                  />
                </g>
              </svg>
            </span>
          </h2>
          <div className="main-edit-profile__form-block">
            <form
              action="#"
              onSubmit={submitProfileForm}
              className="main-edit-profile__form form-edit-profile"
            >
              <div className="form-edit-profile__form-body">
                <div className="form-edit-profile__col">
                  <label
                    htmlFor="form-edit-profile__fullname"
                    className="form-edit-profile__input-wrapper"
                  >
                    <span className="input-title">
                      שם מלא<span>*</span>
                    </span>
                    <input
                      autoComplete="off"
                      type="text"
                      name="fullname"
                      id="form-edit-profile__fullname"
                      className="input"
                      defaultValue={profileInfo.fullname}
                      onInput={(e) => {
                        if (
                          e.target
                            .closest("label")
                            .classList.contains("input-error")
                        ) {
                          validateTextInput(e.target, 3);
                        }
                      }}
                    />
                    <span className="error-text">Minimum 3 chars</span>
                  </label>
                  <label
                    htmlFor="form-edit-profile__tel-1"
                    className="input-wrapper form-edit-profile__input-wrapper"
                  >
                    <span className="input-title">
                      נייד<span>*</span>
                    </span>
                    <input
                      autoComplete="off"
                      type="number"
                      name="tel-1"
                      id="form-edit-profile__tel-1"
                      defaultValue={profileInfo.phoneNumber}
                      className="input"
                      onInput={(e) => {
                        inputMaxCount(e.target, 10);
                        if (
                          e.target
                            .closest("label")
                            .classList.contains("input-error")
                        ) {
                          // validateFixedCountInput(e.target, 7);
                          validateFullPhoneNumber(e.target);
                        }
                      }}
                    />
                    <span className="error-text">
                      Must start with 05 and 8 chars after
                    </span>
                  </label>
                  {/* <div className="form-edit-profile__tel-wrapper">
                   
                    <label
                      htmlFor="form-edit-profile__tel-2"
                      className="input-wrapper"
                    >
                      <input
                        autoComplete="off"
                        type="number"
                        name="tel-2"
                        id="form-edit-profile__tel-2"
                        className="input"
                        defaultValue={profileInfo.telSecond}
                        onInput={(e) => {
                          inputMaxCount(e.target, 3);

                          if (
                            e.target
                              .closest("label")
                              .classList.contains("input-error")
                          ) {
                            validatePhoneNumber(e.target);
                          }
                        }}
                      />
                      <span className="error-text">*05</span>
                    </label>
                  </div> */}
                  <label
                    htmlFor="form-edit-profile__email"
                    className="form-edit-profile__input-wrapper"
                  >
                    <span className="input-title">מייל</span>
                    <input
                      autoComplete="off"
                      type="email"
                      name="email"
                      defaultValue={profileInfo.email}
                      id="form-edit-profile__email"
                      className="input"
                      onInput={(e) => {
                        if (
                          e.target
                            .closest("label")
                            .classList.contains("input-error")
                        ) {
                          validateEmailInput(e.target);
                        }
                      }}
                    />
                    <span className="error-text">Incorrect email</span>
                  </label>
                  <div className="form-edit-profile__birthday birthday-field">
                    <p className="birthday-field__birthday-title">תאריך לידה</p>
                    <div className="birthday-field__birthday-body">
                      <input
                        type="date"
                        className="birthday-field__hidden-main-field"
                        value={selectedDate.date}
                        onChange={(e) => {
                          handleDateChange(
                            e.target,
                            dayRef.current,
                            monthRef.current,
                            yearRef.current
                          );
                        }}
                      />
                      <label
                        htmlFor="form-edit-profile__birthday-year"
                        className="birthday-field__input-wrapper input-wrapper"
                      >
                        <span className="input-title">שנה</span>
                        <input
                          autoComplete="off"
                          type="number"
                          name="birthday-year"
                          id="form-edit-profile__birthday-year"
                          className="input"
                          ref={yearRef}
                          value={selectedDate.year}
                          onChange={(event) => {}}
                        />
                        <span
                          className="error-text"
                          style={{
                            position: "absolute",
                            top: "100%",
                            right: 0,
                            width: "auto",
                            whiteSpace: "nowrap",
                          }}
                        >
                          You must select a date
                        </span>
                      </label>
                      <label
                        htmlFor="form-edit-profile__birthday-month"
                        className="birthday-field__input-wrapper"
                      >
                        <span className="input-title">חודש</span>
                        <input
                          autoComplete="off"
                          type="number"
                          name="birthday-month"
                          id="form-edit-profile__birthday-month"
                          className="input"
                          ref={monthRef}
                          value={selectedDate.month}
                          onChange={(event) => {}}
                        />
                      </label>
                      <label
                        htmlFor="form-edit-profile__birthday-day"
                        className="birthday-field__input-wrapper"
                      >
                        <span className="input-title">יום</span>
                        <input
                          autoComplete="off"
                          type="number"
                          name="birthday-day"
                          id="form-edit-profile__birthday-day"
                          className="input"
                          ref={dayRef}
                          value={selectedDate.day}
                          onChange={(event) => {}}
                        />
                      </label>
                    </div>
                  </div>
                </div>
                <div className="form-edit-profile__col">
                  <p className="form-edit-profile__col-title">כתובת</p>
                  {/* <label
                    htmlFor="form-edit-profile__address"
                    className="form-edit-profile__input-wrapper"
                  >
                    <span className="input-title">כתובת מגורים</span>
                    <input
                      autoComplete="off"
                      type="text"
                      name="address"
                      id="form-edit-profile__address"
                      className="input"
                      defaultValue={profileInfo.city}
                      onInput={(e) => {
                        if (
                          e.target
                            .closest("label")
                            .classList.contains("input-error")
                        ) {
                          validateTextInput(e.target, 1);
                        }
                      }}
                    />
                    <span className="error-text">Minimum 1 char</span>
                  </label> */}
                  <div
                    ref={citiesLabelRef}
                    // htmlFor="form-credentials-section__address"
                    className="form-edit-profile__input-wrapper input-wrapper label"
                  >
                    <span className="input-title">עיר</span>
                    <button
                      type="button"
                      onClick={handleCitiesOpen}
                      className="city-street-select-placeholder"
                    ></button>
                    <input
                      autoComplete="off"
                      type="text"
                      name="city"
                      id="form-edit-profile__city"
                      className="input input-address"
                      // defaultValue={registerFormInfo.address}
                      value={selectedCity}
                    />
                    {formCitiesOpen && (
                      <>
                        <ul
                          className="dropdown-menu inner show"
                          role="presentation"
                        >
                          <div
                            className="bs-searchbox"
                            style={{ padding: "0.3125rem 0.5rem" }}
                          >
                            <input
                              type="search"
                              className="input"
                              autoComplete="off"
                              aria-label="Search"
                              aria-controls="bs-select-1"
                              aria-autocomplete="list"
                              aria-activedescendant="bs-select-1-66"
                              onInput={(e) => {
                                const input = e.target;
                                const searchList =
                                  citiesLabelRef.current.querySelectorAll(
                                    ".dropdown-menu.inner li .dropdown-item .text"
                                  );
                                handleSearch(input, searchList);
                              }}
                            />
                          </div>
                          {citiesArr.map((city, index) => (
                            <li key={index}>
                              <button
                                type="button"
                                role="option"
                                className="dropdown-item"
                                id={`bs-select-1-${index}`}
                                tabIndex={0}
                                onClick={() => citySelectHandler(city)}
                              >
                                <span className="text">
                                  {city["city_name"]}
                                </span>
                              </button>
                            </li>
                          ))}
                        </ul>
                      </>
                    )}
                    <span className="error-text">You must select city</span>
                  </div>
                  {/* <p className="form-title">כתובת</p> */}
                  <div className="street-wrapper">
                    <div
                      ref={streetsLabelRef}
                      className="form-edit-profile__input-wrapper input-wrapper street label"
                    >
                      <span className="input-title">רחוב</span>

                      <button
                        type="button"
                        onClick={handleStreetsOpen}
                        className="city-street-select-placeholder"
                      ></button>
                      <input
                        autoComplete="off"
                        type="text"
                        name="street"
                        id="form-edit-profile__street"
                        className="input"
                        value={selectedStreet}
                      />
                      {selectedCity && formStreetsOpen && (
                        <ul
                          className="dropdown-menu inner show"
                          role="presentation"
                        >
                          <div
                            className="bs-searchbox"
                            style={{ padding: "0.3125rem 0.5rem" }}
                          >
                            <input
                              type="search"
                              className="input"
                              autoComplete="off"
                              aria-label="Search"
                              aria-controls="bs-select-2"
                              aria-autocomplete="list"
                              onInput={(e) => {
                                const input = e.target;
                                const searchList =
                                  streetsLabelRef.current.querySelectorAll(
                                    ".dropdown-menu.inner li .dropdown-item .text"
                                  );
                                handleSearch(input, searchList);
                              }}
                            />
                          </div>
                          {streets.map((street, index) => (
                            <li key={index}>
                              <button
                                type="button"
                                role="option"
                                className="dropdown-item"
                                id={`bs-select-1-${index}`}
                                tabIndex={0}
                                onClick={() => streetSelectHandler(street)}
                              >
                                <span className="text">
                                  {/* {street["street_name"]} */}
                                  {street}
                                </span>
                              </button>
                            </li>
                          ))}
                        </ul>
                      )}
                      <span className="error-text">You must select street</span>
                    </div>
                  </div>
                  <div className="form-edit-profile__address-details">
                    <label
                      htmlFor="form-edit-profile__house-num"
                      className="form-edit-profile__input-wrapper"
                    >
                      <span className="input-title">מס' בית</span>
                      <input
                        autoComplete="off"
                        type="number"
                        name="house-num"
                        id="form-edit-profile__house-num"
                        className="input"
                        defaultValue={profileInfo.houseNumber}
                        onInput={(e) => {
                          if (
                            e.target
                              .closest("label")
                              .classList.contains("input-error")
                          ) {
                            validateTextInput(e.target, 1);
                          }
                        }}
                      />
                      <span className="error-text">Minimum 1 char</span>
                    </label>
                    <label
                      htmlFor="form-edit-profile__apartment-num"
                      className="form-edit-profile__input-wrapper"
                    >
                      <span className="input-title">דירה</span>
                      <input
                        autoComplete="off"
                        type="number"
                        name="apartment-num"
                        id="form-edit-profile__apartment-num"
                        className="input"
                        defaultValue={profileInfo.addressApartments}
                        onInput={(e) => {
                          if (
                            e.target
                              .closest("label")
                              .classList.contains("input-error")
                          ) {
                            validateTextInput(e.target, 1);
                          }
                        }}
                      />
                      <span className="error-text">Minimum 1 char</span>
                    </label>
                    <label
                      htmlFor="form-edit-profile__entrance"
                      className="form-edit-profile__input-wrapper"
                    >
                      <span className="input-title">כניסה</span>
                      <input
                        autoComplete="off"
                        type="text"
                        name="entrance"
                        id="form-edit-profile__entrance"
                        className="input"
                        defaultValue={profileInfo.addressEntrance}
                        onInput={(e) => {
                          if (
                            e.target
                              .closest("label")
                              .classList.contains("input-error")
                          ) {
                            validateTextInput(e.target, 1);
                          }
                        }}
                      />
                      <span className="error-text">Minimum 1 char</span>
                    </label>
                  </div>
                  <div className="form-edit-profile__mail">
                    <label
                      htmlFor="form-edit-profile__mailbox"
                      className="form-edit-profile__input-wrapper"
                    >
                      <span className="input-title">ת.ד</span>
                      <input
                        autoComplete="off"
                        type="number"
                        name="mailbox"
                        id="form-edit-profile__mailbox"
                        className="input"
                        defaultValue={profileInfo.mailbox}
                        onInput={(e) => {
                          // if (
                          //   e.target
                          //     .closest("label")
                          //     .classList.contains("input-error")
                          // ) {
                          //   validateTextInput(e.target, 1);
                          // }
                        }}
                      />
                      <span className="error-text">Minimum 1 char</span>
                    </label>
                    <label
                      htmlFor="form-edit-profile__postal-code"
                      className="form-edit-profile__input-wrapper"
                    >
                      <span className="input-title">מיקוד</span>
                      <input
                        autoComplete="off"
                        type="number"
                        name="postal-code"
                        id="form-edit-profile__postal-code"
                        className="input"
                        defaultValue={profileInfo.postal}
                        onInput={(e) => {
                          if (
                            e.target
                              .closest("label")
                              .classList.contains("input-error")
                          ) {
                            validateTextInput(e.target, 1);
                          }
                        }}
                      />
                      <span className="error-text">Minimum 1 char</span>
                    </label>
                  </div>
                  <div className="form-edit-profile__checkbox checkbox">
                    <input
                      id="c_1"
                      className="checkbox__input"
                      type="checkbox"
                      defaultValue={1}
                      name="isRemember"
                    />
                    <label htmlFor="c_1" className="checkbox__label">
                      <span className="checkbox__text">זכור אותי</span>
                    </label>
                  </div>
                </div>
              </div>
              <div
                className="form-edit-profile__btn-container btn-container_center"
                style={{ marginBottom: 20 }}
              >
                <button type="submit" className="btn">
                  שמירה
                </button>
              </div>
              <div className="form-edit-profile__btn-container btn-container_center">
                <button
                  type="button"
                  className="btn btn-orange"
                  onClick={() => logout()}
                >
                  logout
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </main>
  );
}

export default EditProfile;
