import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import { API_DOMAIN, API_DOMAIN_IMG } from "../Libs/config";
import {
  blessingImg,
  blessingImgNoPeople,
  chooseAppBgImage,
  chooseAppBgImageNoPeople,
  mainBgImage,
  mainBgImageNoPeople,
  mainDecor,
  mobileTitleIconBlessing,
  prevBtnImage,
} from "../Assets/images";

import {
  validateTextInput,
  inputMaxCount,
  validateFixedCountInput,
  validateEmailInput,
  clearError,
  validatePhoneNumber,
  validateFullPhoneNumber,
} from "../Libs/forms";
import axios from "axios";
import { useClickOutsideMulti } from "../hooks/useClickOutside";
import { citiesText } from "../Libs/citiesText";
import AddSmsPhones from "../Popups/AddSmsPhones";

let streetsArr = null,
  citiesArr = null;

function CreateEvent({
  currUserObject,
  currWishlist,
  setCurrWishlist,
  secondStep,
  languageToggle,
  language
}) {
  const navigate = useNavigate();
  const eventData = JSON.parse(localStorage.getItem("eventData"))

  const location = useLocation();
  const params = location.state == null ? {} : location.state;
  const URLParams = new URLSearchParams(window.location.search);
  console.log(params, "params");
  const whishlistId = params?.wishListId;

  const [isDecor, setDecor] = useState(true);

  const [createEventStep, setCreateEventStep] = useState(0);
  const [stepNumber, setStepNumber] = useState(1);
  const [isForm, setForm] = useState(true);
  const [progress, setProgress] = useState(5);
  const [mainBackgroundgImage, setMainBackgroundgImage] = useState({
    main: API_DOMAIN_IMG + eventData.categoryImage,
    noPeople: mainBgImageNoPeople,
  });
  const [selectedValues, setSelectedValues] = useState([]);
  const [step0Info, setStep0Info] = useState({
    fullname: "",
    birthDay: "",
    birthMonth: "",
    birthYear: "",
  });
  const [step1Info, setStep1Info] = useState({
    fullname2: "",
    address: "",
    telFirst: "",
    telSecond: "",
    city: "",
    street: "",
    addressHouse: "",
    addressApartment: "",
    addressEntrance: "",
    mailbox: "",
    postal: "",
    time: "",
  });
  const [urlWishListId, setUrlWishListId] = useState();
  const urlParams = useParams();
  console.log("urlParams", urlParams);
  // setUrlWishListId(urlParams?.wishlistId);
  const [resultInfo, setResultInfo] = useState({
    eventTypeName: params?.eventTypeName ? params.eventTypeName : "",
    eventTypeNameEng: params?.eventTypeNameEng ? params.eventTypeNameEng : "",
    fullname: "",
    birthdate: "",
    fullname2: "",
    birthdate2: "",
    // address: "",
    city: "",
    street: "",
    addressDetails: "",
    postal: "",
    mailbox: "",
    time: "",
    number: "",
    appTypesList: [],
  });
  useEffect(() => {
    // console.log("params.eventTypeDate", params);
    // console.log(resultInfo);
  }, [resultInfo]);
  const [isReadyToSend, setReadyToSend] = useState(false);
  const [step0SelectedDate, setStep0SelectedDate] = useState({
    date: "",
    day: "",
    month: "",
    year: "",
  });
  const step0DayRef = useRef(null);
  const step0MonthRef = useRef(null);
  const step0YearRef = useRef(null);

  const [step1SelectedDate, setStep1SelectedDate] = useState({
    date: "",
    day: "",
    month: "",
    year: "",
  });
  const step1DayRef = useRef(null);
  const step1MonthRef = useRef(null);
  const step1YearRef = useRef(null);
  const [finalInviteUrl, setFinalInviteUrl] = useState(null);

  const [isMobile, setIsMobile] = useState(false);

  const [citiesWholeObject, setCitiesWholeObject] = useState([]);
  const [formCitiesOpen, setFormCitiesOpen] = useState(false);
  const [formStreetsOpen, setFormStreetsOpen] = useState(false);
  const [streets, setStreets] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedStreet, setSelectedStreet] = useState(null);
  const isBirthdayEvent = JSON.parse(localStorage.getItem('isBirthdayEvent'))
  const [isBirthdayMode, setIsBirthdayMode] = useState(isBirthdayEvent);
  const [test, isTest] = useState(true);
  const citiesLabelRef = useRef(null);
  const streetsLabelRef = useRef(null);

  console.log('isBirthdayMode', isBirthdayMode)


  useEffect(() => {
    const userAgent = window.navigator.userAgent;
    setIsMobile(/Mobi|Android/i.test(userAgent));
  }, []);

  const [isOpenSmsPopup, setIsOpenSmsPopup] = useState(false)

  const toggleAddSmsPopup = (e) => {
    e.preventDefault()
    setIsOpenSmsPopup(!isOpenSmsPopup)
  }

  const copyLinkToClipboard = (e, link) => {
    e.preventDefault()

    const button = e.target.closest(".btn.copy-link");
    button.style.position = "relative";
    navigator.clipboard
      .writeText(link)
      .then(() => {
        const hint = document.createElement("div");
        hint.classList.add("single-books-item__dropdown-table-item");
        hint.innerHTML = `<p class="value">wishlist link copied to clipboard!</p>`;
        hint.style.cssText =
          "position: absolute; top: 125%; right: 50%; transform: translateX(50%); width: auto; height: auto; object-fit: unset; white-space: nowrap; background-color:#fff;";
        button.appendChild(hint);
        setTimeout(() => {
          button.removeChild(hint);
        }, 2000);
      })
      .catch((error) => {
        console.error("Ошибка при копировании в буфер обмена:", error);
      });
  };

  // console.log("step0Info_: ", step0Info);

  const goBack = () => {
    navigate(-1);
  };

  const goToStep0 = () => {
    if (isBirthdayMode) {
      navigate(-1);
      return
    }
    console.log("trying to set isBir")

    setIsBirthdayMode(currWishlist?.whishlistObject?.isBirthDay);
    localStorage.setItem('isBirthdayMode', params?.eventTypeNameEng?.includes("birthday") ? "true" : "");
    setCreateEventStep(0);
    setStepNumber(1);
    setProgress(5);
    setForm(true);
    setDecor(true);
    setReadyToSend(false);
    setMainBackgroundgImage({
      main: mainBgImage,
      noPeople: mainBgImageNoPeople,
    });
    window.scrollTo(0, 0);
  };
  const handleStep0DateChange = (input, dayInput, monthInput, yearInput) => {
    const result = parseDate(input.value, dayInput, monthInput, yearInput);
    if (result) {
      setStep0SelectedDate({
        date: result.year + "-" + result.month + "-" + result.day,
        day: result.day,
        month: result.month,
        year: result.year,
      });
    }
    window.scrollTo(0, 0);

  };


  useEffect(() => {
    if (isBirthdayMode && secondStep) {
      setCreateEventStep(1);
      setStepNumber(2);
    } else if (!isBirthdayMode && secondStep) {
      setCreateEventStep(0);
      setStepNumber(1);
    }

    console.log('isBirthdayMode && secondStep', isBirthdayMode, secondStep)

    return () => {

    }
  }, [])

  useEffect(() => {
    // date is passed from my events component when editing existing event
    function getDateObject(dateString) {
      const dateObj = new Date(dateString);

      const result = {
        date: dateObj?.toISOString()?.slice(0, 10),
        day: dateObj.getDate(),
        month: dateObj.getMonth() + 1,
        year: dateObj.getFullYear(),
      };

      return result;
    }

    if (params.eventTypeDate) {
      const date = getDateObject(params.eventTypeDate);
      // console.log("eventTypeDate_", date);

      setStep0SelectedDate(date);
    }

    if (params.eventTypeName) {
      setResultInfo((prev) => ({
        ...prev,
        eventTypeName: params.eventTypeName,
      }));
      setStep0Info((prev) => ({ ...prev, fullname: params.eventTypeName }));
    }

    return () => { };
  }, [params.eventTypeDate, params.eventTypeName]);

  const handleStep1DateChange = (input, dayInput, monthInput, yearInput) => {
    const result = parseDate(input.value, dayInput, monthInput, yearInput);
    if (result) {
      setStep1SelectedDate({
        date: result.year + "-" + result.month + "-" + result.day,
        day: result.day,
        month: result.month,
        year: result.year,
      });
    }
    window.scrollTo(0, 0);

  };

  const parseDate = (value, dayInput, monthInput, yearInput) => {
    if (value) {
      const date = new Date(value);
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear().toString();

      clearError(dayInput);
      clearError(monthInput);
      clearError(yearInput);
      return { date, day, month, year };
    }
  };

  const submitStep0Form = (e) => {
    e.preventDefault();

    const form = e.target;
    // console.log("isBirthdayMode params", params);
    //#change
    setIsBirthdayMode(currWishlist?.whishlistObject?.isBirthDay);
    // console.log("isBirthdayMode ", isBirthdayMode);
    const fullnameInput = form["fullname"];
    const birthdayInputYear = form["birthday-year"];
    const birthdayInputMonth = form["birthday-month"];
    const birthdayInputDay = form["birthday-day"];

    const isFullname = validateTextInput(fullnameInput, 3);
    const isBirthYear = isBirthdayMode ? validateTextInput(birthdayInputYear, 4) : true;
    const isBirthMonth = isBirthdayMode ? validateTextInput(birthdayInputMonth, 1) : true;
    const isBirthDay = isBirthdayMode ? validateTextInput(birthdayInputDay, 1) : true;

    let isFormValid = isFullname && isBirthYear && isBirthMonth && isBirthDay;
    if (!isBirthdayMode) {
      isFormValid = isFullname;
    }

    if (isFormValid) {
      setStep0Info({
        fullname: fullnameInput.value,
        birthDay: birthdayInputDay.value,
        birthMonth: birthdayInputMonth.value,
        birthYear: birthdayInputYear.value,
      });
      setResultInfo({
        eventTypeName: resultInfo?.eventTypeName,
        eventTypeNameEng: resultInfo?.eventTypeNameEng,
        fullname: fullnameInput.value,
        birthdate: isBirthdayMode ?
          birthdayInputYear.value +
          "-" +
          birthdayInputMonth.value +
          "-" +
          birthdayInputDay.value
          : "",
        fullname2: "",
        birthdate2: "",
        addressDetails: selectedCity + ", " + selectedStreet,
        postal: "",
        mailbox: "",
        time: "",
        number: "",
        appTypesList: [],
        city: selectedCity,
        street: selectedStreet,
      });

      console.log('birthdayInputYear', birthdayInputYear, birthdayInputMonth, birthdayInputDay)
      const sendObj = {
        // userId: currUserObject?.user.id,
        description: "description",
        productsIdList: [],
        eventDate: `${birthdayInputYear.value +
          "-" +
          birthdayInputMonth.value +
          "-" +
          birthdayInputDay.value
          }T00:00:00.000Z`,
        // eventDate: "",
        name: fullnameInput.value,
        houseNumber: resultInfo.addressHouse,
        apartmentNumber: resultInfo.addressApartment,
        entranceNumber: resultInfo.addressEntrance,
        mailbox: resultInfo.mailbox,
        postal: resultInfo.postal,
        arrivalTime: resultInfo.time,
        contactPhone: resultInfo.number,
        type: resultInfo?.eventTypeNameEng,
        city: "",
        street: "",
        wishlistId: currWishlist?.whishlistObject?.id
      };

      // const endpoint = secondStep  ? 'UpdateWishlist' : 'CreateWishlist';
      axios
        .post(
          `${API_DOMAIN}/api/ProductCategory/UpdateWishlist`,
          sendObj
        )
        .then((response) => {
          // console.log(response.data);
          if (response.data.result) {
            //  goToWhilistSelect(response.data.wishlistId);
            if (secondStep && !isBirthdayMode) {
              goToStep1();
            } else {
              navigate(`/all-categories-new-event?wishlistId=${response.data.wishlistId}`);
            }
            window.scrollTo(0, 0);

          } else {
            //setLoading(false);
            alert("Something went wrong");
          }
        })
        .catch((error) => {
          console.error(error);
          //setLoading(false);
          alert("Error, please try again");
        });
    }
  };
  const submitStep4Form = (e) => {
    e.preventDefault();

    const form = e.target;
    // console.log("isBirthdayMode params", params);
    //#change
    // console.log("isBirthdayMode ", isBirthdayMode);
    const fullnameInput = form["fullname"];
    const birthdayInputYear = form["birthday-year"];
    const birthdayInputMonth = form["birthday-month"];
    const birthdayInputDay = form["birthday-day"];

    const isFullname = validateTextInput(fullnameInput, 3);
    const isBirthYear = validateTextInput(birthdayInputYear, 4);
    const isBirthMonth = validateTextInput(birthdayInputMonth, 1);
    const isBirthDay = validateTextInput(birthdayInputDay, 1);

    let isFormValid = isFullname && isBirthYear && isBirthMonth && isBirthDay;
    if (!isBirthdayMode) {
      isFormValid = isFullname;
    }

    if (isFormValid) {
      setStep0Info({
        fullname: fullnameInput.value,
        birthDay: birthdayInputDay.value,
        birthMonth: birthdayInputMonth.value,
        birthYear: birthdayInputYear.value,
      });
      setResultInfo({
        eventTypeName: resultInfo?.eventTypeName,
        eventTypeNameEng: resultInfo?.eventTypeNameEng,
        fullname: fullnameInput.value,
        birthdate:
          birthdayInputYear.value +
          "-" +
          birthdayInputMonth.value +
          "-" +
          birthdayInputDay.value,
        fullname2: "",
        birthdate2: "",
        addressDetails: selectedCity + ", " + selectedStreet,
        postal: "",
        mailbox: "",
        time: "",
        number: "",
        appTypesList: [],
        city: selectedCity,
        street: selectedStreet,
      });
      const sendObj = {
        // userId: currUserObject?.user.id,
        description: "description",
        productsIdList: [],
        eventDate: `${birthdayInputYear.value +
          "-" +
          birthdayInputMonth.value +
          "-" +
          birthdayInputDay.value
          }T00:00:00.000Z`,
        // eventDate: "",
        name: fullnameInput.value,
        houseNumber: resultInfo.addressHouse,
        apartmentNumber: resultInfo.addressApartment,
        entranceNumber: resultInfo.addressEntrance,
        mailbox: resultInfo.mailbox,
        postal: resultInfo.postal,
        arrivalTime: resultInfo.time,
        contactPhone: resultInfo.number,
        type: resultInfo?.eventTypeNameEng,
        city: "",
        street: "",
      };
      axios
        .post(
          `${API_DOMAIN}/api/ProductCategory/CreateWishlist`,
          sendObj
        )
        .then((response) => {
          // console.log(response.data);
          if (response.data.result) {
            //  goToStep1();
            //  goToWhilistSelect(response.data.wishlistId);
            setCreateEventStep(3)
            setStepNumber(4);

            window.scrollTo(0, 0);

          } else {
            //setLoading(false);
            alert("Something went wrong");
          }
        })
        .catch((error) => {
          console.error(error);
          //setLoading(false);
          alert("Error, please try again");
        });
    }
  };

  const goToStep1 = () => {
    setIsBirthdayMode(currWishlist?.whishlistObject?.isBirthDay);
    setCreateEventStep(1);
    setStepNumber(2);
    setProgress(30);
    setForm(false);
    setMainBackgroundgImage({
      main: API_DOMAIN_IMG + eventData.categoryImage,
      noPeople: mainBgImageNoPeople,
    });
    setReadyToSend(false);
    setDecor(false);
    window.scrollTo(0, 0);

  };

  console.log("Debugg_result_info", resultInfo)
  const submitStep1Form = (e) => {
    // console.log("its me 1")
    const form = e.target
      .closest(".credentials-section__content")
      .querySelector("form");

    const fullnameInput = document.getElementById(
      "form-credentials-section__fullname"
    );
    const telInputFirst = document.getElementById(
      "form-credentials-section__tel-1"
    );
    // const telInputSecond = document.getElementById(
    //   "form-credentials-section__tel-2"
    // );
    const birthdayInputYear = document.getElementById(
      "form-credentials-section__birthday-year"
    );
    const birthdayInputMonth = document.getElementById(
      "form-credentials-section__birthday-month"
    );
    const birthdayInputDay = document.getElementById(
      "form-credentials-section__birthday-day"
    );
    // const addressInput = document.getElementById(
    //   "form-credentials-section__address"
    // );
    const cityInput = document.getElementById("form-credentials-section__city");
    const streetInput = document.getElementById(
      "form-credentials-section__street"
    );

    console.log("Debugg_cityInput, streetInput", cityInput, streetInput)
    const addressHouseInput = document.getElementById(
      "form-credentials-section__house-num"
    );
    const addressApartmentInput = document.getElementById(
      "form-credentials-section__apartment-num"
    );
    const addressEntranceInput = document.getElementById(
      "form-credentials-section__entrance"
    );
    const mailboxInput = document.getElementById(
      "form-credentials-section__mailbox"
    );
    const postalInput = document.getElementById(
      "form-credentials-section__postal-code"
    );
    const timeInput = document.getElementById("form-credentials-section__time");

    const isFullname = validateTextInput(fullnameInput, 3);
    const isTelFirst = validateFullPhoneNumber(telInputFirst);
    // const isTelFirst = validateFixedCountInput(telInputFirst, 7);
    // const isTelSecond = validatePhoneNumber(telInputSecond);
    const isBirthYear = isBirthdayMode ? validateTextInput(birthdayInputYear, 4) : true;
    const isBirthMonth = isBirthdayMode ? validateTextInput(birthdayInputMonth, 1) : true;
    const isBirthDay = isBirthdayMode ? validateTextInput(birthdayInputDay, 1) : true;
    const isCity = validateTextInput(cityInput, 1);
    const isStreet = validateTextInput(streetInput, 1);
    const isAddressHouse = validateTextInput(addressHouseInput, 1);
    const isAddressApartment = validateTextInput(addressApartmentInput, 1);
    const isAddressEntrance = validateTextInput(addressEntranceInput, 1);
    // const isMailboxInput = validateFixedCountInput(mailboxInput, 9);
    const isPostalInput = validateTextInput(postalInput, 1);
    const isTime = validateTextInput(timeInput, 1);

    const isFormValid =
      isFullname &&
      isTelFirst &&
      // isTelSecond &&
      isBirthYear &&
      isBirthMonth &&
      isBirthDay &&
      isCity &&
      isStreet &&
      isAddressHouse &&
      isAddressApartment &&
      isAddressEntrance &&
      // isMailboxInput &&
      isPostalInput &&
      isTime;

    if (isFormValid) {
      setStep1Info({
        fullname2: fullnameInput.value,
        // address: addressInput.value,
        city: cityInput.value,
        street: streetInput.value,
        telFirst: telInputFirst.value,
        // telSecond: telInputSecond.value,
        addressHouse: addressHouseInput.value,
        addressApartment: addressApartmentInput.value,
        addressEntrance: addressEntranceInput.value,
        mailbox: mailboxInput.value,
        postal: postalInput.value,
        time: timeInput.value,
      });
      setResultInfo({
        eventTypeName: resultInfo?.eventTypeName,
        eventTypeNameEng: resultInfo?.eventTypeNameEng,
        // fullname: resultInfo.fullname,
        // birthdate: resultInfo.birthdate,
        fullname: currWishlist.whishlistObject?.name,
        birthdate: currWishlist.whishlistObject?.eventDate?.slice(0, 10),
        fullname2: fullnameInput.value,
        birthdate2:
          birthdayInputYear.value +
          "-" +
          birthdayInputMonth.value +
          "-" +
          birthdayInputDay.value,
        city: cityInput.value,
        street: streetInput.value,
        addressHouse: addressHouseInput.value,
        addressApartment: addressApartmentInput.value,
        addressEntrance: addressEntranceInput.value,
        postal: form["postal-code"].value,
        mailbox: form["mailbox"].value,
        time: form["time"].value,
        // number: telInputSecond.value + telInputFirst.value,
        number: telInputFirst.value,
        appTypesList: [],
      });

      goToStep2();
    } else {
      const elScrollTo = form.querySelector(".input-error");
      let scrollCoord;
      if (elScrollTo.closest(".form-edit-profile__birthday")) {
        scrollCoord = elScrollTo.closest(
          ".form-edit-profile__birthday"
        ).offsetTop;
      } else {
        scrollCoord = elScrollTo.offsetTop;
      }

      form.parentElement.scrollTo({
        top: scrollCoord,
        left: 0,
        behavior: "smooth",
      });
    }
    window.scrollTo(0, 0);
  };

  const goToStep2 = () => {
    setCreateEventStep(2);
    setStepNumber(3);
    setProgress(65);
    setForm(false);
    setMainBackgroundgImage({
      main: mainBgImage,
      noPeople: mainBgImageNoPeople,
    });
    setReadyToSend(false);
    setDecor(false);
    window.scrollTo(0, 0);
  };

  const submitStep2Form = (e) => {
    console.log("update wishlist hit 2 urlWishlist", urlWishListId);
    const sendObj = {
      userId: currUserObject?.user.id,
      description: resultInfo.fullname,
      productsIdList: [],
      // eventDate: "2023-07-15T09:43:52.718Z",
      eventDate: `${resultInfo.birthdate}T${resultInfo.time}`,
      name: resultInfo.fullname,
      houseNumber: resultInfo.addressHouse,
      apartmentNumber: resultInfo.addressApartment,
      entranceNumber: resultInfo.addressEntrance,
      mailbox: resultInfo.mailbox,
      postal: resultInfo.postal,
      arrivalTime: resultInfo.time,
      contactPhone: resultInfo.number,
      type: resultInfo?.eventTypeNameEng,
      wishlistId: secondStep ? urlWishListId : currWishlist.whishlistObject.id,
      city: resultInfo.city,
      street: resultInfo.street,
    };

    console.log("update wishlist hit 2 urlWishlist", sendObj);
    // axios
    //   .post(
    //     `${API_DOMAIN}/api/ProductCategory/CreateWishlist`,
    //     sendObj
    //   )
    //   .then((response) => {
    //     console.log(response.data);
    //     if (response.data.result) {
    //       goToWhilistSelect(response.data.wishlistId);
    //     } else {
    //       //setLoading(false);
    //       alert("Something went wrong");
    //     }
    //   })
    //   .catch((error) => {
    //     console.error(error);
    //     //setLoading(false);
    //     alert("Error, please try again");
    //   });
    axios
      .post(
        `${API_DOMAIN}/api/ProductCategory/UpdateWishlist`,
        sendObj
      )
      .then((response) => {
        console.log(response.data);
        if (response.data.result) {
          setCurrWishlist({
            whishlistItemsList: response.data.wishlist.whishlistItemsList,
            whishlistObject: response.data.wishlist.whishlistObject,
            whishlistItemsDetailsList:
              response.data.wishlist.whishlistItemsDetailsList,
          });
          goToStep3();
        } else {
          //setLoading(false);
          alert("Something went wrong");
        }
      })
      .catch((error) => {
        console.error(error);
        //setLoading(false);
        alert("Error, please try again");
      });
  };

  const goToStep3 = () => {
    // const nextStep = isBirthdayMode ? 3 : 4

    setCreateEventStep(3); // was 3
    setStepNumber(4);
    setProgress(100);
    setForm(false);
    setReadyToSend(false);

    setMainBackgroundgImage({
      main: chooseAppBgImage,
      noPeople: chooseAppBgImageNoPeople,
    });
    setDecor(false);
    window.scrollTo(0, 0);

  };
  const submitStep3Form = (e) => {
    console.log("its me 1")
    e.preventDefault();

    const form = e.target;

    // const isFormValid = Boolean(selectedValues.length);
    const isFormValid = true;
    if (isFormValid) {
      setResultInfo({
        eventTypeName: resultInfo?.eventTypeName,
        eventTypeNameEng: resultInfo?.eventTypeNameEng,
        fullname: resultInfo.fullname,
        birthdate: resultInfo.birthdate,
        fullname2: resultInfo.fullname2,
        birthdate2: resultInfo.birthdate2,
        city: resultInfo.city,
        street: resultInfo.street,
        addressDetails: resultInfo.addressDetails,
        postal: resultInfo.postal,
        mailbox: resultInfo.mailbox,
        time: resultInfo.time,
        number: resultInfo.number,
        appTypesList: [selectedValues],
      });
      console.log("update event hit urlwishlistId", urlWishListId);
      const paramsObj = {
        userId: currUserObject?.user.id,
        wishlistId: secondStep
          ? urlWishListId
          : currWishlist.whishlistObject.id,
        isShareEmail: selectedValues.includes("מיילים"),
        description: currWishlist.whishlistObject.description,
      };

      axios
        .post(
          `${API_DOMAIN}/api/ProductCategory/UpdateWishlist`,
          paramsObj
        )
        .then((response) => {
          console.log(response.data);
          if (response.data.result) {
            console.log("data that we have got", response.data);
            setCurrWishlist({
              whishlistItemsList: response.data.wishlist.whishlistItemsList,
              whishlistObject: response.data.wishlist.whishlistObject,
              whishlistItemsDetailsList:
                response.data.wishlist.whishlistItemsDetailsList,
            });
            setReadyToSend(true);
            window.scrollTo(0, 0);

          } else {
            //setLoading(false);
            alert("Something went wrong");
          }
        })
        .catch((error) => {
          console.error(error);
          //setLoading(false);
          alert("Error, please try again");
        });
    }
  };

  function goToWhilistSelect(whishlistId) {
    axios
      .get(
        `${API_DOMAIN}/api/ProductCategory/GetWishlistObject?id=${whishlistId}`
      )
      .then((response) => {
        console.log(response.data);
        if (response.data.result) {
          // setLoading(false);
          console.log("whishlist object", response.data);
          setCurrWishlist({
            whishlistItemsList: response.data.whishlistItemsList,
            whishlistObject: response.data.whishlistObject,
            whishlistItemsDetailsList: response.data.whishlistItemsDetailsList,
          });
          setFinalInviteUrl(
            `${window.location.origin}/?inviteWishlistId=${response.data.whishlistObject.id}`
          );
          // goToStep3();
        } else {
          //setLoading(false);
          alert("Something went wrong");
        }
      })
      .catch((error) => {
        console.error(error);
        //setLoading(false);
        alert("Error, please try again");
      });
  }

  const handleCheckBoxChange = (event) => {
    const value = event.target.value;
    if (event.target.checked) {
      setSelectedValues([...selectedValues, value]);
    } else {
      setSelectedValues(selectedValues.filter((item) => item !== value));
    }
  };

  const facebookShare = () => {
    // if (e.target.checked) {
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        finalInviteUrl
      )}`,
      "_blank"
    );
    // }
  };
  const tiktokShare = () => {
    // if (e.target.checked) {
    window.open(
      `tiktok://share?item_id=${encodeURIComponent(finalInviteUrl)}`,
      "_blank"
    );
    // }
  };
  const whatsappShare = (e) => {
    // if (e.target.checked) {
    window.open(
      `https://api.whatsapp.com/send?text=${encodeURIComponent(
        finalInviteUrl
      )}`,
      "_blank"
    );
    // }
  };
  const instagramShare = (e) => {
    // if (e.target.checked) {
    window.open(
      `https://www.instagram.com/share?url=${encodeURIComponent(
        finalInviteUrl
      )}`,
      "_blank"
    );
    // }
  };

  const browserShare = (e) => {
    e.preventDefault();
    console.log(navigator.share);
    if (navigator.share) {
      navigator
        .share({
          title: "My wishlist",
          text: "That's invite to my wishlist. You are welcome!",
          url: finalInviteUrl,
        })
        .then(() => {
          console.log("Успешно отправлено");
        })
        .error((error) => {
          console.error(error);
        });
    }
  };



  const onClickOutsideCitiesHandler = () => {
    // setCitiesObject([]);
    setFormCitiesOpen(false);
  };

  const onClickOutsideStreetsHandler = () => {
    // setStreets([]);
    setFormStreetsOpen(false);
  };

  const { addClickOutsideRef, removeClickOutsideRef } = useClickOutsideMulti();

  function citySelectHandler(city) {
    setSelectedCity(city["city_name"]);


    // const cityId = city["city_code"];
    // console.log('citiesWholeObject',citiesWholeObject)
    // const streetsArray = citiesWholeObject
    //   .filter((obj) => obj["city_code"].trim() === cityId.trim())
    //   .map((obj) => obj["street_name"]);
    // setCitiesObject([]);
    setFormCitiesOpen(false);

    const streetsArray = citiesWholeObject
      .filter((obj) => obj["city_name"].trim() === city["city_name"].trim())
      .map((obj) => obj["street_name"]);

    console.log(streetsArray);
    setSelectedStreet("");
    setStreets(streetsArray);
    clearError(document.getElementById("form-credentials-section__city"));
  }
  function streetSelectHandler(street) {
    // setSelectedStreet(street["street_name"]);
    setSelectedStreet(street);
    setFormStreetsOpen(false);
    clearError(document.getElementById("form-credentials-section__street"));
    // setStreets([]);
  }

  const handleCitiesOpen = () => {
    const newValue = !formCitiesOpen;
    setFormCitiesOpen(newValue);
  };
  const handleSearch = (input, searchList) => {
    const searchText = input.value.toLowerCase();

    for (let i = 0; i < searchList.length; i++) {
      const item = searchList[i];
      const itemName = item.textContent.toLowerCase();

      if (itemName.includes(searchText)) {
        const parentLi = item.closest("li");
        if (parentLi) {
          parentLi.style.display = "inline";
        }
      } else {
        const parentLi = item.closest("li");
        if (parentLi) {
          parentLi.style.display = "none";
        }
      }
    }
  };
  const handleStreetsOpen = () => {
    const newValue = !formStreetsOpen;
    setFormStreetsOpen(newValue);
    if (newValue) {
      const scrollCoord = streetsLabelRef.current.offsetTop;
      document.querySelector(".credentials-section__form-wrapper").scrollTo({
        top: scrollCoord,
        left: 0,
        behavior: "smooth",
      });
    }
  };

  function parseCSVData(csvData) {
    const results = [];
    const lines = csvData.split("\n");
    const headers = lines[0].replace("\r", "").split(",");

    for (let i = 1; i < lines.length; i++) {
      const currentLine = lines[i].split(",");
      if (currentLine.length !== headers.length) {
        continue;
      }

      const entry = {};
      for (let j = 0; j < headers.length; j++) {
        entry[headers[j]] = currentLine[j].trim();
      }
      results.push(entry);
    }

    return results;
  }

  useEffect(() => {
    // prepare street select
    const ourObject = parseCSVData(citiesText);
    // console.log("citiesList", ourObject);
    let citiesWholeList = ourObject.map(({ city_code, city_name }) => ({
      city_code,
      city_name,
    }));
    let streetsWholeList = ourObject.map(({ street_code, street_name }) => ({
      street_code,
      street_name,
    }));

    citiesArr = citiesWholeList.reduce((result, current) => {
      const { city_code, city_name } = current;
      const isDuplicate = result.some(
        (city) => city.city_code === city_code && city.city_name === city_name
      );

      if (!isDuplicate) {
        result.push({ city_code, city_name });
      }

      return result;
    }, []);

    // console.log("citiesArr", citiesArr);
    setCitiesWholeObject(ourObject);

    const streetsArrSorted = streetsWholeList.reduce((result, current) => {
      const { street_code, street_name } = current;
      const streetKey = `${street_code}_${street_name}`;

      if (!result.hasOwnProperty(streetKey)) {
        result[streetKey] = { street_code, street_name };
      }

      return result;
    }, {});

    const uniqueStreets = Object.values(streetsArrSorted);
    streetsArr = uniqueStreets;
    // console.log("streetsDistinctList", uniqueStreets);
  }, []);

  // Add the click outside handlers and their refs
  useEffect(() => {
    addClickOutsideRef(citiesLabelRef, onClickOutsideCitiesHandler);
    addClickOutsideRef(streetsLabelRef, onClickOutsideStreetsHandler);

    // Cleanup the click outside handlers and their refs
    return () => {
      removeClickOutsideRef(citiesLabelRef);
      removeClickOutsideRef(streetsLabelRef);
    };
  }, [addClickOutsideRef, removeClickOutsideRef]);

  useEffect(() => {
    if (isReadyToSend) {
      // console.log(resultInfo);
      localStorage.setItem(`event-created`, JSON.stringify(resultInfo));
      
      // console.log(JSON.parse(localStorage.getItem(`event-created`)));\
      const currentUser = localStorage.getItem("currUserObject");
      if(currentUser){
        navigate("/event-created");
      }else{
        navigate(`/credentials-new-wishlist/${urlParams?.wishListId}`)
      }

      window.location.reload()
    }
  }, [params, resultInfo, isReadyToSend, navigate]);

  useEffect(() => {
    // not logged in
    const currentUser = localStorage.getItem("currUserObject");
    if (!currentUser) {
      // navigate("/credentials");
    }

    if (secondStep) {
      setUrlWishListId(urlParams?.wishListId);
      getWishlistObject(urlParams?.wishListId)
      // console.log("url params", urlParams);
      if (isBirthdayMode) {
        goToStep1();
      }
    }
  }, []);

  function getWishlistObject(whishlistId) {
    axios
      .get(
        `${API_DOMAIN}/api/ProductCategory/GetWishlistObject?id=${whishlistId}`
      )
      .then((response) => {
        console.log(response.data);
        if (response.data.result) {
          console.log("wishilist object", response.data.whishlistObject);
          setIsBirthdayMode(response?.data?.whishlistObject?.isBirthday);
          // setLoading(false);

       /* */   console.log("whishlist object", response.data);
          setCurrWishlist({
            whishlistItemsList: response.data.whishlistItemsList,
            whishlistObject: response.data.whishlistObject,
            whishlistItemsDetailsList: response.data.whishlistItemsDetailsList,
          });
          setFinalInviteUrl(
            `${window.location.origin}/?inviteWishlistId=${response.data.whishlistObject.id}`
          );
          // goToStep3();
        } else {
          //setLoading(false);
          alert("Something went wrong");
        }
      })
      .catch((error) => {
        console.error(error);
        //setLoading(false);
        alert("Error, please try again");
      });
  }
  return (
    <main
      className={` credentials-page ${createEventStep === 0
        ? "create-event"
        : createEventStep === 1
          ? "blessing-form"
          : createEventStep === 2
            ? "blessing-page"
            : createEventStep === 3
              ? "choose-application"
              : ""
        } ${isForm ? "credentials-page_form" : ""}`}
      data-fullscreen
    >
      <section className="credentials-section">
        <div className="credentials-section__bg-image-ibg">
          <img
            src={mainBackgroundgImage.main}
            className="img-people"
            alt="bg"
          />
          <img
            src={mainBackgroundgImage.noPeople}
            className="img-no-people"
            alt="bg"
          />
        </div>
        <div className="credentials-section__content-window">
          <div className="credentials-section__steps">
            <div
              className={`credentials-section__steps-numbers numbers-steps step-${stepNumber}`}
            >
              <ul className="numbers-steps__list">
                <li className="numbers-steps__list-item">
                  <div
                    className="numbers-steps__step"
                    onClick={() =>
                      stepNumber !== 1 && stepNumber < 4 ? goToStep0() : null
                    }
                  >
                    1
                  </div>
                </li>
                <li className="numbers-steps__list-item">
                  <div
                    className="numbers-steps__step"
                    onClick={() =>
                      stepNumber > 2 && stepNumber < 4 ? goToStep1() : null
                    }
                  >
                    2
                  </div>
                </li>
                <li className="numbers-steps__list-item">
                  <div className="numbers-steps__step"
                    onClick={() =>
                      stepNumber > 3 && stepNumber < 4 ? goToStep2() : null
                    }
                  >3</div>
                </li>
                {/* {!isBirthdayMode ? ( */}
                <li className="numbers-steps__list-item">
                  <div className="numbers-steps__step"
                    onClick={() =>
                      stepNumber > 4 && stepNumber < 4 ? goToStep3() : null
                    }
                  >4</div>
                </li>
                {/* ) : ""} */}
                {/* <li className="numbers-steps__list-item">
                  <div className="numbers-steps__step">{isBirthdayMode ? "4" : '5'}</div>
                </li> */}
              </ul>
            </div>
          </div>
          <div className="credentials-section__content">
            {createEventStep === 0 && (
              <>
                <button
                  type="button"
                  onClick={goBack}
                  className="credentials-section__prev-btn"
                >
                  <img src={prevBtnImage} alt="arrow left black" />
                </button>
                <h1 className="credentials-section__title credentials-section__title_icon-top">
                  מזל טוב
                </h1>
                <h2 className="credentials-section__subtitle">
                  בחרת בקטגורית {params?.eventTypeName} מלא/י שם ותאריך לידה
                </h2>
                <div className="credentials-section__form-wrapper">
                  <form
                    action="#"
                    onSubmit={submitStep0Form}
                    className="credentials-section__form form-credentials-section"
                  >
                    <label
                      htmlFor="form-credentials-section__fullname"
                      className="form-credentials-section__input-wrapper input-wrapper"
                    >
                      <span className="input-title">שם אירוע*</span>
                      <input
                        autoComplete="disable-autofill"
                        type="text"
                        name="fullname"
                        id="form-credentials-section__fullname"
                        className="input"
                        defaultValue={step0Info.fullname}
                        onInput={(e) => {
                          if (
                            e.target
                              .closest("label")
                              .classList.contains("input-error")
                          ) {
                            validateTextInput(e.target, 3);
                          }
                        }}
                      />
                      <span className="error-text">minimum 3 chars</span>
                    </label>
                    <div className="form-edit-profile__birthday birthday-field">
                      <p className="birthday-field__birthday-title form-title">
                        תאריך האירוע
                      </p>
                      <div className="birthday-field__birthday-body">
                        <input
                          type="date"
                          className="birthday-field__hidden-main-field"
                          value={step0SelectedDate.date}
                          onChange={(e) => {
                            handleStep0DateChange(
                              e.target,
                              step0DayRef.current,
                              step0MonthRef.current,
                              step0YearRef.current
                            );
                          }}
                        />
                        <label
                          htmlFor="form-credentials-section__birthday-year"
                          className="birthday-field__input-wrapper input-wrapper"
                        >
                          <span className="input-title">שנה</span>
                          <input
                            autoComplete="disable-autofill"
                            type="number"
                            name="birthday-year"
                            id="form-credentials-section__birthday-year"
                            value={step0SelectedDate.year}
                            onChange={(event) => { }}
                            ref={step0YearRef}
                            className="input"
                          />
                          <span
                            className="error-text"
                            style={{
                              position: "absolute",
                              top: "100%",
                              right: 0,
                              width: "auto",
                              whiteSpace: "nowrap",
                            }}
                          >
                            You must select a date
                          </span>
                        </label>
                        <label
                          htmlFor="form-credentials-section__birthday-month"
                          className="birthday-field__input-wrapper input-wrapper"
                        >
                          <span className="input-title">חודש</span>
                          <input
                            autoComplete="disable-autofill"
                            type="number"
                            name="birthday-month"
                            id="form-credentials-section__birthday-month"
                            ref={step0MonthRef}
                            className="input"
                            value={step0SelectedDate.month}
                            onChange={(event) => { }}
                          />
                        </label>
                        <label
                          htmlFor="form-credentials-section__birthday-day"
                          className="birthday-field__input-wrapper input-wrapper"
                        >
                          <span className="input-title">יום</span>
                          <input
                            autoComplete="disable-autofill"
                            type="number"
                            name="birthday-day"
                            ref={step0DayRef}
                            id="form-credentials-section__birthday-day"
                            className="input"
                            value={step0SelectedDate.day}
                            onChange={(event) => { }}
                          />
                        </label>
                      </div>
                    </div>
                    <div className="form-credentials-section__buttons-container">
                      <button type="submit" className="btn">
                        לשלב הבא
                      </button>
                    </div>
                  </form>
                </div>
              </>
            )}

            {createEventStep === 1 && (
              <>
                <button
                  type="button"
                  onClick={goToStep0}
                  className="credentials-section__prev-btn"
                >
                  <img src={prevBtnImage} alt="arrow left black" />
                </button>
                <h1 className="credentials-section__title">
                  <div className="mobile-icon">
                    <img src={mobileTitleIconBlessing} alt="icon" />
                  </div>
                  הזמנה
                </h1>
                <h2 className="credentials-section__subtitle">
                  {/* {isBirthdayMode.toString()} */}
                  מלא/י את הפרטי האירוע
                </h2>
                <div className="credentials-section__form-wrapper">
                  <form
                    action="#"
                    className="credentials-section__form form-credentials-section"
                  >
                    <label
                      htmlFor="form-credentials-section__fullname"
                      className="form-credentials-section__input-wrapper input-wrapper"
                    >
                      <span className="input-title">שם מלא*</span>
                      <input
                        autoComplete="disable-autofill"
                        type="text"
                        name="fullname"
                        id="form-credentials-section__fullname"
                        className="input"
                        defaultValue={step1Info.fullname2}
                        onInput={(e) => {
                          if (
                            e.target
                              .closest("label")
                              .classList.contains("input-error")
                          ) {
                            validateTextInput(e.target, 3);
                          }
                        }}
                      />
                      <span className="error-text">Minimum 3 chars</span>
                    </label>
                    <div style={{ display: isBirthdayMode ? "block" : "none" }} className="form-edit-profile__birthday birthday-field">
                      <p className="birthday-field__birthday-title form-title">
                        תאריך לידה
                      </p>
                      <div className="birthday-field__birthday-body">
                        <input
                          type="date"
                          className="birthday-field__hidden-main-field"
                          value={step1SelectedDate.date}
                          onChange={(e) => {
                            handleStep1DateChange(
                              e.target,
                              step1DayRef.current,
                              step1MonthRef.current,
                              step1YearRef.current
                            );
                          }}
                        />
                        <label
                          htmlFor="form-credentials-section__birthday-year"
                          className="birthday-field__input-wrapper input-wrapper"
                        >
                          <span className="input-title">שנה</span>
                          <input
                            autoComplete="disable-autofill"
                            type="number"
                            name="birthday-year"
                            id="form-credentials-section__birthday-year"
                            className="input"
                            value={step1SelectedDate.year}
                            onChange={(event) => { }}
                            ref={step1YearRef}
                          />
                          <span
                            className="error-text"
                            style={{
                              position: "absolute",
                              top: "100%",
                              right: 0,
                              width: "auto",
                              whiteSpace: "nowrap",
                            }}
                          >
                            You must select a date
                          </span>
                        </label>
                        <label
                          htmlFor="form-credentials-section__birthday-month"
                          className="birthday-field__input-wrapper input-wrapper"
                        >
                          <span className="input-title">חודש</span>
                          <input
                            autoComplete="disable-autofill"
                            type="number"
                            name="birthday-month"
                            id="form-credentials-section__birthday-month"
                            className="input"
                            ref={step1MonthRef}
                            value={step1SelectedDate.month}
                            onChange={(event) => { }}
                          />
                        </label>
                        <label
                          htmlFor="form-credentials-section__birthday-day"
                          className="birthday-field__input-wrapper input-wrapper"
                        >
                          <span className="input-title">יום</span>
                          <input
                            autoComplete="disable-autofill"
                            type="number"
                            name="birthday-day"
                            id="form-credentials-section__birthday-day"
                            className="input"
                            ref={step1DayRef}
                            value={step1SelectedDate.day}
                            onChange={(event) => { }}
                          />
                        </label>
                      </div>
                    </div>
                    <p className="form-title">כתובת</p>
                    <div
                      ref={citiesLabelRef}
                      className="form-credentials-section__input-wrapper input-wrapper label"
                    >
                      <span className="input-title">עיר</span>
                      <button
                        type="button"
                        onClick={handleCitiesOpen}
                        className="city-street-select-placeholder"
                      ></button>
                      <input
                        autoComplete="disable-autofill"
                        type="text"
                        name="address"
                        id="form-credentials-section__city"
                        className="input input-address"
                        value={selectedCity}
                      />
                      {formCitiesOpen && (
                        <>
                          <ul
                            className="dropdown-menu inner show"
                            role="presentation"
                          >
                            <div
                              className="bs-searchbox"
                              style={{ padding: "0.3125rem 0.5rem" }}
                            >
                              <input
                                type="search"
                                className="input"
                                autoComplete="disable-autofill"
                                aria-label="Search"
                                aria-controls="bs-select-1"
                                aria-autocomplete="list"
                                aria-activedescendant="bs-select-1-66"
                                onInput={(e) => {
                                  const input = e.target;
                                  const searchList =
                                    citiesLabelRef.current.querySelectorAll(
                                      ".dropdown-menu.inner li .dropdown-item .text"
                                    );
                                  handleSearch(input, searchList);
                                }}
                              />
                            </div>
                            {citiesArr.map((city, index) => (
                              <li key={index}>
                                <button
                                  type="button"
                                  role="option"
                                  className="dropdown-item"
                                  id={`bs-select-1-${index}`}
                                  tabIndex={0}
                                  onClick={() => citySelectHandler(city)}
                                >
                                  <span className="text">
                                    {city["city_name"]}
                                  </span>
                                </button>
                              </li>
                            ))}
                          </ul>
                        </>
                      )}
                      <span className="error-text">You must select city</span>
                    </div>
                    <div
                      ref={streetsLabelRef}
                      className="form-credentials-section__input-wrapper input-wrapper street label"
                    >
                      <span className="input-title">רחוב</span>
                      <button
                        type="button"
                        onClick={handleStreetsOpen}
                        className="city-street-select-placeholder"
                      ></button>
                      <input
                        autoComplete="disable-autofill"
                        type="text"
                        name="address"
                        id="form-credentials-section__street"
                        className="input"
                        value={selectedStreet}
                      />
                      {selectedCity && formStreetsOpen && (
                        <ul
                          className="dropdown-menu inner show"
                          role="presentation"
                        >
                          <div
                            className="bs-searchbox"
                            style={{ padding: "0.3125rem 0.5rem" }}
                          >
                            <input
                              type="search"
                              className="input"
                              autoComplete="disable-autofill"
                              aria-label="Search"
                              aria-controls="bs-select-2"
                              aria-autocomplete="list"
                              onInput={(e) => {
                                const input = e.target;
                                const searchList =
                                  streetsLabelRef.current.querySelectorAll(
                                    ".dropdown-menu.inner li .dropdown-item .text"
                                  );
                                handleSearch(input, searchList);
                              }}
                            />
                          </div>
                          {streets.map((street, index) => (
                            <li key={index}>
                              <button
                                type="button"
                                role="option"
                                className="dropdown-item"
                                id={`bs-select-1-${index}`}
                                tabIndex={0}
                                onClick={() => streetSelectHandler(street)}
                              >
                                <span className="text">
                                  {/* {street["street_name"]} */}
                                  {street}
                                </span>
                              </button>
                            </li>
                          ))}
                        </ul>
                      )}
                      <span className="error-text">You must select street</span>
                    </div>
                    <div className="three-inputs">
                      <label
                        htmlFor="form-credentials-section__house-num"
                        className="birthday-field__input-wrapper input-wrapper"
                      >
                        <span className="input-title">מס' בית</span>
                        <input
                          autoComplete="disable-autofill"
                          type="number"
                          name="house-num"
                          id="form-credentials-section__house-num"
                          className="input"
                          defaultValue={step1Info.addressHouse}
                          onInput={(e) => {
                            if (
                              e.target
                                .closest("label")
                                .classList.contains("input-error")
                            ) {
                              validateTextInput(e.target, 1);
                            }
                          }}
                        />
                        <span className="error-text">Minimum 1 char</span>
                      </label>
                      <label
                        htmlFor="form-credentials-section__apartment-num"
                        className="birthday-field__input-wrapper input-wrapper"
                      >
                        <span className="input-title">דירה</span>
                        <input
                          autoComplete="disable-autofill"
                          type="number"
                          name="apartment-num"
                          id="form-credentials-section__apartment-num"
                          className="input"
                          defaultValue={step1Info.addressApartment}
                          onInput={(e) => {
                            if (
                              e.target
                                .closest("label")
                                .classList.contains("input-error")
                            ) {
                              validateTextInput(e.target, 1);
                            }
                          }}
                        />
                        <span className="error-text">Minimum 1 char</span>
                      </label>
                      <label
                        htmlFor="form-credentials-section__entrance"
                        className="birthday-field__input-wrapper input-wrapper"
                      >
                        <span className="input-title">כניסה</span>
                        <input
                          autoComplete="disable-autofill"
                          type="text"
                          name="entrance"
                          id="form-credentials-section__entrance"
                          className="input"
                          defaultValue={step1Info.addressEntrance}
                          onInput={(e) => {
                            if (
                              e.target
                                .closest("label")
                                .classList.contains("input-error")
                            ) {
                              validateTextInput(e.target, 1);
                            }
                          }}
                        />
                        <span className="error-text">Minimum 1 char</span>
                      </label>
                    </div>
                    <div className="mail-wrapper">
                      <label
                        htmlFor="form-credentials-section__mailbox"
                        className="input-wrapper"
                      >
                        <span className="input-title">ת.ד</span>
                        <input
                          autoComplete="disable-autofill"
                          type="number"
                          name="mailbox"
                          id="form-credentials-section__mailbox"
                          className="input"
                          defaultValue={step1Info.mailbox}
                          onInput={(e) => {
                            // if (
                            //   e.target
                            //     .closest("label")
                            //     .classList.contains("input-error")
                            // ) {
                            //   validateFixedCountInput(e.target, 9);
                            // }
                            // inputMaxCount(e.target, 9);
                          }}
                        />
                        <span className="error-text">9 numbers</span>
                      </label>
                      <label
                        htmlFor="form-credentials-section__postal-code"
                        className="input-wrapper"
                      >
                        <span className="input-title">מיקוד</span>
                        <input
                          autoComplete="disable-autofill"
                          type="number"
                          name="postal-code"
                          id="form-credentials-section__postal-code"
                          className="input"
                          defaultValue={step1Info.postal}
                          onInput={(e) => {
                            if (
                              e.target
                                .closest("label")
                                .classList.contains("input-error")
                            ) {
                              validateTextInput(e.target, 1);
                            }
                          }}
                        />
                        <span className="error-text">Minimum 1 char</span>
                      </label>
                    </div>
                    <label
                      htmlFor="form-credentials-section__time"
                      className="form-credentials-section__input-wrapper input-wrapper"
                    >
                      <span className="input-title">שעת הגעה</span>
                      <input
                        autoComplete="disable-autofill"
                        type="time"
                        name="time"
                        id="form-credentials-section__time"
                        className="input"
                        defaultValue={step1Info.time}
                        onChange={(e) => validateTextInput(e.target, 1)}
                      />
                      <span className="error-text">You must select time</span>
                    </label>
                    <label
                      htmlFor="form-credentials-section__tel-1"
                      className="input-wrapper"
                    >
                      <span className="input-title">טלפון לתיאומים</span>
                      <input
                        autoComplete="disable-autofill"
                        type="number"
                        name="tel-1"
                        defaultValue={step1Info.telFirst}
                        id="form-credentials-section__tel-1"
                        className="input"
                        onInput={(e) => {
                          inputMaxCount(e.target, 10);
                          if (
                            e.target
                              .closest("label")
                              .classList.contains("input-error")
                          ) {
                            // validateFixedCountInput(e.target, 7);
                            validateFullPhoneNumber(e.target);
                          }
                        }}
                      />
                      <span className="error-text">
                        Must start with 05 and 8 chars after
                      </span>
                    </label>
                    {/* <div className="tel-wrapper">
                      
                      <label
                        htmlFor="form-credentials-section__tel-2"
                        className="input-wrapper"
                      >
                        <input
                          autoComplete="off"
                          type="number"
                          name="tel-2"
                          id="form-credentials-section__tel-2"
                          className="input"
                          defaultValue={step1Info.telSecond}
                          onInput={(e) => {
                            inputMaxCount(e.target, 3);

                            if (
                              e.target
                                .closest("label")
                                .classList.contains("input-error")
                            ) {
                              validatePhoneNumber(e.target);
                            }
                          }}
                        />
                        <span className="error-text">*05</span>
                      </label>
                    </div> */}
                  </form>
                </div>
                <div className="credentials-section__bottom-buttons">
                  <button onClick={submitStep1Form} className="btn">
                    {languageToggle ? languageToggle.newEvent[language].btn1 : "לצפיה ואישור"}
                  </button>
                </div>
              </>
            )}

            {createEventStep === 2 && (
              <div>
                <button type="button" className="credentials-section__prev-btn">
                  <img
                    src={prevBtnImage}
                    onClick={goToStep1}
                    alt="arrow left black"
                  />
                </button>
                <h1 className="credentials-section__title">
                  <div className="mobile-icon">
                    <img src={mobileTitleIconBlessing} alt="icon" />
                  </div>
                  ברכה למוזמנים
                </h1>
                <h2 className="credentials-section__subtitle">
                  יום הולדת יש רק פעם בשנה אני מזמין/ה אתכם לחגוג איתי
                </h2>
                <div className="credentials-section__large-image-ibg">
                  <img src={blessingImg} className="img-people" alt="img" />
                  <img
                    src={blessingImgNoPeople}
                    className="img-no-people"
                    alt="img"
                  />
                </div>
                <div className="credentials-section__table-block">
                  <div className="credentials-section__table">
                    <div className="credentials-section__table-item">
                      <p className="credentials-section__table-item-name">
                        תאריך האירוע
                      </p>
                      <p className="credentials-section__table-item-value">
                        {resultInfo.birthdate}
                      </p>
                    </div>
                    <div className="credentials-section__table-item">
                      <p className="credentials-section__table-item-name">
                        כתובת האירוע
                      </p>
                      <p className="credentials-section__table-item-value">
                        {`${resultInfo.city}, ${resultInfo.city}`}
                        {console.log('resultInfo.addressDetails', resultInfo.addressDetails)}
                      </p>
                    </div>
                    <div className="credentials-section__table-item">
                      <p className="credentials-section__table-item-name">
                        שעת הגעה
                      </p>
                      <p className="credentials-section__table-item-value">
                        {resultInfo.time}
                      </p>
                    </div>
                    <div className="credentials-section__table-item">
                      <p className="credentials-section__table-item-name">
                        טלפון לתיאומים
                      </p>
                      <p className="credentials-section__table-item-value">
                        {resultInfo.number}
                      </p>
                    </div>
                  </div>
                  <p className="credentials-section__under-table-text">
                    בא לכם לפנק אותי במתנה. חסכתי לכם כאב ראש מיותר בחרו מתוך
                    רשימת המתנות המועדפת שלי אז למה אתם מחכים?
                  </p>
                </div>
                <div className="credentials-section__bottom-buttons">
                  <button
                    type="button"
                    onClick={goToStep1}
                    className="btn-outline-white"
                  >
                    {languageToggle ? languageToggle.newEvent[language].btn3 : "עריכה"}awdw


                  </button>
                  <button
                    type="button"
                    onClick={submitStep2Form}
                    className="btn"
                  >
                    {languageToggle ? languageToggle.newEvent[language].btn2 : "אישור"}


                  </button>
                </div>
              </div>
            )}

            {createEventStep === 3 && (
              <>
                {/* <button
                  type="button"
                  onClick={goToStep2}
                  className="credentials-section__prev-btn"
                >
                  <img src={prevBtnImage} alt="arrow left black" />
                </button> */}
                <h1 className="credentials-section__title">ברכה למוזמנים</h1>
                <div className="credentials-section__form-wrapper">
                  <form
                    action="#"
                    onSubmit={submitStep3Form}
                    className="credentials-section__form form-credentials-section"
                  >
                    <p className="form-title checks-title">
                      מאיזה אפליקציה תרצה להזמין חברים
                    </p>
                    <div className="form-credentials-section__checks">
                      {/* {1} */}

                      <div className="checkbox">

                        <button type="button" className="btn" onClick={facebookShare}>
                          <span className="checkbox__text checkbox__text--btn">פייסבוק</span>
                        </button>
                      </div>
                      {/* {2} */}
                      <div className="checkbox">
                        <input
                          id="c_2"
                          className="checkbox__input"
                          type="checkbox"
                          value={"מיילים"}
                          checked={selectedValues.includes("מיילים")}
                          onChange={handleCheckBoxChange}
                          name="app-type"
                        />
                        <label htmlFor="c_2" className="checkbox__label">
                          <span className="checkbox__text">מיילים</span>
                        </label>
                      </div>
                      {
                        isMobile ? (
                          <>
                            {/* {3} */}
                            <div className="checkbox">

                              <button type="button" className="btn" onClick={tiktokShare}>
                                <span className="checkbox__text checkbox__text--btn">טיקטוק</span>
                              </button>
                            </div>
                            {/* {4} */}
                            <div className="checkbox">

                              <button type="button" className="btn" onClick={tiktokShare}>
                                <span className="checkbox__text checkbox__text--btn">
                                  רשימת אנשי קשר בסמארטפון
                                </span>
                              </button>
                            </div>
                          </>
                        ) : ""
                      }
                      {/* {5} */}

                      <div className="checkbox">

                        <button type="button" className="btn" onClick={whatsappShare}>
                          <span className="checkbox__text checkbox__text--btn">וואץ אפ</span>
                        </button>
                      </div>

                      {isMobile ? (
                        <div className="checkbox">
                          {/* {6} */}

                          <button type="button" className="btn" onClick={instagramShare}>
                            <span className="checkbox__text checkbox__text--btn">אינסטגרם</span>
                          </button>
                        </div>
                      ) : ""
                      }

                      <div className="checkbox">
                        {/* {7} */}

                        <button type="button" className="btn" onClick={toggleAddSmsPopup}>
                          <span className="checkbox__text checkbox__text--btn">SMS</span>
                        </button>
                        {
                          isOpenSmsPopup ? (
                            <AddSmsPhones toggleAddSmsPopup={toggleAddSmsPopup} whishlistId={whishlistId} />
                          ) : ""
                        }

                      </div>
                    </div>
                    <p className="credentials-section__text">
                      or use invite link
                      <br />
                      <p style={{ color: "#3c9e1c" }} onClick={(e) => {
                        e.preventDefault()
                        browserShare(e)
                      }}>
                        {finalInviteUrl}
                      </p>
                      <br />
                      <button className="btn copy-link" onClick={(e) => copyLinkToClipboard(e, finalInviteUrl)}>
                        Copy link
                      </button>
                    </p>

                    <p className="form-credentials-section__text-under-checks">
                      שים לב, רשימת המתנות שלך תהיה חשופה לעיני חבריך ברגע
                      שתזמין אותם לאתר
                    </p>
                    <div className="form-credentials-section__buttons-container">
                      <button type="submit" className="btn">
                        {languageToggle ? languageToggle.newEvent[language].btn4 : "להזמנת חברים לרשימת המשאלות"}
                      </button>
                    </div>
                  </form>
                </div>
              </>
            )}

            {createEventStep === 4 && (
              <>
                <button
                  type="button"
                  onClick={goBack}
                  className="credentials-section__prev-btn"
                >
                  <img src={prevBtnImage} alt="arrow left black" />
                </button>
                <h1 className="credentials-section__title credentials-section__title_icon-top">
                  מזל טוב
                </h1>
                <h2 className="credentials-section__subtitle">
                  בחרת בקטגורית {params?.eventTypeName} מלא/י שם ותאריך לידה
                </h2>
                <div className="credentials-section__form-wrapper">
                  <form
                    action="#"
                    onSubmit={submitStep4Form}
                    className="credentials-section__form form-credentials-section"
                  >
                    <label
                      htmlFor="form-credentials-section__fullname"
                      className="form-credentials-section__input-wrapper input-wrapper"
                    >
                      <span className="input-title">שם אירוע*</span>
                      <input
                        autoComplete="disable-autofill"
                        type="text"
                        name="fullname"
                        id="form-credentials-section__fullname"
                        className="input"
                        defaultValue={step0Info.fullname}
                        onInput={(e) => {
                          if (
                            e.target
                              .closest("label")
                              .classList.contains("input-error")
                          ) {
                            validateTextInput(e.target, 3);
                          }
                        }}
                      />
                      <span className="error-text">minimum 3 chars</span>
                    </label>
                    <div className="form-edit-profile__birthday birthday-field">
                      <p className="birthday-field__birthday-title form-title">
                        תאריך האירוע
                      </p>
                      <div className="birthday-field__birthday-body">
                        <input
                          type="date"
                          className="birthday-field__hidden-main-field"
                          value={step0SelectedDate.date}
                          onChange={(e) => {
                            handleStep0DateChange(
                              e.target,
                              step0DayRef.current,
                              step0MonthRef.current,
                              step0YearRef.current
                            );
                          }}
                        />
                        <label
                          htmlFor="form-credentials-section__birthday-year"
                          className="birthday-field__input-wrapper input-wrapper"
                        >
                          <span className="input-title">שנה</span>
                          <input
                            autoComplete="disable-autofill"
                            type="number"
                            name="birthday-year"
                            id="form-credentials-section__birthday-year"
                            value={step0SelectedDate.year}
                            onChange={(event) => { }}
                            ref={step0YearRef}
                            className="input"
                          />
                          <span
                            className="error-text"
                            style={{
                              position: "absolute",
                              top: "100%",
                              right: 0,
                              width: "auto",
                              whiteSpace: "nowrap",
                            }}
                          >
                            You must select a date
                          </span>
                        </label>
                        <label
                          htmlFor="form-credentials-section__birthday-month"
                          className="birthday-field__input-wrapper input-wrapper"
                        >
                          <span className="input-title">חודש</span>
                          <input
                            autoComplete="disable-autofill"
                            type="number"
                            name="birthday-month"
                            id="form-credentials-section__birthday-month"
                            ref={step0MonthRef}
                            className="input"
                            value={step0SelectedDate.month}
                            onChange={(event) => { }}
                          />
                        </label>
                        <label
                          htmlFor="form-credentials-section__birthday-day"
                          className="birthday-field__input-wrapper input-wrapper"
                        >
                          <span className="input-title">יום</span>
                          <input
                            autoComplete="disable-autofill"
                            type="number"
                            name="birthday-day"
                            ref={step0DayRef}
                            id="form-credentials-section__birthday-day"
                            className="input"
                            value={step0SelectedDate.day}
                            onChange={(event) => { }}
                          />
                        </label>
                      </div>
                    </div>
                    <div className="form-credentials-section__buttons-container">
                      <button type="submit" className="btn">
                        לשלב הבא
                      </button>
                    </div>
                  </form>
                </div>
              </>
            )}

            <div className="credentials-section__progress">
              <div className="percent" style={{ right: progress + "%" }}>
                {`${progress}%`}
              </div>
              <div className="bar">
                <div className="background" />
                <div
                  className="percent-line"
                  style={{ width: progress + "%" }}
                />
              </div>
            </div>
            {isDecor && (
              <div className="credentials-section__decor">
                <img src={mainDecor} alt="decor" />
              </div>
            )}
          </div>
        </div>
      </section>
    </main>
  );
}

export default CreateEvent;
