import React, { useEffect, useRef, useState } from "react";

import {
  mainBgImage,
  mainBgImageNoPeople,
  orangeDecor,
  prevBtnImage,
} from "../Assets/images";
import { NavLink } from "react-router-dom";

function ChooseLoginOrRegister({
  updateLoginOrRegister,
  updateStepNumber,
  updateColorMode,
  updateTopNavButtons,
  updateMainBgImage,
  updateRegisterStep,
  updateLoginStep,
  updateLoginWith,
  progress,
  updateProgress,
  updateGreenMobile,
  isRedirectPresent,
}) {
  const [isRegisterChecked, setRegisterCheck] = useState(false);
  const [isLoginChecked, setLoginCheck] = useState(false);

  const setRegister = () => {
    updateLoginOrRegister({
      choose: false,
      register: true,
      login: false,
    });
    updateMainBgImage(mainBgImage, mainBgImageNoPeople);
    updateColorMode(false);
    updateStepNumber(3);
    updateTopNavButtons(true);
    updateRegisterStep({
      userDetails: true,
      verifyPhone: false,
      acceptTerms: false,
      afterRegister: false,
    });
    updateProgress(5);
    updateGreenMobile(false);
    window.scrollTo(0, 0);

  };
  const setLogin = () => {

    updateLoginOrRegister({
      choose: false,
      register: false,
      login: true,
    });
    updateMainBgImage(mainBgImage, mainBgImageNoPeople);
    updateColorMode(false);
    updateStepNumber(3);
    updateTopNavButtons(true);
    updateLoginStep({
      enterPhone: true,
      loginWithSms: false,
    });
    updateLoginWith({
      loginWithPhone: true,
      loginWithSms: false,
    });
    updateProgress(5);
    updateGreenMobile(false);
    window.scrollTo(0, 0);

  };

  const history = window.history;

  function goBack() {
    history.back();
  }

  return (
    <>
      <button onClick={goBack} className="credentials-section__prev-btn">
        <img src={prevBtnImage} alt="arrow left black" />
      </button>
      <h1 className="credentials-section__title">כניסה למערכת</h1>
      <div className="credentials-section__form-wrapper">
        <form
          action="#"
          className="credentials-section__form form-credentials-section"
        >
          <div className="long-checkbox">
            <input
              id="c_1"
              className="long-checkbox__input"
              type="radio"
              value="1"
              name="register-or-login"
              onChange={setRegister}
            />
            <label htmlFor="c_1" className="long-checkbox__label">
              <span className="long-checkbox__text">
                אני חבר/ת חדש רוצה להרשם
              </span>
            </label>
          </div>
          <div className="long-checkbox">
            <input
              id="c_2"
              className="long-checkbox__input"
              type="radio"
              value="2"
              name="register-or-login"
              onChange={setLogin}
            />
            <label htmlFor="c_2" className="long-checkbox__label">
              <span className="long-checkbox__text">אני כבר רשומ/ה</span>
            </label>
          </div>
        </form>
      </div>

      <div className="credentials-section__decor">
        <img src={orangeDecor} alt="decor" />
      </div>
    </>
  );
}

export default ChooseLoginOrRegister;
