import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import TopBar from "../Components/TopBar";
import CategoryCard from "../Components/CategoryCard";
import { API_DOMAIN, API_DOMAIN_IMG } from "../Libs/config";
import {
  NavLink,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom/dist";
import CreateEventPopup from '../Popups/CreateEventPopup'

import { eventTypeImgNoPeople1 } from "../Assets/images";
import ProductsFilter from "../Components/products-filter";

import SingleEventType from "../Components/single-event-type";

const tabButtons = [
  { text: "אירוע חדש", id: 0 },
  { text: "האירועים שלי", id: 1 },
  { text: "היסטוריית אירועים", id: 2 },
  { text: "אירועים שהוזמנתי", id: 3 },
];

export default function MyEvents({
  currUserObject,
  currWishlist,
  setCurrWishlist,
  setWhishlistArray,
  whishlistArray,
  myEventsActiveTab,
  setMyEventsActiveTab,
  updateCurrWishCategories,
  filterToggle,
  setFilterObject
}) {


  const [wishlistCategories, setWishCategories] = useState([])
  const [isCreatePopup, setIsCreatePopup] = useState(false)
  const location = useLocation();
  const [eventData, setEventData] = useState({
    englishName: ""
  })

  const createPopupRef = useRef(null)

  // console.log(location)

  useEffect(() => {
    if (isCreatePopup) {
      document.documentElement.classList.add("popup-show", "loaded");
    } else {
      document.documentElement.classList.remove("popup-show", "loaded");
    }

  }, [isCreatePopup])


  const toggleCreatePopup = (data, eventData) => {
    if (eventData) {
      setEventData(eventData)
    }
    // console.log('eventData', eventData)
    setIsCreatePopup(prev => {
      if (data) {
        return data;
      } else {
        return !prev;
      }
    })
  }

  useEffect(() => {
    if (location?.state?.openCategories) {
      changeTabHandler(0)
    }
    if (location?.state?.openTabNumber) {
      changeTabHandler(1)
      switch (location?.state?.openTabNumber) {
        case 0:
          changeTabHandler(0)
          break;
        case 1:
          changeTabHandler(1)
          break;
        case 2:
          changeTabHandler(2)
          break;
        case 3:
          changeTabHandler(3)
          break;
        default:
          break;
      }
    }

    return () => {

    }
  }, [location?.state])


  // Extract the state from the history location object

  // Extract the state from the location object


  const navigate = useNavigate();


  useEffect(() => {
    getWishlistList();
  }, []);

  function editWhishListItem() {
    navigate("/update-wishlist");
  }

  function goToWhilistSelect(whishlistId) {
    axios
      .get(
        `${API_DOMAIN}/api/ProductCategory/GetWishlistObject?id=${whishlistId}`
      )
      .then((response) => {
        console.log(response.data);
        if (response.data.result) {
          // setLoading(false);
          console.log("whishlist object", response.data);
          setCurrWishlist({
            whishlistItemsList: response.data.whishlistItemsList,
            whishlistObject: response.data.whishlistObject,
            whishlistItemsDetailsList: response.data.whishlistItemsDetailsList,
          });
          navigate(`/all-categories-new-event?wishlistId=${whishlistId}`);
        } else {
          //setLoading(false);
          alert("Something went wrong");
        }
      })
      .catch((error) => {
        console.error(error);
        //setLoading(false);
        alert("Error, please try again");
      });
  }

  function getWishlistList() {
    //console.log("whishlistDebug trying to get  data user Obj", currUserObject.currUserObject?.user?.id);
    // console.log(
    //   "whishlistDebug trying to get  data user Obj",
    //   currUserObject.currUserObject
    // );

    axios
      .get(
        // `${API_DOMAIN}/api/ProductCategory/GetWishlistList?userId=${currUserObject?.currUserObject?.user?.id}`
        `${API_DOMAIN}/api/ProductCategory/GetMyWishlists?userId=${currUserObject?.user?.id}`
      )
      .then((response) => {
        if (response.data.result) {
          // setLoading(false);
          console.log("whishlist list", response.data);
          if (response.data.wishlists.length)
            setWhishlistArray((prevObj) => {
              const newObj = {
                ...prevObj,
                myWishlists: response.data.wishlists,
              };
              return newObj;
            });
        } else {
          //setLoading(false);
          alert("Something went wrong");
        }
      })
      .catch((error) => {
        console.error(error);
        //setLoading(false);
        alert("Error, please try again");
      });

    axios
      .get(
        `${API_DOMAIN}/api/ProductCategory/GetHistoryWishlists?userId=${currUserObject?.user?.id}`
      )
      .then((response) => {
        if (response.data.result) {
          // setLoading(false);
          console.log("history wishlists", response.data);
          if (response.data.wishlists.length)
            setWhishlistArray((prevObj) => {
              const newObj = {
                ...prevObj,
                historyWishlists: response.data.wishlists,
              };
              return newObj;
            });
        } else {
          //setLoading(false);
          alert("Something went wrong");
        }
      })
      .catch((error) => {
        console.error(error);
        //setLoading(false);
        alert("Error, please try again");
      });

    axios
      .get(
        `${API_DOMAIN}/api/ProductCategory/GetInvitedWishlists?userId=${currUserObject?.user?.id}`
      )
      .then((response) => {
        if (response.data.result) {
          // setLoading(false);
          console.log("invited wishlists", response.data);
          if (response.data.wishlists.length)
            setWhishlistArray((prevObj) => {
              const newObj = {
                ...prevObj,
                friendsWishlists: response.data.wishlists,
              };
              return newObj;
            });
        } else {
          //setLoading(false);
          alert("Something went wrong");
        }
      })
      .catch((error) => {
        console.error(error);
        //setLoading(false);
        alert("Error, please try again");
      });
  }

  async function getWishListCategories() {
    try {
      const response = await axios.get(`${API_DOMAIN}/api/ProductCategory/getWishlistCategories`);
      const { data } = response;
      if (data.result) {
        // console.log('handleGetMainCategories: ', data)
        setWishCategories(data.mainCategoriesList)
      }
    } catch (error) {
      alert('Something went wrong getWishListCategories', error)
    }
  }

  useEffect(() => {
    getWishListCategories()
  }, []);

  const goToCartByWishlist = (whishlistId) => {
    axios
      .get(
        `${API_DOMAIN}/api/ProductCategory/GetWishlistObject?id=${whishlistId}`
      )
      .then((response) => {
        console.log(response.data);
        if (response.data.result) {
          // setLoading(false);
          console.log("whishlist object", response.data);
          setCurrWishlist({
            whishlistItemsList: response.data.whishlistItemsList,
            whishlistObject: response.data.whishlistObject,
            whishlistItemsDetailsList: response.data.whishlistItemsDetailsList,
          });

          navigate(`/cart/${whishlistId}`);
        } else {
          //setLoading(false);
          alert("Something went wrong");
        }
      })
      .catch((error) => {
        console.error(error);
        //setLoading(false);
        alert("Error, please try again");
      });
  };

  const goToChooseGift = (whishlistId) => {
    axios
      .get(
        `${API_DOMAIN}/api/ProductCategory/GetWishlistObject?id=${whishlistId}`
      )
      .then((response) => {
        console.log(response.data);
        if (response.data.result) {
          // setLoading(false);
          console.log("whishlist object", response.data);
          setCurrWishlist({
            whishlistItemsList: response.data.whishlistItemsList,
            whishlistObject: response.data.whishlistObject,
            whishlistItemsDetailsList: response.data.whishlistItemsDetailsList,
          });

          navigate("/choose-present-or-send-money");
        } else {
          //setLoading(false);
          alert("Something went wrong");
        }
      })
      .catch((error) => {
        console.error(error);
        //setLoading(false);
        alert("Error, please try again");
      });
  };

  const changeTabHandler = (id) => {
    setMyEventsActiveTab(id);
  };

  const removeMyWishlist = (wishlistId) => {
    axios
      .get(
        `${API_DOMAIN}/api/ProductCategory/RemoveWishlist?userId=${currUserObject.user.id}&wishListId=${wishlistId}`
      )
      .then((response) => {
        if (response.data.result) {
          // setLoading(false);
          setWhishlistArray((prevObj) => {
            const newMyWishlistsArray = whishlistArray.myWishlists.filter(
              (item) => item.id !== wishlistId
            );
            const newObj = {
              ...prevObj,
              myWishlists: newMyWishlistsArray,
            };
            return newObj;
          });
        } else {
          //setLoading(false);
          alert("Something went wrong");
        }
      })
      .catch((error) => {
        console.error(error);
        //setLoading(false);
        alert("Error, please try again");
      });
  };

  const removeHistoryWishlist = (wishlistId) => {
    axios
      .get(
        `${API_DOMAIN}/api/ProductCategory/RemoveWishlist?userId=${currUserObject.user.id}&wishListId=${wishlistId}`
      )
      .then((response) => {
        if (response.data.result) {
          // setLoading(false);
          setWhishlistArray((prevObj) => {
            const newHistoryWishlistsArray =
              whishlistArray.historyWishlists.filter(
                (item) => item.id !== wishlistId
              );
            const newObj = {
              ...prevObj,
              historyWishlists: newHistoryWishlistsArray,
            };
            return newObj;
          });
        } else {
          //setLoading(false);
          alert("Something went wrong");
        }
      })
      .catch((error) => {
        console.error(error);
        //setLoading(false);
        alert("Error, please try again");
      });
  };

  const removeFriendWishlist = (wishlistId) => {
    axios
      .get(
        `${API_DOMAIN}/api/ProductCategory/RemoveInviteWishlist?userId=${currUserObject.user.id}&wishListId=${wishlistId}`
      )
      .then((response) => {
        if (response.data.result) {
          // setLoading(false);
          setWhishlistArray((prevObj) => {
            const newFriendWishlistsArray =
              whishlistArray.friendsWishlists.filter(
                (item) => item.id !== wishlistId
              );
            const newObj = {
              ...prevObj,
              friendsWishlists: newFriendWishlistsArray,
            };
            return newObj;
          });
        } else {
          //setLoading(false);
          alert("Something went wrong");
        }
      })
      .catch((error) => {
        console.error(error);
        //setLoading(false);
        alert("Error, please try again");
      });
  };

  const shareMyWishlist = (e, wishlistId) => {
    const button = e.target.closest(".share");
    button.style.position = "relative";
    const shareLink =
      window.location.origin + "?inviteWishlistId=" + wishlistId;

    navigator.clipboard
      .writeText(shareLink)
      .then(() => {
        const hint = document.createElement("div");
        hint.classList.add("single-books-item__dropdown-table-item");
        hint.innerHTML = `<p class="value">wishlist link copied to clipboard!</p>`;
        hint.style.cssText =
          "position: absolute; top: 125%; right: 50%; transform: translateX(50%); width: auto; height: auto; object-fit: unset; white-space: nowrap;";
        button.appendChild(hint);
        setTimeout(() => {
          button.removeChild(hint);
        }, 2000);
      })
      .catch((error) => {
        console.error("Ошибка при копировании в буфер обмена:", error);
      });
  };

  const [sortFilter, setSortFilter] = useState({
    sortType: "asc",
  });

  useEffect(() => {
    const sortType = sortFilter.sortType;
    const currentActiveTab = myEventsActiveTab;
    const sortBy = "creationDate";
    // console.log(sortFilter);
    if (
      myEventsActiveTab === 1 ||
      myEventsActiveTab === 2 ||
      myEventsActiveTab === 3
    ) {
      setWhishlistArray((prevObj) => {
        const newObj = prevObj;
        let prevArr = [];
        if (myEventsActiveTab === 1) {
          prevArr = prevObj.myWishlists;
        } else if (myEventsActiveTab === 2) {
          prevArr = prevObj.historyWishlists;
        } else if (myEventsActiveTab === 3) {
          prevArr = prevObj.friendsWishlists;
        }

        let filteredArr = [];
        if (prevArr && prevArr.length) {
          filteredArr = prevArr;

          filteredArr.sort((a, b) => {
            const aValue = String(a[sortBy]);
            const bValue = String(b[sortBy]);
            if (sortType === "asc") {
              return aValue.localeCompare(bValue);
            } else {
              return bValue.localeCompare(aValue);
            }
          });
        }

        if (myEventsActiveTab === 1) {
          return { ...newObj, myWishlists: filteredArr };
        } else if (myEventsActiveTab === 2) {
          return { ...newObj, historyWishlists: filteredArr };
        } else if (myEventsActiveTab === 3) {
          return { ...newObj, friendsWishlists: filteredArr };
        }
      });
    }
  }, [sortFilter, myEventsActiveTab]);

  useEffect(() => {
    const currentUser = localStorage.getItem("currUserObject");
    if (!currentUser) {
      // navigate("/credentials");
    }
  }, []);

  return (
    <main className="choose-event">
      {myEventsActiveTab == 0 && (
        <TopBar
          imgUrl="../img/common/topbar-bg.png"
          text="אירוע חדש"
          icon="img/svg/party-icon.svg"
        />
      )}

      {myEventsActiveTab == 1 && (
        <TopBar
          imgUrl="../img/common/topbar-bg.png"
          text="אירוע חדש"
          icon="img/svg/party-icon.svg"
        />
      )}
      {myEventsActiveTab == 3 && (
        <TopBar
          imgUrl="img/common/topbar-bg-green.png"
          text="אירועים שהוזמנתי"
          icon="img/svg/globe.svg"
        />
      )}
      {myEventsActiveTab == 2 && (
        <TopBar
          imgUrl="img/common/topbar-bg-green.png"
          text="אירועים שהוזמנתי"
          icon="img/svg/globe.svg"
        />
      )}

      <div className="choose-event__breadcrumbs breadcrumbs">
        <div className="breadcrumbs__container">
          <ul className="breadcrumbs__list">
            <li className="breadcrumbs__item">
              <NavLink to="/">דף הבית</NavLink>
            </li>
            {myEventsActiveTab == 0 && (
              <>
                <li className="breadcrumbs__item">
                  <p>all categories</p>
                </li>
              </>
            )}
            {myEventsActiveTab == 1 && (
              <>
                <li className="breadcrumbs__item">
                  <p>my events</p>
                </li>
              </>
            )}
            {myEventsActiveTab == 2 && (
              <>
                <li className="breadcrumbs__item">
                  <p>books</p>
                </li>
              </>
            )}
            {myEventsActiveTab == 3 && (
              <>
                <li className="breadcrumbs__item">
                  <p>books</p>
                </li>
              </>
            )}
          </ul>
        </div>
      </div>

      {
        isCreatePopup ? (
          <CreateEventPopup
            currUserObject={currUserObject}
            currWishlist={currWishlist}
            setCurrWishlist={setCurrWishlist}
            toggleCreatePopup={toggleCreatePopup}
            eventData={eventData}
            setFilterObject={setFilterObject}
            createPopupRef={createPopupRef}
          />
        ) : ""
      }

      <section className="choose-event__main main-choose-event">
        <div className="main-choose-event__container">
          <div className="main-choose-event__header">
            <div className="main-choose-event__navigation">
              <ul className="main-choose-event__nav-list">
                {tabButtons?.map((btn, index) => currUserObject || index == 0 ? (
                  <li key={btn.id} className="main-choose-event__nav-item">
                    <button
                      onClick={() => changeTabHandler(btn.id)}
                      type="button"
                      className={`main-choose-event__button ${btn.id === myEventsActiveTab ? "active" : ""
                        }`}
                    >
                      {btn.text}
                    </button>
                  </li>
                ) : ""
                )}
              </ul>
            </div>
            {/* <div className="main-choose-event__filter">
              <p className="text">מיין</p>
              <button
                type="button"
                className="arrow bottom"
                onClick={() =>
                  setSortFilter({
                    sortType: "desc",
                  })
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17.117"
                  height="9.115"
                  viewBox="0 0 17.117 9.115"
                >
                  <g
                    id="Сгруппировать_912"
                    data-name="Сгруппировать 912"
                    transform="translate(19.135 -7.296) rotate(90)"
                  >
                    <g
                      id="Сгруппировать_540"
                      data-name="Сгруппировать 540"
                      transform="translate(14.998 17.723) rotate(180)"
                    >
                      <line
                        id="Линия_434"
                        data-name="Линия 434"
                        y1="7.147"
                        x2="6.289"
                        transform="translate(0)"
                        fill="none"
                        stroke="#000"
                        strokeLinecap="round"
                        strokeWidth="2"
                      />
                      <path
                        id="Контур_6897"
                        data-name="Контур 6897"
                        d="M0,0,6.289,6.861"
                        transform="translate(0 7.432)"
                        fill="none"
                        stroke="#000"
                        strokeLinecap="round"
                        strokeWidth="2"
                      />
                    </g>
                  </g>
                </svg>
              </button>
              <button
                type="button"
                className="arrow top"
                onClick={() =>
                  setSortFilter({
                    sortType: "asc",
                  })
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17.117"
                  height="9.115"
                  viewBox="0 0 17.117 9.115"
                >
                  <g
                    id="Сгруппировать_913"
                    data-name="Сгруппировать 913"
                    transform="translate(19.708 -6.837) rotate(90)"
                  >
                    <g
                      id="Сгруппировать_539"
                      data-name="Сгруппировать 539"
                      transform="translate(8.25 4.002)"
                    >
                      <line
                        id="Линия_434"
                        data-name="Линия 434"
                        y1="7.147"
                        x2="6.289"
                        transform="translate(0 0)"
                        fill="none"
                        stroke="#000"
                        strokeLinecap="round"
                        strokeWidth="2"
                      />
                      <path
                        id="Контур_6897"
                        data-name="Контур 6897"
                        d="M0,0,6.289,6.861"
                        transform="translate(0 7.432)"
                        fill="none"
                        stroke="#000"
                        strokeLinecap="round"
                        strokeWidth="2"
                      />
                    </g>
                  </g>
                </svg>
              </button>
            </div> */}
          </div>

          {myEventsActiveTab == 0 && (
            <>
              {/* <ProductsFilter
                filterToggle={filterToggle}
                allCategoriesList={allCategoriesList}
                // choosedCategoriesList={choosedCategoriesList}
                // setChoosedCategoriesList={setChoosedCategoriesList}
                filterObject={filterObject}
                setFilterObject={setFilterObject}
                filterFunction={handleFilterChecks}
                manufacturers={manufacturers}
                rangePriceMinMax={rangePriceMinMax} 
                /> */}
              <ul className="main-all-categories__list">
                {wishlistCategories && wishlistCategories.map((eventType, index) => (
                  <li key={index} className="main-choose-event__list-item">
                    <SingleEventType
                      eventTypeImg={eventType.categoryImage}
                      eventTypeImgNoPeople={eventTypeImgNoPeople1}
                      eventTypeIcon={eventType.categoryIcon}
                      eventTypeName={eventType.categoryName}
                      eventTypeNameEng={eventType.englishName}
                      updateCurrWishCategories={updateCurrWishCategories}
                      event={eventType}
                      toggleCreatePopup={toggleCreatePopup}
                    />
                  </li>
                ))}
              </ul>

            </>

          )}

          {myEventsActiveTab == 1 && (
            <div className="main-choose-event__body">
              <ul className="main-choose-event__my-events-list">
                {whishlistArray.myWishlists.map((wishlist, index) => {
                  const wishlistObj = JSON.parse(wishlist.wishListObj);
                  const imgUrl = wishlistObj?.categoryImage ? API_DOMAIN_IMG + wishlistObj?.categoryImage : "img/choose-event-type/1.png";

                  return (
                    <li
                      key={wishlist.id}
                      className="main-choose-event__my-events-item"
                    >
                      <div className="my-event-single">
                        <div className="my-event-single__content">
                          <div className="my-event-single__image-ibg">
                            <img
                              // src="img/choose-event-type/1.png"
                              src={imgUrl}
                              className="img-people"
                              alt="1"
                            />
                            <img
                              src="img/no-people-images/choose-event-type/1.png"
                              className="img-no-people"
                              alt="1"
                            />
                          </div>
                          <div className="my-event-single__text-content">
                            <h3 className="my-event-single__title">
                              {wishlist.name}
                            </h3>
                            <p className="my-event-single__date">
                              {wishlist.eventDate
                                .slice(0, 10)
                                .replaceAll("-", "/")}
                            </p>
                            {console.log('wishlist__', wishlist)}

                            <div className="my-event-single__actions">
                              {wishlist.isRemovable && (
                                <button
                                  className="button delete"
                                  onClick={() => removeMyWishlist(wishlist.id)}
                                >
                                  <svg
                                    id="Trash_can"
                                    data-name="Trash can"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xlink="http://www.w3.org/1999/xlink"
                                    width="24.153"
                                    height="28.357"
                                    viewBox="0 0 24.153 28.357"
                                  >
                                    <defs>
                                      <clipPath id="clip-path">
                                        <rect
                                          id="Прямоугольник_1981"
                                          data-name="Прямоугольник 1981"
                                          width="22.385"
                                          height="19.451"
                                          transform="translate(0 0)"
                                          fill="none"
                                          stroke="#707070"
                                          strokeWidth="1.4"
                                        />
                                      </clipPath>
                                      <clipPath id="clip-path-2">
                                        <rect
                                          id="Прямоугольник_1983"
                                          data-name="Прямоугольник 1983"
                                          width="14.146"
                                          height="6.189"
                                          fill="none"
                                          stroke="#707070"
                                          strokeWidth="1.4"
                                        />
                                      </clipPath>
                                    </defs>
                                    <g
                                      id="Прямоугольник_1980"
                                      data-name="Прямоугольник 1980"
                                      transform="translate(0 5.78)"
                                      fill="none"
                                      stroke="#707070"
                                      strokeWidth="1.4"
                                    >
                                      <rect
                                        width="24.153"
                                        height="3.537"
                                        rx="1.768"
                                        stroke="none"
                                      />
                                      <rect
                                        x="0.7"
                                        y="0.7"
                                        width="22.753"
                                        height="2.137"
                                        rx="1.068"
                                        fill="none"
                                      />
                                    </g>
                                    <line
                                      id="Линия_491"
                                      data-name="Линия 491"
                                      x2="1.603"
                                      y2="11.543"
                                      transform="translate(6.809 11.427)"
                                      fill="none"
                                      stroke="#707070"
                                      strokeLinecap="round"
                                      strokeWidth="1.4"
                                    />
                                    <line
                                      id="Линия_492"
                                      data-name="Линия 492"
                                      y2="11.543"
                                      transform="translate(11.96 11.427)"
                                      fill="none"
                                      stroke="#707070"
                                      strokeLinecap="round"
                                      strokeWidth="1.4"
                                    />
                                    <line
                                      id="Линия_493"
                                      data-name="Линия 493"
                                      x1="1.483"
                                      y2="11.291"
                                      transform="translate(15.292 11.412)"
                                      fill="none"
                                      stroke="#707070"
                                      strokeLinecap="round"
                                      strokeWidth="1.4"
                                    />
                                    <g
                                      id="Группа_масок_833"
                                      data-name="Группа масок 833"
                                      transform="translate(0.884 8.906)"
                                      clipPath="url(#clip-path)"
                                    >
                                      <g
                                        id="Контур_8526"
                                        data-name="Контур 8526"
                                        transform="translate(8.235 -3.346)"
                                        fill="none"
                                      >
                                        <path
                                          d="M-5.613,0H11.26a1.47,1.47,0,0,1,1.387,1.541L11.029,20.293a1.47,1.47,0,0,1-1.387,1.541H-4a1.47,1.47,0,0,1-1.387-1.541L-7,1.541A1.47,1.47,0,0,1-5.613,0Z"
                                          stroke="none"
                                        />
                                        <path
                                          d="M -5.562511444091797 1.399997711181641 C -5.575593948364258 1.419382095336914 -5.592249870300293 1.45405387878418 -5.597964286804199 1.504474639892578 L -3.982046127319336 20.23285675048828 L -3.982046127319336 20.29314804077148 C -3.982046127319336 20.36381530761719 -3.960605621337891 20.41061401367188 -3.944553375244141 20.43439674377441 L 9.591161727905273 20.43439674377441 C 9.607213973999023 20.41061401367188 9.628654479980469 20.36381530761719 9.628654479980469 20.29314804077148 L 9.628654479980469 20.23285675048828 L 11.24457263946533 1.504476547241211 C 11.23885726928711 1.454051971435547 11.22219467163086 1.419380187988281 11.2091121673584 1.399997711181641 L -5.562511444091797 1.399997711181641 M -5.613185882568359 -1.9073486328125e-06 L 11.25978469848633 -1.9073486328125e-06 C 12.0257043838501 -1.9073486328125e-06 12.64660453796387 0.6900367736816406 12.64660453796387 1.54124641418457 L 11.02865409851074 20.29314804077148 C 11.02865409851074 21.14435768127441 10.40775394439697 21.83439636230469 9.641834259033203 21.83439636230469 L -3.99522590637207 21.83439636230469 C -4.76114559173584 21.83439636230469 -5.382045745849609 21.14435768127441 -5.382045745849609 20.29314804077148 L -6.999996185302734 1.54124641418457 C -6.999996185302734 0.6900367736816406 -6.379105567932129 -1.9073486328125e-06 -5.613185882568359 -1.9073486328125e-06 Z"
                                          stroke="none"
                                          fill="#707070"
                                        />
                                      </g>
                                    </g>
                                    <g
                                      id="Группа_масок_834"
                                      data-name="Группа масок 834"
                                      transform="translate(5.004 0)"
                                      clipPath="url(#clip-path-2)"
                                    >
                                      <g
                                        id="Прямоугольник_1982"
                                        data-name="Прямоугольник 1982"
                                        transform="translate(0 2.652)"
                                        fill="none"
                                        stroke="#707070"
                                        strokeWidth="1.4"
                                      >
                                        <rect
                                          width="13.262"
                                          height="5.305"
                                          rx="2.652"
                                          stroke="none"
                                        />
                                        <rect
                                          x="0.7"
                                          y="0.7"
                                          width="11.862"
                                          height="3.905"
                                          rx="1.952"
                                          fill="none"
                                        />
                                      </g>
                                    </g>
                                  </svg>
                                </button>
                              )}

                              <button
                                className="button share"
                                onClick={(e) => shareMyWishlist(e, wishlist.id)}
                              >
                                <svg
                                  id="Сгруппировать_1791"
                                  data-name="Сгруппировать 1791"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="25.743"
                                  height="31.05"
                                  viewBox="0 0 25.743 31.05"
                                >
                                  <path
                                    id="Контур_8542"
                                    data-name="Контур 8542"
                                    d="M13.065,25.983a.472.472,0,0,1-.222,0l-8.919-5.2h-.1l-.18-.125a3.814,3.814,0,0,1-1.554-3.121V16.274a1.706,1.706,0,0,1,0-.4A5.077,5.077,0,0,1,7.087,11.71h6.935a5.049,5.049,0,0,1,3.995,1.942.529.529,0,1,1-.832.652,3.981,3.981,0,0,0-3.163-1.54H7.087a4.023,4.023,0,0,0-3.953,3.26,1.276,1.276,0,0,0,0,.264V17.4a2.774,2.774,0,0,0,1.124,2.261l.125.1h0L13.3,24.9a.527.527,0,0,1,.194.721.513.513,0,0,1-.43.361Z"
                                    transform="translate(-2.081 0.379)"
                                    fill="#707070"
                                  />
                                  <path
                                    id="Контур_8541"
                                    data-name="Контур 8541"
                                    d="M9.631,13.835h0A5.424,5.424,0,0,1,9.6,3h0a5.424,5.424,0,1,1,0,10.833Zm0-9.71A4.116,4.116,0,0,0,5.567,8.481a4.116,4.116,0,0,0,4.064,4.355h.291a4.355,4.355,0,1,0,0-8.711H9.631Z"
                                    transform="translate(-1.158 -2.995)"
                                    fill="#707070"
                                  />
                                  <path
                                    id="Контур_8547"
                                    data-name="Контур 8547"
                                    d="M39.436,25.22a4.466,4.466,0,0,0-3.149-.9,2.774,2.774,0,0,1-.472,0,2.885,2.885,0,0,1-2.774-2.774.68.68,0,0,1,0-.236v-.194a2.913,2.913,0,0,1,2.9-2.9h1.29a3.093,3.093,0,0,0,.874,0,3.343,3.343,0,0,0-.9-6.575,3.44,3.44,0,0,0-3.343,3.218,3.662,3.662,0,0,1-1.235,2.414,3.149,3.149,0,0,1-1.193.652,6.935,6.935,0,0,1-1.775.125,3.357,3.357,0,0,0,0,6.7,4.425,4.425,0,0,1,2.774.624A3.482,3.482,0,0,1,33.624,27c.236.652.194,1.387.458,2a3.343,3.343,0,1,0,5.4-3.676Z"
                                    transform="translate(-15.623 -0.792)"
                                    fill="none"
                                    stroke="#707070"
                                    strokeWidth="1.5"
                                  />
                                </svg>
                              </button>
                              <button className="button notify">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24.851"
                                  height="31.251"
                                  viewBox="0 0 24.851 31.251"
                                >
                                  <g
                                    id="Сгруппировать_1792"
                                    data-name="Сгруппировать 1792"
                                    transform="translate(0.769 0.788)"
                                  >
                                    <g
                                      id="Artboard_7"
                                      data-name="Artboard 7"
                                      transform="translate(0 0)"
                                    >
                                      <path
                                        id="Контур_10012"
                                        data-name="Контур 10012"
                                        d="M30.472,28.909a1.418,1.418,0,0,0-.6-.716c-3.316-1.846-2.656-3.182-3.178-9.164-.476-5.3-1.873-9.069-5.926-9.88a.2.2,0,0,1-.143-.125.213.213,0,0,1,.02-.193,2.466,2.466,0,0,0,.4-1.432,2.184,2.184,0,0,0-1.038-1.993,2.026,2.026,0,0,0-2.185.058,2.193,2.193,0,0,0-.937,2.046,2.382,2.382,0,0,0,.4,1.321.213.213,0,0,1,.025.188.2.2,0,0,1-.133.13c-4.068.811-5.465,4.582-5.941,9.88-.522,5.982.138,7.319-3.178,9.164a1.4,1.4,0,0,0-.583.716,2,2,0,0,0,.131,1.817,1.861,1.861,0,0,0,1.527.9H28.8a1.86,1.86,0,0,0,1.538-.9A2,2,0,0,0,30.472,28.909Z"
                                        transform="translate(-7.317 -5.114)"
                                        fill="none"
                                        stroke="#707070"
                                        strokeWidth="1.5"
                                      />
                                      <path
                                        id="Контур_10013"
                                        data-name="Контур 10013"
                                        d="M18.311,22.45a2.7,2.7,0,0,1-2.934,2.376A2.709,2.709,0,0,1,12.43,22.45Z"
                                        transform="translate(-3.722 4.862)"
                                        fill="none"
                                        stroke="#707070"
                                        strokeWidth="1.5"
                                      />
                                    </g>
                                  </g>
                                </svg>
                              </button>
                              <button
                                type="button"
                                onClick={() => goToWhilistSelect(wishlist.id)}
                                className="button gift"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="27.868"
                                  height="30.872"
                                  viewBox="0 0 27.868 30.872"
                                >
                                  <g
                                    id="Layer_6"
                                    data-name="Layer 6"
                                    transform="translate(0.1 0.117)"
                                  >
                                    <path
                                      id="Контур_7573"
                                      data-name="Контур 7573"
                                      d="M35.9,13.985H32.775a3.085,3.085,0,0,0,.613-.682,3.122,3.122,0,0,0,.306-2.74.069.069,0,0,0-.032-.054c-.032-.021,0-.038,0-.054A3.106,3.106,0,0,0,31.6,8.623a3.186,3.186,0,0,0-2.149.193,2.88,2.88,0,0,0,.027-1.709,3.079,3.079,0,0,0-1.87-2.02h0a3.09,3.09,0,0,0-2.73.344,2.929,2.929,0,0,0-.838.87,2.928,2.928,0,0,0-.838-.876,3.1,3.1,0,0,0-2.73-.344h0a3.09,3.09,0,0,0-1.875,2.026,2.928,2.928,0,0,0,.027,1.666,3.224,3.224,0,0,0-2.047-.145A3.106,3.106,0,0,0,14.51,10.46s0,.038,0,.054-.027.032-.032.054a3.122,3.122,0,0,0,.306,2.74,3.084,3.084,0,0,0,.613.682H11.566A1.666,1.666,0,0,0,9.9,15.657v3.31a1.66,1.66,0,0,0,1.537,1.65V33.9A1.671,1.671,0,0,0,13.1,35.565H34.365A1.671,1.671,0,0,0,36.031,33.9V20.611a1.655,1.655,0,0,0,1.537-1.65v-3.31A1.666,1.666,0,0,0,35.9,13.985ZM21.689,15.168h4.3v19.21h-4.3Zm9.586-5.406a1.945,1.945,0,0,1,1.274,1.107l.032.043v.048a1.956,1.956,0,0,1-.193,1.687,2.359,2.359,0,0,1-1.929.978,34.455,34.455,0,0,1-4.917,0c.4-.376.828-.817,1.263-1.29.672-.623,1.462-1.306,2.38-2.031a2.359,2.359,0,0,1,2.09-.543ZM24.6,8.182a2.192,2.192,0,0,1,.876-1.838,1.94,1.94,0,0,1,2.966,1.075,2.149,2.149,0,0,1-.51,1.967c-.419.537-.828,1.037-1.22,1.488-.742.656-1.424,1.3-1.977,1.886A31.291,31.291,0,0,1,24.6,8.182ZM19.626,7.4a1.934,1.934,0,0,1,2.966-1.075,2.192,2.192,0,0,1,.881,1.859,32.166,32.166,0,0,1-.124,4.5c-.629-.656-1.4-1.376-2.241-2.1-.317-.371-.639-.763-.967-1.193A2.149,2.149,0,0,1,19.626,7.4ZM16.88,9.762a2.359,2.359,0,0,1,2.09.57c.7.537,1.322,1.075,1.881,1.574.586.661,1.155,1.257,1.682,1.752a34.31,34.31,0,0,1-4.836,0,2.359,2.359,0,0,1-1.929-.978,1.956,1.956,0,0,1-.193-1.687s0-.032,0-.048l.032-.043a1.945,1.945,0,0,1,1.279-1.139Zm-5.819,9.2v-3.31a.484.484,0,0,1,.505-.484h8.941v4.3H11.566a.484.484,0,0,1-.505-.505ZM12.6,33.9V20.627h7.91V34.383H13.081A.489.489,0,0,1,12.6,33.9Zm21.768.484H27.149V20.627h7.7V33.9A.484.484,0,0,1,34.365,34.383Zm2.02-15.422a.484.484,0,0,1-.484.484H27.149V15.168H35.9a.484.484,0,0,1,.484.484Z"
                                      transform="translate(-9.9 -4.909)"
                                      fill="#707070"
                                      stroke="#707070"
                                      strokeWidth="0.2"
                                    />
                                  </g>
                                </svg>
                              </button>
                              <button
                                className="button favorites"
                                onClick={() => goToCartByWishlist(wishlist.id)}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="26.406"
                                  height="32.951"
                                  viewBox="0 0 26.406 32.951"
                                >
                                  <g
                                    id="Компонент_360_1"
                                    data-name="Компонент 360 – 1"
                                    transform="translate(0.751 0.75)"
                                  >
                                    <path
                                      id="heart"
                                      d="M31.046,32.562l5.834,5.9,6.267-6.333a4.2,4.2,0,0,0,0-5.894,4.094,4.094,0,0,0-5.832,0l-.432.437-.432-.437a4.093,4.093,0,0,0-5.832,0,4.2,4.2,0,0,0,0,5.894l.43.434Z"
                                      transform="translate(-24.705 -12.506)"
                                      fill="none"
                                      stroke="#716e6e"
                                      strokeMiterlimit="10"
                                      strokeWidth="1.5"
                                    />
                                    <path
                                      id="Artboard_2"
                                      data-name="Artboard 2"
                                      d="M87.935,18.61a9.431,9.431,0,0,1,1.582.617,3.751,3.751,0,0,1,1.859,3.3c.018.867,0,1.737,0,2.644H94.83a2.209,2.209,0,0,1,2.331,2.048q.742,5.519,1.474,11.04c.41,3.082.829,6.162,1.229,9.246a2.186,2.186,0,0,1-1.743,2.509.563.563,0,0,0-.108.051H76.876a2.484,2.484,0,0,1-1.465-.933,2.108,2.108,0,0,1-.4-1.506q.58-4.405,1.171-8.816.532-4.027,1.07-8.06c.164-1.229.321-2.457.491-3.679a2.195,2.195,0,0,1,2.13-1.892c1.085-.02,2.169-.007,3.255-.007.386-.138.386-.255.386-.373a26.565,26.565,0,0,1,.057-2.789,3.841,3.841,0,0,1,3.244-3.343.852.852,0,0,0,.139-.053Z"
                                      transform="translate(-74.992 -18.61)"
                                      fill="none"
                                      stroke="#707070"
                                      strokeWidth="1.5"
                                    />
                                  </g>
                                </svg>
                              </button>
                              <button className="button date">
                                <svg
                                  id="iocn"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="28.362"
                                  height="30.656"
                                  viewBox="0 0 28.362 30.656"
                                >
                                  <path
                                    id="Контур_7703"
                                    data-name="Контур 7703"
                                    d="M29.674,33.628a3.066,3.066,0,0,0,2.162-.876,2.988,2.988,0,0,0,.9-2.131V8.777A3.027,3.027,0,0,0,29.687,5.77H7.416A3.027,3.027,0,0,0,4.37,8.777V30.621a3.027,3.027,0,0,0,3.046,3.007ZM5.636,30.621V8.777A1.781,1.781,0,0,1,7.416,7.02H29.674a1.781,1.781,0,0,1,1.8,1.757V30.621a1.781,1.781,0,0,1-1.78,1.757H7.416A1.781,1.781,0,0,1,5.636,30.621Z"
                                    transform="translate(-4.37 -2.972)"
                                    fill="#707070"
                                  />
                                  <path
                                    id="Контур_7704"
                                    data-name="Контур 7704"
                                    d="M11.879,10.291V4.552a.717.717,0,0,0-.717-.732.732.732,0,0,0-.732.732v5.739a.725.725,0,0,0,1.449,0Z"
                                    transform="translate(-2.886 -3.82)"
                                    fill="#707070"
                                  />
                                  <path
                                    id="Контур_7705"
                                    data-name="Контур 7705"
                                    d="M21.439,10.291V4.552a.732.732,0,0,0-.732-.732.717.717,0,0,0-.717.732v5.739a.725.725,0,0,0,1.449,0Z"
                                    transform="translate(-0.546 -3.82)"
                                    fill="#707070"
                                  />
                                  <rect
                                    id="Прямоугольник_1873"
                                    data-name="Прямоугольник 1873"
                                    width="26.755"
                                    height="1.449"
                                    transform="translate(0.717 8.532)"
                                    fill="#707070"
                                  />
                                  <path
                                    id="Контур_7706"
                                    data-name="Контур 7706"
                                    d="M9.962,15.481a.861.861,0,1,0-.861.861A.861.861,0,0,0,9.962,15.481Z"
                                    transform="translate(-3.429 -1.185)"
                                    fill="#707070"
                                  />
                                  <path
                                    id="Контур_7707"
                                    data-name="Контур 7707"
                                    d="M14.522,15.481a.861.861,0,1,0-.861.861A.861.861,0,0,0,14.522,15.481Z"
                                    transform="translate(-2.32 -1.185)"
                                    fill="#707070"
                                  />
                                  <path
                                    id="Контур_7708"
                                    data-name="Контур 7708"
                                    d="M19.06,15.49a.875.875,0,1,0-.875.861.861.861,0,0,0,.875-.861Z"
                                    transform="translate(-1.226 -1.192)"
                                    fill="#707070"
                                  />
                                  <path
                                    id="Контур_7709"
                                    data-name="Контур 7709"
                                    d="M23.592,15.481a.861.861,0,1,0-.861.861A.861.861,0,0,0,23.592,15.481Z"
                                    transform="translate(-0.115 -1.185)"
                                    fill="#707070"
                                  />
                                  <path
                                    id="Контур_7710"
                                    data-name="Контур 7710"
                                    d="M9.962,19.651a.861.861,0,1,0-.861.861A.861.861,0,0,0,9.962,19.651Z"
                                    transform="translate(-3.429 -0.168)"
                                    fill="#707070"
                                  />
                                  <path
                                    id="Контур_7711"
                                    data-name="Контур 7711"
                                    d="M14.522,19.651a.861.861,0,1,0-.861.861A.861.861,0,0,0,14.522,19.651Z"
                                    transform="translate(-2.32 -0.168)"
                                    fill="#707070"
                                  />
                                  <path
                                    id="Контур_7712"
                                    data-name="Контур 7712"
                                    d="M19.06,19.66a.875.875,0,1,0-.875.861.861.861,0,0,0,.875-.861Z"
                                    transform="translate(-1.226 -0.175)"
                                    fill="#707070"
                                  />
                                  <path
                                    id="Контур_7713"
                                    data-name="Контур 7713"
                                    d="M23.592,19.651a.861.861,0,1,0-.861.861A.861.861,0,0,0,23.592,19.651Z"
                                    transform="translate(-0.115 -0.168)"
                                    fill="#707070"
                                  />
                                  <path
                                    id="Контур_7714"
                                    data-name="Контур 7714"
                                    d="M9.962,23.821a.861.861,0,1,0-.861.861.861.861,0,0,0,.861-.861Z"
                                    transform="translate(-3.429 0.849)"
                                    fill="#707070"
                                  />
                                  <path
                                    id="Контур_7715"
                                    data-name="Контур 7715"
                                    d="M14.522,23.821a.861.861,0,1,0-.861.861.861.861,0,0,0,.861-.861Z"
                                    transform="translate(-2.32 0.849)"
                                    fill="#707070"
                                  />
                                  <path
                                    id="Контур_7716"
                                    data-name="Контур 7716"
                                    d="M19.06,23.83a.875.875,0,1,0-.875.861.861.861,0,0,0,.875-.861Z"
                                    transform="translate(-1.226 0.841)"
                                    fill="#707070"
                                  />
                                </svg>
                              </button>
                              <NavLink
                                to={`/update-wishlist/:${wishlist.id}`}
                                state={{
                                  eventTypeName: wishlist.name,
                                  eventTypeDate: wishlist.eventDate,
                                  eventTypeId: wishlist.id,
                                }}
                                // onClick={()=> editWhishListItem(wishlist.name)}
                                className="button-edit"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="19.121"
                                  viewBox="0 0 20 19.121"
                                >
                                  <g id="edit-3" transform="translate(-2 -1.879)">
                                    <path
                                      id="Контур_8553"
                                      data-name="Контур 8553"
                                      d="M12,20h9"
                                      fill="none"
                                      stroke="#959595"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                    />
                                    <path
                                      id="Контур_8554"
                                      data-name="Контур 8554"
                                      d="M16.5,3.5a2.121,2.121,0,1,1,3,3L7,19,3,20l1-4Z"
                                      fill="none"
                                      stroke="#959595"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                    />
                                  </g>
                                </svg>
                              </NavLink>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  )
                })}
              </ul>
            </div>
          )}

          {myEventsActiveTab == 2 && (
            <div className="main-choose-event__body">
              <ul className="main-choose-event__books">
                {whishlistArray.historyWishlists.map((wishlist, index) => {
                  const wishListObj = JSON.parse(wishlist?.wishListObj);
                  const imgUrl = wishListObj?.categoryImage ? API_DOMAIN_IMG + wishListObj?.categoryImage : "img/choose-event-type/1.png";
             
                  return(
                    <li
                    key={wishlist.id}
                    className="main-choose-event__books-item"
                  >
                    <div className="single-books-item">
                      <div className="single-books-item__content">
                        <div className="single-books-item__image-ibg">
                          <img
                            src={imgUrl}
                            className="img-people"
                            alt="1"
                          />
                          <img
                            src="img/no-people-images/choose-event-type/1.png"
                            className="img-no-people"
                            alt="1"
                          />
                        </div>
                        <div className="single-books-item__text-content">
                          <h3 className="single-books-item__title">
                            {wishlist.name}
                          </h3>
                          <p className="single-books-item__date">
                            {wishlist.eventDate
                              .slice(0, 10)
                              .replaceAll("-", "/")}
                          </p>
                          <div className="single-books-item__actions">
                            {wishlist.isRemovable && (
                              <button
                                className="button delete"
                                onClick={() =>
                                  removeHistoryWishlist(wishlist.id)
                                }
                              >
                                <svg
                                  id="Trash_can"
                                  data-name="Trash can"
                                  xmlns="http://www.w3.org/2000/svg"
                                  xlink="http://www.w3.org/1999/xlink"
                                  width="24.153"
                                  height="28.357"
                                  viewBox="0 0 24.153 28.357"
                                >
                                  <defs>
                                    <clipPath id="clip-path">
                                      <rect
                                        id="Прямоугольник_1981"
                                        data-name="Прямоугольник 1981"
                                        width="22.385"
                                        height="19.451"
                                        transform="translate(0 0)"
                                        fill="none"
                                        stroke="#716e6e"
                                        strokeWidth="1.4"
                                      />
                                    </clipPath>
                                    <clipPath id="clip-path-2">
                                      <rect
                                        id="Прямоугольник_1983"
                                        data-name="Прямоугольник 1983"
                                        width="14.146"
                                        height="6.189"
                                        fill="none"
                                        stroke="#716e6e"
                                        strokeWidth="1.4"
                                      />
                                    </clipPath>
                                  </defs>
                                  <g
                                    id="Прямоугольник_1980"
                                    data-name="Прямоугольник 1980"
                                    transform="translate(0 5.78)"
                                    fill="none"
                                    stroke="#716e6e"
                                    strokeWidth="1.4"
                                  >
                                    <rect
                                      width="24.153"
                                      height="3.537"
                                      rx="1.768"
                                      stroke="none"
                                    />
                                    <rect
                                      x="0.7"
                                      y="0.7"
                                      width="22.753"
                                      height="2.137"
                                      rx="1.068"
                                      fill="none"
                                    />
                                  </g>
                                  <line
                                    id="Линия_491"
                                    data-name="Линия 491"
                                    x2="1.603"
                                    y2="11.543"
                                    transform="translate(6.809 11.427)"
                                    fill="none"
                                    stroke="#716e6e"
                                    strokeLinecap="round"
                                    strokeWidth="1.4"
                                  />
                                  <line
                                    id="Линия_492"
                                    data-name="Линия 492"
                                    y2="11.543"
                                    transform="translate(11.96 11.427)"
                                    fill="none"
                                    stroke="#716e6e"
                                    strokeLinecap="round"
                                    strokeWidth="1.4"
                                  />
                                  <line
                                    id="Линия_493"
                                    data-name="Линия 493"
                                    x1="1.483"
                                    y2="11.291"
                                    transform="translate(15.292 11.412)"
                                    fill="none"
                                    stroke="#716e6e"
                                    strokeLinecap="round"
                                    strokeWidth="1.4"
                                  />
                                  <g
                                    id="Группа_масок_833"
                                    data-name="Группа масок 833"
                                    transform="translate(0.884 8.906)"
                                    clipPath="url(#clip-path)"
                                  >
                                    <g
                                      id="Контур_8526"
                                      data-name="Контур 8526"
                                      transform="translate(8.235 -3.346)"
                                      fill="none"
                                    >
                                      <path
                                        d="M-5.613,0H11.26a1.47,1.47,0,0,1,1.387,1.541L11.029,20.293a1.47,1.47,0,0,1-1.387,1.541H-4a1.47,1.47,0,0,1-1.387-1.541L-7,1.541A1.47,1.47,0,0,1-5.613,0Z"
                                        stroke="none"
                                      />
                                      <path
                                        d="M -5.562511444091797 1.399997711181641 C -5.575593948364258 1.419382095336914 -5.592249870300293 1.45405387878418 -5.597964286804199 1.504474639892578 L -3.982046127319336 20.23285675048828 L -3.982046127319336 20.29314804077148 C -3.982046127319336 20.36381530761719 -3.960605621337891 20.41061401367188 -3.944553375244141 20.43439674377441 L 9.591161727905273 20.43439674377441 C 9.607213973999023 20.41061401367188 9.628654479980469 20.36381530761719 9.628654479980469 20.29314804077148 L 9.628654479980469 20.23285675048828 L 11.24457263946533 1.504476547241211 C 11.23885726928711 1.454051971435547 11.22219467163086 1.419380187988281 11.2091121673584 1.399997711181641 L -5.562511444091797 1.399997711181641 M -5.613185882568359 -1.9073486328125e-06 L 11.25978469848633 -1.9073486328125e-06 C 12.0257043838501 -1.9073486328125e-06 12.64660453796387 0.6900367736816406 12.64660453796387 1.54124641418457 L 11.02865409851074 20.29314804077148 C 11.02865409851074 21.14435768127441 10.40775394439697 21.83439636230469 9.641834259033203 21.83439636230469 L -3.99522590637207 21.83439636230469 C -4.76114559173584 21.83439636230469 -5.382045745849609 21.14435768127441 -5.382045745849609 20.29314804077148 L -6.999996185302734 1.54124641418457 C -6.999996185302734 0.6900367736816406 -6.379105567932129 -1.9073486328125e-06 -5.613185882568359 -1.9073486328125e-06 Z"
                                        stroke="none"
                                        fill="#716e6e"
                                      />
                                    </g>
                                  </g>
                                  <g
                                    id="Группа_масок_834"
                                    data-name="Группа масок 834"
                                    transform="translate(5.004 0)"
                                    clipPath="url(#clip-path-2)"
                                  >
                                    <g
                                      id="Прямоугольник_1982"
                                      data-name="Прямоугольник 1982"
                                      transform="translate(0 2.652)"
                                      fill="none"
                                      stroke="#716e6e"
                                      strokeWidth="1.4"
                                    >
                                      <rect
                                        width="13.262"
                                        height="5.305"
                                        rx="2.652"
                                        stroke="none"
                                      />
                                      <rect
                                        x="0.7"
                                        y="0.7"
                                        width="11.862"
                                        height="3.905"
                                        rx="1.952"
                                        fill="none"
                                      />
                                    </g>
                                  </g>
                                </svg>
                              </button>
                            )}

                            <button
                              className="button favorites"
                              onClick={() => goToChooseGift(wishlist.id)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="26.406"
                                height="32.951"
                                viewBox="0 0 26.406 32.951"
                              >
                                <g
                                  id="Компонент_360_1"
                                  data-name="Компонент 360 – 1"
                                  transform="translate(0.751 0.75)"
                                >
                                  <path
                                    id="heart"
                                    d="M31.046,32.562l5.834,5.9,6.267-6.333a4.2,4.2,0,0,0,0-5.894,4.094,4.094,0,0,0-5.832,0l-.432.437-.432-.437a4.093,4.093,0,0,0-5.832,0,4.2,4.2,0,0,0,0,5.894l.43.434Z"
                                    transform="translate(-24.705 -12.506)"
                                    fill="none"
                                    stroke="#716e6e"
                                    strokeMiterlimit="10"
                                    strokeWidth="1.5"
                                  />
                                  <path
                                    id="Artboard_2"
                                    data-name="Artboard 2"
                                    d="M87.935,18.61a9.431,9.431,0,0,1,1.582.617,3.751,3.751,0,0,1,1.859,3.3c.018.867,0,1.737,0,2.644H94.83a2.209,2.209,0,0,1,2.331,2.048q.742,5.519,1.474,11.04c.41,3.082.829,6.162,1.229,9.246a2.186,2.186,0,0,1-1.743,2.509.563.563,0,0,0-.108.051H76.876a2.484,2.484,0,0,1-1.465-.933,2.108,2.108,0,0,1-.4-1.506q.58-4.405,1.171-8.816.532-4.027,1.07-8.06c.164-1.229.321-2.457.491-3.679a2.195,2.195,0,0,1,2.13-1.892c1.085-.02,2.169-.007,3.255-.007.386-.138.386-.255.386-.373a26.565,26.565,0,0,1,.057-2.789,3.841,3.841,0,0,1,3.244-3.343.852.852,0,0,0,.139-.053Z"
                                    transform="translate(-74.992 -18.61)"
                                    fill="none"
                                    stroke="#707070"
                                    strokeWidth="1.5"
                                  />
                                </g>
                              </svg>
                            </button>
                            <div className="arrow">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="9.115"
                                height="17.117"
                                viewBox="0 0 9.115 17.117"
                              >
                                <g
                                  id="Сгруппировать_74339"
                                  data-name="Сгруппировать 74339"
                                  transform="translate(16.411 19.135) rotate(180)"
                                >
                                  <g
                                    id="Сгруппировать_540"
                                    data-name="Сгруппировать 540"
                                    transform="translate(14.998 17.723) rotate(180)"
                                  >
                                    <line
                                      id="Линия_434"
                                      data-name="Линия 434"
                                      y1="7.147"
                                      x2="6.289"
                                      transform="translate(0)"
                                      fill="none"
                                      stroke="#a7a7a7"
                                      strokeLinecap="round"
                                      strokeWidth="2"
                                    />
                                    <path
                                      id="Контур_6897"
                                      data-name="Контур 6897"
                                      d="M0,0,6.289,6.861"
                                      transform="translate(0 7.432)"
                                      fill="none"
                                      stroke="#a7a7a7"
                                      strokeLinecap="round"
                                      strokeWidth="2"
                                    />
                                  </g>
                                </g>
                              </svg>
                            </div>
                          </div>
                        </div>

                        <div className="single-books-item__dropdown-body">
                          <div className="single-books-item__dropdown-content">
                            <div className="single-books-item__dropdown-table">
                              <div className="single-books-item__dropdown-table-column">
                                <div className="single-books-item__dropdown-table-item">
                                  <p className="name">תאריך האירוע</p>
                                  <p className="value">
                                    {wishlist.eventDate
                                      .slice(0, 10)
                                      .replaceAll("-", "/")}
                                  </p>
                                </div>
                                <div className="single-books-item__dropdown-table-item">
                                  <p className="name">שעת הגעה</p>
                                  <p className="value">
                                    {wishlist.arrivalTime}
                                  </p>
                                </div>
                              </div>
                              <div className="single-books-item__dropdown-table-column">
                                <div className="single-books-item__dropdown-table-item">
                                  <p className="name">כתובת האירוע</p>
                                  <p className="value">{wishlist.address}</p>
                                </div>
                                <div className="single-books-item__dropdown-table-item">
                                  <p className="name">טלפון לתיאומים</p>
                                  <p className="value">
                                    {wishlist.contactPhone}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <p className="single-books-item__dropdown-bottom-text">
                              בא לכם לפנק אותי במתנה. חסכתי לכם כאב ראש מיותר
                              בחרו מתוך רשימת המתנות המועדפת שלי אז למה אתם
                              מחכים?
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  )
                })}
              </ul>
            </div>
          )}
          {myEventsActiveTab == 3 && (
            <div className="main-choose-event__body">
              <ul className="main-choose-event__books">
                {whishlistArray.friendsWishlists.map((wishlist, index) => {
                  const wishListObj = JSON.parse(wishlist?.wishListObj);
                  const imgUrl = wishListObj?.categoryImage ? API_DOMAIN_IMG + wishListObj?.categoryImage : "img/choose-event-type/1.png";
             
                  return (
                    <li
                      key={wishlist.id}
                      className="main-choose-event__books-item"
                    >
                      <div className="single-books-item">
                        <div className="single-books-item__content">
                          <div className="single-books-item__image-ibg">
                            <img
                              src={imgUrl}
                              className="img-people"
                              alt="1"
                            />
                            <img
                              src="img/no-people-images/choose-event-type/1.png"
                              className="img-no-people"
                              alt="1"
                            />
                          </div>
                          <div className="single-books-item__text-content">
                            <h3 className="single-books-item__title">
                              {wishlist.name}
                            </h3>
                            <p className="single-books-item__date">
                              {" "}
                              {wishlist.eventDate
                                .slice(0, 10)
                                .replaceAll("-", "/")}
                            </p>
                            <div className="single-books-item__actions">
                              {wishlist.isRemovable && (
                                <button
                                  className="button delete"
                                  onClick={() =>
                                    removeFriendWishlist(wishlist.id)
                                  }
                                >
                                  <svg
                                    id="Trash_can"
                                    data-name="Trash can"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xlink="http://www.w3.org/1999/xlink"
                                    width="24.153"
                                    height="28.357"
                                    viewBox="0 0 24.153 28.357"
                                  >
                                    <defs>
                                      <clipPath id="clip-path">
                                        <rect
                                          id="Прямоугольник_1981"
                                          data-name="Прямоугольник 1981"
                                          width="22.385"
                                          height="19.451"
                                          transform="translate(0 0)"
                                          fill="none"
                                          stroke="#716e6e"
                                          strokeWidth="1.4"
                                        />
                                      </clipPath>
                                      <clipPath id="clip-path-2">
                                        <rect
                                          id="Прямоугольник_1983"
                                          data-name="Прямоугольник 1983"
                                          width="14.146"
                                          height="6.189"
                                          fill="none"
                                          stroke="#716e6e"
                                          strokeWidth="1.4"
                                        />
                                      </clipPath>
                                    </defs>
                                    <g
                                      id="Прямоугольник_1980"
                                      data-name="Прямоугольник 1980"
                                      transform="translate(0 5.78)"
                                      fill="none"
                                      stroke="#716e6e"
                                      strokeWidth="1.4"
                                    >
                                      <rect
                                        width="24.153"
                                        height="3.537"
                                        rx="1.768"
                                        stroke="none"
                                      />
                                      <rect
                                        x="0.7"
                                        y="0.7"
                                        width="22.753"
                                        height="2.137"
                                        rx="1.068"
                                        fill="none"
                                      />
                                    </g>
                                    <line
                                      id="Линия_491"
                                      data-name="Линия 491"
                                      x2="1.603"
                                      y2="11.543"
                                      transform="translate(6.809 11.427)"
                                      fill="none"
                                      stroke="#716e6e"
                                      strokeLinecap="round"
                                      strokeWidth="1.4"
                                    />
                                    <line
                                      id="Линия_492"
                                      data-name="Линия 492"
                                      y2="11.543"
                                      transform="translate(11.96 11.427)"
                                      fill="none"
                                      stroke="#716e6e"
                                      strokeLinecap="round"
                                      strokeWidth="1.4"
                                    />
                                    <line
                                      id="Линия_493"
                                      data-name="Линия 493"
                                      x1="1.483"
                                      y2="11.291"
                                      transform="translate(15.292 11.412)"
                                      fill="none"
                                      stroke="#716e6e"
                                      strokeLinecap="round"
                                      strokeWidth="1.4"
                                    />
                                    <g
                                      id="Группа_масок_833"
                                      data-name="Группа масок 833"
                                      transform="translate(0.884 8.906)"
                                      clipPath="url(#clip-path)"
                                    >
                                      <g
                                        id="Контур_8526"
                                        data-name="Контур 8526"
                                        transform="translate(8.235 -3.346)"
                                        fill="none"
                                      >
                                        <path
                                          d="M-5.613,0H11.26a1.47,1.47,0,0,1,1.387,1.541L11.029,20.293a1.47,1.47,0,0,1-1.387,1.541H-4a1.47,1.47,0,0,1-1.387-1.541L-7,1.541A1.47,1.47,0,0,1-5.613,0Z"
                                          stroke="none"
                                        />
                                        <path
                                          d="M -5.562511444091797 1.399997711181641 C -5.575593948364258 1.419382095336914 -5.592249870300293 1.45405387878418 -5.597964286804199 1.504474639892578 L -3.982046127319336 20.23285675048828 L -3.982046127319336 20.29314804077148 C -3.982046127319336 20.36381530761719 -3.960605621337891 20.41061401367188 -3.944553375244141 20.43439674377441 L 9.591161727905273 20.43439674377441 C 9.607213973999023 20.41061401367188 9.628654479980469 20.36381530761719 9.628654479980469 20.29314804077148 L 9.628654479980469 20.23285675048828 L 11.24457263946533 1.504476547241211 C 11.23885726928711 1.454051971435547 11.22219467163086 1.419380187988281 11.2091121673584 1.399997711181641 L -5.562511444091797 1.399997711181641 M -5.613185882568359 -1.9073486328125e-06 L 11.25978469848633 -1.9073486328125e-06 C 12.0257043838501 -1.9073486328125e-06 12.64660453796387 0.6900367736816406 12.64660453796387 1.54124641418457 L 11.02865409851074 20.29314804077148 C 11.02865409851074 21.14435768127441 10.40775394439697 21.83439636230469 9.641834259033203 21.83439636230469 L -3.99522590637207 21.83439636230469 C -4.76114559173584 21.83439636230469 -5.382045745849609 21.14435768127441 -5.382045745849609 20.29314804077148 L -6.999996185302734 1.54124641418457 C -6.999996185302734 0.6900367736816406 -6.379105567932129 -1.9073486328125e-06 -5.613185882568359 -1.9073486328125e-06 Z"
                                          stroke="none"
                                          fill="#716e6e"
                                        />
                                      </g>
                                    </g>
                                    <g
                                      id="Группа_масок_834"
                                      data-name="Группа масок 834"
                                      transform="translate(5.004 0)"
                                      clipPath="url(#clip-path-2)"
                                    >
                                      <g
                                        id="Прямоугольник_1982"
                                        data-name="Прямоугольник 1982"
                                        transform="translate(0 2.652)"
                                        fill="none"
                                        stroke="#716e6e"
                                        strokeWidth="1.4"
                                      >
                                        <rect
                                          width="13.262"
                                          height="5.305"
                                          rx="2.652"
                                          stroke="none"
                                        />
                                        <rect
                                          x="0.7"
                                          y="0.7"
                                          width="11.862"
                                          height="3.905"
                                          rx="1.952"
                                          fill="none"
                                        />
                                      </g>
                                    </g>
                                  </svg>
                                </button>
                              )}
                              <NavLink
                                to={`/choose-present-or-send-money/${wishlist.id}`}
                                className="button gift"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="27.868"
                                  height="30.872"
                                  viewBox="0 0 27.868 30.872"
                                >
                                  <g
                                    id="Layer_6"
                                    data-name="Layer 6"
                                    transform="translate(0.1 0.117)"
                                  >
                                    <path
                                      id="Контур_7573"
                                      data-name="Контур 7573"
                                      d="M35.9,13.985H32.775a3.085,3.085,0,0,0,.613-.682,3.122,3.122,0,0,0,.306-2.74.069.069,0,0,0-.032-.054c-.032-.021,0-.038,0-.054A3.106,3.106,0,0,0,31.6,8.623a3.186,3.186,0,0,0-2.149.193,2.88,2.88,0,0,0,.027-1.709,3.079,3.079,0,0,0-1.87-2.02h0a3.09,3.09,0,0,0-2.73.344,2.929,2.929,0,0,0-.838.87,2.928,2.928,0,0,0-.838-.876,3.1,3.1,0,0,0-2.73-.344h0a3.09,3.09,0,0,0-1.875,2.026,2.928,2.928,0,0,0,.027,1.666,3.224,3.224,0,0,0-2.047-.145A3.106,3.106,0,0,0,14.51,10.46s0,.038,0,.054-.027.032-.032.054a3.122,3.122,0,0,0,.306,2.74,3.084,3.084,0,0,0,.613.682H11.566A1.666,1.666,0,0,0,9.9,15.657v3.31a1.66,1.66,0,0,0,1.537,1.65V33.9A1.671,1.671,0,0,0,13.1,35.565H34.365A1.671,1.671,0,0,0,36.031,33.9V20.611a1.655,1.655,0,0,0,1.537-1.65v-3.31A1.666,1.666,0,0,0,35.9,13.985ZM21.689,15.168h4.3v19.21h-4.3Zm9.586-5.406a1.945,1.945,0,0,1,1.274,1.107l.032.043v.048a1.956,1.956,0,0,1-.193,1.687,2.359,2.359,0,0,1-1.929.978,34.455,34.455,0,0,1-4.917,0c.4-.376.828-.817,1.263-1.29.672-.623,1.462-1.306,2.38-2.031a2.359,2.359,0,0,1,2.09-.543ZM24.6,8.182a2.192,2.192,0,0,1,.876-1.838,1.94,1.94,0,0,1,2.966,1.075,2.149,2.149,0,0,1-.51,1.967c-.419.537-.828,1.037-1.22,1.488-.742.656-1.424,1.3-1.977,1.886A31.291,31.291,0,0,1,24.6,8.182ZM19.626,7.4a1.934,1.934,0,0,1,2.966-1.075,2.192,2.192,0,0,1,.881,1.859,32.166,32.166,0,0,1-.124,4.5c-.629-.656-1.4-1.376-2.241-2.1-.317-.371-.639-.763-.967-1.193A2.149,2.149,0,0,1,19.626,7.4ZM16.88,9.762a2.359,2.359,0,0,1,2.09.57c.7.537,1.322,1.075,1.881,1.574.586.661,1.155,1.257,1.682,1.752a34.31,34.31,0,0,1-4.836,0,2.359,2.359,0,0,1-1.929-.978,1.956,1.956,0,0,1-.193-1.687s0-.032,0-.048l.032-.043a1.945,1.945,0,0,1,1.279-1.139Zm-5.819,9.2v-3.31a.484.484,0,0,1,.505-.484h8.941v4.3H11.566a.484.484,0,0,1-.505-.505ZM12.6,33.9V20.627h7.91V34.383H13.081A.489.489,0,0,1,12.6,33.9Zm21.768.484H27.149V20.627h7.7V33.9A.484.484,0,0,1,34.365,34.383Zm2.02-15.422a.484.484,0,0,1-.484.484H27.149V15.168H35.9a.484.484,0,0,1,.484.484Z"
                                      transform="translate(-9.9 -4.909)"
                                      fill="#716e6e"
                                      stroke="#716e6e"
                                      strokeWidth="0.2"
                                    />
                                  </g>
                                </svg>
                              </NavLink>
                              <div className="arrow">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="9.115"
                                  height="17.117"
                                  viewBox="0 0 9.115 17.117"
                                >
                                  <g
                                    id="Сгруппировать_74339"
                                    data-name="Сгруппировать 74339"
                                    transform="translate(16.411 19.135) rotate(180)"
                                  >
                                    <g
                                      id="Сгруппировать_540"
                                      data-name="Сгруппировать 540"
                                      transform="translate(14.998 17.723) rotate(180)"
                                    >
                                      <line
                                        id="Линия_434"
                                        data-name="Линия 434"
                                        y1="7.147"
                                        x2="6.289"
                                        transform="translate(0)"
                                        fill="none"
                                        stroke="#a7a7a7"
                                        strokeLinecap="round"
                                        strokeWidth="2"
                                      />
                                      <path
                                        id="Контур_6897"
                                        data-name="Контур 6897"
                                        d="M0,0,6.289,6.861"
                                        transform="translate(0 7.432)"
                                        fill="none"
                                        stroke="#a7a7a7"
                                        strokeLinecap="round"
                                        strokeWidth="2"
                                      />
                                    </g>
                                  </g>
                                </svg>
                              </div>
                            </div>
                          </div>

                          <div className="single-books-item__dropdown-body">
                            <div className="single-books-item__dropdown-content">
                              <div className="single-books-item__dropdown-table">
                                <div className="single-books-item__dropdown-table-column">
                                  <div className="single-books-item__dropdown-table-item">
                                    <p className="name">תאריך האירוע</p>
                                    <p className="value">
                                      {wishlist.eventDate
                                        .slice(0, 10)
                                        .replaceAll("-", "/")}
                                    </p>
                                  </div>
                                  <div className="single-books-item__dropdown-table-item">
                                    <p className="name">שעת הגעה</p>
                                    <p className="value">
                                      {wishlist.eventDate.slice(11, 16)}
                                    </p>
                                  </div>
                                </div>
                                <div className="single-books-item__dropdown-table-column">
                                  <div className="single-books-item__dropdown-table-item">
                                    <p className="name">כתובת האירוע</p>
                                    <p className="value">{wishlist.address}</p>
                                  </div>
                                  <div className="single-books-item__dropdown-table-item">
                                    <p className="name">טלפון לתיאומים</p>
                                    <p className="value">
                                      {wishlist.contactPhone}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <p className="single-books-item__dropdown-bottom-text">
                                בא לכם לפנק אותי במתנה. חסכתי לכם כאב ראש מיותר
                                בחרו מתוך רשימת המתנות המועדפת שלי אז למה אתם
                                מחכים?
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  )
                })}
              </ul>
            </div>
          )}
        </div>
      </section>
    </main>
  );
}
