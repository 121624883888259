export const validatePhoneNumber = (input) => {
  const phoneNumberInput = input;
  const phoneNumber = phoneNumberInput.value;

  if (/^05\d{1}$/.test(phoneNumber)) {
    clearError(phoneNumberInput);
    return true;
  } else {
    const errorText = phoneNumberInput.getAttribute("data-error-text")
      ? phoneNumberInput.getAttribute("data-error-text")
      : "tel is invalid";
    setError(phoneNumberInput, errorText);
    return false;
  }
};

export function isIsraelNumber(input) {
  const phoneNumber = input.value;
  const israelMobilePrefixes = [
    "050",
    "052",
    "053",
    "054",
    "055",
    "056",
    "057",
    "058",
  ];

  const cleanedNumber = phoneNumber.trim().replace(/\D/g, "");

  return israelMobilePrefixes.some((prefix) =>
    cleanedNumber.startsWith(prefix)
  );
}

export const validateFullPhoneNumber = (input) => {
  const phoneNumberInput = input;
  const phoneNumber = phoneNumberInput.value;

  if (/^05\d{8}$/.test(phoneNumber)) {
    clearError(phoneNumberInput);
    return true;
  } else {
    const errorText = phoneNumberInput.getAttribute("data-error-text")
      ? phoneNumberInput.getAttribute("data-error-text")
      : "tel is invalid";
    setError(phoneNumberInput, errorText);
    return false;
  }
};

export const validateTextInput = (input, charsCount, wordsCount = null) => {
  const textInput = input;
  if (textInput?.value?.length < charsCount) {
    const errorText = textInput.getAttribute("data-error-text")
      ? textInput.getAttribute("data-error-text")
      : "input text is invalid";
    setError(textInput, errorText);
    return false;
  } else {
    if (
      wordsCount &&
      (input.value.split(" ").length !== wordsCount ||
        (input.value.split(" ").length === wordsCount &&
          input.value.split(" ")[wordsCount - 1] === ""))
    ) {
      const errorText = textInput.getAttribute("data-error-text")
        ? textInput.getAttribute("data-error-text")
        : "there must be 2 words";
      setError(textInput, errorText);
      return false;
    } else {
      clearError(textInput);
      return true;
    }
  }
};

export const validateEmailInput = (input) => {
  const emailInput = input;
  if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailInput.value)) {
    clearError(emailInput);
    return true;
  } else {
    const errorText = emailInput.getAttribute("data-error-text")
      ? emailInput.getAttribute("data-error-text")
      : "email is invalid";
    setError(emailInput, errorText);
    return false;
  }
};

export const validateFixedCountInput = (input, count, isWholeNumber) => {
  const idInput = input;
  if (
    isWholeNumber &&
    idInput.value.length === count &&
    isIsraelNumber(idInput)
  ) {
    clearError(idInput);
    return true;
  } else if (isWholeNumber == undefined && idInput.value.length === count) {
    clearError(idInput);
    return true;
  } else {
    const errorText = idInput.getAttribute("data-error-text")
      ? idInput.getAttribute("data-error-text")
      : "must be 9-digit";
    setError(idInput, errorText);
    return false;
  }
};

export const inputMaxCount = (input, count, prefix) => {
  let value = input.value;
  if (value.length > count) value = value.slice(0, count);
  input.value = value;
  // if (prefix) {
  //   // const isIsraelNumberv = isIsraelNumber(input.value)
  //   // isIsraelNumber(value)
  // }
};

export const setError = (el, text) => {
  const error = el.parentElement.querySelector(".error");
  if (error) {
    error.innerText = text;
  }
  if (
    el.closest("label") &&
    !el.closest("label").classList.contains("input-error")
  ) {
    el.closest("label").classList.add("input-error");
  } else if (
    el.closest(".label") &&
    !el.closest(".label").classList.contains("input-error")
  ) {
    el.closest(".label").classList.add("input-error");
  }
};
export const clearError = (el) => {
  const error = el.parentElement.querySelector(".error");
  if (error) {
    error.innerText = "";
  }
  if (el.closest("label")) {
    if (el.closest("label").classList.contains("input-error")) {
      el.closest("label").classList.remove("input-error");
    }
  } else if (el.closest(".label")) {
    if (el.closest(".label").classList.contains("input-error")) {
      el.closest(".label").classList.remove("input-error");
    }
  }
};
