import React, { useEffect, useState } from "react";
import { topbarBgGreen } from "../Assets/images";

import {
  validateTextInput,
  validateEmailInput,
  validateFixedCountInput,
  inputMaxCount,
  validatePhoneNumber,
  validateFullPhoneNumber,
} from "../Libs/forms";
import { NavLink, useNavigate } from "react-router-dom";

function ContactUs() {
  const submitContactUsForm = (e) => {
    e.preventDefault();

    const form = e.target;

    const fullnameInput = form["fullname"];
    const telInputFirst = form["tel-1"];
    // const telInputSecond = form["tel-2"];
    const emailInput = form["email"];
    const messageInput = form["message"];

    const isFullname = validateTextInput(fullnameInput, 3);
    const isTelFirst = validateFullPhoneNumber(telInputFirst);
    // const isTelSecond = validatePhoneNumber(telInputSecond);
    const isEmail = validateEmailInput(emailInput);
    const isMessage = validateTextInput(messageInput, 5);

    const isFormValid =
      isFullname && isTelFirst && isEmail && isMessage;

    if (isFormValid) {
      const result = {
        fullname: fullnameInput.value,
        // number: telInputSecond.value + " - " + telInputFirst.value,
        phoneNumber: telInputFirst.value,
        email: emailInput.value,
        message: messageInput.value,
      };
      console.log(result);
    }
  };

  const navigate = useNavigate();
  useEffect(() => {
    const currentUser = localStorage.getItem("currUserObject");
    if (!currentUser) {
      navigate("/credentials");
    }
  }, []);

  return (
    <main className="contact-us">
      <section className="contact-us__topbar topbar">
        <div className="topbar__bg-img-ibg">
          <img src={topbarBgGreen} alt="topbar" />
        </div>
        <div className="topbar__container">
          <div className="topbar__title-wrapper">
            <h1 className="topbar__title">צור קשר</h1>
          </div>
        </div>
      </section>
      <div className="contact-us__breadcrumbs breadcrumbs">
        <div className="breadcrumbs__container">
          <ul className="breadcrumbs__list">
            <li className="breadcrumbs__item">
              <NavLink to="/">דף הבית</NavLink>
            </li>
            <li className="breadcrumbs__item">
              <p>contact us</p>
            </li>
          </ul>
        </div>
      </div>
      <section className="contact-us__main main-contact-us">
        <div className="main-contact-us__container">
          <h2 className="main-contact-us__title">
            כתבו לנו הודעה בכל נושא שאתם רוצים
          </h2>
          <div className="main-contact-us__form-block">
            <form
              action="#"
              onSubmit={submitContactUsForm}
              className="main-contact-us__form contact-us-form"
            >
              <div className="contact-us-form__body">
                <div className="contact-us-form__col">
                  <label
                    htmlFor="contact-us-form__fullname"
                    className="contact-us-form__input-wrapper"
                  >
                    <span className="input-title">
                      שם מלא<span>*</span>
                    </span>
                    <input
                      autoComplete="off"
                      type="text"
                      name="fullname"
                      id="contact-us-form__fullname"
                      className="input"
                      onInput={(e) => {
                        if (
                          e.target
                            .closest("label")
                            .classList.contains("input-error")
                        ) {
                          validateTextInput(e.target, 3);
                        }
                      }}
                    />
                    <span className="error-text">Minimum 3 chars</span>
                  </label>
                  <label
                      htmlFor="contact-us-form__tel-1"
                      className="contact-us-form__input-wrapper input-wrapper"
                    >
                      <span className="input-title">
                        נייד<span>*</span>
                      </span>
                      <input
                        autoComplete="off"
                        type="number"
                        name="tel-1"
                        id="contact-us-form__tel-1"
                        className="input"
                        onInput={(e) => {
                          inputMaxCount(e.target, 10);
                          if (
                            e.target
                              .closest("label")
                              .classList.contains("input-error")
                          ) {
                            validateFullPhoneNumber(e.target);
                          }
                        }}
                      />
                      <span className="error-text">7 chars</span>
                    </label>
                  {/* <div className="contact-us-form__tel-wrapper">
                    <label
                      htmlFor="contact-us-form__tel-2"
                      className="input-wrapper"
                    >
                      <input
                        autoComplete="off"
                        type="number"
                        name="tel-2"
                        id="contact-us-form__tel-2"
                        className="input"
                        onInput={(e) => {
                          inputMaxCount(e.target, 3);

                          if (
                            e.target
                              .closest("label")
                              .classList.contains("input-error")
                          ) {
                            validatePhoneNumber(e.target);
                          }
                        }}
                      />
                      <span className="error-text">*05</span>
                    </label>
                  </div> */}
                  <label
                    htmlFor="contact-us-form__email"
                    className="contact-us-form__input-wrapper"
                  >
                    <span className="input-title">מייל</span>
                    <input
                      autoComplete="off"
                      type="email"
                      name="email"
                      id="contact-us-form__email"
                      className="input"
                      onInput={(e) => {
                        if (
                          e.target
                            .closest("label")
                            .classList.contains("input-error")
                        ) {
                          validateEmailInput(e.target);
                        }
                      }}
                    />
                    <span className="error-text">Email is invalid</span>
                  </label>
                </div>
                <div className="contact-us-form__col">
                  <label
                    htmlFor="contact-us-form__message"
                    className="contact-us-form__input-wrapper contact-us-form__input-wrapper_txta"
                  >
                    <span className="input-title">נושא</span>
                    <textarea
                      autoComplete="off"
                      name="message"
                      id="contact-us-form__message"
                      className="input"
                      onInput={(e) => {
                        if (
                          e.target
                            .closest("label")
                            .classList.contains("input-error")
                        ) {
                          validateTextInput(e.target, 5);
                        }
                      }}
                    />
                    <span className="error-text">Minimum 5 chars</span>
                  </label>
                </div>
              </div>
              <div className="contact-us-form__btn-container btn-container_center">
                <button type="submit" className="btn">
                  שלח/י
                </button>
              </div>
            </form>
          </div>
          <div className="main-contact-us__socials">
            <h3 className="main-contact-us__socials-title">או פנו אלינו ב:</h3>
            <ul className="main-contact-us__socials-list">
              <li className="main-contact-us__socials-item">
                <a href="#" className="main-contact-us__socials-link">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="37.009"
                    height={37}
                    viewBox="0 0 37.009 37"
                  >
                    <g
                      id="Сгруппировать_1636"
                      data-name="Сгруппировать 1636"
                      transform="translate(1.237 0.559)"
                    >
                      <path
                        id="Объединение_70"
                        data-name="Объединение 70"
                        d="M7.99,32.193.417,34.029l2.1-7.477a17.5,17.5,0,1,1,5.47,5.641Z"
                        transform="translate(-0.228 0.441)"
                        fill="none"
                        stroke="#000"
                        strokeWidth={2}
                      />
                      <g id="Phone" transform="translate(10.068 10.068)">
                        <rect
                          id="Прямоугольник_320"
                          data-name="Прямоугольник 320"
                          width="14.644"
                          height="14.644"
                          fill="none"
                        />
                        <path
                          id="Контур_110"
                          data-name="Контур 110"
                          d="M8.5,10.46l1.569-1.438a.48.48,0,0,1,.392-.131l.261.131,3.661,1.7c.262.131.262.262.262.523A3.315,3.315,0,0,1,13.6,13.6a3.746,3.746,0,0,1-2.746,1.046,7.967,7.967,0,0,1-3.661-.915A17.14,17.14,0,0,1,3.53,11.114,22.271,22.271,0,0,1,1.569,8.237,18.073,18.073,0,0,1,.262,5.361,8.046,8.046,0,0,1,0,2.876,3.436,3.436,0,0,1,.915.915,3.976,3.976,0,0,1,3.4,0a.679.679,0,0,1,.523.261L5.884,3.922a.456.456,0,0,0,.131.261.48.48,0,0,1-.131.392l-1.7,1.569a.584.584,0,0,0,0,.654A12.486,12.486,0,0,0,5.753,8.891a13.2,13.2,0,0,0,2.092,1.7C8.107,10.721,8.368,10.721,8.5,10.46Z"
                          transform="translate(0 0)"
                        />
                      </g>
                    </g>
                  </svg>
                </a>
              </li>
              <li className="main-contact-us__socials-item">
                <a href="#" className="main-contact-us__socials-link">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={38}
                    height={39}
                    viewBox="0 0 38 39"
                  >
                    <g id="instagram" transform="translate(0.839 1.39)">
                      <rect
                        id="Прямоугольник_1993"
                        data-name="Прямоугольник 1993"
                        width={36}
                        height={37}
                        rx={5}
                        transform="translate(0.161 -0.39)"
                        fill="none"
                        stroke="#000"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                      />
                      <path
                        id="Контур_8555"
                        data-name="Контур 8555"
                        d="M22.608,14.2a7.322,7.322,0,1,1-6.169-6.169A7.322,7.322,0,0,1,22.608,14.2Z"
                        transform="translate(3.019 2.947)"
                        fill="none"
                        stroke="#000"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                      />
                      <line
                        id="Линия_496"
                        data-name="Линия 496"
                        x2="0.018"
                        transform="translate(28.373 8.237)"
                        fill="none"
                        stroke="#000"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                      />
                    </g>
                  </svg>
                </a>
              </li>
              <li className="main-contact-us__socials-item">
                <a href="#" className="main-contact-us__socials-link">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18.475"
                    height="33.547"
                    viewBox="0 0 18.475 33.547"
                  >
                    <path
                      id="Контур_38594"
                      data-name="Контур 38594"
                      d="M90.691,31.547V17.176H95.6l.7-5.608H90.691V8.062c0-1.577.526-2.8,2.8-2.8h2.979V.175c-.7,0-2.454-.175-4.382-.175-4.206,0-7.186,2.629-7.186,7.361v4.206H80v5.608h4.907V31.547Z"
                      transform="translate(-79 1)"
                      fill="none"
                      stroke="#000"
                      strokeWidth={2}
                      fillRule="evenodd"
                    />
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </main>
  );
}

export default ContactUs;
