import React, { useEffect, useState } from "react";

function SingleReminder({
  img,
  imgNoPeople,
  title,
  higlightedTitleText,
  date,
  place,
}) {
  return (
    <div className="main-reminders__reminder single-reminder">
      <a href="#" className="single-reminder__content">
        <div className="single-reminder__image-ibg">
          <img src={img} className="img-people" alt={1} />
          <img src={imgNoPeople} className="img-no-people" alt={1} />
        </div>
        <div className="single-reminder__text-content">
          <h3 className="single-reminder__title">
            {title}
            <span>{higlightedTitleText}</span>
          </h3>
          <p className="single-reminder__text">
            {date} {place}
          </p>
          <button type="button" className="single-reminder__close" />
        </div>
      </a>
    </div>
  );
}

export default SingleReminder;
